<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="sortedReviews"
          :items-per-page="25"
          :loading="isLoadingData"
          :hide-default-footer="false"
          :loading-text="$t('loadingText')"
          :no-data-text="$t('noData')"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: false,
            itemsPerPageOptions: [25, 50, -1],
            firstIcon: '$first',
            lastIcon: '$last',
            prevIcon: '$prev',
            nextIcon: '$next',
            itemsPerPageAllText: $t('tableFooterAllText'),
            itemsPerPageText: $t('tableFooterNbPerPageText'),
          }"
        >

          <template v-slot:[`item.survey`]="{ item }">
            <div v-if="byUuid[item.survey.poiId]">
              {{ byUuid[item.survey.poiId].name}}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <game-review-dialog :review-id="item.id" @saved="$emit('needs-refresh')" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import GameReviewDialog from './GameReviewDialog.vue';
import UserNotifications from '../../mixins/UserNotifications.vue';
import * as at from '../../store/actionTypes';

export default {
  components: {
    GameReviewDialog,
  },
  data() {
    return {
      games: [],
      game: {},
      isLoadingData: false,
      headers: [
        {
          text: '#',
          align: 'left',
          sortable: false,
          value: 'position',
        },
        {
          text: this.$t('tableHeaderTeamName'),
          value: 'team.name',
          sortable: false,
        },
        { text: this.$t('tableHeaderPOI'), value: 'survey', sortable: false },
        {
          text: this.$t('tableHeaderQuiz'),
          value: 'survey.title',
          sortable: false,
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
    };
  },
  props: {
    partyId: {
      type: String,
      default: null,
    },
    reviews: {
      type: Array,
      required: true,
    },
  },
  mixins: [UserNotifications],
  computed: {
    siteId() {
      return this.$route.params.siteId;
    },
    siteUuid() {
      return this.$store.getters['site/allSites'][this.siteId].uuid;
    },
    ...mapGetters('auth', ['token']),
    ...mapGetters('poi', ['byUuid']),
    sortedReviews() {
      const sorted = [];
      this.reviews
        .filter((item) => item.status === 'pending')
        .forEach((review, index) => {
          sorted.push(review);
          sorted[index].position = index + 1;
        });
      return sorted;
    },
  },
  methods: {
    ...mapActions({
      fetchGames: at.FETCH_PARTIES,
      getReviews: at.GET_REVIEWS,
      getPendingReviews: at.GET_PENDING_REVIEWS,
    }),
  },
  async mounted() {
    try {
      await this.$store.dispatch('poi/all', {
        siteId: this.siteId,
        token: this.token,
        status: 'enabled',
      });
    } catch (error) {
      this.errorNotification(this.$t('notificationFetchPartiesError'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "loadingText": "Chargement en cours",
    "noData": "Pas de données pour cette partie",
    "labelSelectParty": "Sélectionnez une partie",
    "correct": "Corriger",
    "tableHeaderTeamName": "Nom d'équipe",
    "tableHeaderPOI": "POI concerné",
    "tableHeaderQuiz": "Nom du quizz",
    "notificationFetchError": "Impossible de récupérer la liste des revues pour cette partie",
    "notificationFetchPartiesError": "Impossible de récupérer la liste des parties",
    "tableFooterAllText": "Toutes",
    "tableFooterNbPerPageText": "Corrections par page"
  },
  "en": {
    "loadingText": "Loading...",
    "noData": "No data for this game",
    "labelSelectParty": "Select a game",
    "correct": "Review",
    "tableHeaderTeamName": "Team name",
    "tableHeaderPOI": "POI",
    "tableHeaderQuiz": "Challenge name",
    "notificationFetchError": "Unable to retrieve reviews for this game",
    "notificationFetchPartiesError": "Unable to retrieve parties",
    "tableFooterAllText": "All",
    "tableFooterNbPerPageText": "Reviews per page"
  }
}
</i18n>
