<template>
  <v-card>

    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('toolbarTitle') }}</v-toolbar-title>
    </v-toolbar>
    <v-list two-line>
      <template>
        <div v-for="w in websites" :key="w.id">
          <v-list-item ripple>
            <v-list-item-avatar>

              <v-icon v-if="w.urlType === 'facebook'">mdi-facebook</v-icon>
              <v-icon v-else-if="w.urlType === 'twitter'">mdi-twitter</v-icon>
              <v-icon v-else-if="w.urlType === 'instagram'">mdi-instagram</v-icon>
              <v-icon v-else-if="w.urlType === 'snapchat'">mdi-isnapchat</v-icon>
              <v-icon v-else>mdi-web</v-icon>
            </v-list-item-avatar>

            <v-list-item-content @click="openWebsiteForm(w)">
              <v-list-item-title>{{ w.url }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon ripple @click="openDeleteWebsiteDialog(w)">
                <v-icon color="grey lighten-1">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </template>
    </v-list>
    <v-card-text v-if="!websites || websites.length === 0" class="header">{{ $t('header') }}
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="secondary"
        @click="openNewWebsiteForm()">
        {{ $t('btnAdd') }}
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialogOpen"
    >
      <contact-form
        v-if="currentWebsite"
        @close="closeWebsiteForm"
        @save="saveWebsite"
        :title="$t('formTitle')"
        :subtitle="$t('formSubtitle')"
        :options-list="optionsList"
        :initial-option="currentWebsite.urlType"
        :initial-value="currentWebsite.url"
        :id="currentWebsite.id"
        :options-label="$t('formOptionsLabel')"
        :value-label="$t('formValueLabel')"
      />
    </v-dialog>
    <v-dialog
      max-width="350"
      v-model="deleteDialogOpen"
    >
      <confirm-dialog
        :title="$t('dialogTitle')"
        :subtitle="$t('dialogSubtitle')"
        :cancelButtonTitle="$t('dialogCancelBtn')"
        :confirmButtonTitle="$t('dialogConfirmBtn')"
        @confirm="deleteWebsite()"
        @cancel="closeDeletionForm()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import ContactForm from '../base/ContactSelector.vue';
import ConfirmDialog from '../base/ConfirmationDialog.vue';

export default {
  components: { ContactForm, ConfirmDialog },
  props: {
    websites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogOpen: false,
      deleteDialogOpen: false,
      currentWebsite: null,
      websitesWithUUID: [],
      optionsList: [
        { text: this.$t('optionsWebsite'), value: 'website' },
        { text: this.$t('optionsFacebook'), value: 'facebook' },
        { text: this.$t('optionsTwitter'), value: 'twitter' },
        { text: this.$t('optionsInstagram'), value: 'instagram' },
        { text: this.$t('optionsOther'), value: 'other' },
      ],
    };
  },
  methods: {
    openNewWebsiteForm() {
      this.currentWebsite = { type: 'other', value: '', id: uuidv4() };
      this.dialogOpen = true;
    },

    openWebsiteForm(website) {
      this.currentWebsite = website;
      this.dialogOpen = true;
    },

    openDeleteWebsiteDialog(website) {
      this.currentWebsite = website;
      this.dialogOpen = false;
      this.deleteDialogOpen = true;
    },

    saveWebsite(website) {
      this.dialogOpen = false;
      const updatedWebsite = {
        urlType: website.option,
        id: website.id,
        url: website.value,
      };
      const websitesList = this.websites.filter((w) => (w.id !== website.id));
      websitesList.push(updatedWebsite);
      this.$emit('update', websitesList);
      this.currentWebsite = null;
    },

    deleteWebsite() {
      this.dialogOpen = false;
      this.deleteDialogOpen = false;
      const websitesList = this.websites.filter((c) => c.id !== this.currentWebsite.id);
      this.$emit('update', websitesList);
      this.currentWebsite = null;
    },

    closeDeletionForm() {
      this.currentWebsite = null;
      this.deleteDialogOpen = false;
    },

    closeWebsiteForm() {
      this.dialogOpen = false;
      this.currentWebsite = null;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "toolbarTitle": "Sites web et réseau sociaux",
    "header": "Gérez ici les Sites web et réseaux sociaux de ce lieu d'intérêt",
    "btnAdd": "Ajouter un site web",
    "formTitle": "Informations du site web",
    "formSubtitle": "Utilisez ce formulaire pour ajouter un site Web ou un réseau social",
    "formOptionsLabel": "Type",
    "formValueLabel": "Adresse Web (URL)",
    "dialogTitle": "Supprimer ce site web ?",
    "dialogSubtitle": "Cette action est irréversible",
    "dialogCancelBtn": "Annuler",
    "dialogConfirmBtn": "Supprimer",
    "optionsWebsite": "Site web",
    "optionsFacebook": "Facebook",
    "optionsTwitter": "Twitter",
    "optionsInstagram": "Instagram",
    "optionsOther": "Autre"
  },
  "en": {
    "toolbarTitle": "Websites and social networks",
    "header": "Manage websites and social network profiles for this POI",
    "btnAdd": "Add a website",
    "formTitle": "Website information",
    "formSubtitle": "Use this form to add a website or social network",
    "formOptionsLabel": "Type",
    "formValueLabel": "URL",
    "dialogTitle": "Delete this website?",
    "dialogSubtitle": "This cannot be undone",
    "dialogCancelBtn": "Cancel",
    "dialogConfirmBtn": "Delete",
    "optionsWebsite": "Website",
    "optionsFacebook": "Facebook",
    "optionsTwitter": "Twitter",
    "optionsInstagram": "Instagram",
    "optionsOther": "Other"
  }
}
</i18n>
