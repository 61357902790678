<template>
  <v-layout row justify-center>
    <v-dialog v-model="editorOpen" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="translation.name"
                  :placeholder="$t('placeholderFieldTitle')"
                  :rules="[rules.required, rules.maxLength]"
                  counter="255"
                  required
                  :label="$t('labelFieldTitle')"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="[
                    {
                      text: $t('itemFrench'),
                      value: 'fr_FR',
                    },
                    {
                      text: $t('itemEnglish'),
                      value: 'en_GB',
                    },
                    {
                      text: $t('itemGerman'),
                      value: 'de_DE',
                    },
                    {
                      text: $t('itemNL'),
                      value: 'nl_NL',
                    },
                    {
                      text: $t('itemItalian'),
                      value: 'it_IT',
                    },
                    {
                      text: $t('itemSpanish'),
                      value: 'es_ES',
                    },
                    {
                      text: $t('itemPortuguese'),
                      value: 'pt_PT',
                    }]"
                  menu-props="auto"
                  :label="$t('labelFieldLang')"
                  v-model="translation.locale"
                  hide-details
                />
              </v-col>
              <v-col cols="12" v-if="includeDescription === true">
                <vue-editor
                  v-model="translation.description"
                  :placeholder="$t('placeholderFieldDescription')"
                  :editorToolbar="customToolbar"
                  id="description-editor"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="currentDestination.enableHelpButton === true">
                <vue-editor
                  v-model="translation.faqText"
                  :placeholder="$t('placeholderFieldFaqText')"
                  :editorToolbar="customToolbar"
                  id="description-editor"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-if="currentDestination.hasOptin === true"
                  v-model="translation.optinText"
                  :label="$t('firstOptin')"
                  rows="1"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-if="currentDestination.hasSecondaryOptin === true"
                  v-model="translation.secondaryOptinText"
                  :label="$t('secondOptin')"
                  rows="1"
                />
              </v-col>
            </v-row>

          </v-container>
          <small>{{ $t('labelLegend') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="$emit('closeclicked')">
            {{ $t('btnCancel')}}
          </v-btn>
          <v-btn
            color="secondary"
            :disabled="!translationIsValid"
            text
            @click="save">
            {{ $t('btnSave')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import validator from '../../services/validation';

export default {
  components: { VueEditor },
  props: {
    initialTranslation: {
      type: Object,
      required: false,
      default: () => ({
        id: 0,
        locale: 'fr_FR',
        name: '',
        description: '',
        uuid: '',
      }),
    },
    includeDescription: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentDestination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customToolbar: [
        [
          'bold',
          'italic',
          'underline',
          { list: 'ordered' },
          { list: 'bullet' },
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { header: [1, 2, 3, false] },
        ],
      ],
      faqToolBar: [
        [
          'bold',
          'italic',
          'underline',
          { header: [1, 2, 3, false] },
          { list: 'ordered' },
          { list: 'bullet' },
          { align: '' },
          { align: 'center' },
          { align: 'right' },

        ],
      ],
      editorOpen: true,
      translation: {
        id: this.initialTranslation.id,
        name: this.initialTranslation.name,
        description: this.initialTranslation.description,
        locale: this.initialTranslation.locale,
        uuid: this.initialTranslation.uuid,
        optinText: this.initialTranslation.optinText,
        secondaryOptinText: this.initialTranslation.secondaryOptinText,
        faqText: this.initialTranslation.faqText,
      },
      rules: {
        required: (value) => !!value || this.$t('errorsValidationRequired'),
        maxLength: (value) => validator.ValidateMaxLength(value, 255) || this.$t(''),
      },
    };
  },
  computed: {
    translationIsValid() {
      if (
        this.rules.required(this.translation.name) === true
        && this.rules.maxLength(this.translation.name, 255) === true
      ) {
        return true;
      }
      return false;
    },
    title() {
      if (this.initialTranslation.name !== '') {
        return this.$t('titleEdit');
      }
      return this.$t('titleCreate');
    },
  },
  methods: {
    save() {
      if (!this.translationIsValid) {
        return;
      }
      this.$emit('savetranslation', this.translation);
    },
  },
};
</script>
<i18n>
{
  "fr": {
      "itemFrench": "Français",
      "itemEnglish": "Anglais",
      "itemGerman": "Allemand",
      "itemNL": "Néerlandais",
      "itemItalian": "Italien",
      "itemSpanish": "Espagnol",
      "itemPortuguese": "Portugais",
      "titleCreate": "Créer une traduction",
      "titleEdit": "Modifier une traduction",
      "errorsValidationRequired": "Ce champ ne peut pas être vide",
      "errorsValidationTooLong": "Nom trop long",
      "labelFieldTitle": "Titre du bandeau principal",
      "placeholderFieldTitle": "Titre du bandeau principal*",
      "labelFieldLang": "Langue*",
      "placeholderFieldDescription": "Description*",
      "labelLegend": "*Champs obligatoires",
      "btnCancel": "Annuler",
      "btnSave": "Enregistrer",
      "firstOptin": "Message du premier OptIn",
      "secondOptin": "Message du deuxième OptIn",
      "placeholderFieldFaqText": "Message d'aide/règles du jeu"
  },
  "en": {
      "itemFrench": "French",
      "itemEnglish": "English",
      "itemGerman": "German",
      "itemNL": "Dutch",
      "itemItalian": "Italian",
      "itemSpanish": "Spanish",
      "itemPortuguese": "Portuguese",
      "titleCreate": "Create a translation",
      "titleEdit": "Update a translation",
      "errorsValidationRequired": "This field cannot be empty",
      "errorsValidationTooLong": "Name too long",
      "labelFieldTitle": "Main panel title",
      "placeholderFieldTitle": "Main panel title*",
      "labelFieldLang": "Language*",
      "placeholderFieldDescription": "Description*",
      "labelLegend": "*Mandatory field",
      "btnCancel": "Cancel",
      "btnSave": "Save",
      "firstOptin": "Message of the first OptIn",
      "secondOptin": "Message of the second OptIn",
      "placeholderFieldFaqText": "Help message / rules"
  }
}
</i18n>
