/* eslint-disable no-param-reassign */
import client from '../../services/fetch';

import cfg from '../../config';

import * as gt from '../getterTypes';
import * as at from '../actionTypes';
import * as mt from '../mutationTypes';

export default {
  state: {
    rewardsCollection: [],
  },

  getters: {
    [gt.REWARDS_BY_ID](state) {
      const rewardsByID = {};
      state.rewardsCollection.forEach((r) => {
        rewardsByID[r.id] = r;
      });
      return rewardsByID;
    },
  },

  mutations: {
    [mt.DID_FETCH_REWARDS](state, rewards = []) {
      state.rewardsCollection = rewards;
    },
  },

  actions: {
    [at.FETCH_REWARDS]({ commit }, { destinationId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/rewards?destination_id=${destinationId}`;
        client.getJSON(url, {}, token).then((data) => {
          commit(mt.DID_FETCH_REWARDS, data);
          resolve();
        }).catch((error) => { reject(error); });
      });
    },
    [at.CREATE_REWARD](context, { reward, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/rewards`;
        client.postJSON(url, reward, token).then((savedReward) => {
          resolve(savedReward);
        }).catch((error) => { reject(error); });
      });
    },

    [at.UPDATE_REWARD](context, { reward, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/rewards/${reward.id}`;
        client.putJSON(url, reward, token).then((updatedReward) => {
          resolve(updatedReward);
        }).catch((error) => { reject(error); });
      });
    },

    [at.DELETE_REWARD](context, { rewardId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/rewards/${rewardId}`;
        client.deleteJSON(url, token).then(() => {
          resolve();
        }).catch((error) => { reject(error); });
      });
    },
    [at.UPLOAD_REWARD_PICTURE](context, { folder, file, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/pictures/raw?folder=${folder}`;
        const formData = new FormData();
        formData.append('picture', file, file.name);
        client.putFile(url, formData, token).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    async [at.DELETE_REWARD_PICTURE](context, { path }) {
      const url = `${cfg.gameApiUrl}/gameapi/v1/pictures/raw?path=${path}`;
      fetch(url);
    },
  },
};
