<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        ripple
        @click="dialog = true">
        <v-icon color="grey lighten-1">delete</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ subtitle }}
        <ul>
          <li>{{ $t('li1') }}</li>
          <li>{{ $t('li2') }}</li>
          <li>{{ $t('li3') }}</li>
        </ul>
        {{ $t('contentLineEnd') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="accent"
          text
          @click="dialog = false"
        >
          {{ $t('btnCancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="$emit('delete');dialog = false"
        >
          {{ $t('btnConfirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    title() {
      if (this.disabled === true) {
        return this.$t('disabledTitle');
      }
      return this.$t('title');
    },
    subtitle() {
      if (this.disabled === true) {
        return this.$t('disabledText');
      }
      return this.$t('contentLine1');
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "disabledTitle": "ATTENTION: PARTIE EN COURS",
    "disabledText": "Supprimer un défi avec une partie en cours réduit le score maximum atteignable par les équipes. Les données suivantes seront supprimées :",
    "title": "Supprimer ce défi ?",
    "contentLine1": "ATTENTION: La suppression de ce défi entraînera la suppression de toutes les données associées :",
    "li1": "Questions associées au défi",
    "li2": "Corrections en attente",
    "li3": "Historique des réponses utilisateurs",
    "contentLineEnd": "Les scoreboards des parties passées ne seront pas affectés.",
    "btnCancel": "Ne pas supprimer",
    "btnConfirm": "Supprimer"
  },
  "en": {
    "disabledTitle": "WARNING: GAME IN PROGRESS",
    "disabledText": "Removing a challenge while a game is in progress will reduce the maximum score reachable by teams that have not replied to this challenge. The following data will be removed: ",
    "title": "Delete this challenge ? ?",
    "contentLine1": "WARNING: REmoving this challenge will also remove all associated data:",
    "li1": "Questions associated with the challenge",
    "li2": "Pending reviews",
    "li3": "Hostory of the answers by the users",
    "contentLineEnd": "Scoreboards for the ended games will not be affected",
    "btnCancel": "Cancel",
    "btnConfirm": "Delete"
  }
}
</i18n>
