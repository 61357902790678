<template>
  <v-card>
    <v-img
      class="white--text"
      :src="picture"
      gradient="rgba(0,0,0, 0.5), rgba(0,0,0,0.2)"
      :aspect-ratio="aspectRatio">
      <v-container fill-height fluid>
        <v-layout fill-height>
          <v-flex xs12 align-end flexbox>
            <span class="headline">{{title}}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-img>
    <v-card-title primary-title>
      <div>
        <slot name="content" />
      </div>
    </v-card-title>
    <v-card-actions>
      <v-btn
        v-if="leftButtonTitle"
        :disabled="!leftButtonEnabled"
        @click="$emit('leftbuttonclicked')"
        text
        :color="leftButtonColor">{{leftButtonTitle}}
      </v-btn>
      <v-btn
        v-if="rightButtonTitle"
        :disabled="!rightButtonEnabled"
        @click="$emit('rightbuttonclicked')"
        text
        :color="rightButtonColor">{{rightButtonTitle}}
      </v-btn>
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    picture: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 2.75,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    leftButtonTitle: {
      type: String,
      required: false,
      default: () => 'Modifier',
    },
    leftButtonColor: {
      type: String,
      required: false,
      default: 'blue',
    },
    rightButtonTitle: {
      type: String,
      required: false,
      default: () => 'Supprimer',
    },
    rightButtonColor: {
      type: String,
      required: false,
      default: 'pink',
    },
    leftButtonEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    rightButtonEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
