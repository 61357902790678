<template>
  <div id="app">
    <v-app v-if="loaded">
      <v-system-bar color="primary darken-1" />
      <v-app-bar
        color="primary"
        dark
        app
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title>Dotmap - Administration</v-toolbar-title>

        <v-spacer />
        <v-btn icon @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      <nav-bar
        v-if="user"
        :user="user"
        :currentDestination="currentDestination"
        :currentDestinationId="currentSiteId"
        :destinations="sites"
        v-model="drawer" />
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';

import { mapState } from 'vuex';
import NavBar from './components/base/NavBar.vue';

export default {
  name: 'app',
  components: {
    NavBar,
  },
  data() {
    return {
      loaded: false,
      drawer: false,
    };
  },

  methods: {
    logout() {
      this.$store.commit('auth/logout');
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login', path: '/login' });
      }
    },
  },

  computed: {
    ...mapState('site', [
      'currentSiteId',
      'sites',
      'sitesLoadingSucceeded',
    ]),

    currentDestination() {
      if (this.currentSiteId === -1) {
        return {};
      }
      return this.sites[this.currentSiteId];
    },

    user() {
      return this.$store.getters['auth/user'];
    },

    authenticated() {
      return this.$store.getters['auth/authenticated'];
    },

    siteId() {
      if (this.$route.params.siteId) {
        return parseInt(this.$route.params.siteId, 10);
      }
      return -1;
    },
  },
  watch: {
    user() {
      if (this.user != null) {
        if (this.$store.getters['auth/token'] !== null) {
          this.$store.dispatch(
            'site/loadSites',
            this.$store.getters['auth/token'],
          ).catch((error) => {
            Sentry.captureException(error);
            this.logout();
            this.loaded = true;
          });
        }
      }
    },
  },

  created() {
    if (this.$store.getters['auth/token'] !== null) {
      this.$store.dispatch(
        'site/loadSites',
        this.$store.getters['auth/token'],
      ).then(() => {
        this.loaded = true;
      }).catch((error) => {
        Sentry.captureException(error);
        this.loaded = true;
      });
    } else {
      this.loaded = true;
      this.logout();
    }
  },

};
</script>
