/* eslint-disable no-param-reassign */

import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import api from '../../services/polls';

const mutations = {
  loadPolls(state, { placeId, polls }) {
    // Add an uuid to identify each poll when doing multiple edits  in the UI
    // before sending them on the server
    const pollsWithUUID = [];
    polls.forEach((p) => {
      p.uuid = uuidv4();
      pollsWithUUID.push(p);
    });
    Vue.set(state.polls, placeId, pollsWithUUID);
    const s = { ...state.polls };
    s[placeId] = pollsWithUUID;
    state.polls = s;
  },
};

const actions = {
  fetchPolls(context, { placeId, token }) {
    return new Promise((resolve, reject) => {
      api.PollsForPlace(placeId, token).then((polls) => {
        context.commit('loadPolls', { placeId, polls });
        resolve(polls);
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },
  save(context, { poll, token }) {
    return new Promise((resolve, reject) => {
      api.Save(poll, token).then((fetchedPoll) => {
        context.dispatch('fetchPolls', { placeId: fetchedPoll.place_id, token });
        resolve();
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },
  delete(context, { pollId, placeId, token }) {
    return new Promise((resolve, reject) => {
      api.Delete(pollId, token).then(() => {
        context.dispatch('fetchPolls', { placeId, token });
        resolve();
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },
};

const state = {
  polls: {},
};

const namespaced = true;

export default {
  state,
  mutations,
  actions,
  namespaced,
};
