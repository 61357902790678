<template>
  <v-dialog
    v-model="addDialog"
    width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        color="accent"
        v-bind="attrs"
        v-on="on">{{ $t('btnOpenEditor') }}</v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('pageTitle') }}</v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('labelFieldDomain')"
          v-model="domain"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent" @click="close">{{ $t('btnCancel') }}</v-btn>
        <v-btn color="primary" @click="domainAdded">{{ $t('btnAdd') }}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      domain: null,
      addDialog: false,
    };
  },
  methods: {
    domainAdded() {
      this.$emit('domainadded', this.domain);
      this.addDialog = false;
    },
    close() {
      this.domain = null;
      this.addDialog = false;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Ajouter un nom de domaine",
    "btnOpenEditor": "Ajouter un nom de domaine",
    "labelFieldDomain": "Domaine",
    "btnCancel": "Annuler",
    "btnAdd": "Ajouter"
  },
  "en": {
    "pageTitle": "Add a domain name",
    "btnOpenEditor": "Add a domain name",
    "labelFieldDomain": "Domain",
    "btnCancel": "Cancel",
    "btnAdd": "Add"
  }
}
</i18n>
