<template>
  <v-container fluid>
    <v-spacer />

    <div v-if="loading === true">
      <loading-dialog :open="loading" :text="$t('textLoading')" />
    </div>
    <div v-else-if="poi !== null">
      <v-row>
        <v-col cols="12">
          <h1>{{ pageTitle }}</h1>
        </v-col>
      </v-row>
      <v-row v-if="!isIdentifierValid && !isNew">
        <v-col cols="12">
          <v-alert
            :value="true"
            type="warning">
            {{ $t('warningIdentifierFormat') }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <identifier-card
            v-model="poi.name"
            :title="$t('titleIdentifierCard')"
            :subtitle="$t('subtitleIdentifierCard')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <categories-card
            :title="$t('titleCategoriesCard')"
            :subtitle="$t('subtitleCategoriesCard')"
            :available-categories="categories"
            :existing-categories="poi.categoryIds"
            :main-category="poi.categoryId"
            v-model="poi.categoryIds"
            :tags-value="poi.tags"
            @maincat="onMainCategorySet"
            @tags="onTagsChanged"
          />
        </v-col>
      </v-row>
      <v-row v-if="currentSite.platformType !== 'indoor'">
        <v-col cols="12">
          <is-qr-code
            :title="$t('titleIsQRCode')"
            :subtitle="$t('subtitleIsQRCode')"
            :poiCheckinType="poi.checkinType"
            @onCheckinChanged="changeCheckinType"
          />
        </v-col>
      </v-row>
      <v-row v-if="shouldDisplayMap === true">
        <v-col cols="12">
          <map-card
            :title="$t('titleMapCard')"
            :subtitle="$t('subtitleMapCard')"
            :initial-latitude="poi.latitude"
            :initial-longitude="poi.longitude"
            :initial-zoom="currentSite.zoom"
            @positionchanged="onPositionChanged"
          />
        </v-col>
      </v-row>
      <v-row v-if="shouldDisplayQRCode === true">
        <v-col cols="12">
          <qr-code-gen :place-uuid="poi ? poi.uuid : null" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <address-card
            v-if="currentSite.platformType !== 'indoor'"
            :title="$t('titleAddressCard')"
            :subtitle="$t('subtitleAddressCard')"
            :initial-street-address="poi.streetAddress"
            :initial-postal-code="poi.postalCode"
            :initial-city="poi.city"
            :initial-country="poi.country"
            @addresschanged="onAddressChanged"
            @geocode="onGeocodeRequest" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <translations-list
            type="place"
            :initial-translations="poi.locales"
            @transactionschanged="onTranslationsChanged" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <points-card
            :title="$t('titlePointsCard')"
            :subtitle="$t('subtitlePointsCard')"
            @update="onPointsChanged"
            :min="-1000"
            :max="1000"
            :initial-points="poi.points"
            :initial-lose-points-after-checkin="poi.losePointsAfterFirstCheckin"
            :initial-block-after-checkin="poi.blockAfterCheckin"
            :initial-block-delay="poi.blockDuration" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pictures-list
            :title="$t('titlePicturesList')"
            :subtitle="$t('subtitlePicturesList')"
            :pictures="pictures"
            @deletepicture="onDeletePicture"
            @updatepictures="onUpdatePicture"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <temporary-place
            :title="$t('titleTemporaryPlace')"
            :subtitle="$t('subtitleTemporaryPlace')"
            :initialIsTemporaryValue="poi.isTemporary"
            :initialStartDate="poi.startDate"
            :initialEndDate="poi.endDate"
            @temporaryplacechanged="onTemporaryPlaceChanged" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <vip-place
            :title="$t('titleVIPPlace')"
            :subtitle="$t('subtitleVIPPlace')"
            :initialIsVipValue="poi.requiresMinimumBalance"
            :initialPoints="poi.requiredBalance"
            @onvipplacechanged="onVipPlaceChanged" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pricing-information
            :title="$t('titlePricingInformation')"
            :subtitle="$t('subtitlePricingInformation')"
            :initial-minimum="poi.minimumPricing"
            :initial-maximum="poi.maximumPricing"
            @change="onPricingChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <youtube-video
            v-model="poi.videoUrl"
            :auto-play-status="poi.videoAutoPlay"
            :loop-status="poi.videoLoop"
            :initialVideoVisibilityValue="poi.videoVisibility"
            :choicesVideoVisibility="true"
            @autoplaychanged="poi.videoAutoPlay = !poi.videoAutoPlay"
            @loopchanged="poi.videoLoop = !poi.videoLoop"
            @videoVisibilityChanged="onVideoVisibilityChanged"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <audio-documents-list
            :title="$t('titleAudioDocuments')"
            :subtitle="$t('subtitleAudioDocuments')"
            :documents="poi.audioDocuments"
            @documentupdated="onUpsertDocument"
            @documentdeleted="onDeleteDocument"
          />
        </v-col>
      </v-row>
      <v-row v-if="currentSite.useGameApi === true">
        <v-col>
          <survey-list
            :api-base-url="gameapiBaseURL"
            :poi-uuid="poi ? poi.uuid : uuid"
            :token="token"
            :survey-display-mode="poi.surveyDisplayMode"
            :poi-is-new="isNew"
            @displaymodeupdated="onSurveyDisplayModeUpdated"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <contact-list
            :contacts="poi.contacts"
            @update="updateContacts"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <websites-list
            :websites="poi.websites"
            @update="updateWebsites"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            :disabled="!isValid"
            :ripple="true"
            @click="save"
            raised
            large
            :loading="saving"
            color="accent">{{ btnTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <loading-dialog :open="saving" :text="$t('textSaving')" />

    </div>
  </v-container>
</template>

<script>
/* eslint-disable max-len */
import { mapState, mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import validation from '../services/validation';
import UserNotifications from '../mixins/UserNotifications.vue';
import * as at from '../store/actionTypes';
import config from '../config';
import geocoding from '../services/geocoding';

import MapCard from '../components/base/MapCard.vue';
import IdentifierCard from '../components/base/IdentifierCard.vue';
import CategoriesCard from '../components/place/CategoriesCard.vue';
import PointsCard from '../components/place/PointsCard.vue';
import TranslationsList from '../components/base/ObjectTranslationsList.vue';
import TemporaryPlace from '../components/place/TemporaryPlace.vue';
import VipPlace from '../components/place/VIPPlace.vue';
import AddressCard from '../components/place/PlaceAddress.vue';
import PicturesList from '../components/pictures/PictureList.vue';
import ContactList from '../components/place/ContactList.vue';
import WebsitesList from '../components/place/WebsitesList.vue';
import PricingInformation from '../components/place/PricingInformation.vue';
import SurveyList from '../components/survey/SurveyList.vue';
import YoutubeVideo from '../components/video/YoutubeVideo.vue';
import AudioDocumentsList from '../components/audio/DocumentsList.vue';
import LoadingDialog from '../components/base/LoadingDialog.vue';
import QrCodeGen from '../components/place/PlaceQRCode.vue';
import isQrCode from '../components/place/IsQRCode.vue';

export default {

  components: {
    MapCard,
    IdentifierCard,
    PointsCard,
    TranslationsList,
    TemporaryPlace,
    VipPlace,
    AddressCard,
    PicturesList,
    CategoriesCard,
    ContactList,
    WebsitesList,
    PricingInformation,
    SurveyList,
    YoutubeVideo,
    AudioDocumentsList,
    LoadingDialog,
    QrCodeGen,
    isQrCode,
  },

  props: {
    site: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  mixins: [UserNotifications],

  data() {
    return {
      poi: null,
      uuid: uuidv4(),
      loading: true,
      saving: false,
      pictures: [],
      picturesCreationQueue: [],
      picturesUpdateQueue: [],
      picturesDeleteQueue: [],
      audioDocumentsCreationQueue: [],
      audioDocumentsUpdateQueue: [],
      audioDocumentsDeletionQueue: [],
      checkinQrCode: false,
    };
  },
  computed: {

    ...mapGetters('auth', [
      'token',
    ]),
    ...mapGetters('poi', [
      'byId',
    ]),
    ...mapState({
      categories: (state) => state.categories.categoriesList,
    }),
    btnTitle() {
      if (this.saving === true) {
        return this.$t('btnTitleSave');
      }
      return this.$t('btnTitleIdle');
    },
    gameapiBaseURL() {
      return config.gameApiUrl;
    },
    siteId() {
      return parseInt(this.$route.params.siteId, 10);
    },
    place() {
      if (!this.$route.params.itemId) {
        return null;
      }
      return this.$store.getters['poi/byId'][this.$route.params.itemId];
    },
    currentPoiId() {
      return this.$route.params.itemId;
    },
    currentSite() {
      return this.$store.getters['site/allSites'][this.$route.params.siteId];
    },
    shouldDisplayMap() {
      if (!this.currentSite) {
        return false;
      }
      if (this.currentSite.platformType === 'indoor') {
        return false;
      }
      return true;
    },
    shouldDisplayQRCode() {
      if (!this.poi) {
        return false;
      }
      if (!this.currentSite) {
        return false;
      }
      if (this.currentSite.platformType !== 'indoor' && this.poi.checkinType !== 'qrcode') {
        return false;
      }
      return true;
    },
    isValid() {
      if (!this.poi) {
        return false;
      }
      if (this.poi.latitude === 0.0 && this.poi.longitude === 0.0) {
        return false;
      }
      if (validation.ValidateIdentifier(this.poi.name) === false) {
        return false;
      }
      return true;
    },

    isIdentifierValid() {
      return this.poi && validation.ValidateIdentifier(this.poi.name);
    },

    isNew() {
      if (!this.$route.params.itemId) {
        return true;
      }
      return false;
    },

    pageTitle() {
      if (this.isNew) {
        return this.$t('pageTitleCreate');
      }
      return this.$t('pageTitleUpdate');
    },
    initialIsQRCodeValue() {
      if (this.poi.checkinType === 'gps') {
        return false;
      }
      return true;
    },
  },

  async mounted() {
    try {
      await this.populateState();
      if (!this.isNew) {
        const p = this.byId[this.currentPoiId];
        if (p) {
          this.poi = p;
        }
      } else {
        this.poi = this.defaultPOI();
      }
      this.loading = false;
    } catch (error) {
      this.errorNotification(this.$t('notificationErrorFetching'), error);
      this.loading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchCategories: at.FETCH_CATEGORIES,
      fetchSites: 'site/loadSites',
      fetchPOIs: 'poi/all',
      createPOI: 'poi/create',
      updatePOI: 'poi/update',
      createPicture: 'poi/createPicture',
      updatePicture: 'poi/updatePicture',
      deletePicture: 'poi/deletePicture',
      createAudioDocument: 'poi/createAudioDocument',
      updateAudioDocument: 'poi/updateAudioDocument',
      deleteAudioDocument: 'poi/deleteAudioDocument',

    }),
    onSurveyDisplayModeUpdated(e) {
      this.poi.surveyDisplayMode = e;
    },
    async populateState() {
      await this.fetchSites(this.token);
      await this.fetchCategories({ siteId: this.siteId, token: this.token });
      if (this.$route.params.itemId) {
        await this.fetchPOIs({ siteId: this.siteId, token: this.token, status: 'enabled' });
        // populate initial data
        if (this.place) {
          this.identifier = this.place.name;
          this.pictures = this.place.pictures;
        }
      }
    },
    changeCheckinType(value) {
      this.poi.checkinType = value;
    },
    defaultPOI() {
      return {
        id: null,
        uuid: uuidv4(),
        siteId: this.siteId,
        name: null,
        latitude: this.currentSite.latitude,
        longitude: this.currentSite.longitude,
        points: null,
        categoryId: null,
        checkinType: 'gps',
        isTemporary: false,
        endDate: null,
        startDate: null,
        locales: {},
        enabled: true,
        requiresMinimumBalance: false,
        requiredBalance: 0,
        appearsOnlyOnEvent: false,
        source: 'dotmap',
        sourceId: null,
        streetAddress: '',
        postalCode: '',
        city: '',
        country: '',
        accessibilityInfoUrl: null,
        geohash: null,
        versionHash: null,
        schedule: null,
        categoryIds: [],
        websites: [],
        contacts: [],
        serviceIds: [],
        minimumPricing: 0,
        maximumPricing: 0,
        tags: [],
        icon: null,
        videoUrl: null,
        videoAutoPlay: false,
        videoLoop: false,
        videoVisibilty: 'always',
        audioDocuments: [],
        surveyDisplayMode: 'all',
        blockAfterCheckin: false,
        blockDuration: 0,
      };
    },
    async save() {
      this.saving = true;
      this.removeContactIDs();
      this.removeWebsiteIDs();
      if (this.isNew === true) {
        try {
          const savedPlace = await this.createPOI(
            { siteId: this.siteId, poi: this.poi, token: this.token },
          );
          await this.savePictures(savedPlace.id);
          await this.deletePictures();
          await this.saveAudioDocuments(savedPlace.id);
          this.saving = false;
          this.successNotification(this.$t('notificationSaveSuccess'));
          this.redirect();
        } catch (error) {
          this.redirect();
          this.saving = false;
          this.errorNotification(this.$t('notificationSaveError'), error);
        }
      } else {
        try {
          await this.updatePOI({ siteId: this.siteId, poi: this.poi, token: this.token });
          await this.savePictures(this.place.id);
          await this.saveAudioDocuments(this.place.id);
          await this.deletePictures();
          this.saving = false;
          this.successNotification(this.$t('notificationSaveSuccess'));
          this.redirect();
        } catch (error) {
          this.saving = false;
          this.errorNotification(this.$t('notificationSaveError'), error);
        }
      }
    },
    removeContactIDs() {
      const contacts = [];
      this.poi.contacts.forEach((c) => {
        const newContact = { type: c.type, value: c.value };
        contacts.push(newContact);
      });
      this.poi.contacts = contacts;
    },
    removeWebsiteIDs() {
      const websites = [];
      this.poi.websites.forEach((c) => {
        const website = { urlType: c.urlType, url: c.url };
        websites.push(website);
      });
      this.poi.websites = websites;
    },
    redirect() {
      this.$router.push({ name: 'placesList', params: { id: this.siteId } });
    },

    async savePictures(poiID) {
      this.picturesCreationQueue.forEach(async (p) => {
        try {
          await this.createPicture({
            siteId: this.siteId, poiID, picture: p, token: this.token,
          });
        } catch (error) {
          this.errorNotification(`${this.$t('notificationCannotCreatePicture')}: ${error}`, error);
        }
      });
      this.picturesUpdateQueue.forEach(async (p) => {
        try {
          await this.updatePicture({
            siteId: this.siteId, poiID, picture: p, token: this.token,
          });
        } catch (error) {
          this.errorNotification(`${this.$t('notificationCannotUpdatePicture')}: ${error}`, error);
        }
      });
    },
    async deletePictures() {
      this.picturesDeleteQueue.forEach(async (picUUID) => {
        await this.deletePicture({ siteId: this.siteId, pictureId: picUUID, token: this.token });
      });
    },
    async saveAudioDocuments(poiID) {
      this.audioDocumentsCreationQueue.forEach(async (d) => {
        await this.createAudioDocument(
          {
            destinationId: this.siteId,
            parentType: 'poi',
            parentID: poiID,
            audioDoc: d,
            token: this.token,
          },
        );
      });
      this.audioDocumentsUpdateQueue.forEach(async (d) => {
        await this.updateAudioDocument(
          {
            destinationId: this.siteId,
            document: d,
            token: this.token,
          },
        );
      });
      this.audioDocumentsDeletionQueue.forEach(async (d) => {
        await this.deleteAudioDocument(
          {
            destinationId: this.siteId,
            docId: d.id,
            token: this.token,
          },
        );
      });
    },
    updateContacts(contacts) {
      this.poi.contacts = contacts;
    },
    updateWebsites(websites) {
      this.poi.websites = websites;
    },
    onPositionChanged(newCoords) {
      console.log('position changed', newCoords);
      this.poi.latitude = parseFloat(newCoords.latitude);
      this.poi.longitude = parseFloat(newCoords.longitude);
    },
    onCategoryChanged(newCat) {
      this.category = newCat;
    },
    onAddressChanged(newAddress) {
      this.poi.streetAddress = newAddress.streetAddress;
      this.poi.postalCode = newAddress.postalCode;
      this.poi.city = newAddress.city;
      this.poi.country = newAddress.country;
    },
    onTagsChanged(newTags) {
      this.poi.tags = newTags;
    },
    onMainCategorySet(newCategory) {
      this.poi.categoryId = newCategory;
    },

    /**
     * Event handler for @transactionschanged
     */
    onTranslationsChanged(newTranslations) {
      this.poi.locales = newTranslations;
    },

    /**
     * Event handler for @temporaryplacechanged
     */
    onTemporaryPlaceChanged(newValue) {
      this.poi.isTemporary = newValue.isTemporary;
      if (newValue.isTemporary === true) {
        this.poi.startDate = newValue.startDate;
        this.poi.endDate = newValue.endDate;
      }
    },
    onVideoVisibilityChanged(newValue) {
      this.poi.videoVisibility = newValue;
    },
    onVipPlaceChanged(newValue) {
      this.poi.requiresMinimumBalance = newValue.requiresMinimumBalance;
      this.poi.requiredBalance = newValue.requiredBalance;
    },
    onPricingChange(value) {
      this.poi.minimumPricing = value.minimum;
      this.poi.maximumPricing = value.maximum;
    },
    onUpdatePicture(pictures) {
      pictures.forEach((picture) => {
        if (picture.isNew) {
          const picturesCreateQueue = this.picturesCreationQueue.filter(
            (p) => p.uuid !== picture.uuid,
          );
          picturesCreateQueue.push(picture);
          this.picturesCreationQueue = picturesCreateQueue;
        } else {
          // remove the picture from the queue if it exists to avoid a double update
          const picturesUpdateQ = this.picturesUpdateQueue.filter((p) => p.uuid !== picture.uuid);
          picturesUpdateQ.push(picture);
          this.picturesUpdateQueue = picturesUpdateQ;
        }
      });
    },
    onDeletePicture(picture) {
      if (!picture.isNew) {
        this.picturesDeleteQueue.push(picture.uuid);
      }
    },
    onUpsertDocument(document) {
      if (document.isNew) {
        // new document. The goal is to add it to the CreationQueue. But it might already be there.
        // if the user edits an unsaved document. So we actually replace it if we find it.
        // We also push it to the poi.AudioDocuments list since it's the display source
        this.audioDocumentsCreationQueue = this.audioDocumentsCreationQueue.filter(
          (d) => d.uuid !== document.uuid,
        );
        this.audioDocumentsCreationQueue.push(document);
        this.poi.audioDocuments = this.poi.audioDocuments.filter((d) => d.uuid !== document.uuid);
        this.poi.audioDocuments.push(document);
      } else {
        // existing document. add it to the update queue and push it.
        // Also replace it in the poi.audioDocumentsList since it's the display source
        this.audioDocumentsUpdateQueue = this.audioDocumentsUpdateQueue.filter(
          (d) => d.uuid !== document.uuid,
        );
        this.poi.audioDocuments = this.poi.audioDocuments.filter((d) => d.uuid !== document.uuid);
        this.poi.audioDocuments.push(document);
        this.audioDocumentsUpdateQueue.push(document);
      }
    },
    onPointsChanged(event) {
      this.poi.points = event.points;
      this.poi.losePointsAfterFirstCheckin = event.losePointsAfterFirstCheckin;
      this.poi.blockAfterCheckin = event.blockAfterCheckin;
      this.poi.blockDuration = event.blockDelay;
    },
    onDeleteDocument(document) {
      if (document.isNew) {
        this.audioDocumentsCreationQueue = this.audioDocumentsCreationQueue.filter(
          (d) => d.uuid !== document.uuid,
        );
      } else {
        this.audioDocumentsUpdateQueue = this.audioDocumentsUpdateQueue.filter(
          (d) => d.id !== document.id,
        );
        this.audioDocumentsDeletionQueue.push(document);
      }
      this.poi.audioDocuments = this.poi.audioDocuments.filter((d) => d.id !== document.id);
    },
    async onGeocodeRequest() {
      const addrString = `${this.poi.streetAddress},${this.poi.postalCode},${this.poi.city},${this.poi.country}`;

      try {
        const res = await geocoding.geocode(this.siteId, addrString, this.token);
        if (res.Found === true) {
          this.onPositionChanged({ longitude: res.Longitude, latitude: res.Latitude });
          this.successNotification(this.$t('notificationUpdatedPosition'));
        }
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorUpdatingPosition'), error);
      }
    },

  },

};
</script>
<i18n>
{
  "fr": {
    "textLoading": "Chargement du lieu d'intérêt...",
    "warningIdentifierFormat": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer tous les caractères&nbsp; accentués, apostrophes et espaces",
    "titleIdentifierCard": "Identifiant du lieu d'intérêt",
    "subtitleIdentifierCard": "Choisissez un identifiant unique pour ce lieu d'intérêt",
    "titleCategoriesCard": "Catégories",
    "subtitleCategoriesCard": "Associez des catégories à ce lieu",
    "titleMapCard": "Position du lieu",
    "subtitleMapCard": "Faites glisser le marqueur à la position du lieu d'intérêt",
    "titleAddressCard": "Adresse postale",
    "subtitleAddressCard": "Saisissez l'adresse postale du lieu d'intérêt",
    "titlePointsCard": "Points du lieu d'intérêt",
    "subtitlePointsCard": "Sélectionnez le nombre de points gagnés en se géolocalisant sur le lieu d'intérêt",
    "titlePicturesList": "Photos du lieu d'intérêt",
    "subtitlePicturesList": "Faites glisser les photos pour les réorganiser",
    "titleTemporaryPlace": "Lieu temporaire",
    "subtitleTemporaryPlace": "Définissez les dates de début/fin du lieu si vous souhaitez le rendre temporaire",
    "titleVIPPlace": "Lieu VIP",
    "subtitleVIPPlace": "Définissez un solde minimum de points pour que ce lieu apparaisse sur la carte.",
    "titlePricingInformation": "Informations de tarification",
    "subtitlePricingInformation": "Ces tarifs ne sont pas affichées mais permettent de filtrer les POIs par prix",
    "titleAudioDocuments": "Documents audio",
    "subtitleAudioDocuments": "Attachez des fichiers audio à ce POI",
    "btnTitleSave": "Sauvegarde en cours",
    "btnTitleIdle": "Sauvegarder le lieu d'intérêt",
    "pageTitleCreate": "Créer un lieu d'intérêt",
    "pageTitleUpdate": "Mettre à jour un lieu d'intérêt",
    "notificationErrorFetching": "Impossible de charger les données nécessaires.",
    "notificationSaveSuccess": "Lieu d'intérêt sauvegardé",
    "notificationSaveError":"Impossible de sauvegarder le lieu d'intérêt",
    "notificationSaveQuizError": "Impossible de sauvegarder les quizz",
    "notificationUpdatedPosition": "Position mise à jour sur la carte",
    "notificationErrorUpdatingPosition": "Impossible de géocoder cette addresse",
    "textSaving": "Enregistrement en cours...",
    "notificationCannotCreatePicture": "Impossible de créer l'image",
    "notificationCannotUpdatePicture": "Impossible de mettre à jour l'image",
    "titleIsQRCode": "Lieu avec QR Code ",
    "subtitleIsQRCode": "Choissisez si le lieu est uniquement localisable avec le QR Code"
  },
  "en": {
    "textLoading": "Loading...",
    "warningIdentifierFormat": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer tous les caractères&nbsp; accentués, apostrophes et espaces",
    "titleIdentifierCard": "POI Identifier",
    "subtitleIdentifierCard": "Choose a unique identifier for this POI",
    "titleCategoriesCard": "Categories",
    "subtitleCategoriesCard": "Attach categories to this POI",
    "titleMapCard": "POI location",
    "subtitleMapCard": "Drag the marker at the position of the POI on the map",
    "titleAddressCard": "Postal address",
    "subtitleAddressCard": "Enter the POI's postal address",
    "titlePointsCard": "POI Points",
    "subtitlePointsCard": "Define the number of points earned by checking in on the POI",
    "titlePicturesList": "POI Pictures",
    "subtitlePicturesList": "Drag the pictures to reorder them",
    "titleTemporaryPlace": "Temporary POI",
    "subtitleTemporaryPlace": "Define the start and end dates between which the POI will be displayed",
    "titleVIPPlace": "VIP POI",
    "subtitleVIPPlace": "Define the required amount of points to display the POI to the user",
    "titlePricingInformation": "Pricing information",
    "subtitlePricingInformation": "This prices are not displayed but allow filtering the POIs by prices",
    "titleAudioDocuments": "Audio documents",
    "subtitleAudioDocuments": "Attach audio documents to this POI",
    "btnTitleSave": "Saving...",
    "btnTitleIdle": "Save this POI",
    "pageTitleCreate": "Create a POI",
    "pageTitleUpdate": "Update a POI",
    "notificationErrorFetching": "Unable to load data",
    "notificationSaveSuccess": "POI saved",
    "notificationSaveError":"Unable to save the POI",
    "notificationSaveQuizError": "Unable so save challenges",
    "notificationUpdatedPosition": "Location updated on the map",
    "notificationErrorUpdatingPosition": "Unable to geocode this address",
    "textSaving": "Saving...",
    "notificationCannotCreatePicture": "Unable to create the picture",
    "notificationCannotUpdatePicture": "Unable to update the picture",
    "titleIsQRCode": "POI with a QR Code",
    "subtitleIsQRCode": "Choose if the POI should be checked in with QR Code"
  }
}
</i18n>
