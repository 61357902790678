<template>
  <v-container fluid v-if="destination">
    <loader-dg :open="loading" :text="$t('loadingText')" />
    <v-spacer />
    <v-row>

      <v-col cols="12">
        <h1 class="text-h2">{{ $t('title') }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="6" lg="4" xl="3" v-for="r in rewards" :key="r.id">
        <reward-card
          :reward="r"
          :destination-id="destinationId" />
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        color="accent"
        dark
        fab
        fixed
        bottom
        right
        :to="{ name: 'newrewardsEditor', params: { destination: this.destination } }">
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import LoaderDg from '../components/base/LoadingDialog.vue';
import RewardCard from '../components/reward/RewardCard.vue';
import { FETCH_DESTINATIONS, FETCH_REWARDS } from '../store/actionTypes';
import Notifications from '../mixins/UserNotifications.vue';
import cfg from '../config';

export default {
  components: { RewardCard, LoaderDg },
  mixins: [Notifications],
  data() {
    return {
      deleteDialogOpen: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      rewards: (state) => state.rewards.rewardsCollection,
      sites: (state) => state.site.sitesList,
      destinations: (state) => state.destinations.destinations,
    }),
    ...mapGetters('auth', [
      'token',
    ]),
    destination() {
      const dests = this.destinations.filter((s) => s.uuid === this.destinationId);
      return dests[0];
    },
    destinationId() {
      return this.$route.params.destinationId;
    },
  },
  methods: {
    ...mapActions({
      fetchDestinations: FETCH_DESTINATIONS,
      fetchRewards: FETCH_REWARDS,
    }),
  },

  async mounted() {
    this.loading = true;
    try {
      await this.fetchDestinations({ baseUrl: cfg.apiUrl, token: this.token });
      await this.fetchRewards({ destinationId: this.destinationId, token: this.token });
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.errorNotification(this.$t('errorInitialFetching'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Les récompenses",
    "errorInitialFetching": "Impossible de récupérer les données nécessaires",
    "loadingText": "Chargement des récompenses..."
  },
  "en": {
    "title": "Rewards",
    "errorInitialFetching": "Unable to fetch server data",
    "loadingText": "loading rewards..."
  }
}
</i18n>
