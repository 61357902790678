<template>
  <v-layout row justify-center>
    <v-dialog v-model="editorOpen" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="translation.name"
                  single-line
                  :placeholder="$t('titleLabel')"
                  :rules="[rules.required, rules.maxLength]"
                  counter="255"
                  required
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="[
                    {
                      text: $t('itemFrench'),
                      value: 'fr_FR',
                    },
                    {
                      text: $t('itemEnglish'),
                      value: 'en_GB',
                    },
                    {
                      text: $t('itemGerman'),
                      value: 'de_DE',
                    },
                    {
                      text: $t('itemNL'),
                      value: 'nl_NL',
                    },
                    {
                      text: $t('itemItalian'),
                      value: 'it_IT',
                    },
                    {
                      text: $t('itemSpanish'),
                      value: 'es_ES',
                    },
                    {
                      text: $t('itemPortuguese'),
                      value: 'pt_PT',
                    }]"
                  menu-props="auto"
                  :label="$t('langSelectLabel')"
                  v-model="translation.locale"
                  hide-details
                  single-line
                />
              </v-flex>
              <v-flex xs12 v-if="includeDescription === true">
                <vue-editor
                  v-model="translation.description"
                  :placeholder="$t('descriptionPlaceholder')"
                  :editorToolbar="customToolbar"
                  id="description-editor"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="translation.scheduleInformation"
                  single-line
                  :placeholder="$t('schedulePlaceholder')"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="translation.guidingInstructions"
                  single-line
                  :placeholder="$t('guidingInstructions')"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <small>{{ $t('mandatoryFields') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="$emit('closeclicked')">{{ $t('cancelBtn') }}</v-btn>
          <v-btn
            color="secondary"
            :disabled="!translationIsValid"
            text
            @click="save">{{ $t('saveBtn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import validator from '../../services/validation';

export default {
  components: { VueEditor },
  props: {
    initialTranslation: {
      type: Object,
      required: false,
      default: () => ({
        id: 0,
        locale: 'fr_FR',
        name: '',
        description: '',
        uuid: '',
        scheduleInformation: '',
        guidingInstructions: '',
      }),
    },
    includeDescription: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      customToolbar: [
        [
          'bold',
          'italic',
          'underline',
          { list: 'ordered' },
          { list: 'bullet' },
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { header: [1, 2, 3, false] },
        ],
      ],
      editorOpen: true,
      translation: {
        id: this.initialTranslation.id,
        name: this.initialTranslation.name,
        description: this.initialTranslation.description,
        locale: this.initialTranslation.locale,
        uuid: this.initialTranslation.uuid,
        scheduleInformation: this.initialTranslation.scheduleInformation,
        guidingInstructions: this.initialTranslation.guidingInstructions,
      },
      rules: {
        required: (value) => !!value || this.$t('errRequired'),
        maxLength: (value) => validator.ValidateMaxLength(value, 255) || this.$t('errTooLong'),
      },
    };
  },
  computed: {
    translationIsValid() {
      if (
        this.rules.required(this.translation.name) === true
        && this.rules.maxLength(this.translation.name, 255) === true
      ) {
        return true;
      }
      return false;
    },
    title() {
      if (this.initialTranslation.name !== '') {
        return this.$t('titleUpdate');
      }
      return this.$t('titleCreate');
    },
  },
  methods: {
    save() {
      if (!this.translationIsValid) {
        return;
      }
      this.$emit('savetranslation', this.translation);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "itemFrench": "Français",
    "itemEnglish": "Anglais",
    "itemGerman": "Allemand",
    "itemNL": "Néerlandais",
    "itemItalian": "Italien",
    "itemSpanish": "Espagnol",
    "itemPortuguese": "Portugais",
    "langSelectLabel": "Langue*",
    "titleLabel": "Titre*",
    "descriptionPlaceholder": "Description*",
    "schedulePlaceholder": "Horaires",
    "mandatoryFields": "*Champs obligatoires",
    "cancelBtn": "Annuler",
    "saveBtn": "Enregistrer",
    "titleCreate": "Créer une traduction",
    "titleUpdate": "Modifier une traduction",
    "errRequired": "Ce champ ne peut pas etre vide",
    "errTooLong": "Nom trop long",
    "guidingInstructions": "Instructions de guidage"
  },
  "en": {
    "itemFrench": "French",
    "itemEnglish": "English",
    "itemGerman": "German",
    "itemNL": "Dutch",
    "itemItalian": "Italian",
    "itemSpanish": "Spanish",
    "itemPortuguese": "Portuguese",
    "langSelectLabel": "Language*",
    "titleLabel": "Title*",
    "descriptionPlaceholder": "Description*",
    "schedulePlaceholder": "Schedule",
    "mandatoryFields": "*Mandatory fields",
    "cancelBtn": "Cancel",
    "saveBtn": "Save",
    "titleCreate": "Create a translation",
    "titleUpdate": "Update a translation",
    "errRequired": "This field cannot be empty",
    "errTooLong": "Too long",
    "guidingInstructions": "Guiding instructions"
  }
}
</i18n>
