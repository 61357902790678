const LOG_TYPE = Object.freeze({
  BONUS: 'bonus',
});

const PARTY_STATUS = Object.freeze({
  STARTED: 'started',
  PENDING: 'pending',
  ENDED: 'ended',
});

export { LOG_TYPE, PARTY_STATUS };
