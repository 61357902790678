<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ $t('qrCodeBtn') }}</v-list-item-title>
      </v-list-item>
    </template>
    <qrcode-team
      :domain="domain"
      :token="token"
      :platform-type="platformType"
      :short-code="shortCode"
      @close="close" />
  </v-dialog>
</template>
<script>
import QrcodeTeam from '../QrcodeTeam.vue';

export default {
  components: {
    QrcodeTeam,
  },
  props: {
    domain: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    platformType: {
      type: String,
      required: true,
    },
    shortCode: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "qrCodeBtn": "Voir le QR Code"
    },
    "en": {
      "qrCodeBtn": "Show the QR Code"
    }
  }
</i18n>
