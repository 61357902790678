export const QUESTION_TYPE = Object.freeze({
  IMAGE: 'image',
  TEXT: 'text',
  YOUTUBE: 'youtube',
  OPEN: 'open',
  PHOTO: 'photo',
});

export const LANG = Object.freeze({
  FR: 'fr_FR',
});

export const THEME = Object.freeze({
  INACTIVE: 'inactive',
  ACTIVE: 'active',
});
