import Config from '../../config';

const getters = {
  sitesImagesPath(state) {
    return `${state.imagesRoot}${state.imagesSitePrefix}`;
  },
  placesImagesPath(state) {
    return `${state.imagesRoot}${state.imagesPlacesPrefix}`;
  },
  rewardsImagesPath(state) {
    return `${state.imagesRoot}${state.imagesRewardsPrefix}`;
  },
  toursImagesPath(state) {
    return `${state.imagesRoot}${state.imagesToursPrefix}`;
  },
};
const state = Object.assign(Config);
const namespaced = true;

export default {
  namespaced,
  state,
  getters,
};
