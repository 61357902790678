<template>
  <v-card class="mb-12" flat>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="3" class="font-weight-black">{{ $t('titlePartyName') }}</v-col>
          <v-col>{{ game.name }}</v-col>
        </v-row>
        <v-row v-if="currentDestination">
          <v-col cols="3" class="font-weight-black">{{ $t('titlePlatform') }}</v-col>
          <v-col>{{ currentDestination.name }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="font-weight-black">{{ $t('titleTeams') }}</v-col>
          <v-col>{{ $tc('team', teams.length)}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="font-weight-black">{{ $t('titleDates') }}</v-col>
          <v-col>
            {{ $t('from') }} {{ $d(game.startDate, 'short', $i18n.locale) }}
            {{ $t('to') }} {{ $d(game.endDate, 'short', $i18n.locale) }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    game: {
      type: Object,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('site', ['currentSiteId', 'sites', 'sitesLoadingSucceeded']),
    currentDestination() {
      if (this.game.destinationId === null) {
        return {};
      }
      return this.sites[this.game.destinationId];
    },
  },
  methods: {
    formatDate(rfcDate) {
      return this.$d(rfcDate, 'short', this.$i18n.locale);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Récapitulatif",
    "titlePartyName": "Nom de la partie",
    "titlePlatform": "Destination",
    "titleTeams": "Equipes",
    "titleDates": "Dates",
    "from": "Du",
    "to": "au",
    "team": "Une équipe | {count} équipes"
  },
  "en": {
    "title": "Summary",
    "titlePartyName": "Game name",
    "titlePlatform": "Destination",
    "titleTeams": "Teams",
    "titleDates": "Dates",
    "from": "From",
    "to": "To",
    "team": "One team | {count} teams"
  }
}
</i18n>
