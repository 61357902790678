<template>
  <v-card flat>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field
                :label="$t('fieldTeamName')"
                counter="1024"
                required
                v-model="team.name"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="email"
                :label="$t('fieldTeamEmail')"
                counter="1024"
                v-model="team.email"
              />
            </v-col>
            <v-col cols="12">
              <team-color-picker v-model="team.color" />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="team.sendWelcomeEmail"
                :label="$t('labelCheckboxEmail')" />
            </v-col>
          </v-row>
          <game-alert-credit
            @handleCheckedState="updateCheckedState($event)"
            :teamsLength="teamsLength"
            :organizationUseCredits="organizationUseCredits"
            :organizationCreditBalance="organizationCreditBalance"
            :gameIsTest="gameIsTest"
            :checked="creditRuleChecked"
            @onCheck="creditRuleChecked = !creditRuleChecked"
          />
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="$emit('close')"> Annuler</v-btn>
      <v-btn text :disabled="valid === false || (organizationUseCredits && organizationCreditBalance <= 0) && creditRuleChecked === false" color="accent" @click="addTeam()">Ajouter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TeamColorPicker from './TeamColorPicker.vue';
import { CREATE_TEAMS } from '../../store/actionTypes';
import UserNotifs from '../../mixins/UserNotifications.vue';
import GameAlertCredit from './GameAlertCredit.vue';

export default {
  components: {
    TeamColorPicker,
    GameAlertCredit,
  },
  mixins: [UserNotifs],
  props: {
    destinationId: {
      type: String,
      required: true,
    },
    gameId: {
      type: String,
      required: true,
    },
    teamsLength: {
      type: Number,
      required: true,
    },
    organizationCreditBalance: {
      type: Number,
      required: true,
    },
    organizationUseCredits: {
      type: Boolean,
      required: true,
    },
    gameIsTest: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      nameRules: [
        (v) => !!v || this.$t('ruleTeamName'),
        (v) => (v && v.length <= 1024) || this.$t('ruleTeamNameTooLong'),
        (v) => (v && v.length >= 2) || this.$t('ruleTeamNameTooShort'),
      ],
      team: {
        email: null,
        name: '',
        color: '',
        sendWelcomeEmail: false,
      },
      creditRuleChecked: false,
      disabled: (this.organizationCreditBalance <= 0 && this.organizationUseCredits) ? false : null,
    };
  },
  watch: {
    valid(val) {
      this.valid = val;
    },
  },
  computed: {
    ...mapGetters('auth', ['token']),
  },
  methods: {
    ...mapActions({
      storeTeams: CREATE_TEAMS,
    }),
    addTeam() {
      const newTeam = {
        name: this.team.name,
        email: this.team.email ? this.team.email : null,
        color: this.team.color,
        partyId: this.gameId,
        destinationId: this.destinationId,
      };
      const teams = [newTeam];
      this.storeTeams({
        teams,
        sendEmail: this.team.sendWelcomeEmail,
        token: this.token,
      }).then(() => {
        this.$emit('teamAdded');
        this.successNotification(this.$t('teamCreated'));
        this.team.name = '';
        this.team.email = '';
        this.team.color = '';
        this.$emit('close');
      }).catch((error) => {
        this.errorNotification(this.$t('teamCreationFailed'), error);
      });
      this.creditRuleChecked = false;
    },
    updateCheckedState(updatedCheched) {
      this.creditRuleChecked = updatedCheched;
    },
  },
  mounted() {
    this.team.name = '';
    this.team.email = null;
    this.team.color = '';
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Ajouter une équipe",
    "fieldTeamName": "Nom de l'équipe",
    "fieldTeamEmail": "Email de l'équipe",
    "ruleTeamName": "Veuillez saisir un nom d'équipe",
    "ruleTeamNameTooLong": "Le nom doit faire moins de 1024 caractères",
    "ruleTeamNameTooShort": "Le nom doit faire au moins 2 caractères",
    "labelCheckboxEmail": "Envoyer un email de connexion",
    "teamCreated": "Equipe ajoutée avec succès",
    "teamCreationFailed": "Impossible de créer l'équipe. Email déjà utilisé ?"
  },
  "en": {
    "title": "Add a team",
    "fieldTeamName": "Team name",
    "fieldTeamEmail": "Team email",
    "ruleTeamName": "Please enter a team name",
    "ruleTeamNameTooLong": "Name must be less than 1024 chars long",
    "ruleTeamNameTooShort": "Name must be at least 2 chars long",
    "labelCheckboxEmail": "Send a connection email",
    "teamCreated": "Team added to the game",
    "teamCreationFailed": "Unable to create the team"
  }
}
</i18n>
