<template>
  <v-list-item
    :href="link"
  >
    <v-list-item-title>{{ $t('btnText') }}</v-list-item-title>
  </v-list-item>
</template>
<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
<i18n>
    {
        "fr": {
            "btnText": "Télécharger l'export des photos"
        },
        "en": {
            "btnText": "Download pictures export"
        }
    }
</i18n>
