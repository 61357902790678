import Vue from 'vue';
import Router from 'vue-router';

import DestinationsListView from './views/DestinationsListView.vue';

import LoginView from './views/LoginView.vue';

import AuthGuard from './auth/guard';

import DestinationsEditorView from './views/DestinationEditorView.vue';

import CategoriesListView from './views/CategoriesListView.vue';
import CategoriesEditorView from './views/CategoriesEditorView.vue';

import PlacesListView from './views/PlacesListView.vue';
import PlaceEditorView from './views/PlaceEditorView.vue';

import RewardsListView from './views/RewardsListView.vue';
import RewardEditorView from './views/RewardEditorView.vue';

import ToursListView from './views/ToursView.vue';
import TourEditorView from './views/ToursEditorView.vue';

import GameListView from './views/GamesListView.vue';

import GameEditorView from './views/GamesEditorView.vue';
import GameScoreboardView from './views/GameScoreboardView.vue';

import OrganizationView from './views/OrganizationView.vue';
import ListOrganizationView from './views/ListOrganizationView.vue';

import SimpleQuestionsVue from './views/SimpleQuestionsView.vue';

import DestinationValidationVue from './views/DestinationValidator.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: DestinationsListView,
    },
    {
      path: '/login',
      component: LoginView,
      name: 'login',
    },
    {
      path: '/destinations/new',
      component: DestinationsEditorView,
      name: 'createDestination',
    },
    {
      path: '/destinations/:destinationId',
      component: DestinationsEditorView,
      name: 'destinationDetails',
    },
    {
      path: '/destinations/:destinationId/validate',
      component: DestinationValidationVue,
      name: 'destinationValidation',
    },
    {
      path: '/switch/:destinationId',
      name: 'switch destinations',
      redirect: '/destinations/:destinationId',
    },
    {
      path: '/destinations/:destinationId/rewards',
      component: RewardsListView,
      name: 'rewardsList',
      props: { itemType: 'rewards', title: 'Récompenses' },
    },
    {
      path: '/sites/:siteId/places',
      component: PlacesListView,
      name: 'placesList',
    },
    {
      path: '/sites/:siteId/tours',
      component: ToursListView,
      name: 'toursList',
    },
    {
      path: '/sites/:siteId/categories',
      component: CategoriesListView,
      name: 'categoriesList',
    },
    {
      path: '/sites/:siteId/categories/new',
      component: CategoriesEditorView,
      name: 'newCategoryEditor',
    },
    {
      path: '/sites/:siteId/categories/:catId',
      component: CategoriesEditorView,
      name: 'categoryEditor',
    },
    {
      path: '/sites/:siteId/games',
      component: GameListView,
      name: 'gamesList',
    },
    {
      path: '/sites/:siteId/games/new',
      component: GameEditorView,
      name: 'newGameEditor',
    },
    {
      path: '/sites/:siteId/games/:gameId/scoreboard',
      component: GameScoreboardView,
      name: 'scoreboard',
    },
    {
      path: '/sites/:siteId/places/new',
      component: PlaceEditorView,
      name: 'newplacesEditor',
    },
    {
      path: '/sites/:siteId/places/:itemId',
      component: PlaceEditorView,
      name: 'placesEditor',
      props: true,
    },

    {
      path: '/sites/:siteId/tours/new',
      component: TourEditorView,
      name: 'NewTourEditor',
    },
    {
      path: '/sites/:siteId/tours/:tourId',
      component: TourEditorView,
      name: 'tourEditor',
    },
    {
      path: '/sites/:siteId/simpleQuestions',
      component: SimpleQuestionsVue,
      name: 'simpleQuestions',
    },
    {
      path: '/destinations/:destinationId/rewards/new',
      component: RewardEditorView,
      name: 'newrewardsEditor',
    },
    {
      path: '/destinations/:destinationId/rewards/:rewardId',
      component: RewardEditorView,
      name: 'rewardsEditor',
    },
    {
      path: '/organization',
      component: OrganizationView,
      name: 'OrganizationView',
    },
    {
      path: '/organizations',
      component: ListOrganizationView,
      name: 'ListOrganizationView',
    },
    {
      path: '/organizations/:organizationId',
      component: OrganizationView,
      name: 'OrganizationEditor',
    },
  ],
});
router.beforeEach(AuthGuard.checkAuth);

export default router;
