<template>
  <v-list-item @click="exportScoreboard">
    <v-list-item-title>{{ $t('exportScoreboard') }}</v-list-item-title>
  </v-list-item>
</template>
<script>
export default {
  props: {
    scoreboard: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async exportScoreboard() {
      const csvScore = Object.values(this.scoreboard.teams);
      const csvData = [];
      const trimmedCsv = csvScore.map((element) => ({
        name: element.name,
        score: element.score,
        activity: element.updatedAt,
      }));
      csvData.push(trimmedCsv);
      let csv = `${this.$t('scoreboardCSVHeader')}\n`;
      csvData[0].forEach((row) => {
        csv += `${row.activity}, ${row.name}, ${row.score} \n`;
      });
      const csvHref = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      const link = document.createElement('a');
      link.setAttribute('href', csvHref);
      link.setAttribute('download', 'scoreboard.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "exportScoreboard": "Obtenir les scores en CSV"
    },
    "en": {
      "exportScoreboard": "Get scoreboard as CSV"
    }
  }
</i18n>
