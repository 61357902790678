<template>
  <v-dialog v-model="isOpen" max-width="800" persistent>
    <v-card>
      <v-card-title> {{ title }}</v-card-title>
      <v-card-text>
        <v-form v-if="currentDocument">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="currentDocument.title"
                  :label="$t('labelTitle')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="[
                    {
                      text: $t('itemFrench'),
                      value: 'fr_FR',
                    },
                    {
                      text: $t('itemEnglish'),
                      value: 'en_GB',
                    },
                    {
                      text: $t('itemGerman'),
                      value: 'de_DE',
                    },
                    {
                      text: $t('itemNL'),
                      value: 'nl_NL',
                    },
                    {
                      text: $t('itemItalian'),
                      value: 'it_IT',
                    },
                    {
                      text: $t('itemSpanish'),
                      value: 'es_ES',
                    },
                    {
                      text: $t('itemProtuguese'),
                      value: 'pt_PT',
                    }]"
                  menu-props="auto"
                  :label="$t('labelLang')"
                  v-model="currentDocument.lang"
                  :hint="$t('hintLang')"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  v-model="currentDocument.description"
                  :label="$t('labelDescription')"
                  :hint="$t('hintDescription')"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  v-if="isNew"
                  accept="audio/mpeg,audio/mp4"
                  :label="$t('labelFile')"
                  v-model="currentDocument.file" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  v-model="currentDocument.transcript"
                  :label="$t('labelTranscript')"
                  :hint="$t('hintTanscript')"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="currentDocument.copyright"
                  :label="$t('labelCredit')" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <div>
          <v-alert
            v-if="validate().length > 0"
            color="red"
            border="left"
            dark>
            <p><strong>{{ $t('labelMandatoryFields') }}</strong></p>
            <ul>
              <li v-for="e in validate()" :key="e">{{ e }}</li>
            </ul>
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click.stop="$emit('close')"
        >
          {{ $t('btnCancel') }}
        </v-btn>
        <v-btn
          :disabled="validate().length > 0"
          text
          color="secondary"
          @click.stop="save"
        >
          {{ $t('btnSave') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    audioDocument: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      currentDocument: null,
      dialogOpen: null,
    };
  },
  watch: {
    audioDocument() {
      this.currentDocument = this.audioDocument;
    },
  },
  computed: {
    title() {
      if (this.currentDocument && this.currentDocument.id) {
        return this.$t('titleUpdate');
      }
      return this.$t('titleCreate');
    },
    isNew() {
      return !this.currentDocument.id;
    },
    isOpen: {
      set(value) {
        this.dialogOpen = value;
      },
      get() {
        if (this.dialogOpen === null) {
          return this.open;
        }
        return this.dialogOpen;
      },
    },
  },
  mounted() {
    this.currentDocument = this.audioDocument;
  },
  methods: {
    save() {
      this.$emit('documentupdated', this.currentDocument);
    },
    validate() {
      const errors = [];
      if (this.currentDocument) {
        if (!this.currentDocument.title) {
          errors.push(this.$t('labelEmptyTitleField'));
        } else if (this.currentDocument.title.length >= 255) {
          errors.push(this.$t('labelTooLongTitleField'));
        }
        if (!this.currentDocument.description) {
          errors.push(this.$t('labelEmptyDescriptionField'));
        }
        if (!this.currentDocument.file && this.isNew) {
          errors.push(this.$t('labelEmptyFileField'));
        }
      }
      return errors;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "labelTitle": "Titre*",
    "itemFrench": "Français",
    "itemEnglish": "Anglais",
    "itemGerman": "Allemand",
    "itemNL": "Néerlandais",
    "itemItalian": "Italien",
    "itemSpanish": "Espagnol",
    "itemPortuguese": "Portugais",
    "labelLang": "Langue*",
    "hintLang": "le document ne sera affiché que lorsque l'application de l'utilisateur est dans la langue choisie.",
    "labelDescription": "Description*",
    "hintDescription": "Une brève description du document pour inciter à l'écoute",
    "labelFile": "Fichier (formats acceptés: mp3 ou mp4/aac)",
    "labelTranscript": "Transcription",
    "hintTanscript": "La transcription est une version texte du document audio à destination des personnes malentendantes",
    "labelCredit": "Crédits et copyright",
    "btnSave": "Enregistrer",
    "btnCancel": "Annuler",
    "titleCreate": "Ajouter un document audio",
    "titleUpdate": "Modifier un document audio",
    "labelMandatoryFields": "Champs obligatories",
    "labelEmptyTitleField":"Le champ titre est vide",
    "labelTooLongTitleField":"Le champ titre contient une valeur trop longue",
    "labelEmptyDescriptionField":"Le champ descrition est vide",
    "labelEmptyFileField":"Le champ fichier est vide"
  },
  "en": {
    "labelTitle": "Title*",
    "itemFrench": "French",
    "itemEnglish": "English",
    "itemGerman": "German",
    "itemNL": "Dutch",
    "itemItalian": "Italian",
    "itemSpanish": "Spanish",
    "itemPortuguese": "Portuguese",
    "labelLang": "Language*",
    "hintLang": "The document will only be displayed if it is in the same language as the user's phone",
    "labelDescription": "Description*",
    "hintDescription": "A brief summary of the document to invite the user to listen to it",
    "labelFile": "File (accepted formats: mp3 ou mp4/aac)",
    "labelTranscript": "Transcript",
    "hintTanscript": "Text transcript of the document to make it accessible to people with hearing difficulties",
    "labelCredit": "Credits and copyright",
    "btnSave": "Save",
    "btnCancel": "Cancel",
    "titleCreate": "Add an audio document",
    "titleUpdate": "Update an audio document",
    "labelMandatoryFields": "Mandatory fields",
    "labelEmptyTitleField":"Title field is empty",
    "labelTooLongTitleField":"Title field contains too long a value",
    "labelEmptyDescriptionField":"Description field is empty",
    "labelEmptyFileField":"File field is empty"
  }
}
</i18n>
