<template>
  <div class="text-left py-2" v-if="survey">
    <v-sheet elevation="1" class="pa-3">
      <h2>{{$t('challengeTitle')}} {{ survey.title }}</h2>
      <div class="py-5" v-for="(q, idx) in survey.questions" :key="q.id">
        <v-row>
          <v-col cols="6">
            <h4><v-chip x-small :color="(chipContent(q)).color" dark>
              {{ chipContent(q).text }}
            </v-chip> {{ $t('questionTitle') }} </h4>
            <blockquote class="blockquote">{{ q.title }}</blockquote>
          </v-col>
          <v-col cols="6">
            <h4>{{ $t('maximumScore') }}</h4>
            <p>{{ score(q) }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="questionHasPropositions(q)">
            <h4>{{ $t('propositions') }}</h4>
            <p>
              <ul>
                <li
                  v-for="p in q.propositions"
                  :key="p.text">
                  {{ p.text }} ({{ p.points }} points)
                </li>
              </ul>
            </p>
          </v-col>
          <v-col cols="6" v-else>
            <h4>{{ $t('expectedAnswer') }}</h4>
            <p>{{ q.expectedAnswer && q.expectedAnswer.length > 0 ? q.expectedAnswer : $t('noExpectedAnswer') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" offset="3">
            <v-divider v-if="idx < survey.questions.length - 1" />
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>

</template>

<script>

export default {

  props: {
    survey: {
      type: Object,
      required: true,
    },
  },
  methods: {
    questionHasPropositions(question) {
      switch (question.type) {
        case 'single':
        case 'multiple':
        case 'image':
        case 'video':
        case 'audio':
          return true;
        default:
          return false;
      }
    },
    chipContent(question) {
      const content = {
        text: this.$t('radioQuestionTypeValueMultiple'),
        color: 'orange',
      };
      switch (question.type) {
        case 'single':
          content.text = this.$t('radioQuestionTypeValueSingle');
          content.color = 'green';
          break;
        case 'multiple':
          content.text = this.$t('radioQuestionTypeValueMultiple');
          content.color = 'orange';
          break;
        case 'open':
          content.text = this.$t('radioQuestionTypeValueOpen');
          content.color = 'blue';
          break;
        case 'photo':
          content.text = this.$t('radioQuestionTypeValuePhoto');
          content.color = 'red';
          break;
        case 'video':
          content.text = this.$t('radioQuestionTypeValueVideo');
          content.color = 'purple';
          break;
        case 'audio':
          content.text = this.$t('radioQuestionTypeValueAudio');
          content.color = 'pink';
          break;
        case 'team_name':
          content.text = this.$t('radioQuestionTypeValueTeamName');
          content.color = 'black';
          break;
        case 'image':
          content.text = this.$t('radioQuestionTypeValueImage');
          content.color = 'black';
          break;
        default:
          break;
      }
      return content;
    },
    score(question) {
      if (question.propositions && question.propositions.length > 0) {
        return question.propositions.reduce((acc, p) => (p.points > 0 ? acc + p.points : acc), 0);
      }
      return question.points;
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "challengeTitle": "Défi :",
      "radioQuestionTypeLabel": "Type de question",
      "radioQuestionTypeValueOpen": "Question à réponse ouverte",
      "radioQuestionTypeValuePhoto": "Prise de photo",
      "radioQuestionTypeValueSingle": "Question à réponse unique",
      "radioQuestionTypeValueMultiple": "Question à réponses multiples",
      "radioQuestionTypeValueVideo": "Question sur une vidéo",
      "radioQuestionTypeValueAudio": "Question sur un document audio",
      "radioQuestionTypeValueImage": "Question sur une image",
      "radioQuestionTypeValueTeamName": "Choix du nom d'équipe",
      "questionTitle": "Titre de la question",
      "maximumScore": "Score maximum",
      "propositions": "Propositions",
      "expectedAnswer": "Réponse attendue",
      "noExpectedAnswer": "Aucune réponse indiquée"
    },
    "en": {
      "challengeTitle": "Challenge:",
      "radioQuestionTypeValueOpen": "Open answer",
      "radioQuestionTypeValuePhoto": "Taking a picture",
      "radioQuestionTypeValueSingle": "Closed answer",
      "radioQuestionTypeValueMultiple": "Multiple answers",
      "radioQuestionTypeValueVideo": "Video",
      "radioQuestionTypeValueAudio": "Audio",
      "radioQuestionTypeValueImage": "Picture",
      "radioQuestionTypeValueTeamName": "Team name"
    }
  }
  </i18n>
