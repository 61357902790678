<template>
  <v-container fluid>
    <v-row>
      <v-col><h3>{{ $t('mainTitle')}}</h3></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="temporaryPlacesHeaders"
          :loading="loading"
          :loadingText="$t('tableLoadingText')"
          class="elevation-1"
          :items="tableContent">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('tableTitle') }}</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <add-temporary-place
                :game-id="partyId"
                :pois="poiList"
                :token="token"
                :existing-temporary-places="temporaryPlaces"
                @added="save"
              />
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <delete-temporary-place :temporary-place="item" @delete="deleteTemporaryPlace" />
          </template>
          <template v-slot:no-data>
            <p class="text">{{ $t('noPlaceText') }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { format } from 'date-fns-tz';
import { fr } from 'date-fns/locale';
import http from '../../services/fetch';
import cfg from '../../config';
import AddTemporaryPlace from './AddTemporaryPlace.vue';
import DeleteTemporaryPlace from './DeleteTemporaryItemConfirmationDialog.vue';
import UserNotifications from '../../mixins/UserNotifications.vue';

export default {
  components: {
    AddTemporaryPlace,
    DeleteTemporaryPlace,
  },
  mixins: [UserNotifications],
  props: {
    partyId: {
      type: String,
      required: true,
    },
    destinationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      temporaryPlaces: [],
    };
  },
  computed: {
    ...mapState('poi', [
      'poiList',
    ]),
    ...mapGetters('auth', ['token']),
    temporaryPlacesHeaders() {
      return [
        { text: this.$t('tableHeaderName'), value: 'name' },
        { text: this.$t('tableHeaderVisibleFrom'), value: 'visibleFrom' },
        { text: this.$t('tableHeaderVisibleUntil'), value: 'visibleUntil' },
        { text: this.$t('tableHeaderHideOnFirstCheckin'), value: 'hideOnFirstCheckin' },
        { text: this.$t('tableHeaderActions'), value: 'actions' },
      ];
    },
    tableContent() {
      if (this.loading === true) {
        return [];
      }
      const res = [];
      const pois = this.poisByUUID;
      if (!pois || pois.length === 0) {
        return [];
      }
      this.temporaryPlaces.forEach((tp) => {
        const currentPOI = pois[tp.poiId];
        if (!currentPOI) {
          return;
        }
        const tableLine = {
          id: tp.id,
          name: currentPOI.name,
          visibleFrom: format(new Date(tp.visibleFrom), 'PP p', { locale: fr }),
          visibleUntil: format(new Date(tp.visibleUntil), 'PP p', { locale: fr }),
          hideOnFirstCheckin: tp.hideOnFirstCheckin === true ? this.$t('tableYes') : this.$t('tableNo'),
        };
        res.push(tableLine);
      });
      return res;
    },
    poisByUUID() {
      const res = {};
      this.poiList.forEach((p) => {
        res[p.uuid] = p;
      });
      return res;
    },
  },
  methods: {
    ...mapActions({
      fetchPOIs: 'poi/all',
    }),
    async fetchTemporaryPlaces() {
      this.loading = true;
      const url = `${cfg.gameApiUrl}/gameapi/v1/temporary-places?party_id=${this.partyId}`;
      this.temporaryPlaces = await http.getJSON(url, {}, this.token);
      this.loading = false;
    },
    async save(temporaryPlace) {
      const url = `${cfg.gameApiUrl}/gameapi/v1/temporary-places`;
      try {
        await http.postJSON(url, temporaryPlace, this.token);
        await this.fetchTemporaryPlaces();
      } catch (error) {
        this.errorNotification(this.$t('errorSavingPlace'), error);
      }
    },
    async deleteTemporaryPlace(temporaryPlace) {
      const url = `${cfg.gameApiUrl}/gameapi/v1/temporary-places/${temporaryPlace.id}`;
      try {
        await http.deleteJSON(url, this.token);
        await this.fetchTemporaryPlaces();
      } catch (error) {
        this.errorNotification(this.$t('errorDeletingPlace'), error);
      }
    },
  },
  async mounted() {
    // fetch temporary places
    try {
      this.fetchTemporaryPlaces();
      await this.fetchPOIs({ siteId: this.destinationId, token: this.token, status: 'enabled' });
      this.loading = false;
    } catch (error) {
      this.errorNotification(this.$t('errorFetchingPlaces'), error);
    }
  },
};
</script>
<i18n>
  {
    "fr": {
      "noPlaceText": "Pas encore de lieu. Cliquez sur 'ajouter' pour en créer un.",
      "mainTitle": "Lieux temporaires",
      "tableTitle": "Lieux temporaires actifs",
      "tableLoadingText": "Chargement en cours...",
      "tableHeaderName": "Nom du lieu",
      "tableHeaderVisibleFrom": "Date d'apparition",
      "tableHeaderVisibleUntil": "Date de disparition",
      "tableHeaderHideOnFirstCheckin": "Disparait au premier checkin ?",
      "tableHeaderActions": "Actions",
      "tableYes": "Oui",
      "tableNo": "Non",
      "errorSavingPlace": "Impossible d'enregistrer ce lieu",
      "errorDeletingPlace": "Impossible de supprimer ce lieu",
      "errorFetchingPlaces": "Impossible de lister les lieux temporaires"
    },
    "en": {
      "noPlaceText": "No temporary place yet. Click 'add' to create one.",
      "mainTitle": "Temporary POIs",
      "tableTitle": "Active temporary POIs",
      "tableLoadingText": "Loading...",
      "tableHeaderName": "POI name",
      "tableHeaderVisibleFrom": "Activation date",
      "tableHeaderVisibleUntil": "Deactivation date",
      "tableHeaderHideOnFirstCheckin": "Dissappears on first checkin?",
      "tableHeaderActions": "Actions",
      "tableYes": "Yes",
      "tableNo": "No",
      "errorSavingPlace": "An error occurred while saving this place",
      "errorDeletingPlace": "An error occurred while deleting this place",
      "errorFetchingPlaces": "An error occurred while fetching the places"
    }
  }
</i18n>
