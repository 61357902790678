var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.site)?_c('v-container',{attrs:{"fluid":""}},[_c('v-spacer'),_c('v-row',[_c('v-col',[_c('h1',{staticClass:"text-h2"},[_vm._v(_vm._s(_vm.$t('pageTitle')))])])],1),_c('v-row',{staticClass:"mb-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"solo":"","prepend-inner-icon":"filter_list","items":_vm.currentStatusItems},model:{value:(_vm.currentStatus),callback:function ($$v) {_vm.currentStatus=$$v},expression:"currentStatus"}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":_vm.$t('labelSearch'),"solo":"","clearable":""},model:{value:(_vm.currentSearchTerm),callback:function ($$v) {_vm.currentSearchTerm=$$v},expression:"currentSearchTerm"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"9"}},[_c('v-btn-toggle',{model:{value:(_vm.toggleList),callback:function ($$v) {_vm.toggleList=$$v},expression:"toggleList"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-view-grid")])],1),_c('v-btn',[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)],1),(_vm.toggleList === 1 && _vm.currentStatus === 'enabled')?_c('v-col',{staticClass:"text-rigth",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"solo":"","prepend-inner-icon":"filter_list","items":[
          {
            text: _vm.$t('selectPois'),
            value: 'select',
          },
          {
            text: _vm.$t('sortPois'),
            value: 'sort',
          },
        ]},model:{value:(_vm.currentAction),callback:function ($$v) {_vm.currentAction=$$v},expression:"currentAction"}})],1):_vm._e()],1),(_vm.toggleList === 0)?_c('v-row',{attrs:{"wrap":""}},_vm._l((_vm.filteredPOIList),function(p){return _c('v-col',{key:p.id,attrs:{"fill-height":"","xs":"12","sm":"12","md":"6","lg":"4","xl":"3","pa-1":""}},[_c('place-card',{attrs:{"place":p},on:{"refresh":_vm.refresh}})],1)}),1):_vm._e(),(_vm.toggleList === 1)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"fill-height":"","xs":"12","sm":"12","md":"12","lg":"12","xl":"12","pa-1":""}},[_c('places-list',{attrs:{"places":_vm.filteredPOIList,"currentStatus":_vm.currentStatus,"currentAction":_vm.currentAction},on:{"refresh":_vm.refresh}})],1)],1):_vm._e(),_c('v-row',{attrs:{"pt-12":""}},[_c('v-col',{staticClass:"text-xs-center",attrs:{"xs":"12"}},[_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":"20"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('v-fab-transition',[(_vm.toggleList === 0)?_c('v-btn',{attrs:{"color":"accent","dark":"","fab":"","fixed":"","bottom":"","right":"","to":{ name: 'newplacesEditor', params: { siteId: _vm.siteId, site: _vm.site } }}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }