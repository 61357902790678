<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>

    <v-card-text>
      <v-container fluid grid-list-sm v-if="placeUuid">
        <v-row>
          <v-col align-self="center">
            <vue-qrcode :value="placeUuid" :options="{ width: 400 }" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  components: {
    VueQrcode,
  },
  props: {
    placeUuid: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    title() {
      return 'QR Code du lieu';
    },
    subtitle() {
      return 'Imprimez et affichez ce QR code pour permettre aux visiteurs de confirmer leur présence dans le lieu';
    },
  },
};
</script>
