import axios from './axios';
import cfg from '../config';
import http from './fetch';

const PostMetadata = (destinationId, parentType, parentID, audioDoc, token) => new Promise(
  (resolve, reject) => {
    const doc = {
      destinationId: parseInt(destinationId, 10),
      title: audioDoc.title,
      description: audioDoc.description,
      lang: audioDoc.lang,
      transcript: audioDoc.transcript,
      copyright: audioDoc.copyright,
    };
    const url = `/api/v3/destinations/${destinationId}/audio?parent_type=${parentType}&parent_id=${parentID}`;
    axios.post(url, doc, {
      headers: {
        Authorization: `BEARER ${token}`,
      },
    }).then((response) => { resolve(response.data); })
      .catch((error) => { reject(error); });
  },
);
const PutMetadata = (destinationId, audioDoc, token) => new Promise(
  (resolve, reject) => {
    const url = `/api/v3/destinations/${destinationId}/audio/${audioDoc.id}`;
    axios.put(url, audioDoc, {
      headers: {
        Authorization: `BEARER ${token}`,
      },
    }).then((response) => { resolve(response.data); })
      .catch((error) => { reject(error); });
  },
);
const PutFile = (destinationId, docId, file, token) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('media', file, file.name);
  const url = `/api/v3/destinations/${destinationId}/audio/${docId}/upload`;
  axios.put(url, formData, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
  }).then((response) => { resolve(response.data); }).catch((error) => { reject(error); });
});

const DeleteDocument = (destinationId, docId, token) => new Promise((resolve, reject) => {
  const url = `/api/v3/destinations/${destinationId}/audio/${docId}`;
  axios.delete(url, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
  }).then(() => { resolve(); })
    .catch((error) => { reject(error); });
});

const PutSurveyFile = (file, token) => new Promise((resolve, reject) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/surveys/audio`;
  const formData = new FormData();
  formData.append('media', file, file.name);
  http.putFile(url, formData, token).then((res) => {
    resolve(res);
  }).catch((error) => {
    reject(error);
  });
});
const DeleteSurveyFile = (surveyId, token) => new Promise((resolve, reject) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/surveys/${surveyId}/audio`;
  http.deleteJSON(url, token).then(() => {
    resolve();
  }).catch((error) => {
    reject(error);
  });
});

export default {
  PostMetadata,
  PutMetadata,
  PutFile,
  DeleteDocument,
  PutSurveyFile,
  DeleteSurveyFile,
};
