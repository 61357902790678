<template>
  <v-container fluid>
    <v-row
      class="">
      <v-col cols="12">
        <h3 class="text-h3" v-if="organization && organization.id !== 4 && user.organizationId === 4">{{ $t('modification') + ` ${organization.name}`}}</h3>
        <h1 class="text-h2" v-show="organization" v-else>{{$t('organizationTitle')}}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tabs"
        align-with-title>
        <v-tab key="coords">{{$t('organizationDetails')}}</v-tab>
        <v-tab key="credits">{{$t('organizationCredits')}}</v-tab>
        <v-tab v-if="user.organizationId === 4" key="destinations">Destinations</v-tab>
        <v-tab v-if=" user.organizationId === 4" key="editors">Administrateurs</v-tab>

      </v-tabs>
    </v-row>
    <!-- added v-if statement to organization: in order to pass data to the child component
needed to make a v-if statement otherwise while fetching data it would be undefined. -->
    <v-container>
      <v-tabs-items v-model="tabs" v-if="organization">
        <v-tab-item key="coords">
          <coords-form
            v-if="organization"
            :organization="organization" />
        </v-tab-item>
        <v-tab-item key="credits">
          <credit-form
            v-if="organization"
            :organization="organization"
            @update="getOrganizationById(organization.id, token)" />
        </v-tab-item>
        <v-tab-item v-if=" user.organizationId === 4" key="destinations">
          <destinations-list :organization-id="organization.id" />
        </v-tab-item>
        <v-tab-item v-if=" user.organizationId === 4" key="editors">
          <editor-list :organization-id="organization.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import http from '../services/axios';
import CoordsForm from '../components/organization/CoordsForm.vue';
import CreditForm from '../components/organization/CreditForm.vue';
import EditorList from '../components/organization/EditorList.vue';
import DestinationsList from '../components/organization/DestinationsList.vue';

export default {
  components: {
    CoordsForm,
    CreditForm,
    EditorList,
    DestinationsList,
  },
  data() {
    return {
      isCoordActive: true,
      isCreditActive: false,
      organization: null,
      tabs: null,
    };
  },
  methods: {
    enableCoord() {
      if (this.isCreditActive === true || this.isListOrgaActive === true) {
        this.isCreditActive = false;
        this.isListOrgaActive = false;
      }
      this.isCoordActive = true;
    },
    enableCredit() {
      if (this.isCoordActive === true || this.isListOrgaActive === true) {
        this.isCoordActive = false;
        this.isListOrgaActive = false;
      }
      this.isCreditActive = true;
    },
    async getOrganizationById(id, token) {
      const response = await http.get(`/api/v3/organizations/${id}`, { headers: { Authorization: `BEARER ${token}` } });
      const organization = response.data;
      this.organization = organization;
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('auth', ['token']),
    organizationId() {
      if (this.$route.params.organizationId) {
        return this.$route.params.organizationId;
      }
      return this.user.organizationId;
    },
  },
  async created() {
    await this.getOrganizationById(this.organizationId, this.token);
  },

};
</script>
<i18n>
{
  "fr": {
    "modification": "modification de :",
    "organizationTitle": "Mon Organisation",
    "organizationDetails": "Coordonnées",
    "organizationCredits": "Crédits"
  },
  "en": {
    "modification": "change of :",
    "organizationTitle": "My Organization",
    "organizationDetails": "Details",
    "organizationCredits": "Credits"
  }
}
</i18n>
