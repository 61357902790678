<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('toolbarTitle') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <template v-for="(t, idx) in tags">

        <v-list-item :key="t.id" @click="i = 1">
          <v-list-item-avatar>
            <flag :iso="flagIconClass(t.locale)" />
          </v-list-item-avatar>
          <v-list-item-content @click="editTag(t)">
            <v-list-item-title>{{ t.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon ripple @click="openDeleteDialog(t)">
              <v-icon color="grey lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="idx" />

      </template>
    </v-list>
    <v-card-text v-if="tags.length === 0">
      {{ $t('placeHolderEmptyList')}}
    </v-card-text>
    <v-dialog v-model="editDialog" max-width="60%">
      <tag-editor
        @cancel="closeEditDialog"
        @save="saveTag"
        :tag="currentTag"
        :title="editorTitle"
        :subtitle="$t('editorSubtitle')"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="350px">
      <confirmation-dialog
        :title="$t('confirmationTitle')"
        :subtitle="$t('confirmationSubtitle')"
        :cancel-button-title="$t('confirmationBtnCancel')"
        :confirm-button-title="$t('confirmationBtnConfirm')"
        @cancel="closeDeleteDialog"
        @confirm="deleteTag"
      />
    </v-dialog>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="accent"
        @click="openAddTagDialog">
        {{ $t('btnAddTag') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
/* eslint-disable max-len */

import FlagIcon from 'vue-flag-icon';
import { v4 as uuidv4 } from 'uuid';
import TagEditor from './TagEditor.vue';
import ConfirmationDialog from '../base/ConfirmationDialog.vue';

export default {
  components: {
    TagEditor,
    // eslint-disable-next-line
    FlagIcon,
    ConfirmationDialog,
  },
  props: {
    initialTags: {
      type: Array,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
    destinationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      currentTag: null,
    };
  },
  computed: {
    tags() {
      return this.initialTags.map((t) => {
        const newTag = { ...t };
        if (!newTag.uuid) {
          newTag.uuid = uuidv4();
        }
        return newTag;
      });
    },
    editorTitle() {
      if (this.currentTag) {
        return this.$t('editorTitleUpdate');
      }
      return this.$t('editorTitleCreate');
    },
  },
  methods: {
    openAddTagDialog() {
      this.currentTag = null;
      this.editDialog = true;
    },
    flagIconClass(locale) {
      const countryCode = locale.substring(locale.indexOf('_') + 1).toLowerCase();
      return `flag-icon flag-icon-${countryCode}`;
    },
    deleteTag() {
      const newTags = this.tags.filter((t) => t.uuid !== this.currentTag.uuid);
      this.$emit('updated', newTags);
      this.deleteDialog = false;
      this.currentTag = null;
    },
    openDeleteDialog(tag) {
      this.currentTag = tag;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.currentTag = null;
    },
    editTag(tag) {
      this.currentTag = tag;
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.currentTag = null;
    },
    saveTag(tag) {
      const newTag = { ...tag };
      newTag.categoryId = this.categoryId;
      newTag.destinationId = this.destinationId;
      const newTags = this.tags.filter((t) => t.uuid !== newTag.uuid);
      newTags.push(newTag);
      this.$emit('updated', newTags);
      this.editDialog = false;
      this.currentTag = null;
    },
  },
};
</script>
<i18n>
{
  "fr": {
  "toolbarTitle": "Tags",
    "placeHolderEmptyList": "Vous pouvez ajouter ici des tags à rattacher à vos Lieux d'intérêt pour proposer des filtres additionnels à vos utilisateurs",
    "editorTitleCreate": "Créer un tag",
    "editorTitleUpdate": "Modifier un tag",
    "editorSubtitle": "Saisissez un titre et une langue pour le tag",
    "confirmationTitle": "Supprimer ce tag ?",
    "confirmationSubtitle": "Cette action est irréversible",
    "confirmationBtnCancel": "Annuler",
    "confirmationBtnConfirm": "Supprimer",
    "btnAddTag": "Ajouter un tag"
  },
  "en": {
  "toolbarTitle": "Tags",
    "placeHolderEmptyList": "Attach tags to your POIs to create additional filters for the end users",
    "editorTitleCreate": "Create a tag",
    "editorTitleUpdate": "Update a tag",
    "editorSubtitle": "Type a title and a language for this tag",
    "confirmationTitle": "Delete this tag?",
    "confirmationSubtitle": "This cannot be undone",
    "confirmationBtnCancel": "Cancel",
    "confirmationBtnConfirm": "Delete",
    "btnAddTag": "Add a tag"
  }
}
</i18n>
