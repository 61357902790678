<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-container fluid grid-list-sm>
        <v-layout row wrap>
          <v-flex xs12>
            <v-select
              v-model="checkinType"
              :items="checkinTypeValues"
              @input="triggerUpdate" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    poiCheckinType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkinType: this.poiCheckinType,
      checkinTypeValues: ['gps', 'qrcode'],
    };
  },
  methods: {
    triggerUpdate(event) {
      this.$emit('onCheckinChanged', event);
    },
  },
};
</script>
