import axios from './axios';

const PollsForPlace = (placeId, token) => new Promise((resolve, reject) => {
  axios.get(`/api/v2/polls?placeId=${placeId}`, { headers: { Authorization: `bearer ${token}` } }).then((response) => {
    resolve(response.data);
  }).catch((error) => reject(error));
});

const Save = (poll, token) => new Promise((resolve, reject) => {
  axios.post('/api/v2/polls', poll, { headers: { Authorization: `bearer ${token}` } }).then((response) => {
    resolve(response.data);
  }).catch((err) => reject(err));
});
const Delete = (pollId, token) => new Promise((resolve, reject) => {
  axios.delete(`/api/v2/polls/${pollId}`, { headers: { Authorization: `bearer ${token}` } }).then(() => {
    resolve();
  }).catch((err) => reject(err));
});

export default {
  PollsForPlace,
  Save,
  Delete,
};
