<template>
  <div>
    <v-row
      v-for="proposition in propositions"
      :key="proposition.name">

      <v-col cols="12">
        <proposition-editor
          :current-proposition="proposition"
          @update-proposition="updateProposition"
          @delete-proposition="deleteProposition" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="text-right">
        <v-btn
          small
          text
          color="secondary"
          @click="addProposition"
        >{{ $t('btnAddProposition') }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PropositionEditor from './PropositionEditor.vue';

export default {
  components: {
    PropositionEditor,
  },
  props: {
    propositions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      propsList: [],
    };
  },
  methods: {
    addProposition() {
      this.$emit('add-proposition');
    },
    updateProposition(prop) {
      this.$emit('update-proposition', prop);
    },
    deleteProposition(proposition) {
      this.$emit('remove-proposition', proposition);
    },
  },
};
</script>

<i18n>
  {
    "fr": {
      "btnAddProposition": "Ajouter une proposition"
    },
    "en": {
      "btnAddProposition": "Add an answer"
    }
  }
</i18n>
