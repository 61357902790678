<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('title') }}</span>
    </v-card-title>
    <v-card-text class="d-flex-column justify-center">
      <div class="text-center">
        {{ $t('text') }}
      </div>
      <div class="text-center">
        <qrcode :value="teamLink" :options="{ width: 200 }" />
      </div>
      <div class="text-center">{{ teamShortCode }}</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="$emit('close')">{{ $t('btnOk') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DESTINATION_TYPE } from '../../const';
/* eslint-disable max-len */
import cfg from '../../config';

export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    platformType: {
      type: String,
      required: true,
    },
    shortCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    teamLink() {
      if (this.platformType === DESTINATION_TYPE.INDOOR) {
        return `https://${this.domain}/#/auth/${this.token}`;
      }
      if (this.platformType === DESTINATION_TYPE.NIGHTQUIZ) {
        return `https://${this.domain}/#/auth/teams/${this.token}`;
      }
      return `https://${this.domain}/auth/teams/${this.token}`;
    },
    teamShortCode() {
      return `${cfg.godotmapfrBaseURl}${this.shortCode}`;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Informations de connexion équipe",
    "text":"Scannez le QR code ou copiez le lien ci-dessous sur la tablette de l'équipe pour la connecter",
    "btnOk": "Ok"
  },
  "en": {
    "title": "Team Sign in information",
    "text":"Scan the QR code or paste the link on the team's device",
    "btnOk": "OK"
  }
}
</i18n>
