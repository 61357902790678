<template>
  <v-dialog
    :value="dialog"
    hide-overlay
    fullscreen
    transition="dialog-bottom-transition"
    @input="emitCloseEvent"
  >
    <v-card v-if="teamData && teamLogs">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="emitCloseEvent"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ teamData.name }} - Score : {{ teamData.score }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="formatedData"
          :search="poiTypeFilter"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="2">
                <v-select
                  solo
                  prepend-inner-icon="filter_list"
                  v-model="poiTypeFilter"
                  :items="filterValues"
                />
              </v-col>
            </v-row>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.givenAnswers="{ item }">
            <template v-if="(item.type === 'photo' && item.givenAnswers)">
              <span v-for="(answer, index) in item.givenAnswers" :key="index">
                <a :href="item.givenAnswers" target="_blank" rel="noopener noreferrer">Photo{{item.givenAnswers.length > 1 ? ` ${index + 1}` : ''}}</a>
                <span v-if="index < item.givenAnswers.length - 1">, </span>
              </span>
            </template>
            <div v-if="(item.type != 'photo' && item.givenAnswers)">{{ item.givenAnswers.toString() }}</div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.score="{ item }">
            <span :style="`color: ${item.score > 0 ? 'green' : 'red'}`">{{ item.score }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.type="{ item }">
            {{ $t(item.type) }}
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable max-len */
import { parseISO } from 'date-fns';
import { mapState } from 'vuex';
import { LOG_TYPE } from '.';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    teamData: {
      type: Object,
      default: null,
    },
    teamLogs: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      poiTypeFilter: '',
      filterValues: [
        {
          text: this.$t('filterAll'),
          value: '',
        },
        {
          text: this.$t('photo'),
          value: 'photo',
        },
        {
          text: this.$t('checkin'),
          value: 'checkin',
        },
        {
          text: this.$t('question'),
          value: 'question',
        },
        {
          text: this.$t('bonus'),
          value: 'bonus',
        },
      ],
    };
  },
  computed: {
    ...mapState('poi', ['poiList']),
    headers() {
      return [
        { text: this.$t('tableHeaderHistoryDate'), value: 'date', sortable: true },
        {
          text: this.$t('tableHeaderHistoryType'), value: 'type', sortable: false, filterable: true,
        },
        { text: this.$t('tableHeaderHistoryPoi'), value: 'poiName', sortable: false },
        { text: this.$t('tableHeaderHistoryQuestion'), value: 'question', sortable: false },
        { text: this.$t('tableHeaderHistoryGivenAnswer'), value: 'givenAnswers', sortable: false },
        { text: this.$t('tableHeaderHistoryExpectedAnswer'), value: 'expectedAnswers', sortable: false },
        { text: this.$t('tableHeaderHistoryScore'), value: 'score', sortable: false },
      ];
    },
    formatedData() {
      if (this.teamLogs && this.teamLogs.length > 0) {
        return this.teamLogs.map((log) => ({
          date: this.formatDate(log.createdAt),
          type: log.type,
          poiName: this.findPOINameFromUuid(log.poiId),
          question: log.content.questionTitle,
          givenAnswers: log.type === LOG_TYPE.BONUS ? log.content.reason : log.content.givenAnswers,
          expectedAnswers: log.content.expectedAnswers,
          score: log.score,
        }));
      }
      return [];
    },
  },
  model: {
    prop: 'dialog',
    event: 'close',
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    formatDate(rfcDate) {
      const rawDate = parseISO(rfcDate);
      return this.$d(rawDate, 'short', this.$i18n.locale);
    },
    findPOINameFromUuid(poiId) {
      const foundPoi = this.poiList.find((poi) => (poi.uuid === poiId));
      return foundPoi ? foundPoi.name : '';
    },
  },
};
</script>

<i18n>
{
  "fr": {
    "bonus": "Bonus",
    "question": "Question",
    "checkin": "Checkin",
    "photo": "Photo",
    "filterAll": "Tous",
    "tableHeaderHistoryDate": "Date/Heure",
    "tableHeaderHistoryType": "Type",
    "tableHeaderHistoryPoi": "POI",
    "tableHeaderHistoryQuestion": "Question",
    "tableHeaderHistoryGivenAnswer": "Réponse apportée",
    "tableHeaderHistoryExpectedAnswer": "Réponse attendue",
    "tableHeaderHistoryScore": "Points"
  },
  "en": {
    "bonus": "Bonus",
    "question": "Question",
    "checkin": "Checkin",
    "photo": "Photo",
    "filterAll": "Tous",
    "tableHeaderHistoryDate": "Date/Time",
    "tableHeaderHistoryType": "Type",
    "tableHeaderHistoryPoi": "POI",
    "tableHeaderHistoryQuestion": "Question",
    "tableHeaderHistoryGivenAnswer": "Given Answer",
    "tableHeaderHistoryExpectedAnswer": "Expected Answer",
    "tableHeaderHistoryScore": "Points"
  }
}
</i18n>
