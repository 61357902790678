<template>
  <v-container fluid>
    <v-stepper v-if="organization" v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          color="secondary"
          :complete="e1 > 1"
          step="1">{{ $t('titleStep1') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          color="secondary"
          :complete="e1 > 2"
          step="2">{{ $t('titleStep2') }}
        </v-stepper-step>
        <v-divider v-if="platformType === 'evjf'" />
        <v-stepper-step
          v-if="platformType === 'evjf'"
          color="secondary"
          step="3">{{ $t('titleStepEvjf') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          color="secondary"
          :complete="platformType === 'evjf' ? e1 > 4 : e1 > 3"
          :step="platformType === 'evjf' ? 4 : 3">{{ $t('titleStep3') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <game-step1
            :game="game"
            :langs="currentSiteLangs"
            :platform-type="platformType"
            @valid="step1 = $event" />

          <div class="d-flex justify-space-between">
            <v-btn color="grey" @click="cancel()">{{ $t('btnCancel') }}</v-btn>
            <v-btn color="secondary" @click="e1 = 2" :disabled="!step1">
              {{ $t('btnNextStep') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <game-step2
            v-if="game.isTest === true"
            :teams="testTeam"
            :isTestGame="game.isTest"
            :organization="organization"
            @valid="step2 = $event"
          />
          <game-step2
            v-else
            :teams="teams"
            :organization="organization"
            :isTestGame="game.isTest"
            @valid="step2 = $event"
            @add="addNewTeam"
            @delete="deleteTeam" />
          <v-row v-if="displayCreditsAlert">
            <game-alert-credit
              @onCheck="creditsAlertChecked = !creditsAlertChecked"
            />
          </v-row>
          <div class="d-flex justify-space-between">
            <v-btn color="gray" @click="e1 = 1">{{ $t('btnPreviousStep') }}</v-btn>
            <v-btn color="secondary" @click="e1 = 3" :disabled="nextIsDisabled">
              {{ $t('btnNextStep') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3" v-if="platformType === 'evjf'">
          <game-step-evjf
            :game="game"
            :teams="teams"
            :organization="organization"
            :destination-id="currentSiteId"
            @valid="step3 = $event"
            @update="updateEndgame"
          />
          <div class="d-flex justify-space-between">
            <v-btn color="gray" @click="e1 = 2">{{ $t('btnPreviousStep') }}</v-btn>
            <v-btn color="secondary" @click="e1 = 4" :disabled="nextIsDisabled">
              {{ $t('btnNextStep') }}
            </v-btn>
          </div>
        </v-stepper-content>
        <v-stepper-content :step="platformType === 'evjf' ? 4 : 3">
          <game-step3 v-if="game.isTest === false" :teams="teams" :game="game" />
          <game-step3 v-else :teams="testTeam" :game="game" />
          <div class="d-flex justify-space-between">
            <v-btn color="gray" @click="e1 = 2">{{ $t('btnPreviousStep') }}</v-btn>
            <div>
              <v-btn
                color="primary"
                @click="cancelDialog = true"
                class="mr-4">{{ $t('btnCancel') }}
              </v-btn>
              <v-btn
                color="secondary"
                @click="save()"
                :loading="isSaving">
                {{ $t('btnCreateParty') }}
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="cancelDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $t('cancelDialogTitle') }}</v-card-title>
        <v-card-text>{{ $t('cancelDialogText') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="cancelDialog = false">{{ $t('btnDontCancel') }}
          </v-btn>
          <v-btn
            color="secondary darken-1"
            text
            @click="cancel()">{{ $t('btnConfirmCancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserNotifications from '../../mixins/UserNotifications.vue';
import * as at from '../../store/actionTypes';
import creditCrud from '../../services/credits';
import http from '../../services/axios';
import GameStep1 from './GameStep1.vue';
import GameStep2 from './GameStep2.vue';
import GameStep3 from './GameStep3.vue';
import GameStepEvjf from './GameStepEvjf.vue';
import teamColors from '../../const';
import GameAlertCredit from './GameAlertCredit.vue';

export default {
  components: {
    GameStep1,
    GameStep2,
    GameStep3,
    GameAlertCredit,
    GameStepEvjf,
  },
  mixins: [UserNotifications],
  props: {
    currentSiteId: {
      type: String,
      required: true,
    },
    currentSiteUuid: {
      type: String,
      required: true,
    },
    currentSiteLangs: {
      type: Array,
      required: false,
      default: () => ['fr_FR'],
    },
    platformType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      cancelDialog: false,
      e1: 1,
      game: {
        status: 'pending',
        name: '',
        id: '',
        isTest: false,
        destinationId: this.currentSiteUuid,
        startDate: new Date(),
        endDate: this.returnTomorrow(),
      },
      teams: [],
      testTeam: [],
      step1: false,
      step2: false,
      isSaving: false,
      organization: null,
      creditsAlertChecked: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['token']),
    ...mapGetters('auth', ['user']),

    displayCreditsAlert() {
      if (this.organization.creditBalance < this.teams.length && this.game.isTest === false) {
        return true;
      }
      return false;
    },
    nextIsDisabled() {
      if (this.displayCreditsAlert === true && this.creditsAlertChecked === false) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      createGame: at.CREATE_PARTY,
      createTeams: at.CREATE_TEAMS,
      updateGame: at.UPDATE_PARTY,

    }),
    returnTomorrow() {
      const date = new Date();
      date.setDate(date.getDate() + 1);

      return date;
    },
    async getOrganizationById(id, token) {
      const response = await http.get(`/api/v3/organizations/${id}`, { headers: { Authorization: `BEARER ${token}` } });
      const organization = response.data;
      this.organization = organization;
    },
    addNewTeam() {
      const index = Math.floor(Math.random() * teamColors.teamColors.length);
      const teamNumber = this.teams.length + 1;
      this.teams.push({
        id: new Date().getTime(),
        name: `${this.$t('textTeam')} ${teamNumber}`,
        color: teamColors.teamColors[index].id,
        email: '',
      });
    },
    addTestTeam() {
      this.testTeam.push({
        id: new Date().getTime(),
        name: 'Test',
        color: teamColors.teamColors[1].id,
        email: null,
      });
    },
    deleteTeam(index) {
      this.teams.splice(index, 1);
      if (this.displayCreditsAlert === false && this.creditsAlertChecked === true) {
        this.creditsAlertChecked = false;
      }
    },
    buildTeamName(color) {
      return `${this.$t('textTeam')} ${color}`;
    },
    updateEndgame(data) {
      this.game.hasEndGame = data.hasEndGame;
      this.game.endgameRewards = data.endgameRewards;
      this.game.endgamePoiId = data.endgamePoiId;
    },
    save() {
      this.isSaving = true;
      this.createGame({ game: this.game, token: this.token })
        .then((game) => {
          this.game.id = game.id;
          if (this.game.isTest === false) {
            this.createTeams({
              teams: this.teams.map((team) => ({
                destinationId: game.destinationId,
                partyId: game.id,
                name: team.name,
                email: team.email ? team.email : null,
                color: team.color,
              })),
              sendEmail: this.game.shouldSendWelcomeEmail,
              token: this.token,
            });
          } else {
            this.createTeams({
              teams: this.testTeam.map((team) => ({
                destinationId: game.destinationId,
                partyId: game.id,
                name: team.name,
                email: team.email ? team.email : null,
                color: team.color,
              })),
              sendEmail: this.game.shouldSendWelcomeEmail,
              token: this.token,
            });
          }
        })
        .then(async () => {
          if (this.organization.useCredits === true && this.game.isTest === false) {
            try {
              const req = [];
              req.push({
                organizationId: this.organization.id,
                partyId: this.game.id,
                partyName: this.game.name,
                editorEmail: this.user.email,
                amount: this.teams.length,
              });
              const response = await creditCrud.consumeCredits(req[0], this.token);
              if (response.status === 'insufficient_funds') {
                this.game.status = 'ended';
                this.updateGame({
                  game: this.game,
                  token: this.token,
                });
                this.isSaving = false;

                this.errorNotification(this.$t('insufficientCredits'));
              } else {
                this.successNotification(this.$t('notificationSuccess'));
                this.cancel();
              }
            } catch (error) {
              this.isSaving = false;
              this.errorNotification(this.$t('notificationError'), error);
            }
          } else {
            this.successNotification(this.$t('notificationSuccess'));
            this.cancel();
          }
        })
        .catch((error) => {
          this.isSaving = false;
          this.errorNotification(this.$t('notificationError'), error);
        });
    },
    cancel() {
      const url = {
        name: 'gamesList',
        params: {
          siteId: this.currentSiteId,
        },
      };
      this.$router.push(url);
    },
  },
  async mounted() {
    try {
      await this.getOrganizationById(this.user.organizationId, this.token);
      this.addNewTeam();
      this.addTestTeam();
    } catch (error) {
      this.errorNotification(this.$t('notificationError'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "titleStep1": "Créer partie",
    "titleStep2": "Créer équipes",
    "titleStep3": "Confirmation",
    "titleStepEvjf": "Fin de jeu EVJF",
    "btnCancel": "Annuler",
    "btnNextStep": "Etape suivante",
    "btnPreviousStep": "Etape précédente",
    "btnCreateParty": "Créer la partie",
    "cancelDialogTitle": "Annuler",
    "cancelDialogText": "Voulez-vous vraiment Annuler la création de la partie ?",
    "btnDontCancel": "Non",
    "btnConfirmCancel": "Oui",
    "textTeam": "Equipe",
    "notificationSuccess": "Partie créée avec succès",
    "notificationError": "Une erreur est survenue",
    "insufficientCredits": "Nombre de crédits équipe insuffisant"
  },
  "en": {
    "titleStep1": "Create game",
    "titleStep2": "Create teams",
    "titleStep3": "Validation",
    "titleStepEvjf": "EVJF end game",
    "btnCancel": "Cancel",
    "btnNextStep": "Next step",
    "btnPreviousStep": "Previous step",
    "btnCreateParty": "Create the game",
    "cancelDialogTitle": "Cancel",
    "cancelDialogText": "Cancel the game creation?",
    "btnDontCancel": "No",
    "btnConfirmCancel": "Yes",
    "textTeam": "Team",
    "notificationSuccess": "Game created",
    "notificationError": "An error occurred",
    "insufficientCredits": "Number of team credits insufficient"
  }
}
</i18n>
