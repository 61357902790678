import cfg from '../config';
import fileApi from './fetch';

const putImageFile = (file, token) => new Promise((resolve, reject) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/pictures/raw?folder=simple-questions`;
  const formData = new FormData();
  formData.append('picture', file, file.name);
  fileApi
    .putFile(url, formData, token)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});

export default putImageFile;
