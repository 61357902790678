// Rewards module
export const DID_FETCH_REWARDS = 'DID_FETCH_REWARDS';

// Tours module
export const WILL_FETCH_TOURS = 'WILL_FETCH_TOURS';
export const DID_FETCH_TOURS = 'DID_FETCH_TOURS';
export const DID_FAIL_TO_FETCH_TOURS = 'DID_FAIL_TO_FETCH_TOURS';

// Categories module
export const DID_FETCH_CATEGORIES = 'DID_FETCH_CATEGORIES';
export const DID_FAIL_TO_FETCH_CATEGORIES = 'DID_FAIL_TO_FETCH_CATEGORIES';

// Events module
export const WILL_FETCH_EVENTS = 'WILL_FETCH_EVENTS';
export const DID_FETCH_EVENTS = 'DID_FETCH_EVENTS';
export const DID_FAIL_TO_FETCH_EVENTS = 'DID_FAIL_TO_FETCH_EVENTS';

// destinations module
export const SET_DESTINATIONS = 'SET_DESTINATIONS';
