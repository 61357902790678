<template>
  <div class="py-5 text-center">
    <div class="text-center" v-if="hasSurveys === true">
      <v-icon color="green" x-large>mdi-check-circle</v-icon>
      <h2>{{ $t('titleHasChallenges') }}</h2>
    </div>
    <div v-else class="text-center">
      <v-icon color="orange" x-large>mdi-alert</v-icon>
      <h2>{{ $t('titleHasNoChallenges') }}</h2>
    </div>

    <div v-if="surveys.length > 0">
      <p>{{ $t('labelDisplayMode') }} : <strong>{{ displayModeTitle }}</strong></p>

      <h3 class="text-left">{{ $t('challengesList') }}</h3>
      <v-divider />

      <v-simple-table v-if="includeContent === false">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('order') }}
              </th>
              <th class="text-left">{{ $t('title') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in sortedSurveys" :key="s.id">
              <td class="text-left">{{ s.order }}</td>
              <td class="text-left">
                {{ s.title }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        <SurveyValidatorWithContent v-for="s in sortedSurveys" :key="s.id" :survey="s" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import cfg from '../../../config';
import SurveyValidatorWithContent from './SurveyValidatorWithContent.vue';

export default {
  components: {
    SurveyValidatorWithContent,
  },
  props: {
    poiUuid: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    displayMode: {
      type: String,
      required: true,
    },
    includeContent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      surveys: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['token']),
    hasSurveys() {
      if (this.surveys.length === 0) {
        return false;
      }

      return true;
    },
    sortedSurveys() {
      return this.surveys.toSorted((a, b) => a.order - b.order);
    },
    displayModeTitle() {
      if (this.displayMode === 'all') {
        return 'Tous en même temps';
      }
      return 'Un par un';
    },
  },
  methods: {
    fetchSurveys() {
      const url = `/gameapi/v1/admin/surveys?poi_id=${this.poiUuid}`;
      const http = axios.create({ baseURL: cfg.gameApiUrl });
      return http.get(url, { headers: { Authorization: `JWT ${this.token}` } });
    },

  },
  async mounted() {
    const res = await this.fetchSurveys();
    if (Array.isArray(res.data)) {
      this.surveys = res.data.filter((s) => s.lang === this.lang);
    }
  },
};
</script>
<i18n>
  {
    "fr": {
      "titleHasChallenges": "Ce lieu comporte des défis pour la langue sélectionnée",
      "titleHasNoChallenges": "Ce lieu ne comporte pas de défi pour la langue sélectionnée",
      "labelDisplayMode": "Mode d'affichage des défis : ",
      "challengesList": "Liste des défis",
      "title": "Titre",
      "order": "Ordre"
    },
    "en": {
      "titleHasChallenges": "This location has challenges for the selected language",
      "titleHasNoChallenges": "This location has no challenges for the selected language",
      "labelDisplayMode": "Challenge display mode: ",
      "challengesList": "Challenges list",
      "title": "Title",
      "order": "Order"
    }
  }
</i18n>
