<template>
  <v-container fluid>
    <v-spacer />
    <div v-if="loading === true">
      <h3 class="text-xs-center">{{ $t('textLoading') }}</h3>
      <v-progress-linear
        :indeterminate="true" />
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <h1>{{ pageTitle }}</h1>
        </v-col>
      </v-row>
      <v-row v-if="!isIdentifierValid && !tourIsNew">
        <v-col>
          <v-alert
            border="left"
            colored-border
            elevation="1"
            type="warning">
            {{ $t('alertIdentifierFormat') }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <identifier-card
            v-model="tour.name"
            :title="$t('identifierTitle')"
            :subtitle="$t('identifierSubtitle')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pictures-list
            :title="$t('picturesListTitle')"
            :subtitle="$t('picturesListSubtitle')"
            :pictures="tour.pictures"
            @deletepicture="onDeletePicture"
            @updatepictures="onUpdatePicture"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <poi-selector
            :pois="poisCollection"
            :selected-pois="tour.pois"
            :ordered-pois="tour.pois"
            @poisupdated="onPOIsListUpdated"
            @sortedpois="onPOIsSorted"
          />

        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <selector-card
            :title="$t('transportTitle')"
            :subtitle="$t('transportSubtitle')"
            :label="$t('transportLabel')"
            v-model="tour.transportation"
            :options="[{ text: $t('trasportOptionFoot'), value: 'walking' }, { text: $t('transportOptionBike'), value: 'cycling' }]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <tour-map
            :title="$t('mapTitle')"
            :subtitle="$t('mapSubtitle')"
            :enable-trace="tour.hasGeojsonTrace"
            :initial-trace="tour.geojsonTrace"
            :initial-color="tour.color"
            :initial-distance="tour.distance"
            :initial-use-numbered-pois="tour.hasNumberedIcons"
            :transport="tour.transportation"
            @traceupdated="onTraceUpdated"
            :pois="tour.pois" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <translations-list
            :initial-translations="tour.locales"
            @transactionschanged="onTranslationsChanged" />
        </v-col>
      </v-row>
      <v-row v-if="validationErrors.length > 0">
        <v-col>
          <v-alert
            border="left"
            colored-border
            elevation="1"
            type="error">
            <p>{{ $t('errorsTitle') }}
              <ul>
                <li v-for="e in validationErrors" :key="e">{{ e}}</li>
              </ul>
            </p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-spacer />
          <v-btn
            :disabled="validationErrors.length > 0"
            raised
            large
            color="accent"
            @click="save">{{ $t('saveBtn') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UserNotifications from '../mixins/UserNotifications.vue';
import IdentifierCard from '../components/base/IdentifierCard.vue';
import validation from '../services/validation';
import TourMap from '../components/tour/TourMap.vue';
import PoiSelector from '../components/tour/POISelector.vue';
import SelectorCard from '../components/base/SingleFieldSelectorCard.vue';
import TranslationsList from '../components/base/ObjectTranslationsList.vue';
import * as at from '../store/actionTypes';
import PicturesList from '../components/pictures/PictureList.vue';

export default {
  components: {
    IdentifierCard,
    TourMap,
    PoiSelector,
    SelectorCard,
    TranslationsList,
    PicturesList,
  },
  mixins: [UserNotifications],
  data() {
    return {
      loading: true,
      tourIsNew: true,
      tour: null,
      validationErrors: [],
      picturesCreationQueue: [],
      picturesUpdateQueue: [],
      picturesDeleteQueue: [],
    };
  },
  computed: {
    ...mapState({
      poisCollection: (state) => state.poi.poiList,
    }),
    ...mapGetters('auth', [
      'token',
    ]),
    siteId() {
      return parseInt(this.$route.params.siteId, 10);
    },
    selectedPOIsIds() {
      return this.tour.pois.map((p) => p.poiId);
    },

    isIdentifierValid() {
      return validation.ValidateIdentifier(this.tour.name);
    },
    pageTitle() {
      if (this.tourIsNew) {
        return this.$t('pageTitleCreate');
      }
      return this.$t('pageTitleUpdate');
    },
  },
  methods: {
    ...mapActions({
      fetchTours: at.FETCH_TOURS,
      fetchPOIs: 'poi/all',
      createTour: at.CREATE_TOUR,
      updateTour: at.UPDATE_TOUR,
    }),
    defaultTour() {
      return {
        name: null,
        destinationId: null,
        color: '#1c7ed6',
        picture: '',
        pictureCredits: '',
        locales: {},
        hasGeojsonTrace: false,
        geojsonTrace: {},
        pois: [],
        distance: 0,
        transportation: 'walking',
        versionHash: null,
        pictures: [],
        hasNumberedIcons: false,

      };
    },
    validate() {
      const errors = [];
      if (!this.isIdentifierValid) {
        errors.push(this.$t('errInvalidID'));
      }
      if (this.tour.pois.length < 2) {
        errors.push(this.$t('errMissingPOI'));
      }
      if (!this.tour.locales || Object.keys(this.tour.locales).length < 1) {
        errors.push(this.$t('errNoLocale'));
      }
      if (this.tour.distance === 0) {
        errors.push(this.$t('errDistance'));
      }
      this.validationErrors = errors;
      return errors.length === 0;
    },
    onPOIsListUpdated(newPOIs) {
      this.tour.pois = newPOIs;
    },
    onPOIsSorted(sortedPOIs) {
      this.tour.pois = sortedPOIs;
    },
    onTranslationsChanged(newTranslations) {
      this.tour.locales = newTranslations;
      this.validate();
    },
    onTraceUpdated(newTraceInfo) {
      this.tour.geojsonTrace = newTraceInfo.trace;
      this.tour.hasGeojsonTrace = newTraceInfo.hasTrace;
      this.tour.distance = newTraceInfo.distance;
      this.tour.color = newTraceInfo.color;
      this.tour.hasNumberedIcons = newTraceInfo.useNumberedPOIs;
    },
    onUpdatePicture(pictures) {
      pictures.forEach((picture) => {
        if (picture.isNew) {
          const picturesCreateQueue = this.picturesCreationQueue.filter(
            (p) => p.uuid !== picture.uuid,
          );
          picturesCreateQueue.push(picture);
          this.picturesCreationQueue = picturesCreateQueue;
        } else {
          // remove the picture from the queue if it exists to avoid a double update
          const picturesUpdateQ = this.picturesUpdateQueue.filter((p) => p.uuid !== picture.uuid);
          picturesUpdateQ.push(picture);
          this.picturesUpdateQueue = picturesUpdateQ;
        }
        this.tour.pictures = this.tour.pictures.filter((p) => p.uuid !== picture.uuid);
      });
    },
    onDeletePicture(picture) {
      if (!picture.isNew) {
        this.picturesDeleteQueue.push(picture.uuid);
      }
      this.tour.pictures = this.tour.pictures.filter((p) => p.uuid !== picture.uuid);
    },

    async save() {
      if (!this.validate()) {
        return;
      }
      let tourID = null;
      try {
        if (this.tourIsNew) {
          const savedTour = await this.createTour(
            {
              siteId: this.siteId,
              tour: this.tour,
              token: this.token,
            },
          );
          tourID = savedTour.id;
        } else {
          tourID = this.tour.id;
          await this.updateTour({
            siteId: this.siteId,
            tour: this.tour,
            token: this.token,
          });
        }
        this.savePictures(tourID);
        this.deletePictures();
        this.successNotification(this.$t('notifSaved'));
        this.$router.push({ name: 'toursList', params: { siteId: this.siteId } });
      } catch (error) {
        this.errorNotification(this.$t('errCantSave'), error);
      }
    },
    savePictures(tourID) {
      this.picturesCreationQueue.forEach((p) => {
        this.$store.dispatch(
          at.CREATE_TOUR_PICTURE,
          {
            siteId: this.siteId,
            parentID: tourID,
            picture: p,
            token: this.token,
          },
        );
      });
      this.picturesUpdateQueue.forEach((p) => {
        this.$store.dispatch(
          at.UPDATE_TOUR_PICTURE,
          {
            siteId: this.siteId,
            tourID,
            picture: p,
            token: this.token,
          },
        );
      });
    },
    deletePictures() {
      this.picturesDeleteQueue.forEach((picUUID) => {
        this.$store.dispatch(
          at.DELETE_TOUR_PICTURE,
          {
            siteId: this.siteId,
            pictureId: picUUID,
            token: this.token,
          },
        );
      });
    },
  },
  async mounted() {
    const { siteId, tourId } = this.$route.params;
    try {
      await this.fetchPOIs({ siteId, token: this.token, status: 'enabled' });
      if (this.$route.name === 'tourEditor') {
        const tours = await this.fetchTours({ siteId, token: this.token });
        [this.tour] = tours.filter((tr) => tr.uuid === tourId);
        if (!this.tour) {
          throw new Error('tour not found');
        }
        this.tourIsNew = false;
      } else {
        this.tourIsNew = true;
        this.tour = this.defaultTour();
      }
      this.loading = false;
    } catch (error) {
      console.log('error initializing view', error);
      this.loading = false;
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "textLoading": "Chargement du circuit...",
    "alertIdentifierFormat": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer&nbsp; tous les caractères accentués, apostrophes et espaces",
    "identifierTitle": "Identifiant du circuit",
    "identifierSubtitle": "Choisissez un identifiant unique pour ce circuit",
    "picturesListTitle": "Photos du circuit",
    "picturesListSubtitle": "Faites glisser les photos pour les réorganiser",
    "transportTitle": "Mode de transport",
    "transportSubtitle": "Choisissez le mode de transport utilisé pour ce circuit.",
    "transportLabel": "Choisir un mode de transport",
    "trasportOptionFoot": "Piéton",
    "transportOptionBike": "Vélo",
    "mapTitle": "Tracé du circuit",
    "mapSubtitle": "Voir le tracé",
    "errorsTitle": "Les erreurs suivantes empêchent la sauvegarde:",
    "saveBtn": "Sauvegarder le circuit",
    "pageTitleCreate": "Créer un circuit",
    "pageTitleUpdate": "Mettre à jour un circuit",
    "errInvalidID": "Identifiant du circuit invalide",
    "errMissingPOI": "Le cirucit doit contenir au moins 2 points d'intérêt.",
    "errNoLocale": "Le circuit ne contient pas de traduction",
    "errDistance": "La distance ne peut pas être égale à 0m",
    "notifSaved": "Circuit sauvegardé",
    "errCantSave": "Impossible de sauvegarder le circuit"
  },
  "en": {
    "textLoading": "Loading...",
    "alertIdentifierFormat": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer&nbsp; tous les caractères accentués, apostrophes et espaces",
    "identifierTitle": "Tour identifier",
    "identifierSubtitle": "Choose a unique identifier for this tour",
    "picturesListTitle": "Tour pictures",
    "picturesListSubtitle": "Drag the pictures to reorder them",
    "transportTitle": "Transportation mode",
    "transportSubtitle": "Choose the transportation mode used for this tour.",
    "transportLabel": "Choose a transportation mode",
    "trasportOptionFoot": "Walking",
    "transportOptionBike": "Biking",
    "mapTitle": "Tour trace",
    "mapSubtitle": "See the trace",
    "errorsTitle": "The following issues prevent saving the tour:",
    "saveBtn": "Save the tour",
    "pageTitleCreate": "Create a tour",
    "pageTitleUpdate": "Update a tour",
    "errInvalidID": "Invalid tour identifier",
    "errMissingPOI": "The tour must have at least 2 POIs",
    "errNoLocale": "The tour does not have any translation",
    "errDistance": "Tour's distance cannot be 0m",
    "notifSaved": "Tour saved",
    "errCantSave": "Unable to save the tour"
  }
}
</i18n>
