<template>
  <v-container fluid>
    <loading-dialog
      v-if="themesLoading === true"
      :open="themesLoading"
      :text="$t('loading')"
    />
    <v-row v-else>
      <v-col cols="12">
        <h2 class="text-h2">{{ $t('pageTitle') }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <simple-questions-editor
          :destination-id="currentDestination.uuid" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LoadingDialog from '../components/base/LoadingDialog.vue';
import SimpleQuestionsEditor from '../components/simpleQuestion/SimpleQuestionEditor.vue';
import { DESTINATION_TYPE, NIGHTQUIZ_THEMES } from '../const';
import { getThemes, postTheme } from '../services/simpleQuestion';
import UserNotifications from '../mixins/UserNotifications.vue';

export default {
  components: {
    SimpleQuestionsEditor,
    LoadingDialog,
  },
  mixins: [UserNotifications],
  data() {
    return {
      themesLoading: true,
    };
  },
  computed: {
    ...mapState({
      currentDestination: (state) => state.site.sites[state.site.currentSiteId],
    }),
    ...mapGetters('auth', [
      'token',
    ]),
  },
  methods: {
    async ensureDefaultThemes() {
      if (this.currentDestination.platformType !== DESTINATION_TYPE.NIGHTQUIZ) {
        return;
      }
      // fetch themes
      const themes = await getThemes(this.currentDestination.uuid);
      let activeThemeFound = false;
      let inactiveThemeFound = false;
      themes.forEach((theme) => {
        if (theme.name === NIGHTQUIZ_THEMES.ACTIVE) {
          activeThemeFound = true;
        } else if (theme.name === NIGHTQUIZ_THEMES.INACTIVE) {
          inactiveThemeFound = true;
        }
      });
      if (activeThemeFound === true && inactiveThemeFound === true) {
        this.themesLoading = false;
      }
      if (activeThemeFound === false) {
        const newTheme = {
          name: NIGHTQUIZ_THEMES.ACTIVE,
          lang: 'fr_FR',
          destinationId: this.currentDestination.uuid,
        };
        // create active theme
        await postTheme(newTheme, this.token);
      }

      if (inactiveThemeFound === false) {
        const newTheme = {
          name: NIGHTQUIZ_THEMES.INACTIVE,
          lang: 'fr_FR',
          destinationId: this.currentDestination.uuid,
        };
        // create inactive theme
        await postTheme(newTheme, this.token);
      }
      // if empty, create default themes
      this.themesLoading = false;
    },
  },
  mounted() {
    try {
      this.ensureDefaultThemes();
    } catch (error) {
      this.errorNotification(this.$t('errorLoadingThemes'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Questions du quiz",
    "newQuestion": "Nouvelle question",
    "errorLoadingThemes": "Une erreur est survenue lors du chargement des thèmes",
    "loading": "Chargement en cours"
    },
  "en": {
    "pageTitle": "Quiz questions",
    "newQuestion": "New question",
    "errorLoadingThemes": "An error occurred while loadign the question themes",
    "loading": "Loading..."

    }
}
</i18n>
