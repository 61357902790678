<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item>
        <v-list-item-title v-bind="attrs" v-on="on">{{ $t('mainTitle') }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ $t('cardTitle') }}
      </v-card-title>

      <v-card-text>
        {{ $t('text') }}
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                type="number"
                v-model.number="points"
                :label="$t('labelNbPoints')" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="reason"
                :label="$t('labelReason')" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="accent"
          text
          @click="close"
        >
          {{ $t('btnCancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!valid"
          @click="sendEvent"
        >
          {{ $t('btnAdd') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable max-len */

/**
 * Dialog that opens from the scoreboard and allows admins to add points to a team
 */
export default {
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      points: 0,
      reason: '',
    };
  },
  computed: {
    valid() {
      return this.points !== 0 && this.reason && this.reason.length > 0;
    },
  },
  methods: {
    sendEvent() {
      this.$emit('addevent', { points: this.points, reason: this.reason, teamId: this.teamId });
      this.close();
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "mainTitle": "Ajouter des points",
    "cardTitle": "Ajouter des points à l'équipe",
    "text": "Saisissez le nombre de points à ajouter et indiquez un motif qui apparaitra dans le scoreboard",
    "labelNbPoints": "Nombre de points",
    "labelReason": "Motif",
    "btnCancel": "Annuler",
    "btnAdd": "Ajouter"
  },
  "en": {
    "mainTitle": "Add points",
    "cardTitle": "Add points to the team",
    "text": "Indicate the number of points to add and a reason that will be displayed on the scoreboard",
    "labelNbPoints": "Points",
    "labelReason": "Reason",
    "btnCancel": "Cancel",
    "btnAdd": "Add"
  }
}
</i18n>
