<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-4">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t('pageTitle') }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-alert
                :value="authenticationFailed"
                type="warning">
                {{ $t('form.errorLoginFailed') }}
              </v-alert>
              <v-text-field
                v-model="username"
                :rules="[rules.required, rules.email]"
                prepend-icon="person"
                name="login"
                :label="$t('form.labelUsername')"
                type="email" />
              <v-text-field
                v-model="password"
                :rules="[rules.required, rules.minLength]"

                id="password"
                prepend-icon="lock"
                name="password"
                :label="$t('form.labelPassword')"
                type="password"
              />
              <v-btn
                :disabled="!formValid"
                :loading="authenticationInProgress"
                @click="authenticateUser"
                color="primary">{{ $t('form.submitTitle') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import validator from '../services/validation';

export default {
  data: () => ({
    username: '',
    password: null,
    rules: {
      required: (value) => !!value || 'Ce champ ne peut pas être vide.',
      email: (value) => validator.ValidateEmail(value) || 'Cette adresse email est invalide',
      identifier: (value) => validator.ValidateIdentifier(value) || 'Ce champ ne peut pas être vide',
      minLength: (value) => validator.ValidateMinLength(value, 6) || 'Mot de passe trop court',
    },
    formValid: false,
  }),
  computed: {
    ...mapState('auth', [
      'authenticationInProgress',
      'authenticationFailed',
    ]),
  },
  watch: {
    username: 'validateForm',
    password: 'validateForm',
  },
  methods: {
    validateForm() {
      this.formValid = this.$refs.form.validate();
    },
    authenticateUser() {
      this.$store.dispatch('auth/authenticate', { username: this.username, password: this.password }).then(() => {
        this.$router.push({ name: 'home' });
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Connexion",
    "form": {
      "labelUsername": "Email",
      "labelPassword": "Mot de passe",
      "submitTitle": "Connexion",
      "errorLoginFailed": "Email ou mot de passe incorrect"
    }
  },
  "en": {
    "pageTitle": "Sign in",
    "form": {
      "labelUsername": "Email",
      "labelPassword": "Password",
      "submitTitle": "Sign in",
      "errorLoginFailed": "Incorrect email or password"
    }
  }
}
</i18n>
