<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('toolbarTitle') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <template v-for="(c, idx) in categories">

        <v-list-item :key="c.id" @click="i = 1">
          <v-list-item-avatar height="64" width="64">
            <v-img aspect-ratio="1" width="64" height="64" contain :src="imgUrl(c.icon)" />
          </v-list-item-avatar>
          <v-list-item-content @click="$emit('listitemclicked', c)">
            <v-list-item-title> {{ c.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon ripple>
              <v-icon color="grey lighten-1" @click="openLinksDialog(c)">mdi-link</v-icon>
            </v-btn>
            <v-btn icon ripple @click="deleteCategory(c)">
              <v-icon color="grey lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="idx" />

      </template>
    </v-list>
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">{{ $t('linkTitle') }}</v-card-title>

        <v-card-text>
          <p>{{ $t('mainAppLinkTitle') }}</p>
          <v-text-field
            id="links-normal"
            label="Lien"
            solo
            readonly
            append-icon="mdi-clipboard-text"
            @click:append="copyLink('#links-normal')"
            :value="links.normal"
          />
          <p>{{ $t('widgetAppLinkTitle') }}</p>
          <v-text-field
            id="links-widget"
            label="Lien"
            solo
            readonly
            :value="links.widget"
            append-icon="mdi-clipboard-text"
            @click:append="copyLink('#links-widget')"
          />

        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            color="secondary"
            text
            @click="closeLinksDialog"
          >
            {{ $t('btnClose') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="accent" @click="$emit('new')">{{ $t('btnAddCategory') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: false,
      default: () => [],
    },
    site: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      currentCategory: null,
    };
  },
  computed: {
    links() {
      if (this.currentCategory) {
        return {
          normal: `https://${this.site.domains[0]}/?cat=${this.currentCategory.name}`,
          widget: `https://${this.site.domains[0]}/?widget=on&cat=${this.currentCategory.name}`,
        };
      }
      return {
        normal: '',
        widget: '',
      };
    },
  },
  methods: {
    deleteCategory(category) {
      this.$emit('deletecategory', category);
    },
    openLinksDialog(category) {
      this.currentCategory = category;
      this.dialog = true;
    },
    closeLinksDialog() {
      this.currentCategory = null;
      this.dialog = false;
    },
    copyLink(id) {
      const copyText = document.querySelector(id);
      copyText.select();
      document.execCommand('copy');
      this.$toasted.show(this.$t('copiedLink'), { icon: 'check ', duration: 2000 });
    },
    imgUrl(iconName) {
      return `${this.baseUrl}/api/v3/sites/${this.site.id}/icons/${iconName}.svg`;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "toolbarTitle": "Identifiant",
    "linkTitle": "Liens vers cette catégorie",
    "mainAppLinkTitle": "Lien vers l'application principale",
    "widgetAppLinkTitle": "Lien vers le widget (Uniquement pour intégration en iframe)",
    "btnClose": "Fermer",
    "btnAddCategory": "Ajouter une catégorie",
    "copiedLink": "Lien copié dans le presse-papier !"
  },
  "en": {
    "toolbarTitle": "Identifier",
    "linkTitle": "Links to this category",
    "mainAppLinkTitle": "Link to the main application",
    "widgetAppLinkTitle": "Link to the widget (Only for iframe integration)",
    "btnClose": "Close",
    "btnAddCategory": "Add a category",
    "copiedLink": "Link copied to clipboard!"
  }
}
</i18n>
