<template>
  <v-container fluid>
    <v-spacer />
    <div v-if="loading === true">
      <h3 class="text-xs-center">{{ $t('textLoading') }}</h3>
      <v-progress-linear
        :indeterminate="true" />
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <h1>{{ pageTitle }}</h1>
        </v-col>
      </v-row>

      <v-row v-if="!isIdentifierValid && !isNew">
        <v-col>
          <v-alert
            :value="true"
            type="warning">
            {{ $t('warningIdentifierFormat') }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <identifier-card
            v-model="identifier"
            :title="$t('titleIdentifierCard')"
            :subtitle="$t('subtitleIdentifierCard')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <icon-manager
            :base-url="apiBaseUrl"
            :icon-name="category.icon ? category.icon : identifier"
            :destination-id="siteId"
            :is-new="isNew"
            @icon-updated="onIconUpdated"
            @content-updated="onIconContentUpdated"
            @color-updated="onIconColorUpdated"
          />
        </v-col>
      </v-row>
      <v-row pt-5>
        <v-col>
          <translations-list
            :initial-translations="translations"
            :include-description="false"
            @transactionschanged="onTranslationsChanged" />
        </v-col>
      </v-row>

      <v-row pt-5>
        <v-col>
          <tags-list
            :initial-tags="tags"
            :destinationId="siteId"
            :categoryId="category.id ? category.id : -1"
            @updated="updateTags" />
        </v-col>
      </v-row>
      <v-row pt-5>
        <v-col class="d-flex justify-end">
          <v-btn
            :disabled="!isValid"
            :ripple="true"
            @click="save"
            raised
            large
            :loading="saving"
            color="accent">{{ btnTitle }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
/* eslint-disable max-len */

import { mapState, mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import validation from '../services/validation';
import IdentifierCard from '../components/base/IdentifierCard.vue';
import TranslationsList from '../components/base/TranslationsList.vue';
import UserNotifications from '../mixins/UserNotifications.vue';
import TagsList from '../components/categories/TagsList.vue';
import IconManager from '../components/categories/IconManager.vue';
import * as at from '../store/actionTypes';
import config from '../config';

import axios from '../services/axios';

export default {
  components: {
    IdentifierCard,
    TranslationsList,
    TagsList,
    IconManager,
  },
  mixins: [UserNotifications],

  data() {
    return {
      saving: false,
      id: 0,
      identifierValue: null,
      translationsValue: [],
      tagsValue: [],
      iconColor: '#cc0000',
      iconIsCustom: false,
      iconContent: null,
      iconChanged: false,
      updatedIcon: null, // contains the latest icon from the icon component
    };
  },

  computed: {
    loading() {
      return this.categoriesLoaded !== true;
    },

    ...mapState({
      categories: (state) => state.categories.categoriesList,
      categoriesLoaded: (state) => state.categories.didFetchCategories,
    }),
    apiBaseUrl() {
      return config.apiUrl;
    },
    category() {
      if (this.$route.params.catId) {
        const catID = parseInt(this.$route.params.catId, 10);
        const [cat] = this.categories.filter((c) => c.id === catID);
        return cat;
      }
      return {
        id: 0,
        name: '',
        locales: [],
      };
    },
    identifier: {
      get() {
        if (this.category !== null) {
          if (this.identifierValue === null) {
            return this.category.name;
          }
        }
        return this.identifierValue;
      },
      set(value) {
        this.identifierValue = value;
      },
    },
    translations: {
      get() {
        if (this.translationsValue.length > 0) {
          return this.convertLocalesForComponent(this.translationsValue);
        }
        if (this.category !== null) {
          return this.convertLocalesForComponent(this.category.locales);
        }
        return [];
      },
      set(value) {
        this.translationsValue = this.convertLocalesFromComponent(value);
      },
    },
    tags: {
      get() {
        if (this.tagsValue.length > 0) {
          return this.tagsValue;
        }
        if (this.category !== null && this.category.tags) {
          return this.category.tags;
        }
        return [];
      },
      set(value) {
        this.tagsValue = value;
      },
    },
    ...mapGetters('auth', [
      'token',
    ]),
    btnTitle() {
      if (this.saving === true) {
        return this.$t('btnTitleSaving');
      }
      return this.$t('btnTitleSave');
    },
    siteId() {
      return parseInt(this.$route.params.siteId, 10);
    },
    isIdentifierValid() {
      return validation.ValidateIdentifier(this.identifier);
    },
    isNew() {
      if (!this.category) {
        return true;
      }
      if (this.category.id === 0) {
        return true;
      }
      return false;
    },
    isValid() {
      if (!this.isIdentifierValid) {
        return false;
      }
      if (this.translations.length === 0) {
        return false;
      }
      return true;
    },

    pageTitle() {
      if (this.isNew) {
        return this.$t('pageTitleCreate');
      }
      return this.$t('pageTitleUpdate');
    },
  },
  methods: {
    ...mapActions({
      fetchCategories: at.FETCH_CATEGORIES,
      createCategory: at.CREATE_CATEGORY,
      updateCategory: at.UPDATE_CATEGORY,
    }),
    makeNewIcon(name, color, content) {
      return {

        destinationId: this.siteId,
        name,
        isCustom: content && content !== '',
        color,
        content,
      };
    },
    onIconColorUpdated(newColor) {
      this.iconColor = newColor;
      this.iconIsCustom = false;
      this.iconChanged = true;
    },
    onIconContentUpdated(newContent) {
      this.iconIsCustom = true;
      this.iconContent = newContent;
      this.iconChanged = true;
    },
    convertLocalesForComponent(locales) {
      return locales.map((l) => ({
        uuid: l.uuid ? l.uuid : uuidv4(),
        categoryId: l.categoryId,
        name: l.title,
        locale: l.locale,
      }));
    },
    convertLocalesFromComponent(locales) {
      return locales.map((l) => ({
        categoryId: l.categoryId,
        title: l.name,
        locale: l.locale,
      }));
    },
    async populateState() {
      try {
        await this.$store.dispatch('site/loadSites', this.token);
        await this.fetchCategories({ siteId: this.siteId, token: this.token });
      } catch (error) {
        this.errorNotification(this.$t('errorLoading'), error);
      }
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.update();
      }
    },
    onTranslationsChanged(incomingTranslations) {
      this.translations = incomingTranslations;
    },
    updateTags(newTags) {
      this.tags = newTags;
    },

    async create() {
      const cat = {
        name: this.identifier,
        destinationId: parseInt(this.siteId, 10),
        type: 'place',
        locales: this.convertLocalesFromComponent(this.translations),
        tags: this.tags,
        icon: this.identifier,
      };
      try {
        await this.createCategory({ siteId: this.siteId, category: cat, token: this.token });
        await this.createIcon(this.updatedIcon);
        this.successNotification(this.$t('notificationCategorySaved'));
        this.redirect();
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorSavingCategory'), error);
      }
    },
    redirect() {
      this.$router.push({ name: 'categoriesList', params: { id: this.siteId } });
    },
    async update() {
      const cat = {
        id: parseInt(this.category.id, 10),
        name: this.identifier,
        destinationId: parseInt(this.siteId, 10),
        type: 'place',
        versionHash: this.category.versionHash,
        locales: this.convertLocalesFromComponent(this.translations),
        tags: this.tags,
        icon: this.category.icon ? this.category.icon : 'default',

      };

      try {
        await this.updateCategory({ siteId: this.siteId, category: cat, token: this.token });
        if (this.iconChanged === true) {
          if (this.updatedIcon && this.updatedIcon.id === null) {
            this.createIcon(this.updatedIcon);
          } else if (this.updatedIcon) {
            this.updateIcon(this.updatedIcon);
          }
        }
        this.successNotification(this.$t('notificationCategoryUpdated'));
        this.redirect();
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorUpdatingCategory'), error);
      }
    },
    onIconUpdated(icon) {
      this.updatedIcon = icon;
      this.iconChanged = true;
    },
    async createIcon(icon) {
      const i = JSON.parse(JSON.stringify(icon));
      i.name = this.identifier;
      await axios.post(
        `/api/v3/sites/${this.siteId}/icons`,
        i,
        {
          headers: {
            Authorization: `BEARER ${this.token}`,
          },
        },
      );
    },
    async updateIcon(icon) {
      await axios.put(
        `/api/v3/sites/${this.siteId}/icons/${icon.name}`,
        icon,
        {
          headers: {
            Authorization: `BEARER ${this.token}`,
          },
        },
      );
    },
  },

  async mounted() {
    await this.populateState();
  },
};
</script>
<i18n>
{
  "fr": {
    "textLoading": "Chargement de la catégorie...",
    "warningIdentifierFormat": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer tous les caractères&nbsp; accentués, apostrophes et espaces",
    "titleIdentifierCard": "Identifiant de la catégorie",
    "subtitleIdentifierCard": "Choisissez un identifiant unique pour cette catégorie",
    "btnTitleSaving": "Sauvegarde en cours",
    "btnTitleSave": "Sauvegarder la catégorie",
    "pageTitleCreate": "Créer une catégorie",
    "pageTitleUpdate": "Mettre à jour une catégorie",
    "errorLoading": "Impossible de contacter le serveur",
    "notificationCategorySaved": "Catégorie sauvegardée",
    "notificationErrorSavingCategory": "Impossible de sauvegarder la catégorie",
    "notificationCategoryUpdated": "Catégorie mise à jour",
    "notificationErrorUpdatingCategory": "Impossible de mettre à jour la catégorie"
  },
  "en": {
    "textLoading": "Loading...",
    "warningIdentifierFormat": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer tous les caractères&nbsp; accentués, apostrophes et espaces",
    "titleIdentifierCard": "Category identifier",
    "subtitleIdentifierCard": "Choose a unique identifier for this category",
    "btnTitleSaving": "Saving...",
    "btnTitleSave": "Save this category",
    "pageTitleCreate": "Create a category",
    "pageTitleUpdate": "Update a category",
    "errorLoading": "Unable to reach server",
    "notificationCategorySaved": "Category save",
    "notificationErrorSavingCategory": "Unable to save the category",
    "notificationCategoryUpdated": "Category updated",
    "notificationErrorUpdatingCategory": "Unable to update the category"
  }
}
</i18n>
