<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="green"
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('activatorBtn') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          {{$t('title') }}
        </v-card-title>

        <v-card-text>
          {{ $t('content') }}
          <v-select
            :label="$t('labelTargetDestination')"
            v-model="selectedSiteId"
            :cache-items="true"
            :eager="true"
            :items="destinationItems" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="accent"
            text
            @click="dialog = false"
          >
            {{ $t('cancelBtn') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="sendDuplication"
          >
            {{ $t('duplicateBtn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    placeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      selectedSiteId: this.currentSiteId,
      destinationItems: [],
    };
  },
  computed: {
    ...mapState('site', [
      'sites',
      'currentSiteId',
    ]),
  },
  methods: {
    sendDuplication() {
      const event = {
        targetSiteId: this.selectedSiteId,
        poiID: this.placeId,
      };
      this.$emit('duplicate', event);
      this.dialog = false;
    },
  },
  mounted() {
    const sites = Object.values(this.sites).map((s) => ({
      text: s.name,
      value: s.id,
    }));
    sites.sort((a, b) => a.text.localeCompare(b.text));
    this.destinationItems = sites;
  },
};
</script>
<i18n>
{
  "fr": {
    "activatorBtn": "Dupliquer",
    "title": "Dupliquer ce lieu",
    "content": "Choisissez la destination cible pour ce lieu d'intérêt. Le lieu sera intégralement copié sur cette destination",
    "labelTargetDestination": "Destination cible",
    "cancelBtn": "Annuler",
    "duplicateBtn": "Dupliquer"
  },
  "en": {
    "activatorBtn": "Clone",
    "title": "Clone this POI",
    "content": "Choose the target destination on which this POI will be cloned",
    "labelTargetDestination": "Target destination",
    "cancelBtn": "Cancel",
    "duplicateBtn": "Clone"
  }
}
</i18n>
