<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('headline') }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('labelTeamName')"
                counter="1024"
                required
                v-model="currentTeam.name"
                :rules="nameRules"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                type="email"
                :label="$t('labelTeamEmail')"
                counter="1024"
                v-model="currentTeam.email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <team-color-picker v-model="currentTeam.color" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="accent" text @click="$emit('close')">{{ $t('btnCancel') }}</v-btn>
      <v-btn color="primary" :disabled="!valid" text @click="$emit('save', currentTeam)">
        {{ $t('btnEdit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TeamColorPicker from './TeamColorPicker.vue';

export default {
  components: {
    TeamColorPicker,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTeam: this.team,
      valid: true,
      nameRules: [
        (v) => !!v || this.$t('rulesNameMissing'),
        (v) => (v && v.length <= 1024) || this.$t('rulesTooLong'),
        (v) => (v && v.length >= 2) || this.$t('rulesTooShort'),
      ],
    };
  },
};
</script>
<i18n>
{
  "fr": {
    "headline": "Modifier l'équipe",
    "labelTeamName": "Nom de l'équipe",
    "labelTeamEmail": "Email de l'équipe",
    "btnCancel": "Annuler",
    "btnEdit": "Modifier",
    "rulesNameMissing": "Veuillez saisir un nom d'équipe",
    "rulesTooLong": "Le nom doit faire moins de 1024 caractères",
    "rulesTooShort": "Le nom doit faire au moins 2 caractères"
  },
  "en": {
    "headline": "Edit the team",
    "labelTeamName": "Team name",
    "labelTeamEmail": "Team email",
    "btnCancel": "Cancel",
    "btnEdit": "Save",
    "rulesNameMissing": "Please enter a team name",
    "rulesTooLong": "Name must be less than 1024 chars long",
    "rulesTooShort": "Name must be at least 2 chars long"
  }
}
</i18n>
