<template>
  <v-card class="mb-12" flat>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-container>
          <v-row align="center">
            <v-col cols="6">
              <v-checkbox
                v-model="hasEndGame"
                @change="update()"
                :label="$t('labelHasEndGame')" />
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-for="(prop, index) in propositions" :key="index">
            <v-col cols="9">
              <v-text-field
                :label="$t('textField')"
                :disabled="!hasEndGame"
                required
                v-model="prop.text"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                :label="$t('minScoreField')"
                v-model.number="prop.minScore"
                :disabled="!hasEndGame"
              />
            </v-col>
            <v-col cols="1">
              <v-btn icon ripple @click="removeProposition(index)" v-if="index > 0">
                <v-icon color="grey lighten-1">delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              fab
              color="primary"
              :disabled="!hasEndGame"
              @click="addProposition"><v-icon>mdi-plus</v-icon></v-btn>
          </v-row>
          <v-row align="center" justify="center">
            <v-select

              v-model="endgamePoiId"
              :items="formatedPois"
              required
              :disabled="!hasEndGame"
              :label="$t('labelFieldPoi')"

            />
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    game: {
      type: Object,
      required: true,
    },
    destinationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasEndGame: this.game.hasEndGame,
      propositions: this.game.engameRewards ? this.game.engameRewards : [],
      endgamePoiId: this.game.endgamePoiId,
    };
  },
  computed: {
    ...mapState({
      pois: (state) => state.poi.poiList,
    }),
    ...mapGetters('auth', ['token']),
    formatedPois() {
      return this.pois.map((poi) => ({
        text: poi.name,
        value: poi.uuid,
      }));
    },
  },

  methods: {
    ...mapActions({
      fetchPOIs: 'poi/all',
    }),
    update() {
      this.$emit('update', { endgamePoiId: this.endgamePoiId, hasEndGame: this.hasEndGame, endgameRewards: this.propositions });
    },
    addProposition() {
      this.propositions.push({ minScore: 0, maxScore: 50, text: '' });
      this.update();
    },
    removeProposition(index) {
      this.propositions.splice(index, 1);
      this.update();
    },
  },
  async mounted() {
    await this.fetchPOIs({ siteId: this.destinationId, token: this.token });
  },
};

</script>
<i18n>
  {
    "fr": {
      "title": "Ajouter les défis de fin de jeu EVJF",
      "labelHasEndGame": "Le jeu a-t-il des défis de fin de jeu ?",
      "textField": "Texte du défi",
      "minScoreField": "Score minimum",
      "maxScoreField": "Score maximum",
      "labelFieldPoi": "Point d'intérêt de fin de jeu"
    }
  }
</i18n>
