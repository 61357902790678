<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('title') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <template>
        <draggable v-model="orderedPois" group="pois" @start="drag = true" @end="drag = false">

          <div v-for="p in orderedPois" :key="p.id">
            <v-list-item>
              <v-list-item-avatar>
                <v-btn icon ripple>
                  <v-icon>reorder</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ p.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </draggable>
      </template>
    </v-list>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="secondary"
        text
        @click="$emit('close')"
      >
        {{ $t('btnCancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="$emit('save', orderedPois);"
      >
        {{ $t('btnSave') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: { draggable },
  props: {
    pois: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      drag: false,
      poisValue: [],
    };
  },
  computed: {
    orderedPois: {
      get() {
        let newPoisArray = [];
        if (this.poisValue.length > 0) {
          newPoisArray = this.poisValue;
        } else {
          newPoisArray = this.pois;
        }

        newPoisArray.sort((a, b) => a.order - b.order);
        const notEmptyPOIs = [];
        newPoisArray.forEach((part) => {
          const poi = part.id;
          if (poi) {
            notEmptyPOIs.push(part);
          }
        });
        return notEmptyPOIs;
      },
      set(value) {
        const newValues = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= value.length; i++) {
          const nv = value[i - 1];
          if (nv) {
            nv.order = i;
            newValues.push(nv);
          }
        }
        this.poisValue = newValues;
      },
    },
  },
};

</script>
<i18n>
{
  "fr": {
    "title": "Déplacer un lieu dans la liste pour définir son ordre",
    "btnCancel": "Annuler",
    "btnSave": "Enregistrer"
  },
  "en": {
    "title": "Drag a POI in the list to reorder it",
    "btnCancel": "Cancel",
    "btnSave": "Save"
  }
}
</i18n>
