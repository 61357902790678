<template>
  <v-list-item
    :disabled="menuIsDisabled"
    @click="generatePicturesExport">
    <v-list-item-title>{{ btnText }}</v-list-item-title>
  </v-list-item>
</template>
<script>
export default {
  props: {
    partyStatus: {
      type: String,
      required: true,
    },
    archiveStatus: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    menuIsDisabled() {
      if (this.partyStatus !== 'ended') {
        return true;
      }
      if (this.archiveStatus === 'started') {
        return true;
      }
      return false;
    },
    btnText() {
      if (this.partyStatus !== 'ended') {
        return this.$t('partyNotEnded');
      }
      if (this.archiveStatus === 'started') {
        return this.$t('archiveStarted');
      }
      return this.$t('generatePicturesExportBtn');
    },
  },
  methods: {
    generatePicturesExport() {
      this.$emit('generate');
    },
  },
};
</script>
<i18n>
    {
        "fr": {
            "generatePicturesExportBtn": "Générer l'export des photos",
            "partyNotEnded": "Terminer la partie démarrer un export des photos",
            "archiveStarted": "L'export est en cours et sera disponible dans quelques minutes"
        },
        "en": {
            "generatePicturesExportBtn": "Generate pictures export",
            "partyNotEnded": "End the party to generate pictures export",
            "archiveStarted": "Pictures export is in progress and will be available in a few minutes"
        }
    }
</i18n>
