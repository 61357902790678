<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>

    <v-card-text>
      <v-container fluid grid-list-sm>
        <v-layout
          row
          wrap
        >
          <v-flex xs9>
            <v-slider
              v-model="points"
              :min="min"
              :max="max"
              :step="1"
              label="Points"
              thumb-label="always"
            />
          </v-flex>

          <v-flex xs3>
            <v-text-field
              v-model="points"
              class="mt-0"
              type="number"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initialPoints: {
      type: Number,
      required: false,
      default: 100,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 3000,
    },
  },
  data() {
    return {
      points: this.initialPoints,
    };
  },
  watch: {
    points() {
      this.$emit('input', this.points);
    },
  },
};
</script>
