<template>
  <v-container>
    <v-row>
      <v-col cols="12"><h4>{{ $t('titleClueSection') }}</h4></v-col>
    </v-row>
    <v-row v-for="(clue, idx) in clues" :key="idx">
      <v-col class="pt-5" cols="8">
        <v-text-field
          :label="$t('labelQuestionClue')"
          v-model="clue.content"
          @input="dirty = true"
          :placeholder="$t('placeHolderQuestionClue')"
          required
          :disabled="editingClueIdx !== idx"
        />
      </v-col>
      <v-col class="pt-5" cols="2">
        <v-text-field
          v-model.number="clue.cost"
          :label="$t('labelCostClue')"
          type="number"
          :disabled="editingClueIdx !== idx"
          :rules="[rules(clue.cost)]" />
      </v-col>
      <v-col v-if="editingClueIdx === null && clue.id" class="pt-5 text-center" cols="1">
        <v-btn height="70%" color="primary" @click="editingClueIdx = idx" class="button-font-size-xl">
          <v-icon>edit</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="editingClueIdx === idx" class="pt-5 text-center" cols="1">
        <v-btn
          height="70%"
          color="primary"
          @click="updateClue(idx)"
          class="button-font-size-xl"
          :disabled="clue.content === '' || rules(clue.cost) !== true"
        >
          <v-icon>check</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pt-5 text-center" cols="1">
        <v-btn v-if="editingClueIdx === null" height="70%" color="primary" @click="displayConfirmDialog = true, deletingClueIdx = idx" class="button-font-size-xl">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-5" cols="8">
        <v-text-field
          :label="$t('labelQuestionClue')"
          v-model="clue.content"
          @input="dirty = true"
          :placeholder="$t('placeHolderQuestionClue')"
          required
          :disabled="editingClueIdx !== null"
        />
      </v-col>
      <v-col class="pt-5" cols="2">
        <v-text-field
          v-model.number="clue.cost"
          :label="$t('labelCostClue')"
          type="number"
          :disabled="editingClueIdx !== null"
          :rules="[rules(clue.cost)]" />
      </v-col>
      <v-col class="pt-5 text-center" cols="2">
        <v-btn
          height="50px"
          color="primary"
          :disabled="!clue.content || rules(clue.cost) !== true"
          @click="addClue">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="displayConfirmDialog">
      <confirmation-dialog
        :title="$t('dialogTitleClue')"
        :subtitle="$t('dialogSubtitleClue')"
        :cancelButtonTitle="$t('dialogCancelClue')"
        :confirmButtonTitle="$t('dialogConfirmClue')"
        @cancel="displayConfirmDialog = false"
        @confirm="removeClue(deletingClueIdx)"
        v-show="displayConfirmDialog === true" />
    </v-dialog>
  </v-container>
</template>
<script>
import * as Sentry from '@sentry/vue';
import { mapGetters } from 'vuex';
import UserNotifications from '../../mixins/UserNotifications.vue';
import { getClues, deleteClue, putClue } from '../../services/clues';
import ConfirmationDialog from '../base/ConfirmationDialog.vue';

export default {
  components: {
    ConfirmationDialog,
  },
  mixins: [UserNotifications],
  props: {
    questionId: {
      type: String,
      required: false,
      default: null,
    },
    isNewQuestion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      clue: {
        questionId: null,
        questionType: 'surveys',
        content: '',
        cost: 0,
        updatedBy: null,
      },
      clues: [],
      newClues: [],
      editingClueIdx: null,
      deletingClueIdx: null,
      displayConfirmDialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'token',
    ]),
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    rules(v) {
      if (Number.isInteger(v) && v >= 0 && v < 1000) {
        return true;
      }
      return this.$t('labelCostRules');
    },
    addClue() {
      this.clues.push(this.clue);
      this.newClues.push(this.clue);
      this.clue = {
        questionId: this.questionId,
        questionType: 'surveys',
        content: '',
        cost: 0,
        updatedBy: this.user.name,
      };
      this.$emit('clues', this.newClues);
    },
    async removeClue(idx) {
      if (this.clues[idx] && this.clues[idx].id !== undefined) {
        try {
          await deleteClue(this.clues[idx].id, this.token);
          this.clues.splice(idx, 1);
        } catch (error) {
          this.errorNotification(this.$t('notificationErrorDeleteClues'), error);
          Sentry.captureException(error);
        }
      } else {
        this.clues.splice(idx, 1);
      }
      this.displayConfirmDialog = false;
    },
    async updateClue(idx) {
      try {
        await putClue(this.clues[idx], this.token);
        this.errorNotification(this.$t('notificationClueUpdated'));
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorUpdatingingClues'), error);
        Sentry.captureException(error);
      }
      this.editingClueIdx = null;
    },
  },
  async mounted() {
    this.clue.questionId = this.questionId;
    this.clue.updatedBy = this.user.name;
    if (!this.isNewQuestion) {
      try {
        const res = await getClues(this.questionId, this.token);
        this.clues = res.sort((a, b) => b.cost - a.cost);
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorFetchingClues'), error);
        Sentry.captureException(error);
      }
    }
  },
};
</script>
<i18n>
  {
    "fr": {
      "titleClueSection": "Gestion des indices",
      "labelQuestionClue": "Intitulé de l'indice",
      "placeHolderQuestionClue": "Indice",
      "labelCostClue": "Coût de l'indice",
      "labelClueRules": "Le nombre doit être un entier entre 0 et 1000",
      "notificationErrorFetchingClues": "Erreur lors du chargement des indices de la question",
      "notificationErrorDeleteClues": "Erreur lors de la suppression de l'indice",
      "notificationClueUpdated": "Indice mis à jour",
      "notificationErrorUpdatingingClues": "Erreur lors de la mise à jour de l'indice",
      "dialogTitleClue": "Supression de l'indice",
      "dialogSubtitleClue": "Voulez-vous vraiment supprimer cet indice ?",
      "dialogCancelClue": "Annuler",
      "dialogConfirmClue": "Supprimer",
      "labelCostRules": "Le coût doit être un entier entre 0 et 1000"
    },
    "en": {
      "titleClueSection": "Clues management",
      "labelQuestionClue": "Title of clue",
      "placeHolderQuestionClue": "Clue",
      "labelCostClue": "Clue cost",
      "labelClueRules": "Number has to be integer between 0 and 1000",
      "notificationErrorFetchingClues": "Error to get clues of the question",
      "notificationErrorDeleteClues": "Error to delete the clue",
      "notificationClueUpdated": "Updated clue",
      "notificationErrorUpdatingingClues": "Error to update the clue",
      "dialogTitleClue": "Clue supression",
      "dialogSubtitleClue": "Do you really want to delete this clue?",
      "dialogCancelClue": "Cancel",
      "dialogConfirmClue": "Delete",
      "labelCostRules": "Le coût doit être un entier entre 0 et 1000"
    }
  }
  </i18n>
