<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h2">{{ $t('pageTitle') }}</h2>
      </v-col>
    </v-row>
    <v-spacer />

    <v-row>
      <v-col cols="12">
        <categories-list
          :base-url="baseUrl"
          :categories="categories"
          :site="site"
          @deletecategory="openDeletionDialog"
          @new="addCategory"
          @listitemclicked="openEditionView" />
      </v-col>
    </v-row>

    <v-dialog
      v-model="deleteDialogOpen"
      max-width="50%">
      <confirmation-dialog
        :title="$t('deleteDialogTitle')"
        :subtitle="$t('deleteDialogSubtitle')"
        :cancel-button-title="$t('deleteDialogCancelBtn')"
        :confirm-button-title="$t('deleteDialogDeleteBtn')"
        @confirm="deleteCategory"
        @cancel="closeDeletionDialog"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CategoriesList from '../components/categories/CategoriesList.vue';
import * as at from '../store/actionTypes';
import ConfirmationDialog from '../components/base/ConfirmationDialog.vue';
import UserNotification from '../mixins/UserNotifications.vue';
import config from '../config';

export default {
  mixins: [UserNotification],
  components: {
    CategoriesList,
    ConfirmationDialog,
  },
  data() {
    return {
      isDeleting: false,
      currentCategory: null,
      deleteDialogOpen: false,
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.categories.categoriesList,
    }),
    siteId() {
      return this.$route.params.siteId;
    },
    ...mapGetters('auth', [
      'token',
    ]),
    site() {
      return this.$store.getters['site/allSites'][this.siteId];
    },
    baseUrl() {
      return config.apiUrl;
    },
  },
  methods: {
    ...mapActions({
      fetchCategories: at.FETCH_CATEGORIES,
      doDeleteCategory: at.DELETE_CATEGORY,
    }),
    openDeletionDialog(category) {
      this.currentCategory = category;
      this.deleteDialogOpen = true;
    },
    openEditionView(category) {
      this.$router.push({ name: 'categoryEditor', params: { id: this.siteId, catId: category.id } });
    },
    closeDeletionDialog() {
      this.currentCategory = null;
      this.deleteDialogOpen = false;
    },
    addCategory() {
      this.$router.push({ name: 'newCategoryEditor', params: { siteId: this.siteId, site: this.site } });
    },
    async deleteCategory() {
      try {
        await this.doDeleteCategory(
          { siteId: this.siteId, categoryId: this.currentCategory.id, token: this.token },
        );
        this.successNotification(this.$t('notificationDeletionSuccess'));
      } catch (error) {
        this.errorNotification(this.$t('notificationDeletionError'), error);
      }
      this.currentCategory = null;
      this.deleteDialogOpen = false;
    },
  },
  async mounted() {
    try {
      await this.fetchCategories({ siteId: this.siteId, token: this.token });
    } catch (error) {
      this.errorNotification(this.$t('notificationErrorLoading'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Les catégories de lieux d'intérêt",
    "deleteDialogTitle": "Supprimer cette catégorie ?",
    "deleteDialogSubtitle": "Cette action est irréversible",
    "deleteDialogCancelBtn": "Annuler",
    "deleteDialogDeleteBtn": "Supprimer",
    "notificationErrorLoading": "Impossible de récupérer la liste des catégories",
    "notificationDeletionSuccess": "Catégorie supprimée",
    "notificationDeletionError": "Impossible de supprimer la catégorie"
  },
  "en": {
    "pageTitle": "POI categories",
    "deleteDialogTitle": "Delete this category?",
    "deleteDialogSubtitle": "This cannot be undone",
    "deleteDialogCancelBtn": "Cancel",
    "deleteDialogDeleteBtn": "Delete",
    "notificationErrorLoading": "Unable to retrieve the categories list",
    "notificationDeletionSuccess": "Category deleted",
    "notificationDeletionError": "Unable to delete the category"
  }
}
</i18n>
