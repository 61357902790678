<template>
  <v-card>
    <v-card-title>
      POI: {{ poi.name }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <h3>{{ $t('titleContentChecking') }}</h3>
        <v-divider />

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Contrôle
                </th>
                <th class="text-right">{{ $t('tableHeaderStatus') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('tableColTranslationFound')}} </td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(hasLocale).color">
                    {{ statusIconClass(hasLocale).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>{{ $t('tableColCategoryFound') }}</td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(poi.categoryIds && poi.categoryIds.length > 0).color">
                    {{ statusIconClass(poi.categoryIds && poi.categoryIds.length > 0).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>{{ $t('tableColPictureFound')}}</td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(poi.pictures && poi.pictures.length > 0).color">
                    {{ statusIconClass(poi.pictures && poi.pictures.length > 0).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>{{ $t('tableColCheckinType') }}</td>
                <td class="text-right"><strong>{{ poi.checkinType }}</strong></td>
              </tr>
              <tr>
                <td>{{$t('tablColTemporary') }}</td>
                <td class="text-right">
                  <strong v-if="poi.isTemporary === true">
                    {{ $t('tableColYES') }}
                  </strong>
                  <p v-else> {{ $t('tableColNo') }}</p>
                </td>
              </tr>
              <tr v-if="poi.isTemporary === true">
                <td>{{$t('tableColTempoDates')}}</td>
                <td
                  class="text-right"
                  v-html="$t('tableColDates', { startDate: poi.startDate, endDate: poi.endDate })"
                />
              </tr>
              <tr>
                <td>{{ $t('tableColIsVip') }}</td>
                <td class="text-right">
                  <strong
                    v-if="poi.requiresMinimumBalance === true"> {{ $t('tableColYES') }}</strong>
                  <p v-else> {{ $t('tableColNo') }}</p>
                </td>
              </tr>
              <tr v-if="poi.requiresMinimumBalance === true">
                <td>{{ $t('tableColPoints') }}</td>
                <td class="text-right">
                  <v-icon v-if="poi.requiredBalance > 200" color="orange">mdi-alert</v-icon>
                  <strong>
                    {{ poi.requiredBalance }}
                  </strong>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="includeContent === true && currentLocale !== null" class="py-5">
          <h3>{{$t('titleTranslation')}}</h3>
          <v-divider />
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('tableHeaderField')}}
                  </th>
                  <th class="text-left">{{ $t('tableHeaderContent') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('tableColTitle') }}</td>
                  <td>
                    <blockquote class="blockquote">{{ currentLocale.name }} </blockquote>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('tableColDesc') }}</td>
                  <td v-html="desc(currentLocale.description)" />
                </tr>
                <tr
                  v-if="
                    currentLocale.guidingInstructions
                      && currentLocale.guidingInstructions.length > 0">
                  <td>{{ $t('tableColGuidingInstructions') }}</td>
                  <td>
                    {{ currentLocale.guidingInstructions }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <h3>{{ $t('titleChallenges')}}</h3>
        <v-divider />
        <surveys-validator
          :poi-uuid="poi.uuid"
          :lang="lang"
          :display-mode="poi.surveyDisplayMode"
          :include-content="includeContent"
        />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="accent" @click="goToPoi"> {{ $t('btnGoToPoi') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import SurveysValidator from './SurveysValidator.vue';

export default {
  components: {
    SurveysValidator,
  },
  props: {
    poi: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    includeContent: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasLocale() {
      if (!this.poi) {
        return false;
      }
      if (!this.poi.locales) {
        return false;
      }

      const localeKeys = Object.keys(this.poi.locales);
      if (localeKeys.length === 0) {
        return false;
      }
      const found = localeKeys.filter((l) => l === this.lang);
      return found.length === 1;
    },
    currentLocale() {
      if (!this.poi) {
        return null;
      }
      if (!this.poi.locales) {
        return null;
      }

      const localeKeys = Object.keys(this.poi.locales);
      if (localeKeys.length === 0) {
        return null;
      }
      const found = localeKeys.filter((l) => l === this.lang);
      if (found.length === 0) {
        return null;
      }
      return this.poi.locales[found[0]];
    },
  },
  methods: {
    desc(desc) {
      return `<blockquote class="blockquote">${desc}</blockquote>`;
    },
    goToPoi() {
      this.$router.push(`/sites/${this.poi.siteId}/places/${this.poi.id}`);
    },
    statusIconClass(value) {
      if (!value) {
        return {
          icon: 'mdi-alert-circle',
          color: 'red',
        };
      }
      return {
        icon: 'mdi-check-circle',
        color: 'green',
      };
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "titleContentChecking": "contrôle des contenus",
      "tableHeaderStatus": "Statut",
      "tableColTranslationFound": "Traduction trouvée",
      "tableColCategoryFound": "Au moins une catégorie trouvée",
      "tableColPictureFound": "A au moins une photo",
      "tableColCheckinType": "Type de checkin",
      "tablColTemporary": "Lieu temporaire",
      "tableColYES": "OUI",
      "tableColNo": "Non",
      "tableColTempoDates": "Lieu temporaire : dates d'activité (attention, dates UTC !)",
      "tableColDates": "Du <strong>{startDate}</strong> au <strong> {endDate}</strong>",
      "tableColIsVip": "Lieu VIP",
      "tableColPoints": "Points nécessaires",
      "titleTranslation": "Contrôle de la traduction",
      "tableHeaderField": "Champ",
      "tableHeaderContent": "Contenu",
      "tableColTitle": "Titre",
      "tableColDesc": "Description",
      "tableColGuidingInstructions": "Instructions de guidage",
      "titleChallenges": "Contrôle des défis",
      "btnGoToPoi": "Accéder à la fiche du POI"
    },
    "en": {
      "titleContentChecking": "Content checking",
      "tableHeaderStatus": "Status",
      "tableColTranslationFound": "Translation found",
      "tableColCategoryFound": "At least one category found",
      "tableColPictureFound": "At least one picture",
      "tableColCheckinType": "Checkin type",
      "tablColTemporary": "Temporary location",
      "tableColYES": "YES",
      "tableColNo": "No",
      "tableColTempoDates": "Temporary location: activity dates (UTC dates!)",
      "tableColDates": "From <strong>{startDate}</strong> to <strong> {endDate}</strong>",
      "tableColIsVip": "VIP location",
      "tableColPoints": "Required points",
      "titleTranslation": "Translation check",
      "tableHeaderField": "Field",
      "tableHeaderContent": "Content",
      "tableColTitle": "Title",
      "tableColDesc": "Description",
      "tableColGuidingInstructions": "Guiding instructions",
      "titleChallenges": "Challenges check",
      "btnGoToPoi": "Go to POI page"
    }
  }
</i18n>
