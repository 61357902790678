<template>
  <v-dialog v-model="dialog" persistent max-width="550">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ $t('unlockBtnTitle') }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline">{{ $t('unlockTeamTitle') }}</v-card-title>
      <v-card-text>{{ $t('unlockTeamText') }} </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="accent"
          text
          @click="close">
          {{ $t('unlockTeamBtnNo') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="confirm()">
          {{ $t('unlockTeamBtnYes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
    },
    confirm() {
      this.$emit('unlock', this.team);
      this.close();
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "unlockTeamTitle": "Débloquer l'équipe",
      "unlockTeamText": "Voulez-vous débloquer cette équipe ?",
      "unlockTeamBtnNo": "Ne pas débloquer",
      "unlockTeamBtnYes": "Débloquer",
      "unlockBtnTitle": "Débloquer"
    },
    "en": {
      "unlockTeamTitle": "Unlock team",
      "unlockTeamText": "Do you want to unlock this team?",
      "unlockTeamBtnNo": "Do not unlock",
      "unlockTeamBtnYes": "Unlock",
      "unlockBtnTitle": "Unlock"
    }
  }
  </i18n>
