<template>
  <v-select
    class="custom"
    :rules="[v => !!v || $t('ruleColor')]"
    :items="colors"
    item-text="name"
    item-value="id"
    :label="$t('label')"
    persistent-hint
    :value="value"
    @change="$emit('input', $event)"
  >
    <template v-slot:selection="{ item }">
      <v-chip class="ma-2" small :color="item.id" />{{ item.name }}
    </template>
    <template v-slot:item="{ item }">
      <v-chip class="ma-2" small :color="item.id" />{{ item.name }}
    </template>
  </v-select>
</template>

<script>
// import teamsColor from '../../const';
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.items = this.colors;
  },
  computed: {
    colors() {
      return [
        { id: '#AD4363', name: this.$t('colors.pink') },
        { id: '#FF0000', name: this.$t('colors.red') },
        { id: '#CC0033', name: this.$t('colors.deepRed') },
        { id: '#0033CC', name: this.$t('colors.deepBlue') },
        { id: '#428BCA', name: this.$t('colors.lightBlue') },
        { id: '#34495E', name: this.$t('colors.darkBlue') },
        { id: '#44AD8E', name: this.$t('colors.mintGreen') },
        { id: '#A8D695', name: this.$t('colors.lightGreen') },
        { id: '#5CB85C', name: this.$t('colors.green') },
        { id: '#004E00', name: this.$t('colors.darkGreen') },
        { id: '#7F8C8D', name: this.$t('colors.grey') },
        { id: '#D1D100', name: this.$t('colors.yellow') },
        { id: '#A295D6', name: this.$t('colors.mauve') },
        { id: '#5843AD', name: this.$t('colors.purple') },
        { id: '#F0AD4E', name: this.$t('colors.orange') },
      ];
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "ruleColor": "Veuillez choisir une couleur",
    "label": "Couleur de l'équipe",
    "colors": {
      "pink": "Rose",
      "red": "Rouge",
      "deepRed": "Rouge Profond",
      "deepBlue": "Bleu profond",
      "lightBlue": "Bleu clair",
      "darkBlue": "Bleu sombre",
      "mintGreen": "Vert menthe",
      "lightGreen": "Vert clair",
      "green": "Vert",
      "darkGreen": "Vert foncé",
      "grey": "Grise",
      "yellow": "Jaune",
      "mauve": "Mauve",
      "purple": "Violette",
      "orange": "Orange"
    }
  },
  "en": {
    "ruleColor": "Please choose a color",
    "label": "Color for the team",
    "colors": {
      "pink": "Pink",
      "red": "Red",
      "deepRed": "Deep red",
      "deepBlue": "Deep blue",
      "lightBlue": "Light blue",
      "darkBlue": "Dark blue",
      "mintGreen": "Mint green",
      "lightGreen": "Light green",
      "green": "Green",
      "darkGreen": "Dark green",
      "grey": "Grey",
      "yellow": "Yellow",
      "mauve": "Mauve",
      "purple": "Purple",
      "orange": "Orange"
    }
  }
}
</i18n>
