import themeApi from './fetch';
import cfg from '../config';

const getThemes = async (destinationId) => {
  try {
    return await themeApi.getJSON(
      `${cfg.gameApiUrl}/gameapi/v2/simple-questions/themes?destination_id=${destinationId}`,
    );
  } catch (error) {
    return error;
  }
};

const postTheme = (theme, token) => new Promise((resolve, reject) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/simple-questions/themes`;
  themeApi
    .postJSON(url, theme, token)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getQuestions = async (themeId) => {
  try {
    const result = await themeApi.getJSON(
      `${cfg.gameApiUrl}/gameapi/v2/simple-questions?theme_id=${themeId}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};

const postQuestion = async (question, token) => {
  const res = await themeApi.postJSON(
    `${cfg.gameApiUrl}/gameapi/v1/simple-questions`,
    question,
    token,
  );
  return res;
};
const updateQuestion = async (question, token) => {
  try {
    await themeApi.putJSON(
      `${cfg.gameApiUrl}/gameapi/v1/simple-questions/${question.id}`,
      question,
      token,
    );
  } catch (error) {
    console.log(error);
  }
};

const delQuestion = async (questionId, token) => {
  try {
    await themeApi.deleteJSON(`${cfg.gameApiUrl}/gameapi/v1/simple-questions/${questionId}`, token);
  } catch (error) {
    console.log(error);
  }
};

export {
  getThemes, postTheme, getQuestions, postQuestion, updateQuestion, delQuestion,
};
