import store from '../store';

export default {
  checkAuth(to, from, next) {
    if (to.name === 'login' || store.getters['auth/authenticated'] === true) {
      next();
    } else {
      store.commit('auth/logout');
      if (to.name !== 'login') {
        next({ name: 'login' });
      }
    }
  },
};
