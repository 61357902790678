const buildHeaders = (headers = {}, token = null) => {
  const reqHeaders = new Headers();

  let authFound = false;
  Object.keys(headers).forEach((k) => {
    if (k.toLowerCase() === 'authorization') {
      authFound = true;
    }
    reqHeaders.append(k, headers[k]);
  });
  if (authFound === false) {
    reqHeaders.append('Authorization', `BEARER ${token}`);
  }
  return reqHeaders;
};

const getJSON = (URL, headersMap = {}, token = null) => new Promise((resolve, reject) => {
  const reqOptions = {
    method: 'GET',
    headers: buildHeaders(headersMap, token),
    mode: 'cors',
    cache: 'default',
  };

  const req = new Request(URL, reqOptions);

  fetch(req, reqOptions).then((response) => {
    if (response.ok) {
      response.json().then((data) => { resolve(data); }).catch((error) => { reject(error); });
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => { reject(error); });
});

// eslint-ignore-next-line
const postJSON = (URL, body, token, headersMap = {}) => new Promise((resolve, reject) => {
  const reqOptions = {
    method: 'POST',
    headers: buildHeaders(headersMap, token),
    body: JSON.stringify(body),
    mode: 'cors',
    cache: 'default',
  };

  const req = new Request(URL, reqOptions);
  fetch(req, reqOptions).then((response) => {
    if (response.ok) {
      response.json().then((data) => { resolve(data); }).catch((error) => { reject(error); });
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => { reject(error); });
});

const putJSON = (URL, body, token, headersMap = {}) => new Promise((resolve, reject) => {
  const reqOptions = {
    method: 'PUT',
    headers: buildHeaders(headersMap, token),
    body: JSON.stringify(body),
    mode: 'cors',
    cache: 'default',
  };

  const req = new Request(URL, reqOptions);

  fetch(req, reqOptions).then((response) => {
    if (response.ok) {
      response.json().then((data) => { resolve(data); }).catch((error) => { reject(error); });
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => { reject(error); });
});

const putFile = (URL, body, token, headersMap = {}) => new Promise((resolve, reject) => {
  const reqOptions = {
    method: 'PUT',
    headers: buildHeaders(headersMap, token),
    mode: 'cors',
    cache: 'default',
    body,
  };
  const req = new Request(URL, reqOptions);

  fetch(req, reqOptions).then((response) => {
    if (response.ok) {
      response.json().then((data) => { resolve(data); }).catch((error) => { reject(error); });
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => reject(error));
});

const deleteJSON = (URL, token, headersMap = {}) => new Promise((resolve, reject) => {
  const reqOptions = {
    method: 'DELETE',
    headers: buildHeaders(headersMap, token),
    mode: 'cors',
    cache: 'default',
  };

  const req = new Request(URL, reqOptions);

  fetch(req, reqOptions).then((response) => {
    if (response.ok) {
      resolve();
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => { reject(error); });
});

export default {
  getJSON,
  postJSON,
  putJSON,
  deleteJSON,
  putFile,
};
