import Vue from 'vue';
import Vuex from 'vuex';

import site from './store/modules/site';
import categories from './store/modules/categories_v2';
import places from './store/modules/places';
import rewards from './store/modules/rewards';
import config from './store/modules/config';
import auth from './store/modules/auth';
import tours from './store/modules/tours_v2';
import polls from './store/modules/polls';
import poi from './store/modules/poi';
import games from './store/modules/games';
import destinations from './store/modules/destinations';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    site,
    places,
    config,
    rewards,
    auth,
    tours,
    categories,
    polls,
    poi,
    games,
    destinations,
  },
});

Vue.prototype.$authToken = () => store['auth/token'];

export default store;
