<template>
  <v-container>
    <v-divider />
    <v-row>
      <v-col cols="12"><h4>{{ $t('title') }}</h4></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :label="$t('labelQuestionTitle')"
          v-model="title"
          @input="dirty = true"
          required
        >{{ $t('placeholderQuestionTitle') }}</v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="displayAnswer" :label="$t('switchDisplayAnswer')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-5" cols="12">
        <v-slider
          :label="$t('labelPoints')"
          v-model="points"
          thumb-label="always"
          :hint="$t('hintPoints')"
          persistent-hint
        />
      </v-col>
    </v-row>
    <v-divider class="mt-6" />
    <clues-question
      :questionId="questionId"
      :is-new-question="isNewQuestion"
      @clues="handleClues"
    />
    <v-divider class="mt-6" />
    <v-row>
      <v-col class="my-4" cols="12"><h4>{{ $t('titleResponses') }}</h4></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert type="info">
          {{ $t('alert') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          outlined
          :name="$t('nameHelp')"
          :label="$t('labelHelp')"
          v-model="expectedAnswer"
          :hint="$t('hintHelp')"
          persistent-hint
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn color="primary" @click="removeQuestion">{{ $t('btnRemove') }}</v-btn>&nbsp;
        <v-btn color="secondary" @click="saveQuestion">{{ $t('btnSave')}}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import CluesQuestion from './CluesQuestion.vue';

export default {
  components: {
    CluesQuestion,
  },
  props: {
    question: {
      type: Object,
      required: false,
      default: null,
    },
    surveyId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
    isNewQuestion: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dirty: false,
      id: uuidv4(),
      order: 1,
      points: 0,
      requiresReview: true,
      title: null,
      type: 'photo',
      expectedAnswer: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      displayAnswer: false,
      clues: null,
    };
  },
  methods: {
    populateWith(question) {
      this.expectedAnswer = question.expectedAnswer;
      this.points = question.points;
      this.order = question.order;
      this.id = question.id;
      this.title = question.title;
      this.createdAt = question.createdAt;
      this.updatedAt = question.updatedAt;
      this.requiresReview = question.requiresReview;
      this.displayAnswer = question.displayAnswer;
    },
    saveQuestion() {
      this.dirty = false;
      const q = {
        id: this.id,
        title: this.title,
        order: this.order,
        points: this.points,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
        questionId: this.question.id ? this.question.id : uuidv4(),
        surveyId: this.surveyId,
        type: this.type,
        expectedAnswer: this.expectedAnswer,
        displayAnswer: this.displayAnswer,
      };
      if (this.clues !== null) {
        this.$emit('question-updated', q, this.clues);
      } else {
        this.$emit('question-updated', q, null);
      }
    },
    removeQuestion() {
      this.dirty = false;
      this.$emit('question-removed', this.id);
    },
    handleClues(clues) {
      this.clues = clues;
    },
  },
  mounted() {
    if (this.question) {
      this.populateWith(this.question);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Paramètres d'une question \"Photo\"",
    "alert": "Pour ce type de question, le joueur doit prendre une photo. L'animateur devra corriger la réponse : il pourra visualiser la photo prise et définir si elle correspond à la photo demandée. L'aide à la correction permet de donner des informations au correcteur pour l'aider à corriger et ne sera pas affichée aux joueurs.",
    "labelQuestionTitle": "Intitulé de la question",
    "placeholderQuestionTitle": "Question",
    "labelPoints": "Points",
    "hintPoints": "Définissez le nombre de points gagnés en cas de réponse correcte",
    "nameHelp": "Aide à la correction",
    "labelHelp": "Aide à la correction",
    "hintHelp": "Décrivez la photo attendue pour aider les correcteurs",
    "btnRemove": "Supprimer",
    "btnSave": "Enregistrer",
    "switchDisplayAnswer": "Afficher les réponses correctes une fois que les joueurs ont répondu (indoor uniquement)",
    "titleResponses": "Gestion des élements de réponses"
  },
  "en": {
    "title": "\"Photo\" question parameters",
    "alert": "The player will answer with a picture. A staff member will have to review and approve the picture for the team to get the points",
    "labelQuestionTitle": "Question title",
    "placeholderQuestionTitle": "Question",
    "labelPoints": "Points",
    "hintPoints": "Define the number of points",
    "nameHelp": "Review hint",
    "labelHelp": "Review hint",
    "hintHelp": "Describe the expected picture to help the reviewers",
    "btnRemove": "Delete",
    "btnSave": "Save",
    "switchDisplayAnswer": "Display the expected answers when the players have answered (indoor only)",
    "titleResponses": "Response element management"
  }
}
</i18n>
