<template>
  <v-dialog
    v-model="isOpen"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
      v-model="isOpen"
    >
      <v-card-text>
        {{ text }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.open,
    };
  },
  watch: {
    open() {
      this.isOpen = this.open;
    },
  },
};
</script>
