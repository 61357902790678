<template>
  <v-card>
    <v-card-title>
      Destination: {{ destination.name }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Contrôle
                </th>
                <th class="text-right">Statut</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Traduction trouvée</td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(hasLocale).color">
                    {{ statusIconClass(hasLocale).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>Géolocalisation activée</td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(destination.enableGeolocation === true).color">
                    {{ statusIconClass(destination.enableGeolocation === true).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>Checkin activé</td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(destination.enableCheckin === true).color">
                    {{ statusIconClass(destination.enableCheckin === true).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>Mode jeu</td>
                <td class="text-right">
                  <v-icon
                    large
                    :color="statusIconClass(destination.useGameApi === true).color">
                    {{ statusIconClass(destination.useGameApi === true).icon }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>Type de plateforme</td>
                <td class="text-right">
                  <strong>{{ destination.platformType }}</strong>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn plain color="accent" :to="`/destinations/${destination.id}`"> Accéder aux réglages de la destination</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    destination: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasLocale() {
      if (!this.destination || !Array.isArray(this.destination.locales)) {
        return false;
      }
      if (this.destination.locales.length === 0) {
        return false;
      }
      const found = this.destination.locales.filter((l) => l.locale === this.lang);
      return found.length === 1;
    },
  },
  methods: {
    statusIconClass(value) {
      if (!value) {
        return {
          icon: 'mdi-alert-circle',
          color: 'red',
        };
      }
      return {
        icon: 'mdi-check-circle',
        color: 'green',
      };
    },
  },
};
</script>
