export default {
  imagesRoot: '/',
  imagesSitePrefix: '/',
  imagesPlacesPrefix: '/places',
  imagesRewardsPrefix: '/rewards',
  imagesToursPrefix: '/tours',
  useConsoleLogForErrors: process.env.NODE_ENV === 'development',
  useCors: process.env.VUE_APP_ADM_USE_CORS === 'true',
  baseUrl: process.env.VUE_APP_ADM_BASE_URL ? process.env.VUE_APP_ADM_BASE_URL : '',
  apiUrl: process.env.VUE_APP_ADM_API_HOST ? process.env.VUE_APP_ADM_API_HOST : '',
  gameApiUrl: process.env.VUE_APP_ADM_GAME_API_HOST ? process.env.VUE_APP_ADM_GAME_API_HOST : '',
  imagesBaseUrl: process.env.VUE_APP_ADM_IMAGES_BASE_URL ? process.env.VUE_APP_ADM_IMAGES_BASE_URL : '',
  godotmapfrBaseURl: process.env.VUE_APP_ADM_GODOTMAPFR_BASE_URL ? process.env.VUE_APP_ADM_GODOTMAPFR_BASE_URL : 'https://go.dotmap.fr/code/',
};
