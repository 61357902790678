<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title> {{ $t('editTeamBtn') }}</v-list-item-title>
      </v-list-item></template>
    <edit-team v-if="team" :team="team" @close="close" @save="onSave" />
  </v-dialog>
</template>
<script>
import EditTeam from '../EditTeam.vue';

export default {
  components: {
    EditTeam,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    onSave(team) {
      this.$emit('save', team);
      this.close();
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },

  },
};
</script>
<i18n>
  {
    "fr": {
      "editTeamBtn": "Modifier"
    },
    "en": {
      "editTeamBtn": "Edit"
    }
  }
</i18n>
