<template>
  <v-container>
    <GamePoiCHeckinsPerTeamPoiModal
      v-if="selectedPoi"
      v-model="dialog"
      :poi-name="selectedPoi.name"
      :teams="teams"
      :teamsWithCheckin="selectedPoiTeamsWithCheckins"
    />
    <v-data-table
      :headers="headers"
      :items="formatedData"

      :loading="loading"
      @click:row="openPoiView"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12">
            <v-progress-linear v-if="globalProgression" :value="globalProgression" height="30">
              <template v-slot:default="{ value }">
                <strong style="color: #ffffff">{{ value }}%</strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.poiCompletion`]="{ item }">
        <v-progress-linear :value="item.poiCompletion" height="20">
          <template v-slot:default="{ value }">
            <strong style="color: #ffffff">{{ value }}%</strong>
          </template>
        </v-progress-linear>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import * as at from '../../store/actionTypes';
import GamePoiCHeckinsPerTeamPoiModal from './GamePoiCheckinsPerTeamPoiModal.vue';

export default {
  components: {
    GamePoiCHeckinsPerTeamPoiModal,
  },
  props: {
    siteId: {
      type: String,
      required: true,
    },
    gameId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkinsPerTeam: null,
      loading: false,
      dialog: false,
      selectedPoi: null,
      selectedPoiTeamsWithCheckins: null,
      interval: null,
    };
  },
  computed: {
    ...mapState('poi', ['poiList']),
    ...mapGetters('auth', ['token']),
    checkinsPerPoi() {
      return this.checkinsPerTeam ? this.checkinsPerTeam.checkinsPerPoi : [];
    },
    teams() {
      return this.checkinsPerTeam ? this.checkinsPerTeam.teams : [];
    },
    headers() {
      return [
        { text: this.$t('tableHeaderCheckinsPerTeamPoi'), value: 'poiName', sortable: true },
        { text: this.$t('tableHeaderCheckinsPerTeamCompletion'), value: 'poiCompletion', sortable: true },
        { text: this.$t('tableHeaderCheckinsPerTeamTeamsNumber'), value: 'teamsNumber', sortable: false },
      ];
    },
    formatedData() {
      return this.checkinsPerPoi.map((item) => {
        const foundPOI = this.poiList.find((poi) => (poi.uuid === item.poiId));
        return {
          poiName: foundPOI ? foundPOI.name : '',
          poiCompletion: ((item.checkinsCount * 100) / this.checkinsPerTeam.teamsCount).toFixed(0),
          teamsNumber: `${item.checkinsCount} / ${this.checkinsPerTeam.teamsCount}`,
          teamsWithCheckin: item.teamIds,
          poiData: foundPOI,
        };
      });
    },
    globalProgression() {
      return this.checkinsPerTeam
        ? Math.floor((this.checkinsPerTeam.checkinsCount * 100)
        / (this.checkinsPerTeam.teamsCount
        * this.checkinsPerTeam.checkinsPerPoi.length))
        : null;
    },
  },
  methods: {
    ...mapActions({
      fetchPOIs: 'poi/all',
      getCheckinsPerTeam: at.GET_CHECKINS_PER_TEAM,
      openPoiView(_event, item) {
        this.selectedPoiTeamsWithCheckins = item.teamsWithCheckin;
        this.selectedPoi = item.poiData;
        this.dialog = true;
      },
      fetchCheckinsPerTeam() {
        this.loading = true;
        this.getCheckinsPerTeam({ partyId: this.gameId, token: this.token })
          .then((data) => {
            this.checkinsPerTeam = data;
            this.loading = false;
          });
      },
    }),
  },
  async mounted() {
    this.fetchPOIs({ siteId: this.siteId, token: this.token, status: 'enabled' });
    this.fetchCheckinsPerTeam();
    this.interval = setInterval(() => {
      this.fetchCheckinsPerTeam();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<i18n>
{
  "fr": {
    "tableHeaderCheckinsPerTeamPoi": "Nom du POI",
    "tableHeaderCheckinsPerTeamCompletion": "Progression",
    "tableHeaderCheckinsPerTeamTeamsNumber": "Nbre d'équipes / Total"
  },
  "en": {
    "tableHeaderCheckinsPerTeamPoi": "POI name",
    "tableHeaderCheckinsPerTeamCompletion": "Progression",
    "tableHeaderCheckinsPerTeamTeamsNumber": "Number of Teams / Total"
  }
}
</i18n>
