<template>
  <v-container>
    <p class="text">
      {{ $t('introText') }}
    </p>
    <v-data-table
      :headers="headers"
      :items="destinationsList"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ $t('title')}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('btnAdd') }}
              </v-btn>
            </template>
            <destinations-form
              :associated-destinations="destinationsList"
              @save="associateDestination"
              @close="dialog = false" />
          </v-dialog>
          <v-dialog v-model="deleteDialog">
            <confirmation-dialog
              :title="$t('confirmationTitle')"
              :subtitle="$t('confirmationSubtitle')"
              :cancel-button-title="$t('confirmationBtnCancel')"
              :confirm-button-title="$t('confirmationBtnConfirm')"
              @cancel="closeDeleteDialog"
              @confirm="dissociateDestination"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          :disabled="organizationId === 4"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import organizationApi from '../../services/orga';
import UserNotifications from '../../mixins/UserNotifications.vue';
import ConfirmationDialog from '../base/ConfirmationDialog.vue';
import DestinationsForm from './DestinationsForm.vue';

export default {
  components: {
    ConfirmationDialog,
    DestinationsForm,
  },
  mixins: [UserNotifications],
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      editedItem: null,
      destinationsList: [],
      headers: [
        { text: this.$t('tableHeaderId'), value: 'id' },
        { text: this.$t('tableHeaderUUID'), value: 'uuid' },
        { text: this.$t('tableHeaderName'), value: 'name' },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'token',
    ]),
  },
  methods: {
    deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.editedItem = null;
      this.deleteDialog = false;
    },
    async associateDestination(destinationId) {
      try {
        this.dialog = false;
        await organizationApi.associateDestination(this.organizationId, destinationId, this.token);
        this.successNotification(this.$t('associateSuccess'));
      } catch (error) {
        this.errorNotification(this.$t('associateError'), error);
      } finally {
        this.refreshDestinations();
      }
    },
    async dissociateDestination() {
      try {
        this.deleteDialog = false;
        await organizationApi.dissociateDestination(
          this.organizationId,
          this.editedItem.id,
          this.token,
        );
        this.successNotification(this.$t('dissociateSucces'));
      } catch (error) {
        this.errorNotification(this.$t('dissociateError'), error);
      } finally {
        this.refreshDestinations();
        this.editedItem = null;
      }
    },
    async refreshDestinations() {
      try {
        const dests = await organizationApi.getDestinationsForOrganization(
          this.organizationId,
          this.token,
        );
        this.destinationsList = dests;
      } catch (error) {
        console.log(error);
        this.errorNotification(this.$t('refreshError'), error);
      }
    },
  },
  mounted() {
    this.refreshDestinations();
  },
};
</script>
<i18n>
  {
    "fr": {
      "introText": "Les destinations suivantes sont accessibles pour les membres de cette organisation",
      "title": "Destinations associées",
      "btnAdd": "Associer une destination",
      "tableHeaderId": "ID",
      "tableHeaderUUID": "UUID",
      "tableHeaderName": "Nom",
      "associateSuccess": "Destination associée",
      "associateError": "Impossible d'associer la destination",
      "dissociateSucces": "Destination dissociée",
      "dissociateError": "Impossible de dissocier la destination",
      "refreshError": "impossible de récupérer les destinations",
      "confirmationTitle": "Dissocier la destination ?",
      "confirmationSubtitle": "L'organisation n'aura plus accès à cette destination",
      "confirmationBtnCancel": "Ne pas dissocier",
      "confirmationBtnConfirm": "Dissocier"
    }
  }
</i18n>
