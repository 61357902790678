<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('title') }}</v-toolbar-title>
    </v-toolbar>

    <v-list two-line>
      <template v-for="(t, idx) in translations">
        <v-list-item
          :key="t.uuid"
          @click="i = 1"
        >
          <v-list-item-avatar>
            <flag :iso="flagIconClass(t.locale)" />
          </v-list-item-avatar>
          <v-list-item-content @click="editTranslation(t)">
            <v-list-item-title>{{ t.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="includeDescription">
              {{ truncate(stripHTML(t.description)) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon ripple @click="deleteTranslation(t)">
              <v-icon color="grey lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="idx" />
      </template>
    </v-list>
    <v-card-text
      v-if="!translations || translations.length === 0"
      class="header">
      {{ $t('noTranslations') }}
    </v-card-text>
    <v-card-actions>
      <v-switch
        v-model="hasOptin"
        @change="$emit('updateoptin', hasSecondaryOptin)"
        :label="$t('displayFirstOptin')"
      />
      <v-spacer />
      <v-switch
        v-model="hasSecondaryOptin"
        @change="$emit('updatesecondaryoptin', hasSecondaryOptin)"
        :label="$t('displaySecondOptin')"
      />
      <v-spacer />
      <v-spacer />
      <v-spacer />
      <v-spacer />
      <v-btn
        @click="editorOpen = true"
        text
        color="secondary">
        {{ $t('btnAddTranslation') }}
      </v-btn>
    </v-card-actions>
    <translation-editor
      v-if="editorOpen === true"
      :initial-translation="currentTranslation"
      :include-description="includeDescription"
      :currentDestination="currentDestination"
      @savetranslation="saveTranslation"
      @closeclicked="editorOpen = false" />
  </v-card>
</template>

<script>

import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line
import FlagIcon from 'vue-flag-icon';
import TranslationEditor from './TranslationEditor.vue';

export default {
  components: { TranslationEditor },
  props: {
    initialTranslations: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    includeDescription: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentDestination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editorOpen: false,
      translations: this.initialTranslations ? this.initialTranslations : [],
      currentTranslation: this.resetCurrentTranslation(),
      i: 1,
      hasOptin: this.currentDestination.hasOptin,
      hasSecondaryOptin: this.currentDestination.hasSecondaryOptin,
    };
  },
  methods: {
    editTranslation(translation) {
      this.currentTranslation = translation;
      if (!this.currentTranslation.uuid) {
        this.currentTranslation.uuid = uuidv4();
      }
      this.editorOpen = true;
    },
    deleteTranslation(t) {
      let translationIndex = -1;
      let isLocalOnly = false;
      if (!t.id) {
        isLocalOnly = true;
        translationIndex = this.translations.findIndex((e) => t.uuid === e.uuid);
      } else {
        translationIndex = this.translations.findIndex((e) => t.id === e.id);
      }
      if (translationIndex >= -1) {
        this.translations.splice(translationIndex, 1);
        if (!isLocalOnly) {
          this.transactionsChanged();
        }
      }
    },
    saveTranslation(incomingTranslation) {
      this.editorOpen = false;
      const existingTranslationId = this.translations.findIndex(
        (element) => element.uuid === incomingTranslation.uuid,
      );
      if (existingTranslationId === -1) {
        this.translations.push(incomingTranslation);
      } else {
        this.translations[existingTranslationId] = incomingTranslation;
      }
      this.transactionsChanged();
      this.resetCurrentTranslation();
    },

    transactionsChanged() {
      this.$emit('transactionschanged', this.translations);
    },
    flagIconClass(locale) {
      const countryCode = locale.substring(locale.indexOf('_') + 1).toLowerCase();
      return `flag-icon flag-icon-${countryCode}`;
    },
    resetCurrentTranslation() {
      this.currentTranslation = {
        id: null, name: '', uuid: uuidv4(), description: '', header: '', locale: 'fr_FR',
      };
    },
    truncate(value) {
      return value.length < 150 ? value : `${value.substring(0, 150)}...`;
    },
    stripHTML(value) {
      const div = document.createElement('div');
      div.innerHTML = value;
      return div.textContent || div.innerText || '';
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Traductions",
    "noTranslations": "Ajoutez une traduction pour cette catégorie avant de la sauvegarder",
    "btnAddTranslation": "Ajouter une traduction",
    "displayFirstOptin": "Afficher le premier optin",
    "displaySecondOptin": "Afficher le deuxième optin"
  },
  "en": {
    "title": "Translations",
    "noTranslations": "Add a translation before saving",
    "btnAddTranslation": "Add a translation",
    "displayFirstOptin": "Display the first optin",
    "displaySecondOptin": "Display the second optin"
  }
}
</i18n>
