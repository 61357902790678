import http from './axios';

const destinationById = (id, token) => new Promise((resolve, reject) => {
  http.get(`/api/v3/destinations/${id}`, { headers: { Authorization: `BEARER ${token}` } })
    .then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
});

const createDestination = (destination, token) => new Promise((resolve, reject) => {
  http.post(
    '/api/v3/destinations',
    destination,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});

const updateDestination = (destination, token) => new Promise((resolve, reject) => {
  http.put(
    `/api/v3/destinations/${destination.id}`,
    destination,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});

export default {
  destinationById,
  updateDestination,
  createDestination,
};
