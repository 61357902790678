<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('title') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <template>
        <draggable v-model="tourPois" group="pois" @start="drag = true" @end="drag = false">
          <div v-for="p in tourPois" :key="p.poiId">
            <v-list-item>
              <v-list-item-avatar>
                <v-btn icon ripple>
                  <v-icon>reorder</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                >{{ byId[p.poiId].name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </draggable>
      </template>
    </v-list>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="secondary"
        text
        @click="$emit('close')"
      >
        {{ $t('btnCancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="$emit('save', tourPois);"
      >
        {{ $t('btnSave') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  components: { draggable },
  props: {
    pois: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      drag: false,
      poisValue: [],
    };
  },
  computed: {
    ...mapState({
      poisCollection: (state) => state.poi.poiList,
    }),
    ...mapGetters('poi', [
      'byId',
    ]),
    poisList() {
      return this.byId;
    },
    tourPois: {
      get() {
        let pois = [];
        if (this.poisValue.length > 0) {
          pois = this.poisValue;
        } else {
          // eslint-disable-next-line prefer-destructuring
          pois = this.pois;
        }

        pois.sort((a, b) => a.order - b.order);
        const notEmptyPOIs = [];
        pois.forEach((p) => {
          const poi = this.poisList[p.poiId];
          if (poi) {
            notEmptyPOIs.push(p);
          }
        });
        return notEmptyPOIs;
      },
      set(value) {
        const newValues = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= value.length; i++) {
          const nv = value[i - 1];
          if (nv) {
            nv.order = i;
            newValues.push(nv);
          }
        }
        this.poisValue = newValues;
      },
    },
  },
  watch: {
    pois() {
      this.poisValue = this.pois;
    },
  },

};
</script>
<i18n>
{
  "fr": {
    "title": "Déplacer un lieu dans la liste pour définir son ordre",
    "btnCancel": "Annuler",
    "btnSave": "Enregistrer"
  },
  "en": {
    "title": "Drag a POI in the list to reorder it",
    "btnCancel": "Cancel",
    "btnSave": "Save"
  }
}
</i18n>
