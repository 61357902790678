<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-text-field
        :value="value"
        :rules="[rules.required, rules.identifier, rules.maxLength]"
        v-on:input="valueChanged"
        :placeholder="$t('placeholder')"
        single-line
        counter="255"
        required />
      <p>{{ $t('constraintIdentifier')}} </p>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable max-len */

import validator from '../../services/validation';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('errorValidationRequired'),
        identifier: (value) => validator.ValidateIdentifier(value) || this.$t('errorValidationIdentifier'),
        maxLength: (value) => validator.ValidateMaxLength(value, 255) || this.$t('errorValidationMaxLength'),
      },
    };
  },
  methods: {
    valueChanged($e) {
      if (this.rules.identifier($e) === true) {
        this.$emit('input', $e);
      }
    },
  },

};
</script>

<i18n>
{
  "fr": {
    "constraintIdentifier": "L'Identifiant ne peut être composé que de lettres, chiffres, \"-\" et \"_\"",
    "placeholder": "Identifiant*",
    "errorValidationRequired": "Ce champ ne peut pas être vide",
    "errorValidationIdentifier": "Identifiant invalide",
    "errorValidationMaxLength": "Identifiant trop long"
  },
  "en": {
    "constraintIdentifier": "Identifier can only contain letters, numbers, \"-\" and \"_\"",
    "placeholder": "Identifier*",
    "errorValidationRequired": "This field cannot be empty",
    "errorValidationIdentifier": "Invalid identifier",
    "errorValidationMaxLength": "Identifier too long"
  }
}
</i18n>
