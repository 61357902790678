<template>
  <v-card>

    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('title') }}</v-toolbar-title>
    </v-toolbar>
    <v-list two-line>
      <template>
        <div v-for="c in contacts" :key="c.value">
          <v-list-item ripple>
            <v-list-item-avatar>
              <v-icon v-if="c.type === 'phone'">mdi-phone</v-icon>
              <v-icon v-else-if="c.type === 'email'">mdi-email</v-icon>
              <v-icon v-else>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content @click="openEditContactForm(c)">
              <v-list-item-title>{{ c.value }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon ripple @click="openDeleteContactDialog(c)">
                <v-icon color="grey lighten-1">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </template>
    </v-list>
    <v-card-text
      v-if="!contacts || contacts.length === 0"
      class="header">
      {{ $t('cardHeader') }}
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="secondary" @click="openNewContactForm()">{{ $t("btnAdd") }}</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialogOpen"
    >
      <contact-form
        v-if="currentContact"
        @close="closeContactForm"
        @save="saveContact"
        :title="$t('fieldTitle')"
        :subtitle="$t('fieldSubtitle')"
        :options-list="optionsList"
        :initial-option="currentContact.type"
        :initial-value="currentContact.value"
        :id="currentContact.id"
        :options-label="$t('labelContactType')"
        :value-label="$t('labelValue')"
      />
    </v-dialog>
    <v-dialog
      max-width="350"
      v-model="deleteDialogOpen"
    >
      <confirm-dialog
        :title="$t('deleteDialogTitle')"
        :subtitle="$t('deleteDialogSubtitle')"
        :cancelButtonTitle="$t('btnDeleteDialogCancel')"
        :confirmButtonTitle="$t('btnDeleteDialogConfirm')"
        @confirm="deleteContact()"
        @cancel="closeDeletionForm()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import ContactForm from '../base/ContactSelector.vue';
import ConfirmDialog from '../base/ConfirmationDialog.vue';

export default {
  components: { ContactForm, ConfirmDialog },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogOpen: false,
      deleteDialogOpen: false,
      currentContact: null,
      optionsList: [
        { text: this.$t('contactTypePhone'), value: 'phone' },
        { text: this.$t('contactTypeEmail'), value: 'email' },
        { text: this.$t('contactTypeOther'), value: 'other' },
      ],
    };
  },
  methods: {
    openNewContactForm() {
      this.currentContact = { type: 'other', value: '', id: uuidv4() };
      this.dialogOpen = true;
    },
    openEditContactForm(contact) {
      this.currentContact = contact;
      this.dialogOpen = true;
    },
    openDeleteContactDialog(contact) {
      this.currentContact = contact;
      this.dialogOpen = false;
      this.deleteDialogOpen = true;
    },
    saveContact(contact) {
      this.dialogOpen = false;
      const contactsList = this.contacts.filter((c) => c.id !== contact.id);
      const updatedContact = { type: contact.option, id: contact.id, value: contact.value };
      contactsList.push(updatedContact);
      this.$emit('update', contactsList);
      this.currentContact = null;
    },
    deleteContact() {
      this.dialogOpen = false;
      this.deleteDialogOpen = false;
      const contactsList = this.contacts.filter((c) => c.id !== this.currentContact.id);
      this.$emit('update', contactsList);
      this.currentContact = null;
    },
    closeDeletionForm() {
      this.currentContact = null;
      this.deleteDialogOpen = false;
    },
    closeContactForm() {
      this.dialogOpen = false;
      this.currentContact = null;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Coordonnées de contact",
    "cardHeader": "Gérez ici les numéros de téléphone de contact et les emails permettant aux visiteurs de contacter ce lieu d'intérêt",
    "btnAdd": "Ajouter un contact",
    "fieldTitle": "Coordonnées du contact",
    "fieldSubtitle": "Ajoutez des coordonnées téléphoniques ou email à ce POI",
    "labelContactType": "Type de contact",
    "labelValue": "Coordonnées",
    "deleteDialogTitle": "Supprimer ce contact ?",
    "deleteDialogSubtitle": "Cette action est irréversible",
    "btnDeleteDialogConfirm": "Supprimer",
    "btnDeleteDialogCancel": "Annuler",
    "contactTypePhone": "Téléphone",
    "contactTypeEmail": "Email",
    "contactTypeOther": "Autre"
  },
  "en": {
    "title": "Contact information",
    "cardHeader": "Manage phone numbers and email addresses that can be used by visitors to reach this POI",
    "btnAdd": "Add a contact",
    "fieldTitle": "Contact information",
    "fieldSubtitle": "Add phone numbers or email addresses to this POI",
    "labelContactType": "Contact type",
    "labelValue": "Information",
    "deleteDialogTitle": "Delete this contact?",
    "deleteDialogSubtitle": "This cannot be undone",
    "btnDeleteDialogConfirm": "Delete",
    "btnDeleteDialogCancel": "Cancel",
    "contactTypePhone": "Phone number",
    "contactTypeEmail": "Email",
    "contactTypeOther": "Other"
  }
}
</i18n>
