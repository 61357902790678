<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-select
        multiple
        chips
        v-model="currentCategories"
        :items="categories"
        :label="$t('fieldLabelCategories')"
      />
      <v-select
        v-if="currentCategories.length > 1"
        v-model="currentMainCategory"
        :items="currentCategories"
        :label="$t('fieldLabelMainCategory')"
      />
      <v-select
        multiple
        chips
        v-model="currentTags"
        :items="availableTags"
        :label="$t('fieldLabelTags')"
      />
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable max-len */
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    value: {
      type: Array,
      required: true,
    },
    availableCategories: {
      type: Array,
      required: false,
      default: () => [],
    },
    tagsValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    mainCategory: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedCats: this.value,
      selectedTags: this.tagsValue,
      selectedMainCat: this.mainCategory,
    };
  },
  computed: {
    categories() {
      return this.availableCategories.map((cat) => ({
        text: cat.name,
        value: cat.id,
      }));
    },
    availableTags() {
      if (!this.selectedCats) {
        return [];
      }
      const selectedCategories = this.availableCategories.filter(
        (c) => this.selectedCats.includes(c.id),
      );
      const allTags = [];
      selectedCategories.forEach((c) => {
        c.tags.forEach((t) => {
          allTags.push(t);
        });
      });
      const preparedTags = allTags.map((t) => ({ text: t.name, value: t.id }));
      return preparedTags;
    },
    availableTagsIDS() {
      return this.availableTags.map((t) => t.value);
    },
    currentMainCategory: {
      get() {
        if (!this.selectedMainCat) {
          return null;
        }
        const currentCat = this.availableCategories.filter((c) => c.id === this.selectedMainCat);
        if (currentCat.length !== 1) {
          return null;
        }
        return {
          text: currentCat[0].name,
          value: currentCat[0].id,
        };
      },
      set(value) {
        this.selectedMainCat = value;
        this.$emit('maincat', value);
      },
    },
    currentCategories: {
      get() {
        const cats = [];

        this.value.forEach((cat) => {
          const currentCat = this.availableCategories.filter((c) => c.id === cat);
          if (currentCat.length === 1) {
            cats.push(
              {
                text: currentCat[0].name,
                value: currentCat[0].id,
              },
            );
          }
        });
        return cats;
      },
      set(value) {
        this.selectedCats = value;
        if (value.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          this.currentMainCategory = value[0];
        }
        const newTags = this.currentTags.filter((t) => this.availableTagsIDS.includes(t));
        this.currentTags = newTags;
        this.$emit('input', value);
      },
    },
    currentTags: {
      get() {
        const tags = [];
        this.tagsValue.forEach((tag) => {
          const currentTag = this.availableTags.filter((c) => c.value === tag);
          if (currentTag.length === 1) {
            tags.push(
              currentTag[0].value,
            );
          }
        });
        return tags;
      },
      set(value) {
        this.selectedTags = value;
        this.$emit('tags', value);
      },
    },
  },

};
</script>
<i18n>
{
  "fr": {
    "fieldLabelCategories": "Catégories",
    "fieldLabelMainCategory": "Catégorie principale. le lieu affichera l'icône de cette catégorie sur la carte",
    "fieldLabelTags": "Tags"
  },
  "en": {
    "fieldLabelCategories": "Categories",
    "fieldLabelMainCategory": "Main Category. The POI will display this category's icon on the map",
    "fieldLabelTags": "Tags"
  }
}
</i18n>
