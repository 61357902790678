<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text> <p class="subtitle"> {{ subtitle }}</p>
      <v-text-field
        v-model="name"
        :label="$t('labelName')" />
      <v-select
        v-model="locale"
        :items="[
          {
            text: $t('itemFrench'),
            value: 'fr_FR',
          },
          {
            text: $t('itemEnglish'),
            value: 'en_GB',
          },
          {
            text: $t('itemGerman'),
            value: 'de_DE',
          },
          {
            text: $t('itemNL'),
            value: 'nl_NL',
          },
          {
            text: $t('itemItalian'),
            value: 'it_IT',
          },
          {
            text: $t('itemSpanish'),
            value: 'es_ES',
          },
          {
            text: $t('itemPortuguese'),
            value: 'pt_PT',
          }]"
        menu-props="auto"
        :label="$t('labelLang')"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        @click="$emit('cancel')"
        color="secondary">{{ $t('btnCancel') }}
      </v-btn>
      <v-btn
        :disabled="!name || name.length === 0"
        text
        @click="save"
        color="primary">{{ $t('btnSave') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    tag: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      nameValue: null,
      localeValue: null,
    };
  },
  computed: {
    name: {
      get() {
        if (this.nameValue) {
          return this.nameValue;
        }
        if (this.tag) {
          return this.tag.name;
        }
        return null;
      },
      set(value) {
        this.nameValue = value;
      },
    },
    locale: {
      get() {
        if (this.localeValue) {
          return this.localeValue;
        }
        if (this.tag) {
          return this.tag.locale;
        }
        return 'fr_FR';
      },
      set(value) {
        this.localeValue = value;
      },
    },
  },
  methods: {
    save() {
      let newTag = null;
      if (this.tag) {
        newTag = { ...this.tag };
        newTag.name = this.name;
        newTag.locale = this.locale;
      } else {
        newTag = {
          name: this.name,
          locale: this.locale,
          uuid: uuidv4(),
        };
      }
      this.$emit('save', newTag);
      this.localeValue = null;
      this.nameValue = null;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "itemFrench": "Français",
    "itemEnglish": "Anglais",
    "itemGerman": "Allemand",
    "itemNL": "Néerlandais",
    "itemItalian": "Italien",
    "itemSpanish": "Espagnol",
    "itemPortuguese": "Portugais",
    "labelName": "Nom*",
    "labelLang": "Langue*",
    "btnCancel": "Annuler",
    "btnSave": "Enregistrer"
  },
  "en": {
    "itemFrench": "French",
    "itemEnglish": "English",
    "itemGerman": "German",
    "itemNL": "Dutch",
    "itemItalian": "Italian",
    "itemSpanish": "Spanish",
    "itemPortuguese": "Portuguese",
    "labelName": "Name*",
    "labelLang": "Language*",
    "btnCancel": "Cancel",
    "btnSave": "Save"
  }
}
</i18n>
