<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small>
        mdi-note-edit
      </v-icon>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('mainTitle') }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="save"
          >
            {{ $t('btnSave') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="!loading">
        <v-row class="title">
          <span class="font-weight-bold">{{ $t('titleQuiz')}} {{ review.survey.title }}</span>
          <v-spacer />
          <span>{{ $t('titleTeam')}} {{ review.team.name }}</span>
        </v-row>
        <v-row v-for="(answer, index) in review.answers" :key="answer.id">
          <game-review-detail-answer
            :answer="answer"
            @validate="validate($event, answer.id)"
          />
          <v-col cols="12">
            <v-divider v-if="index < review.answers.length - 1" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            color="grey"
            dark
            x-large
            class="mx-4"
            @click="close"
          >
            {{ $t('btnCancel') }}
          </v-btn>
          <v-btn
            :disabled="shouldDisableSaveButton()"
            :loading="loading"
            color="secondary"
            :dark="!shouldDisableSaveButton()"
            x-large
            class="mx-4"
            @click="save"
          >
            {{ $t('btnSave') }}
          </v-btn>
        </v-row>
      </v-container>
      <v-container fluid v-if="loading === true">
        <h3 class="text-xs-center">{{ $t('textLoading') }}</h3>
        <v-progress-linear
          :indeterminate="true" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import UserNotifications from '../../mixins/UserNotifications.vue';
import GameReviewDetailAnswer from './GameReviewDetailAnswer.vue';
import * as at from '../../store/actionTypes';

export default {
  components: {
    GameReviewDetailAnswer,
  },
  mixins: [UserNotifications],
  props: {
    reviewId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      correctedAnswers: [],
      loading: true,
      review: null,
      dialog: false,
    };
  },

  async mounted() {
    this.loading = true;
    this.review = await this.getReview({ reviewId: this.reviewId, token: this.token });
    this.loading = false;
    this.review.answers.forEach((answer) => {
      this.correctedAnswers.push({
        questionId: answer.question.id,
        answerId: answer.id,
        isCorrect: null,
        answerText: answer.answerText,
        type: answer.question.type,
      });
    });
  },
  computed: {
    ...mapGetters('auth', ['token']),
    siteId() {
      return this.$route.params.siteId;
    },
  },
  methods: {
    ...mapActions({
      correctReview: at.CORRECT_REVIEW,
      getReview: at.GET_REVIEW_BY_ID,
    }),
    validate(eventValue, answerId) {
      const index = this.correctedAnswers.findIndex(
        (answer) => answer.answerId === answerId,
      );
      this.correctedAnswers[index].isCorrect = eventValue;
    },
    shouldDisableSaveButton() {
      return (this.correctedAnswers.filter((answer) => answer.isCorrect === null).length > 0);
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.loading = true;
      const reviewPayload = {
        id: this.review.id,
        teamId: this.review.teamId,
        partyId: this.review.partyId,
        surveyId: this.review.surveyId,
        answers: this.correctedAnswers,
      };
      this.correctReview({ review: reviewPayload, token: this.token })
        .then(() => {
          this.loading = false;
          this.successNotification(this.$t('notificationSucess'));
          this.$emit('saved');
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.errorNotification(this.$t('notificationError'), error);
        });
    },
    goBackToReviews() {
      this.$router.push({
        name: 'gameReviewsList',
        params: {
          siteId: this.siteId,
          partyId: this.review.partyId,
        },
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "textLoading": "Chargement de la correction...",
    "titleQuiz": "Défi :",
    "titleTeam": "Equipe :",
    "btnCancel": "Annuler",
    "btnSave": "Enregistrer",
    "notificationSucess": "Corrections enregistrées",
    "notificationError": "Impossible d'enregistrer le défi, une erreur est survenue"
  },
  "en": {
    "textLoading": "Loading review...",
    "titleQuiz": "Challenge:",
    "titleTeam": "Team:",
    "btnCancel": "Cancel",
    "btnSave": "Save",
    "notificationSucess": "Review saved",
    "notificationError": "Unable to save review"
  }
}
</i18n>
