<template>
  <v-card>
    <v-card-title>
      {{ title }}

    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-container>
        <v-row>
          <v-col xs12 sm3 offset-sm-1>
            <v-text-field
              v-model="minimum"
              :label="$t('labelMinimum')"
              type="number" />
          </v-col>
          <v-col xs12 sm3 offset-sm-1>
            <v-text-field
              v-model="maximum"
              :label="$t('labelMaximum')"
              type="number" />

          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    initialMinimum: {
      type: Number,
      required: true,
    },
    initialMaximum: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      minimum: this.initialMinimum,
      maximum: this.initialMaximum,
    };
  },
  watch: {
    minimum() {
      this.onChange();
    },
    maximum() {
      this.onChange();
    },
  },
  methods: {
    onChange() {
      this.$emit('change', { minimum: parseFloat(this.minimum), maximum: parseFloat(this.maximum) });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "labelMinimum": "Prix minimum",
    "labelMaximum": "Prix maximum"
  },
  "en": {
    "labelMinimum": "Minimum price",
    "labelMaximum": "Maximum price"
  }
}

</i18n>
