<template>
  <v-container fluid v-if="game">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="10" offset-md="1">
        <v-text-field
          v-model="teamSearch"
          :label="$t('labelTeamSearch')"
          solo
          clearable
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="teams"
      :items-per-page="teams.length"
      :hide-default-footer="true"
      :loading="isUpdating"
      :loading-text="$t('loadingText')"
      class="elevation-1"
      @click:row="handleClickRow"

    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <v-chip class="ma-2" small :color="item.color" />{{ item.name }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.action="{ item }">
        <v-menu
          bottom
          left
          v-model="teamMenu[item.id]"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <menu-action-add-bonus
              :teamId="item.id"
              @addevent="addBonus"
              @close="teamMenu[item.id] = false"
            />
            <menu-action-unlock-team
              :team="item"
              @unlock="unlockTeam"
              @close="teamMenu[item.id] = false"
            />
            <menu-action-edit-team
              :team="item"
              @save="save"
              @close="teamMenu[item.id] = false" />
            <menu-action-show-q-r-code
              :domain="destination.domains.length > 0
                ? destination.domains[0] : destination.name"
              :token="item.token"
              :short-code="item.shortcode"
              :platform-type="destination.platformType"
              @close="teamMenu[item.id] = false" />
            <menu-action-delete-team
              :team="item"
              @confirmdelete="validateTeamDeletion"
              @close="teamMenu[item.id] = false"
            />
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <scoreboard-history v-model="historyModal" :teamData="teamData" :teamLogs="teamLogs" />
  </v-container>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import UserNotifications from '../../mixins/UserNotifications.vue';
import * as at from '../../store/actionTypes';
import ScoreboardHistory from './GameScoreboardHistory.vue';

import MenuActionEditTeam from './teamactionsmenu/ActionEditTeam.vue';
import MenuActionAddBonus from './teamactionsmenu/ActionAddBonus.vue';
import MenuActionShowQRCode from './teamactionsmenu/ActionShowQRCode.vue';
import MenuActionDeleteTeam from './teamactionsmenu/ActionDeleteTeam.vue';
import MenuActionUnlockTeam from './teamactionsmenu/ActionUnlockTeam.vue';

export default {
  components: {
    ScoreboardHistory,
    MenuActionEditTeam,
    MenuActionAddBonus,
    MenuActionShowQRCode,
    MenuActionDeleteTeam,
    MenuActionUnlockTeam,
  },
  props: {
    destination: {
      type: Object,
      required: true,
    },
    game: {
      type: Object,
      required: true,
    },
    gameId: {
      type: String,
      required: true,
    },
    siteId: {
      type: String,
      required: true,
    },
    scoreboard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      team: undefined,
      qrcodedialog: false,
      dialog: false,
      deletedialog: false,
      historyModal: false,
      teamData: null,
      teamLogs: null,
      displayEndConfirmDialog: false,
      checked: false,
      teamMenu: [],
      teamSearch: null,
    };
  },
  mixins: [UserNotifications],
  computed: {
    ...mapGetters('auth', ['token', 'user']),
    ...mapGetters('auth', ['token']),
    ...mapState('site', ['sites']),

    headers() {
      const headers = [
        {
          text: '#',
          align: 'left',
          sortable: false,
          value: 'position',
        },
        { text: this.$t('tableHeaderTeamName'), value: 'name', sortable: false },
        { text: this.$t('tableaHeaderLastActivity'), value: 'lastEvent', sortable: false },
        { text: this.$t('tableHeaderLastActivityTime'), value: 'lastEventDate', sortable: false },
        { text: this.$t('tableHeaderTeamLocked'), value: 'isBlocked', sortable: false },
        { text: this.$t('tableHeaderScore'), value: 'score', sortable: false },
      ];
      if (this.game && this.game.status !== 'ended') {
        headers.push({ text: this.$t('tableHeaderActions'), value: 'action', sortable: false });
      }
      return headers;
    },
    currentDestination() {
      return this.$store.state.site.sites[this.siteId];
    },
    teams() {
      const isBlocked = (team) => {
        if (team.blockedUntil === null) {
          return this.$t('tableContentNo');
        }
        const now = new Date();
        const blockedUntil = Date.parse(team.blockUntil);
        if (blockedUntil > now) {
          return this.$t('tableContentYes', { date: this.$d(blockedUntil, 'short') });
        }
        return this.$t('tableContentNo');
      };
      const teamsWithBlockingStatus = [];
      this.scoreboard.teams.forEach((team) => {
        teamsWithBlockingStatus.push({
          ...team,
          isBlocked: isBlocked(team),
        });
      });
      if (this.teamSearch && this.teamSearch.length > 1) {
        return teamsWithBlockingStatus.filter(
          (t) => t.name.toLowerCase().includes(this.teamSearch.toLowerCase()),
        );
      }
      return teamsWithBlockingStatus;
    },
    isUpdating() {
      return !this.game;
    },
  },
  methods: {
    ...mapActions({
      getTeamLogs: at.GET_TEAM_LOGS,
      updateTeam: at.UPDATE_TEAM,
      deleteTeam: at.DELETE_TEAM,
      createAdminEvent: at.CREATE_ADMIN_EVENT,
      fetchPOIs: 'poi/all',
    }),

    setTeam(team) {
      this.team = {
        id: team.id,
        name: team.name,
        email: team.email,
        color: team.color,
        partyId: team.partyId,
        destinationId: team.destinationId,
        token: team.token,
        score: team.score,
      };
    },
    askDeleteTeam(team) {
      this.setTeam(team);
      this.deletedialog = true;
    },
    editTeam(team) {
      this.setTeam(team);
      this.dialog = true;
    },
    showQrCode(team) {
      this.setTeam(team);
      this.qrcodedialog = true;
    },
    validateTeamDeletion(team) {
      this.deleteTeam({ team, token: this.token })
        .then(() => {
          this.$emit('needs-refresh');
          this.deletedialog = false;
        })
        .catch((error) => {
          this.errorNotification(this.$t('notificationCantDeleteTeam'), error);
        });
    },
    save(team) {
      this.updateTeam({ team, token: this.token })
        .then(() => {
          this.$emit('needs-refresh');
          this.successNotification(this.$t('notificationTeamUpdated'));
        })
        .catch((error) => {
          this.errorNotification(this.$t('notificationCantUpdateTeam'), error);
        });
    },

    unlockTeam(team) {
      const updatedTeam = { ...team };
      updatedTeam.blockUntil = null;
      this.updateTeam({ team: updatedTeam, token: this.token })
        .then(() => {
          this.$emit('needs-refresh');
          this.successNotification(this.$t('notificationTeamUnlocked'));
        })
        .catch((error) => {
          this.errorNotification(this.$t('notificationCantUnlockTeam'), error);
        });
    },

    addBonus({ teamId, points, reason }) {
      const event = {
        teamId,
        type: 'bonus',
        name: reason,
        score: points,
        partyId: this.gameId,
        date: new Date(),
        author: this.user.email,
      };
      this.createAdminEvent({ event, token: this.token }).then(() => {
        this.successNotification(this.$t('notificationBonusAdded'));
        this.$emit('needs-refresh');
      }).catch((error) => {
        this.errorNotification(this.$t('notificationCantAddBonus'), error);
      });
    },

    handleClickRow(event) {
      this.teamData = event;
      Promise.all([
        this.getTeamLogs({ teamId: this.teamData.id, teamToken: this.teamData.token }),
        this.fetchPOIs({ siteId: this.siteId, token: this.token, status: 'enabled' }),
      ]).then(
        (d) => {
          [this.teamLogs] = d;
          if (this.teamLogs && this.teamLogs.length > 0) {
            this.historyModal = true;
          } else {
            this.errorNotification(this.$t('notificationErrorNoHistory'));
          }
        },
      );
    },
  },
  mounted() {
    this.$emit('needs-refresh');
  },
};
</script>
<i18n>
{
  "fr": {
    "gameText": "Partie :",
    "stateText": "Etat : ",
    "loadingText": "Chargement en cours",
    "tableHeaderTeamName": "Nom d'équipe",
    "tableHeaderTeamEmail": "Email d'équipe",
    "tableaHeaderLastActivity": "Dernière activité",
    "tableHeaderLastActivityTime": "Heure de dernière activité",
    "tableHeaderTeamLocked": "Equipe bloquée ?",
    "tableHeaderScore": "Points",
    "tableHeaderActions": "Actions",
    "notificationCantDeleteTeam": "Impossible de supprimer l'équipe",
    "notificationCantUpdateTeam": "Impossible de mettre à jour l'équipe",
    "notificationBonusAdded": "Bonus ajouté",
    "notificationCantAddBonus": "Impossible d'ajouter les points bonus",
    "notificationTeamUpdated": "Equipe mise à jour",
    "notificationTeamUnlocked": "Equipe débloquée",
    "notificationCantUnlockTeam": "Impossible de débloquer l'équipe",
    "notificationErrorNoHistory": "Pas encore d'historique pour cette équipe",
    "labelTeamSearch": "Rechercher une équipe",
    "tableContentNo": "Non",
    "tableContentYes": "Oui (Fin : {date})"
  },
  "en": {
    "gameText": "Game:",
    "stateText": "State: ",
    "loadingText": "Loading...",
    "tableHeaderTeamName": "Team name",
    "tableHeaderTeamEmail": "Team email",
    "tableaHeaderLastActivity": "Last activity name",
    "tableHeaderLastActivityTime": "Last activity time",
    "tableHeaderTeamLocked": "Team is blocked?",
    "tableHeaderScore": "Points",
    "tableHeaderActions": "Actions",
    "notificationCantDeleteTeam": "Unable to delete the team",
    "notificationCantUpdateTeam": "Unable to update the team",
    "notificationBonusAdded": "Bonus added",
    "notificationCantAddBonus": "Unable to add the bonus",
    "notificationErrorNoHistory": "No history for this team yet",
    "labelTeamSearch": "search a team",
    "notificationTeamUnlocked": "Team unlocked",
    "notificationCantUnlockTeam": "Unable to unlock the team",
    "tableContentNo": "No",
    "tableContentYes": "Yes (End: {date})"
  }
}
</i18n>
