<template>
  <v-card>
    <v-card-title> {{ $t('title') }}</v-card-title>
    <v-card-text>
      {{ $t('content') }}
      <v-container>
        <v-row>
          <v-col xs="12" sm="10" offset-sm="1">
            <v-text-field
              :label="$t('urlFieldLabel')"
              :value="value"
              @input="onInput" />
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="embedURL" xs="12" sm="8" offset-sm="2">
            <p>{{ $t('previewTitle') }}</p>
            <iframe
              title="yt"
              width="560"
              height="315"
              :src="embedURL"
              frameborder="0"
              allow="accelerometer; autoplay;
            clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen />

          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-if="choicesVideoVisibility === true">
      {{ $t('videoVisibilty') }}
      <v-container>
        <v-row>
          <v-radio-group
            v-model="isVideoVisible"
            column
          >
            <v-radio
              :label="$t('alwaysDisplayVideo')"
              value='always'
            />
            <v-radio
              :label="$t('displayVideoIfCheckin')"
              value='afterCheckin'
            />
            <v-radio
              :label="$t('displayVideoIfSurveyDone')"
              value='afterSurvey'
            />
          </v-radio-group>
        </v-row>
      </v-container>
    </v-card-text>

  </v-card>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    loopStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoPlayStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialVideoVisibilityValue: {
      type: String,
      required: false,
      default: '',
    },
    choicesVideoVisibility: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      refresh: false,
      videoVisibility: this.initialVideoVisibilityValue,
    };
  },
  computed: {
    embedURL() {
      if (!this.value) {
        return null;
      }
      const vid = this.value.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (!vid) {
        return null;
      }
      if (vid.length === 2) {
        return `https://www.youtube-nocookie.com/embed/${vid[1]}`;
      }
      return vid[1] || null;
    },
    loopValue: {
      immediate: true,
      set(value) {
        this.$emit('loopchanged', value);
      },
      get() {
        return this.loopStatus;
      },
    },
    autoplayValue: {
      immediate: true,
      set(value) {
        this.$emit('autoplaychanged', value);
      },
      get() {
        return this.autoPlayStatus;
      },
    },
    isVideoVisible: {
      set(value) {
        this.videoVisibility = value;
        this.$emit('videoVisibilityChanged', value);
      },
      get() {
        return this.videoVisibility;
      },
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Vidéo Youtube",
    "content": "Collez l'url de partage d'une vidéo youtube :",
    "urlFieldLabel": "Adresse complète de la vidéo",
    "previewTitle": "Aperçu de la vidéo",
    "alwaysDisplayVideo": "Visible tout le temps",
    "displayVideoIfCheckin": "Seulement si l'utilisateur a fait un CheckIn",
    "displayVideoIfSurveyDone": "Seulement si l'utilisateur a terminé les quizz",
    "videoVisibilty": "Visibilité"
  },
  "en": {
    "title": "Youtube video",
    "content": "Paste the Sharing URL of the youtube video:",
    "urlFieldLabel": "Full video URL",
    "previewTitle": "Video preview",
    "alwaysDisplayVideo": "Always visible",
    "displayVideoIfCheckin": "Only if user has checked in",
    "displayVideoIfSurveyDone": "Only if user has answered all the quizzes",
    "videoVisibilty": "Visibility"
  }
}
</i18n>
