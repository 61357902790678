<template>
  <v-card>
    <l-map
      ref="themap"
      style="height: 400px; z-index:1"
      :zoom="zoom"
      :center="center"
      :options="{ scrollWheelZoom: false }">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker v-for="p in pois" :key="p.id" :lat-lng="[p.latitude, p.longitude]">
        <l-popup>
          <router-link :to="getPoiUrl(p.id)">POI: {{ p.name }}</router-link>
        </l-popup>
      </l-marker>
    </l-map>
    <v-card-title>{{ $t('title') }}</v-card-title>
    <v-card-text>
      {{ $t('description') }}
    </v-card-text>
  </v-card>
</template>
<script>

import 'leaflet/dist/leaflet.css';
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet';
import L, { Icon } from 'leaflet';

export default {
  props: {
    initialCenter: {
      type: Array,
      required: true,
    },
    initialZoom: {
      type: Number,
      required: true,
    },
    pois: {
      type: Array,
      required: false,
      default: () => [],
    },
    destinationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  computed: {
    bounds() {
      const rawValues = [];
      this.pois.forEach((p) => {
        rawValues.push([p.latitude, p.longitude]);
      });
      return L.latLngBounds(rawValues);
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: this.initialZoom,
      center: this.initialCenter,
      markerLatLng: [51.504, -0.159],
    };
  },
  methods: {
    getPoiUrl(poiId) {
      return `/sites/${this.destinationId}/places/${poiId}`;
    },
    resetLeafletIcon() {
      // eslint-disable-next-line
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        // eslint-disable-next-line
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        // eslint-disable-next-line
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        // eslint-disable-next-line
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      });
    },
  },
  mounted() {
    this.resetLeafletIcon();
    this.$refs.themap.fitBounds(this.bounds);
  },
};
</script>
<i18n>
  {
    "fr": {
      "title": "Placement des lieux sur la carte",
      "description": "Utilisez cette carte pour vérifier le placement des lieux"
    },
    "en": {
      "title": "Location Placement on Map",
      "description": "Use this map to verify the location placement"
    }
  }
</i18n>
