<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <survey-editor
        v-if="dialog == true"
        :update-disabled="shouldPreventModifications"
        :creation-disabled="poiIsNew"
        :destination-id="currentDestination.uuid"
        :poi-id="poiUuid"
        :survey="currentSurvey"
        :is-new="isNewSurvey"
        :api-base-url="apiBaseUrl"
        @closedialog="closeEditor"
        @createsurvey="createSurvey"
        @updatesurvey="updateSurvey" />
    </v-dialog>
    <v-card>

      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('toolbarTitle') }}</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <template>
          <v-list-item>
            <v-list-item-content>
              <v-alert v-if="poiIsNew" type="warning">
                {{ $t('alertNewPOI') }}
              </v-alert>
              <v-alert v-else-if="shouldPreventModifications" type="warning">
                {{ $t('alert') }}
              </v-alert>
              <v-divider />

            </v-list-item-content>
          </v-list-item>
          <div v-for="s in sortedSurveys" :key="s.id">
            <v-list-item>
              <v-list-item-avatar>
                <flag :iso="flagIconClass(s.lang)" />
              </v-list-item-avatar>
              <v-list-item-content @click="openEditorInEditMode(s)">
                <v-list-item-title>{{ s.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <survey-deleter
                  :disabled="shouldPreventModifications"
                  @delete="deleteSurvey(s)" />
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </div>
        </template>
      </v-list>
      <v-card-text v-if="!existingSurveys || existingSurveys.length === 0" class="header">
        {{ $t('listPlaceholder') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-select
          label="Mode d'affichage des défis"
          :items="surveyDisplayModeOptions"
          :value="surveyDisplayMode"
          @input="$emit('displaymodeupdated', $event)" />
        <v-spacer />
        <v-btn text color="secondary" @click="openSortSurvey()">{{ $t('sortSurvey') }}</v-btn>
        <v-dialog v-model="openSort">
          <SortSurvey
            :pois="existingSurveys"
            @close="closeOpenSort"
            @save="saveSortedSurvey" />
        </v-dialog>
        <v-btn :disabled="poiIsNew" color="secondary" @click="openEditorinCreateMode">
          {{ $t('addBtn') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapActions } from 'vuex';
import axios from 'axios';
import * as at from '../../store/actionTypes';
import UserNotifications from '../../mixins/UserNotifications.vue';

import SurveyEditor from './SurveyEditor.vue';
import SurveyDeleter from './SurveyDeleter.vue';
import SortSurvey from './SortSurvey.vue';
import { postClues } from '../../services/clues';

export default {
  components: { SurveyEditor, SurveyDeleter, SortSurvey },
  mixins: [UserNotifications],
  props: {
    apiBaseUrl: {
      type: String,
      required: true,
    },
    poiUuid: {
      type: String,
      required: true,
    },
    surveyDisplayMode: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    poiIsNew: {
      Type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      existingSurveys: [], // list of surveys that are already saved on this POI
      dialog: false, // controls the display of the SurveyEditor dialog
      currentSurvey: null, // survey being edited
      isNewSurvey: false, // indicates if the editor is open for a new survey
      games: null,
      openSort: false,
    };
  },
  computed: {

    currentDestination() {
      return this.$store.state.site.sites[this.$route.params.siteId];
    },
    shouldPreventModifications() {
      if (this.games === null) {
        return false;
      }
      if (this.games.length === 0) {
        return false;
      }
      return true;
    },
    sortedSurveys() {
      if (!this.existingSurveys) {
        return [];
      }
      const sortedSurveys = [...this.existingSurveys];
      sortedSurveys.sort((a, b) => a.order - b.order);
      return sortedSurveys;
    },
    surveyDisplayModeOptions() {
      return [{
        text: 'Tous en même temps',
        value: 'all',
      },
      {
        text: 'Un par un',
        value: 'one',
      },
      ];
    },
  },

  methods: {
    ...mapActions({
      fetchGames: at.FETCH_PARTIES,
    }),
    fetchSurveys(baseURL, poiID) {
      const url = `/gameapi/v1/admin/surveys?poi_id=${poiID}`;
      const http = axios.create({ baseURL });
      return http.get(url, { headers: { Authorization: `JWT ${this.token}` } });
    },
    flagIconClass(locale) {
      const countryCode = locale.substring(locale.indexOf('_') + 1).toLowerCase();
      return `flag-icon flag-icon-${countryCode}`;
    },
    openEditorInEditMode(survey) {
      this.currentSurvey = survey;
      this.isNewSurvey = false;
      this.dialog = true;
    },
    openSortSurvey() {
      this.openSort = true;
    },
    closeOpenSort() {
      this.openSort = false;
    },
    async updateSurvey(q, clues) {
      const url = `/gameapi/v1/admin/surveys/${q.id}`;
      const http = axios.create({ baseURL: this.apiBaseUrl });
      try {
        await http.put(url, q, { headers: { Authorization: `JWT ${this.token}` } });
        if (clues !== null && Array.isArray(clues)) {
          clues.forEach(async (clue) => {
            try {
              await postClues(clue, this.token);
            } catch (error) {
              this.errorNotification(this.$t('notificationPostCluesError'), error);
              Sentry.captureException(error);
            }
          });
        }
        this.closeEditor();
      } catch (error) {
        this.errorNotification(this.$t('notificationUpdateError'), error);
        Sentry.captureException(error);
      }
    },
    async createSurvey(q, clues) {
      const url = '/gameapi/v1/admin/surveys';
      const http = axios.create({ baseURL: this.apiBaseUrl });
      try {
        await http.post(url, q, { headers: { Authorization: `JWT ${this.token}` } });
        if (clues !== null) {
          clues.forEach(async (clue) => {
            try {
              await postClues(clue, this.token);
            } catch (error) {
              this.errorNotification(this.$t('notificationPostCluesError'), error);
              Sentry.captureException(error);
            }
          });
        }
        this.closeEditor();
      } catch (error) {
        this.errorNotification(this.$t('notificationCreateError'), error);
      }
    },
    async saveSortedSurvey(value) {
      this.openSort = false;
      value.forEach(async (element) => {
        this.updateSurvey(element);
      });
      const res = await this.fetchSurveys(this.apiBaseUrl, this.poiUuid);
      this.existingSurveys = res.data;
      this.sortExistingSurveys();
    },
    sortExistingSurveys() {
      if (!this.existingSurveys || this.existingSurveys.length === 0) {
        return;
      }
      this.existingSurveys.sort((a, b) => a.order - b.order);
    },
    openEditorinCreateMode() {
      this.isNewSurvey = true;
      this.dialog = true;
    },
    async closeEditor() {
      this.dialog = false;
      this.currentSurvey = null;
      this.isNewSurvey = false;
      try {
        const res = await this.fetchSurveys(this.apiBaseUrl, this.poiUuid);
        this.existingSurveys = res.data;
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorFetchingQuizzs', error));
      }
    },
    async deleteSurvey(survey) {
      try {
        const url = `/gameapi/v1/admin/surveys/${survey.id}`;
        const http = axios.create({ baseURL: this.apiBaseUrl });
        await http.delete(url, { headers: { Authorization: `JWT ${this.token}` } });
        const res = await this.fetchSurveys(this.apiBaseUrl, this.poiUuid);
        this.existingSurveys = res.data;
        this.successNotification(this.$t('notificationDeleted'));
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorDeletion'), error);
      }
    },
  },

  async mounted() {
    try {
      const res = await this.fetchSurveys(this.apiBaseUrl, this.poiUuid);
      this.existingSurveys = res.data;
      this.sortExistingSurveys();
      this.fetchGames({
        siteId: this.currentDestination.uuid,
        token: this.token,
        status: 'pending,started',
      })
        .then((games) => {
          this.games = games;
        })
        .catch((error) => {
          this.errorNotification(this.$t('notificationErrorFetchingParties'), error);
        });
    } catch (error) {
      this.errorNotification(this.$t('notificationErrorFetchingQuizzs'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "toolbarTitle": "Défis",
    "alert": "Les modifications des défis existants sont désactivées car au moins une partie n'est pas terminée.",
    "listPlaceholder": "Les défis attachés à ce lieu apparaitront ici",
    "addBtn": "Ajouter un défi",
    "notificationDeleted": "Défi supprimé",
    "notificationErrorDeletion": "Impossible de supprimer le défi. Avez-vous une partie en cours ?",
    "notificationErrorFetchingParties": "Impossible de récupérer la liste des parties",
    "notificationErrorFetchingQuizzs": "Impossible de récupérer la liste des défis",
    "sortSurvey": "Choisir un ordre de tri",
    "notificationUpdateError": "Impossible de mettre à jour le défi",
    "notificationPostCluesError": "Erreur lors de l'enregistrement de l'indice. Veuillez Réessayer",
    "alertNewPOI": "Enregistrez le lieu d'intérêt avant de lui ajouter des défis",
    "notificationCreateError": "Impossible de créer le défi"

  },
  "en": {
    "toolbarTitle": "Challenges",
    "alert": "Existing challengers cannot be modified because at least one game is not finished",
    "listPlaceholder": "Challenges attached to this POI will appear here",
    "addBtn": "Add a challenge",
    "notificationDeleted": "Challenge deleted",
    "notificationErrorDeletion": "Unable to delete challenge. Is there a game in progress?",
    "notificationErrorFetchingParties": "Unable to retrieve parties",
    "notificationErrorFetchingQuizzs": "Unable to retrieve challenges",
    "sortSurvey": "Pick a sort sequence",
    "alertNewPOI": "Please save the POI before adding challenges",
    "notificationUpdateError": "Unable to update the challenge",
    "notificationPostCluesError": "Error when registering clue. Please try again",
    "notificationCreateError": "Unable to create the challenge"

  }
}
</i18n>
