<template>
  <v-dialog
    v-model="dialog"
    max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('btnConfirm') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">{{ $t('title') }}</v-card-title>
      <v-card-text>
        {{ $t('subtitle', { name: name }) }}<br>

      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >{{ $t('btnCancel') }}
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="$emit('delete')"
        >{{ $t('btnConfirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    isDeleting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Supprimer ce lieu d'intérêt ?",
    "subtitle": "{name} sera supprimé définitivement. Cette action est irréversible",
    "btnCancel": "Annuler",
    "btnConfirm": "Supprimer"
  },
  "en": {
    "title": "Delete this POI?",
    "subtitle": "{name} will be deleted permanently. This cannot be undone",
    "btnCancel": "Cancel",
    "btnConfirm": "Delete"
  }
}
</i18n>
