import * as at from '../actionTypes';
import http from '../../services/fetch';
import cfg from '../../config';

export default {
  mutations: {},
  actions: {
    [at.GET_PARTY](context, { gameId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/parties/${gameId}`;
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.GET_SCOREBOARD](context, { gameId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/scoreboard?party_id=${gameId}`;
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.GET_TEAM_LOGS](context, { teamId, teamToken }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/teams/${teamId}/logs`;
        http
          .getJSON(url, {}, teamToken)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.GET_CHECKINS_PER_TEAM](context, { partyId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/parties/${partyId}/checkins-per-team

        `;
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.FETCH_PARTIES](context, { siteId, token, status }) {
      return new Promise((resolve, reject) => {
        let url = `${cfg.gameApiUrl}/gameapi/v1/parties?destination_id=${siteId}`;
        if (status) {
          url += `&status=${status}`;
        }
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.CREATE_PARTY](context, { game, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/parties`;
        http
          .postJSON(url, game, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.UPDATE_PARTY](context, { game, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/parties/${game.id}`;
        http
          .putJSON(url, game, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.UPDATE_TEAM](context, { team, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/teams/${team.id}`;
        http
          .putJSON(url, team, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.DELETE_TEAM](context, { team, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/teams/${team.id}`;
        http
          .deleteJSON(url, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.CREATE_TEAMS](context, { teams, sendEmail, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/teams/batch?send_welcome_email=${sendEmail}`;
        http
          .postJSON(url, teams, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.GET_REVIEWS](context, { partyId, token, status }) {
      return new Promise((resolve, reject) => {
        let url = `${cfg.gameApiUrl}/gameapi/v1/surveys/reviews?party_id=${partyId}`;
        if (status) {
          url += `&status=${status}`;
        }
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.GET_PENDING_REVIEWS](context, { partyId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/surveys/reviews_previews?party_id=${partyId}`;
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.GET_REVIEW_BY_ID](context, { reviewId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/surveys/reviews/${reviewId}`;
        http
          .getJSON(url, {}, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.CORRECT_REVIEW](context, { review, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/surveys/reviews/${review.id}`;
        http
          .putJSON(url, review, token)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [at.CREATE_ADMIN_EVENT](context, { event, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.gameApiUrl}/gameapi/v1/admin/bonus`;
        http.postJSON(url, event, token, { Authorization: `JWT ${token}` })
          .then((data) => {
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
      });
    },
  },
};
