var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sortedReviews,"items-per-page":25,"loading":_vm.isLoadingData,"hide-default-footer":false,"loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noData'),"footer-props":{
          showFirstLastPage: false,
          itemsPerPageOptions: [25, 50, -1],
          firstIcon: '$first',
          lastIcon: '$last',
          prevIcon: '$prev',
          nextIcon: '$next',
          itemsPerPageAllText: _vm.$t('tableFooterAllText'),
          itemsPerPageText: _vm.$t('tableFooterNbPerPageText'),
        }},scopedSlots:_vm._u([{key:`item.survey`,fn:function({ item }){return [(_vm.byUuid[item.survey.poiId])?_c('div',[_vm._v(" "+_vm._s(_vm.byUuid[item.survey.poiId].name)+" ")]):_vm._e()]}},{key:`item.action`,fn:function({ item }){return [_c('game-review-dialog',{attrs:{"review-id":item.id},on:{"saved":function($event){return _vm.$emit('needs-refresh')}}})]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }