<template>
  <v-dialog
    v-model="dialog"
    max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('disableBtn')}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">{{ $tc('headline', count) }}</v-card-title>
      <v-card-text>
        <strong>{{name}}</strong> {{ $tc('contentLine1', count) }}<br>
        {{ $tc('contentLine2', count) }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >{{ $t('cancelBtn') }}
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="$emit('disable')"
        >{{ $t('disableBtn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    isDeleting: {
      type: Boolean,
      required: true,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<i18n>
{
  "fr": {
    "headline": "Désactiver ce lieu d'intérêt | Désactiver ces lieux d'intérêt ?",
    "contentLine1": "ne sera plus visible. | ne seront plus visibles pour vos visiteurs.",
    "contentLine2": "Vous pouvez réactiver ce lieu à tout moment en filtrant les lieux désactivés | Vous pouvez réactiver ces lieux à tout moment en filtrant les lieux désactivés",
    "cancelBtn": "Annuler",
    "disableBtn": "Désactiver"
  },
  "en": {
    "headline": "Disable this POI? | Disable these POI?",
    "contentLine1": "will be hidden from visitors",
    "contentLine2": "You can use the 'disabled' filter to enable this POI again at all times | You can use the 'disabled' filter to enable these POIs again at all times" ,
    "cancelBtn": "Cancel",
    "disableBtn": "Disable"
  }
}
</i18n>
