<template>
  <v-dialog
    ref="dialog"
    v-model="dateDialog"
    :return-date.sync="date"
    persistent
    full-width
    width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        slot="activator"
        v-model="date"
        :label="label"
        prepend-icon="event"
        :error-messages="validationErrors"
        v-on="on"
        readonly />
    </template>

    <v-date-picker
      v-model="date"
      scrollable
      :first-day-of-week="1"
      :locale="currentLocale">
      <v-spacer />
      <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  model: {
    prop: 'date',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    initialDate: {
      type: String,
      required: false,
      default: null,
    },

  },
  data() {
    return {
      date: this.initialDate,
      dateDialog: null,
    };
  },
  watch: {
    date() {
      this.$emit('change', this.date);
    },
  },
  computed: {
    validationErrors() {
      if (this.date === null) {
        return this.$t('validationErr');
      }
      return null;
    },
    currentLocale() {
      const l = navigator.language || navigator.userLanguage;
      return l;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "validationErr": "Ce champ est obligatoire"
  },
  "en": {
    "validationErr": "This field is mandatory"
  }
}
</i18n>
