import axios from './axios';

/* eslint-disable max-len */
const PostMetadata = (
  siteID,
  parentType,
  parentID,
  picture,
  token,
) => new Promise((resolve, reject) => {
  const pic = {
    destinationId: parseInt(siteID, 10),
    filename: picture.filename,
    author: picture.author,
    altText: picture.altText,
    order: picture.order,
  };
  const url = `/api/v3/sites/${siteID}/pictures?picture_type=${parentType}&parent=${parentID}`;
  axios.post(url, pic, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
  }).then((response) => { resolve(response.data); })
    .catch((error) => { reject(error); });
});

const PutMetadata = (siteID, picture, token) => new Promise((resolve, reject) => {
  const pic = {
    uuid: picture.uuid,
    destinationId: parseInt(siteID, 10),
    filename: picture.filename,
    author: picture.author,
    altText: picture.altText,
    order: picture.order,
  };
  const url = `/api/v3/sites/${siteID}/pictures/${pic.uuid}/metadata`;
  axios.put(url, pic, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
  }).then((response) => { resolve(response.data); })
    .catch((error) => { reject(error); });
});

const PutFile = (siteID, pictureUUID, picture, token) => new Promise((resolve, reject) => {
  const url = `/api/v3/sites/${siteID}/pictures/${pictureUUID}`;
  axios.put(url, picture, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
    timeout: 60000,
  }).then((response) => { resolve(response.data); }).catch((error) => { reject(error); });
});

const Delete = (siteID, pictureUUID, token) => new Promise((resolve, reject) => {
  const url = `/api/v3/sites/${siteID}/pictures/${pictureUUID}`;
  axios.delete(url, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
  }).then(() => { resolve(); }).catch((error) => { reject(error); });
});

/**
 * Stores a picture on the gameAPI.
 *
 * @param {String} baseURL the base url of the game api
 * @param {string} folder the base folder to store the picture
 * @param {*} file
 * @param {String} token the authentication token
 * @returns a json object containing the file url
 */
const PutGameAPIFile = (baseURL, folder, file, token) => new Promise((resolve, reject) => {
  const rawFile = new FormData();
  rawFile.append('picture', file);
  const url = `${baseURL}/gameapi/v1/pictures/raw?folder=${folder}`;
  axios.put(url, rawFile, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
    timeout: 60000,
  }).then((response) => { resolve(response.data); }).catch((error) => { reject(error); });
});

const DeleteGameAPIFile = (baseURL, filepath, token) => new Promise((resolve, reject) => {
  const url = `${baseURL}/gameapi/v1/pictures/raw?path=${filepath}`;
  axios.delete(url, {
    headers: {
      Authorization: `BEARER ${token}`,
    },
  }).then(() => { resolve(); }).catch((error) => { reject(error); });
});

export default {
  PostMetadata,
  PutMetadata,
  PutFile,
  Delete,
  PutGameAPIFile,
  DeleteGameAPIFile,
};
