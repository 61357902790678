<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('toolbarTitle') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <template v-if="isLoading">
        <v-list-item>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular indeterminate color="secondary" />
          </v-row>
        </v-list-item>
      </template>
      <template v-else v-for="(g, idx) in games">
        <v-list-item :key="'game_' + g.id" @click="$emit('gameClicked', g)">
          <v-list-item-content>
            <v-list-item-title>{{ g.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('from')}} {{ formatDate(g.startDate) }} {{ $t('to') }}
              {{ formatDate(g.endDate) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon><game-status :status="g.status" /></v-list-item-icon>
        </v-list-item>
        <v-divider :key="idx" class="my-0" />
      </template>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-checkbox :label="$t('viewGames')" v-model="displayEndedGames" @change="displayGames()" />
      <v-btn text color="accent" @click="$emit('new')">{{ $t('btnCreateParty') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { parseISO } from 'date-fns';
import UserNotifications from '../../mixins/UserNotifications.vue';

import * as at from '../../store/actionTypes';

import GameStatus from './GameStatus.vue';

export default {
  components: {
    GameStatus,
  },
  data() {
    return {
      isLoading: false,
      games: [],
      displayEndedGames: false,
    };
  },
  mixins: [UserNotifications],
  computed: {
    siteUuid() {
      return this.$store.getters['site/allSites'][this.$route.params.siteId].uuid;
    },
    ...mapGetters('auth', ['token']),
  },
  methods: {
    ...mapActions({
      fetchGames: at.FETCH_PARTIES,
    }),

    formatDate(rfcDate) {
      const rawDate = parseISO(rfcDate);
      return this.$d(rawDate, 'short', this.$i18n.locale);
    },
    displayGames() {
      let statuses = 'started,pending';

      if (this.displayEndedGames === true) {
        statuses = 'started,pending,ended';
      }

      this.fetchGames({
        siteId: this.siteUuid,
        token: this.token,
        status: statuses,
      })
        .then((games) => {
          this.games = games;
        })
        .catch((error) => {
          this.errorNotification(this.$t('notificationErrorFetch'), error);
        });
    },
  },
  mounted() {
    this.isLoading = true;
    this.displayGames();
    this.isLoading = false;
  },
};
</script>
<i18n>
{
  "fr": {
    "toolbarTitle": "Parties",
    "from": "Du",
    "to": "au",
    "btnCreateParty": "Créer une nouvelle partie",
    "notificationErrorFetch": "Impossible de récupérer la liste des parties",
    "viewGames":"Voir les parties terminées"
  },
  "en": {
    "toolbarTitle": "Games",
    "from": "From",
    "to": "To",
    "btnCreateParty": "Create a new game",
    "notificationErrorFetch": "Unable to retrieve the games list",
    "viewGames":"See completed games"
  }
}
</i18n>
