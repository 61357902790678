<template>
  <v-card>
    <v-card-title>
      {{ title }}

    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-container>
        <v-row>
          <v-col cols="7">
            <v-text-field
              v-model="streetAddress"
              v-on:input="addressChanged"

              :rules="[rules.maxLength]"
              :label="$t('fieldLabelStreetAddress')"
              counter="255"
              required />
          </v-col>
          <v-col cols="4" offset="1">
            <v-text-field
              v-model="postalCode"
              v-on:input="addressChanged"
              :rules="[rules.postalCode]"
              :label="$t('fieldLabelPostalCode')"
              counter="20"
              required />
          </v-col>
        </v-row>
        <v-row row wrap>
          <v-col cols="7">
            <v-text-field
              v-model="city"
              v-on:input="addressChanged"
              :rules="[rules.cityCountry]"
              :label="$t('fieldLabelCity')"
              counter="100"
              required />
          </v-col>
          <v-col cols="4" offset="1">
            <v-text-field
              v-model="country"
              :rules="[rules.cityCountry]"
              v-on:input="addressChanged"
              :label="$t('fieldLabelCountry')"
              counter="100"
              required />
          </v-col>
        </v-row>
      </v-container>

    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="accent"
        :disabled="disableGeocode"
        @click="$emit('geocode')">{{ $t('btnGeocode') }}</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>

import validator from '../../services/validation';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    initialStreetAddress: {
      type: String,
      required: true,
    },
    initialPostalCode: {
      type: String,
      required: true,
    },
    initialCity: {
      type: String,
      required: true,
    },
    initialCountry: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('ruleRequired'),
        maxLength: (value) => validator.ValidateMaxLength(value, 255) || this.$t('ruleMaxLength'),
        postalCode: (value) => validator.ValidateMaxLength(value, 20) || this.$t('ruleMaxLength'),
        cityCountry: (value) => validator.ValidateMaxLength(value, 100) || this.$t('ruleMaxLength'),

      },
      streetAddressValue: null,
      postalCodeValue: null,
      cityValue: null,
      countryValue: null,
    };
  },

  computed: {
    streetAddress: {
      set(value) {
        this.streetAddressValue = value;
      },
      get() {
        if (this.streetAddressValue === null) {
          return this.initialStreetAddress;
        }
        return this.streetAddressValue;
      },
    },
    postalCode: {
      set(value) {
        this.postalCodeValue = value;
      },
      get() {
        if (this.postalCodeValue === null) {
          return this.initialPostalCode;
        }
        return this.postalCodeValue;
      },
    },
    city: {
      set(value) {
        this.cityValue = value;
      },
      get() {
        if (this.cityValue === null) {
          return this.initialCity;
        }
        return this.cityValue;
      },
    },
    country: {
      set(value) {
        this.countryValue = value;
      },
      get() {
        if (this.countryValue === null) {
          return this.initialCountry;
        }
        return this.countryValue;
      },
    },
    disableGeocode() {
      if (!this.streetAddress || !this.postalCode || !this.city) {
        return true;
      }
      return false;
    },
  },
  methods: {
    addressChanged() {
      this.$emit('addresschanged', {
        streetAddress: this.streetAddress,
        postalCode: this.postalCode,
        city: this.city,
        country: this.country,
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "fieldLabelStreetAddress": "Adresse",
    "fieldLabelPostalCode": "Code postal",
    "fieldLabelCity": "Ville",
    "fieldLabelCountry": "Pays",
    "btnGeocode": "Trouver la position GPS à partir de l'adresse",
    "ruleRequired": "Ce champ ne peut pas être vide.",
    "ruleMaxLength": "valeur trop longue"
  },
  "en": {
    "fieldLabelStreetAddress": "Street address",
    "fieldLabelPostalCode": "Postal code",
    "fieldLabelCity": "City",
    "fieldLabelCountry": "Country",
    "btnGeocode": "Fetch GPS location from this address",
    "ruleRequired": "This field cannot be empty.",
    "ruleMaxLength": "Too long"
  }
}
</i18n>
