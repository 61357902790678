<template>
  <div>
    <v-list-item @click="generatePdf">
      <v-list-item-title>PDF</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  props: {
    game: {
      type: Object,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    platformType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pdfConverter: false,
    };
  },
  methods: {
    teamLink(teamToken) {
      if (this.platformType === 'indoor') {
        return `https://${this.domain}/#/auth/${teamToken}`;
      }
      return `https://${this.domain}/auth/teams/${teamToken}`;
    },
    pdfBody() {
      const content = [];

      // eslint-disable-next-line
      for (let idx = 0; idx < this.teams.length; idx++) {
        const team = this.teams[idx];
        const node = [];
        node.push(
          {
            fontSize: 18,
            bold: true,
            alignment: 'center',
            margin: [30, ((idx % 2) === 0 && idx !== 0) ? 70 : 20, 20, 0],
            headlineLevel: idx % 2 && idx !== 0,
            text: [{ text: this.$t('team'), italics: true }, `${team.name}`],
          },
          {
            qr: this.teamLink(team.token),
            fit: '200',
            alignment: 'center',
            margin: [0, 10],
          },
          {
            text: this.$t('qrIssue'),
            fontSize: 10,
            bold: false,
            alignment: 'left',
            margin: [30, 0, 20, 10],
          },
        );
        let lastNode = null;
        const shortCodeText = {
          text: [
            'ou rendez-vous sur ',
            { text: 'https://go.dotmap.fr', bold: true },
            ' et saisissez le code : ',
            { text: team.shortcode, bold: true },
          ],
          fontSize: 10,
          bold: false,
          alignment: 'left',
          margin: [30, 0, 20, 10],
        };
        if (!this.game.phoneNumber) {
          lastNode = shortCodeText;
        } else {
          node.push(shortCodeText);
          lastNode = {
            text: this.$t('assistancePhoneNumber') + (this.game.phoneNumber ? this.game.phoneNumber : ' '),
            fontSize: 12,
            bold: true,
            alignment: 'left',
            margin: [30, 0, 20, 40],
          };
        }
        if (idx % 2 !== 0 && idx !== 0 && idx < this.teams.length - 1) {
          lastNode.pageBreak = 'after';
        }
        node.push(lastNode);
        content.push(node);
      }
      return content;
    },
    generatePdf() {
      const docDefinition = {
        pageSize: 'A4',
        info: {
          title: this.$t('titleGeneratedPdf') + this.game.name,
        },
        content: this.pdfBody(),
      };
      const pdf = pdfMake.createPdf(docDefinition);
      if (pdf !== null) {
        pdf.open();
      } else {
        this.errorNotification(this.$t('errorGeneratePdf'));
        console.log('error');
      }
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "qrIssue": "En cas de problème avec votre téléphone, scannez ce QR code pour reprendre la partie sur un nouveau téléphone",
      "qrHelper": "Assistance",
      "team": "Équipe : ",
      "assistancePhoneNumber": "Numéro d'assistance : ",
      "titleGeneratedPdf": "QR codes équipes de la partie : ",
      "errorGeneratePdf": "Impossible de récupérer les QR codes"
    },
    "en": {
      "qrIssue": "In case of phone issue, please scan this QR Code to continue playing on another phone",
      "qrHelper": "Support",
      "team": "Team: ",
      "assistancePhoneNumber": "Phone number for assistance: ",
      "titleGeneratedPdf": "Teams QR codes for the party: ",
      "errorGeneratePdf": "Impossible to get QR codes"
    }
  }
</i18n>
