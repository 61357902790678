<template>
  <div>
    <h2
      class="text-h5 ml-9 mt-9 mb-6">
      {{$t('billAddress')}}</h2>
    <v-form
      :disabled="modifyForm === false"
    >
      <v-row
        class="mx-auto">
        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            v-model="name"
            required
            class="my-0 py-0"
            :label="$t('labelFieldName')"
            :disabled="user.organizationId !== 4"
          />
        </v-col>

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            v-model="addressMain"
            required
            class="my-0 py-0"
            :label="$t('labelFieldAddress')"

          />
        </v-col>

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            v-model="addressComplement"
            class="my-0 py-0"
            :label="$t('labelFieldAddressBis')"
          />
        </v-col>
        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            v-model="zipCode"
            required
            class="my-0 py-0"
            :label="$t('labelFieldZipcode')"
          />
        </v-col>

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            required
            v-model="city"
            class="my-0 py-0"
            :label="$t('labelFieldTown')"
          />
        </v-col>

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            required
            v-model="country"
            class="my-0 py-0"
            :label="$t('labelFieldCountry')"
          />
        </v-col>
        <v-col
          class="mx-auto mt-n2"
          cols="8"
          md="8">
          <v-checkbox
            :disabled="user.organizationId !== 4 && user.role === 'editor'"
            class="my-0 py-0"
            v-model="useCredits"
            :label="$t('labelUseCredits')" />
        </v-col>
      </v-row>
      <h2
        class="text-h5  ml-9 mt-9 mb-3">
        {{$t('detailsCoords')}}

      </h2>
      <v-row
        class="mx-auto">

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            required
            v-model="contactName"
            class="mb-0 mt-2 py-0"
            :label="$t('labelFieldContactName')"

          />
        </v-col>
        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            required
            v-model="contactEmail"
            class="mb-0 mt-2 py-0"
            :label="$t('labelFieldContactEmail')"

          />
        </v-col>

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            v-model="billingEmail"
            class="my-0 py-0"
            :label="$t('labelFieldBillEmail')"

          />
        </v-col>

        <v-col
          class="mx-auto"
          cols="8"
          md="8"
        >
          <v-text-field
            v-model="contactPhone"
            class="my-0 py-0"
            :label="$t('labelFieldNumber')"

          />
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      v-if="activeModification()"
      class="float-end mb-9"
      color="primary"
      @click="modifyForm = true">
      Modifier
    </v-btn>
    <v-btn
      v-if=" modifyForm === true"
      class="float-end mb-9"
      color="secondary"
      @click="saveOrga">
      {{$t('modifyButton')}}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, numeric } from 'vuelidate/lib/validators';
import orgaCrud from '../../services/orga';
import Notifications from '../../mixins/UserNotifications.vue';
import k from '../../const';

export default {
  mixins: [Notifications, validationMixin],
  props: {
    organization: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      addressMain: this.organization.addressMain,
      addressComplement: this.organization.addressComplement,
      billingEmail: this.organization.billingEmail,
      city: this.organization.city,
      contactEmail: this.organization.contactEmail,
      contactName: this.organization.contactName,
      contactPhone: this.organization.contactPhone,
      country: this.organization.country,
      name: this.organization.name,
      zipCode: this.organization.zipCode,
      modifyForm: false,
      useCredits: this.organization.useCredits,
      dotmapOrgId: k.dotmapOrgId,
    };
  },
  validations: {
    name: { required },
    addressMain: { required },
    city: { required },
    zipCode: { required, numeric },
    country: { required },
    contactEmail: { required, email },
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('auth', ['token']),

  },
  methods: {
    // utiliser vuelidate ??
    async saveOrga() {
      const req = [];
      if (this.$v.$invalid) {
        this.errorNotification(this.$t('errorMissingField'));
      } else {
        try {
          req.push({
            id: this.organization.id,
            name: this.name,
            addressMain: this.addressMain,
            addressComplement: this.addressComplement,
            billingEmail: this.billingEmail,
            city: this.city,
            contactName: this.contactName,
            contactEmail: this.contactEmail,
            contactPhone: this.contactPhone,
            country: this.country,
            versionHash: this.organization.versionHash,
            zipCode: this.zipCode,
            useCredits: this.useCredits,
          });
          const changedOrganization = req[0];
          const result = await orgaCrud.updateOrganization(changedOrganization, this.token);
          if (result) {
            this.modifyForm = false;
            this.successNotification(this.$t('successSaving'));
          }
        } catch (error) {
          this.modifyForm = false;
          this.errorNotification(this.$t('errorsSavingFailed'), error);
        }
      }
    },
    activeModification() {
      let activated = false;
      if (this.organization && this.organization.id === this.user.organizationId) {
        activated = true;
      } else if (this.organization && this.user.organizationId === this.dotmapOrgId) {
        activated = true;
      }
      return activated;
    },
  },
};
</script>
<i18n>
{
  "fr":
  {
    "billAddress": "Information de facturation",
    "labelFieldName": "Nom *",
    "labelFieldAddress": "Adresse *",
    "labelFieldAddressBis": "Adresse(complément)",
    "labelFieldZipcode": "Code postal *",
    "labelFieldTown": "Ville *",
    "labelFieldCountry": "Pays *",
    "detailsCoords": "Coordonnées",
    "labelFieldContactEmail": "Email de contact *",
    "labelFieldContactName": "Nom de la personne de contact ",
    "labelFieldBillEmail": "Email de facturation",
    "labelFieldNumber": "Numéro de téléphone",
    "modifyButton": "Sauvegarder les modifications",
    "successSaving": "Organisation mise à jour !",
    "errorsSavingFailed": "Problème lors de la mise à jour !",
    "errorMissingField": "Veuillez remplir tous les champs avec un *",
    "labelUseCredits":"Cette organisation utilise des crédits"
  },
  "en":
  {
    "billAddress": "Billing information",
    "labelFieldName": "Name *",
    "labelFieldAddress": "Address *",
    "labelFieldAddressBis": "Address(detailed)",
    "labelFieldZipcode": "Zipcode *",
    "labelFieldTown": "Town *",
    "labelFieldCountry": "Country *",
    "detailsCoords": "Localisation",
    "labelFieldContactEmail": "Contact Email *",
    "labelFieldContactName": "Customer name ",
    "labelFieldBillEmail": "Billing Email",
    "labelFieldNumber": "Telephone number",
    "modifyButton": "Save changes",
    "successSaving": "Organization updated!",
    "errorsSavingFailed": "Update failed!",
    "errorMissingField": "Please fill all the form containing *",
    "labelUseCredits":"This organization uses credits"

  }
}
</i18n>
