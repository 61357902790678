<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ $t('titleAdd') }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"

            >
              <v-text-field
                v-model="name"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                :error-messages="nameErrors"
                :label="$t('labelName')"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="email"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                :label="$t('labelEmail')"
                :error-messages="emailErrors"
                type="email"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="password"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                :label="$t('labelPassword')"
                :error-messages="passwordErrors"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              >
                <template v-slot:append-outer>
                  <v-btn
                    icon
                    @click="generatePassword">
                    <v-icon>refresh</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="copyToClipboard(password)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="accent"
        text
        @click="close"
      >
        {{ $t('btnClose') }}
      </v-btn>
      <v-btn
        color="primary"
        :disabled="formInvalid"
        text
        @click="save"
      >
        {{ $t('btnAdd') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import UserNotifications from '../../mixins/UserNotifications.vue';

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const passwordLength = 13;
export default {
  mixins: [UserNotifications],
  data() {
    return {
      name: '',
      email: '',
      password: '',
      showPassword: false,
    };
  },
  computed: {
    formInvalid() {
      return this.$v.$invalid;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.minLength) {
        errors.push(this.$t('errNameMinLen'));
      }
      if (!this.$v.name.required) {
        errors.push(this.$t('errNameRequired'));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.email) {
        errors.push(this.$t('errEmailIsEmail'));
      }
      if (!this.$v.email.required) {
        errors.push(this.$t('errEmailRequired'));
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push(this.$t('errPasswordRequired'));
      }
      if (!this.$v.password.minLength) {
        errors.push(this.$t('errPasswordMinLen'));
      }
      return errors;
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    generatePassword() {
      this.password = '';
      for (let i = 0; i <= passwordLength; i += 1) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        this.password += chars.substring(randomNumber, randomNumber + 1);
      }
    },
    copyToClipboard(pass) {
      navigator.clipboard.writeText(pass);
      this.successNotification(this.$t('notificationPasswordCopied'));
    },
    save() {
      this.$emit('add', {
        name: this.name,
        email: this.email,
        password: this.password,
      });
    },
  },
  mounted() {
    this.name = '';
    this.email = '';
    this.password = '';
    this.$v.$touch();
  },
};
</script>
<i18n>
  {
    "fr": {
      "saveBtn": "Ajouter",
      "cancelBtn": "Annuler",
      "titleAdd": "Ajouter un administrateur",
      "labelName": "Nom",
      "labelEmail": "Email",
      "labelPassword": "Mot de passe",
      "btnAdd": "Ajouter",
      "btnClose": "Annuler",
      "errEmailIsEmail": "Entrez une email valide.",
      "errEmailRequired": "Un email est obligatoire.",
      "errNameRequired": "Le nom est obligatoire.",
      "errNameMinLen": "Le nom doit faire au moins 2 charactères",
      "errPasswordRequired": "Un mot de passe est requis",
      "errPasswordMinLen": "Le mot de passe doit faire au moins 8 caractères",
      "notificationPasswordCopied": "Mot de passe copié dans le presse-papier"
    },
    "en": {
      "saveBtn": "Add",
      "cancelBtn": "Cancel",
      "titleAdd": "Add an editor",
      "labelName": "Name",
      "labelEmail": "Email",
      "labelPassword": "Password",
      "btnAdd": "Add",
      "btnClose": "Cancel",
      "errEmailIsEmail": "Type a valid email.",
      "errEmailRequired": "Email is mandatory.",
      "errNameRequired": "Name is mandatory.",
      "errNameMinLen": "Name must be at least 2 characters long",
      "errPasswordRequired": "Password is requried",
      "errPasswordMinLen": "Password must be at least 8 characters long",
      "notificationPasswordCopied": "Password copied to the clipboard"
    }
  }
</i18n>
