<template>
  <v-container>
    <v-divider />
    <v-row>
      <v-col cols="12"><h4>{{ $t('title') }}</h4></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :label="$t('questionTitleLabel')"
          v-model="title"
          @input="dirty = true"
          required
        >{{ $t('questionTitlePlaceholder') }}</v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="displayAnswer" :label="$t('switchDisplayAnswer')" />
      </v-col>
    </v-row>
    <v-divider class="mt-6" />
    <clues-question
      :question-id="question.id"
      :is-new-question="isNewQuestion"
      @clues="handleClues"
    />
    <v-divider class="mt-6" />
    <v-row>
      <v-col class="my-4" cols="12"><h4>{{ $t('titleResponses') }}</h4></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert type="info">
          {{ $t('alert') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-for="p in propositions" :key="p.id">
      <v-col cols="12">
        <v-row>
          <v-col sm="12" md="8">
            <v-text-field
              :label="$t('propositionLabel')"
              :hint="$t('propositionHint')"
              :persistent-hint="!p.text"
              v-model="p.text"
              @input="dirty = true" />
          </v-col>
          <v-col sm="12" md="4">
            <v-text-field
              v-model.number="p.points"
              type="number"
              :label="$t('pointsLabel')"
              :hint="$t('pointsHint')"
              @input="dirty = true"
              :persistent-hint="p.points === null" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              small
              text
              color="primary"
              @click="removeProposition(p.id)">{{ $t('removePropositionBtn') }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          small
          text
          color="secondary"
          @click="addProposition">{{ $t('addPropositionBtn') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn color="primary" @click="removeQuestion">{{ $t('removeQuestionBtn') }}</v-btn>&nbsp;
        <v-btn color="secondary" @click="saveQuestion">{{ $t('saveBtn') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import CluesQuestion from './CluesQuestion.vue';

export default {
  components: {
    CluesQuestion,
  },
  props: {
    question: {
      type: Object,
      required: false,
      default: null,
    },
    surveyId: {
      type: String,
      required: true,
    },
    isNewQuestion: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dirty: false,
      id: uuidv4(),
      order: 1,
      points: 0,
      propositions: [],
      requiresReview: false,
      title: null,
      type: 'single',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      displayAnswer: false,
      clues: null,
    };
  },
  methods: {
    populateWith(question) {
      this.id = question.id;
      this.order = question.order;
      this.propositions = question.propositions;
      this.title = question.title;
      this.createdAt = question.createdAt;
      this.updatedAt = question.updatedAt;
      this.displayAnswer = question.displayAnswer;
    },
    addProposition() {
      this.dirty = true;
      if (!this.propositions) {
        this.propositions = [];
      }

      this.propositions.push(
        {
          id: uuidv4(),
          text: '',
          order: this.propositions.length > 0 ? this.propositions.length + 1 : 1,
          points: 0,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          questionId: this.question.id,
          surveyId: this.surveyId,
        },
      );
      this.updatedAt = new Date().toISOString();
    },
    removeProposition(propositionId) {
      this.dirty = true;
      this.propositions = this.propositions.filter((p) => p.id !== propositionId);
      this.updatedAt = new Date().toISOString();
    },
    saveQuestion() {
      this.points = 0;
      const q = {
        id: this.id,
        title: this.title,
        order: this.order,
        points: 0,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
        questionId: this.question.id ? this.question.id : uuidv4(),
        surveyId: this.surveyId,
        type: this.type,
        propositions: this.propositions,
        displayAnswer: this.displayAnswer,
      };
      if (this.clues !== null) {
        this.$emit('question-updated', q, this.clues);
      } else {
        this.$emit('question-updated', q, null);
      }
      this.dirty = false;
    },
    removeQuestion() {
      this.dirty = false;
      this.$emit('question-removed', this.id);
    },
    handleClues(clues) {
      this.clues = clues;
    },
  },

  mounted() {
    if (this.question) {
      this.populateWith(this.question);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Paramètres d'une question à réponse unique",
    "questionTitleLabel": "Intitulé de la question",
    "questionTitlePlaceholder": "question",
    "alert": "Pour ce type de question, le joueur ne peut sélectionner qu'une seule réponse. Mais il vous est possible de définir plusieurs réponses correctes en leur affectant des points.",
    "propositionLabel": "Proposition",
    "propositionHint": "Indiquez la proposition de réponse",
    "pointsLabel": "Points",
    "pointsHint": "Définissez le score de cette réponse",
    "removePropositionBtn": "Supprimer la proposition",
    "addPropositionBtn": "Ajouter une proposition",
    "removeQuestionBtn": "Supprimer",
    "saveBtn": "Enregistrer",
    "switchDisplayAnswer": "Afficher les réponses correctes une fois que les joueurs ont répondu (indoor uniquement)",
    "titleResponses": "Gestion des élements de réponses"
  },
    "en": {
    "title": "Closed-answer question parameters",
    "questionTitleLabel": "Question title",
    "questionTitlePlaceholder": "question",
    "alert": "The player can only give one answer to this question. However, you can assign points to several answers to allow multiple correct answers",
    "propositionLabel": "Answer",
    "propositionHint": "Enter the enswer",
    "pointsLabel": "Points",
    "pointsHint": "Define the number of points for this answer",
    "removePropositionBtn": "Delete the answer",
    "addPropositionBtn": "Add an answer",
    "removeQuestionBtn": "Delete",
    "saveBtn": "Save",
    "switchDisplayAnswer": "Display the expected answers when the players have answered (indoor only)",
    "titleResponses": "Response element management"

  }
}
</i18n>
