<template>
  <v-card>
    <v-card-title class="headline">{{ title }}</v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>
    <v-card-actions>
      <v-spacer />

      <v-btn
        color="accent"
        text
        @click="$emit('cancel')"
      >
        {{ cancelButtonTitle }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="$emit('confirm')"
      >
        {{ confirmButtonTitle }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    cancelButtonTitle: {
      type: String,
      required: true,
    },
    confirmButtonTitle: {
      type: String,
      required: true,
    },
  },
};
</script>
