<template>
  <v-chip small :color="statusColor">{{ partyStatus }}</v-chip>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    partyStatus() {
      switch (this.status) {
        case 'pending':
          return this.$t('statusPending');
        case 'started':
          return this.$t('statusStarted');
        case 'ended':
          return this.$t('statusEnded');
        default:
          return this.status;
      }
    },
    statusColor() {
      switch (this.status) {
        case 'pending':
          return 'warning';
        case 'started':
          return 'success';
        case 'ended':
        default:
          return '';
      }
    },
  },
};
</script>

<i18n>
{
  "fr": {
    "statusPending": "En attente",
    "statusStarted": "En cours",
    "statusEnded": "Terminé"
  },
  "en": {
    "statusPending": "Pending",
    "statusStarted": "In progress",
    "statusEnded": "Ended"
  }
}
</i18n>
