<template>
  <div>
    <base-card
      :title="title"
      :description="description"
      :picture="picture"
      :left-button-title="$t('cardEditBtn')"
      :right-button-title="$t('cardDeleteBtn')"
      @rightbuttonclicked="deleteDialogOpen = true"
      @leftbuttonclicked="onEditButtonClicked">
      <template slot="content">
        <v-chip label class="ma-2" color="green" text-color="white">
          <v-icon>translate</v-icon>
          {{ description }}

        </v-chip>
        <v-chip label class="ma-2" color="secondary" text-color="white">
          <v-icon>room</v-icon>
          {{ `${tour.pois.length}` }} {{ $t('poisCount')}}
        </v-chip>
      </template>
    </base-card>

    <v-dialog
      v-model="deleteDialogOpen"
      max-width="50%">
      <v-card>
        <v-card-title class="headline">{{ $t('deleteDialogTitle') }}</v-card-title>
        <v-card-text>
          {{ $t('deleteDialogContentL1') }} <strong>{{ tour.name }}</strong>.<br>
          {{ $t('deleteDialogContentL2') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="deleteDialogOpen = false"
          >{{ $t('btnCancel') }}
          </v-btn>

          <v-btn
            color="pink"
            text
            :loading="isDeleting"
            @click="deleteTour()"
          >{{ $t('btnConfirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cfg from '../../config';
import BaseCard from '../base/BaseCard.vue';

import * as at from '../../store/actionTypes';

export default {
  components: { BaseCard },
  props: {
    tour: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteDialogOpen: false,
      isDeleting: false,
    };
  },
  computed: {
    title() {
      return this.tour.name;
    },
    description() {
      let locales = 0;
      if (this.tour.locales) {
        locales = Object.keys(this.tour.locales).length;
      }
      return this.$tc('translations', locales);
    },
    picture() {
      if (this.tour.pictures.length > 0) {
        const pics = [...this.tour.pictures];
        pics.sort((a, b) => a.order - b.order);
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${this.tour.destinationId}/${pics[0].filename}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
  },
  methods: {
    async deleteTour() {
      this.isDeleting = true;
      const token = this.$store.getters['auth/token'];
      try {
        await this.$store.dispatch(
          at.DELETE_TOUR,
          { siteId: this.tour.destinationId, tourId: this.tour.uuid, token },
        );
        this.isDeleting = false;
        this.deleteDialogOpen = false;
        await this.$store.dispatch(at.FETCH_TOURS, { siteId: this.tour.destinationId, token });
      } catch (error) {
        this.isDeleting = false;
        this.deleteDialogOpen = false;
        console.log(error);
      }
    },
    onEditButtonClicked() {
      this.$router.push(`/sites/${this.tour.destinationId}/tours/${this.tour.uuid}`);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "poisCount": "Lieux d'intérêt",
    "deleteDialogTitle": "Supprimer ce circuit ?",
    "deleteDialogContentL1": "Vous allez supprimer",
    "deleteDialogContentL2": "Cette action est irréversible",
    "btnCancel": "Ne pas supprimer",
    "btnConfirm": "Supprimer",
    "translations": "Pas de traduction | Une traduction | { count } traductions",
    "cardEditBtn": "Modifier",
    "cardDeleteBtn": "Supprimer"
  },
  "en": {
    "poisCount": "POIs",
    "deleteDialogTitle": "Delete this tour ?",
    "deleteDialogContentL1": "You will delete ",
    "deleteDialogContentL2": "This cannot be undone",
    "btnCancel": "Cancel",
    "btnConfirm": "Delete",
    "translations": "No translation | One translation | { count } translations",
    "cardEditBtn": "Edit",
    "cardDeleteBtn": "Delete"
  }
}
</i18n>
