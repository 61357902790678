<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-title>&nbsp;</v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-select
        v-model="currentOption"
        :items="optionsList"
        :label="optionsLabel"
      />
      <v-text-field
        v-model="currentValue"
        :label="valueLabel"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        text
        @click="$emit('close')">
        {{ $t('btnCancel') }}
      </v-btn>
      <v-btn
        color="secondary"
        text
        @click="save">{{ $t('btnSave') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    optionsList: {
      type: Array,
      required: true,
    },
    optionsLabel: {
      type: String,
      required: true,
    },
    valueLabel: {
      type: String,
      required: true,
    },
    initialOption: {
      type: String,
      required: false,
      default: null,
    },
    initialValue: {
      type: String,
      required: true,
    },
    id: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.initialValue,
      currentOption: this.initialOption,
    };
  },
  methods: {
    save() {
      this.$emit('save', {
        option: this.currentOption,
        value: this.currentValue,
        id: this.id,
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "btnCancel": "Annuler",
    "btnSave": "Enregistrer"
  },
  "en": {
    "btnCancel": "Cancel",
    "btnSave": "Save"
  }
}
</i18n>
