import http from './axios';

const organizationById = (id, token) => new Promise((resolve, reject) => {
  http.get(`/api/v3/organizations/${id}`, { headers: { Authorization: `BEARER ${token}` } })
    .then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
});

const allOrganizations = (token) => new Promise((resolve, reject) => {
  http.get(
    '/api/v3/organizations',
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});

const createOrganization = (organization, token) => new Promise((resolve, reject) => {
  http.post(
    '/api/v3/organizations',
    organization,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});
const updateOrganization = (organization, token) => new Promise((resolve, reject) => {
  http.put(
    `/api/v3/organizations/${organization.id}`,
    organization,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});

const getDestinationsForOrganization = (organizationId, token) => new Promise((resolve, reject) => {
  http.get(
    `/api/v3/organizations/${organizationId}/destinations`,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});

const associateDestination = (organizationId, destinationId, token) => (
  new Promise((resolve, reject) => {
    http.post(
      `/api/v3/organizations/${organizationId}/destinations/${destinationId}`,
      null,
      { headers: { Authorization: `BEARER ${token}` } },
    ).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  })
);

const dissociateDestination = (organizationId, destinationId, token) => (
  new Promise((resolve, reject) => {
    http.delete(
      `/api/v3/organizations/${organizationId}/destinations/${destinationId}`,
      { headers: { Authorization: `BEARER ${token}` } },
    ).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  })
);

export default {
  organizationById,
  createOrganization,
  allOrganizations,
  updateOrganization,
  getDestinationsForOrganization,
  associateDestination,
  dissociateDestination,
};
