<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-card class="mb-3">
        <v-card-title>{{ $t('InactiveQuestions') }}</v-card-title>
        <v-row>
          <v-col cols="2" class="text-center">
            <v-card-subtitle class="font-weight-medium">#</v-card-subtitle>
          </v-col>
          <v-col cols="5">
            <v-card-subtitle class="font-weight-medium">{{ $t('headerQuestion') }}</v-card-subtitle>
          </v-col>
          <v-col cols="2" class="d-flex justify-center text-center">
            <v-card-subtitle class="font-weight-medium">{{ $t('headerTypeOfQuestion') }}</v-card-subtitle>
          </v-col>
          <v-col cols="3" class="d-flex justify-center text-center">
            <v-card-subtitle class="font-weight-medium">{{ $t('headerActiveQuestion') }}</v-card-subtitle>
          </v-col>
        </v-row>
        <v-card v-for="(question, index) in inactiveQuestions" :key="question.id">
          <v-row>
            <v-col cols="2" class="text-center">
              <v-card-text class="font-weight-medium">{{ index + 1 }}</v-card-text>
            </v-col>
            <v-col cols="5">
              <v-card-text>{{ question.question }}</v-card-text>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center pa-0">
              <v-chip
                size="default"
                dark
                x-small
                :color="chipContent(question).color"
              >{{ chipContent(question).text }}
              </v-chip>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center">
              <v-btn
                color="secondary"
                x-small
                text
                @click="onSelectQuestion(question)">
                {{ $t('headerActiveQuestion')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-card class="mb-3">
        <v-card-title>{{ $t('ActiveQuestions') }}</v-card-title>
        <v-row>
          <v-col cols="1" class="text-center">
            <v-card-subtitle class="font-weight-medium">#</v-card-subtitle>
          </v-col>
          <v-col cols="5">
            <v-card-subtitle class="font-weight-medium">{{ $t('headerQuestion') }}</v-card-subtitle>
          </v-col>
          <v-col cols="3" class="d-flex justify-center text-center">
            <v-card-subtitle class="font-weight-medium">{{ $t('headerTypeOfQuestion') }}</v-card-subtitle>
          </v-col>
          <v-col cols="3" class="d-flex justify-center text-center">
            <v-card-subtitle class="font-weight-medium">{{ $t('headerDisableQuestion') }}</v-card-subtitle>
          </v-col>
        </v-row>
        <v-card v-for="(question, index) in activeQuestions" :key="question.id">
          <v-row>
            <v-col cols="1" class="text-center">
              <v-card-text class="font-weight-medium">{{ index + 1 }}</v-card-text>
            </v-col>
            <v-col cols="5">
              <v-card-text>{{ question.question }}</v-card-text>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center pa-0">
              <v-chip
                size="default"
                dark
                x-small
                :color="chipContent(question).color"
              >{{ chipContent(question).text }}
              </v-chip>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center">
              <v-btn
                color="primary"
                x-small
                text
                @click="onSelectQuestion(question)">
                {{ $t('headerDisableQuestion')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {
  getQuestions,
  getThemes,
  updateQuestion,
} from '../../../services/simpleQuestion';
import { QUESTION_TYPE, THEME } from '../../simpleQuestion';

export default {

  props: {
    destinationId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeQuestions: [],
      inactiveQuestions: [],
      inactiveTheme: null,
      activeTheme: null,
    };
  },
  methods: {
    async getquestionsOfAllThemes() {
      await Promise.all(
        this.themes.map(async (theme) => {
          if (theme.name === THEME.INACTIVE) {
            this.inactiveTheme = theme;
            const res = await getQuestions(theme.id);
            this.inactiveQuestions = [];
            this.inactiveQuestions = res.sort((a, b) => a.order - b.order);
          }
          if (theme.name === THEME.ACTIVE) {
            this.activeTheme = theme;
            const res = await getQuestions(theme.id);
            this.activeQuestions = [];
            this.activeQuestions = res
              .sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
          }
        }),
      );
    },
    chipContent(question) {
      const content = {
        text: '',
        color: '',
      };
      switch (question.type) {
        case QUESTION_TYPE.TEXT:
          content.text = this.$t('QuestionTypeText');
          content.color = 'green';
          break;
        case QUESTION_TYPE.OPEN:
          content.text = this.$t('QuestionTypeOpen');
          content.color = 'blue';
          break;
        case QUESTION_TYPE.PHOTO:
          content.text = this.$t('QuestionTypePhoto');
          content.color = 'red';
          break;
        case QUESTION_TYPE.YOUTUBE:
          content.text = this.$t('QuestionTypeYoutube');
          content.color = 'purple';
          break;
        case QUESTION_TYPE.IMAGE:
          content.text = this.$t('QuestionTypeImage');
          content.color = 'black';
          break;
        default:
          break;
      }
      return content;
    },
    async onSelectQuestion(question) {
      const uq = question;
      if (question.themeId === this.activeTheme.id) {
        uq.themeId = this.inactiveTheme.id;
        await updateQuestion(uq, this.token);
        this.getquestionsOfAllThemes();
        return;
      }
      if (question.themeId === this.inactiveTheme.id) {
        uq.themeId = this.activeTheme.id;
        await updateQuestion(uq, this.token);
        this.getquestionsOfAllThemes();
      }
    },
  },
  async mounted() {
    this.themes = await getThemes(this.destinationId);
    await this.getquestionsOfAllThemes();
  },
};

</script>
<i18n>
  {
    "fr": {
      "InactiveQuestions": "Questions inactives",
      "ActiveQuestions": "Questions actives",
      "headerQuestion": "Intitulée de la question",
      "headerTypeOfQuestion": "Type",
      "headerActiveQuestion": "Activer",
      "headerDisableQuestion": "Désactiver",
      "QuestionTypeText": "Texte",
      "QuestionTypeOpen": "Ouverte",
      "QuestionTypePhoto": "Photo",
      "QuestionTypeYoutube": "Vidéo",
      "QuestionTypeImage": "Image"
      },
    "en": {
      "InactiveQuestions": "Questions inactives",
      "ActiveQuestions": "Questions actives",
      "headerQuestion": "Title of the question",
      "headerTypeOfQuestion": "Type",
      "headerActiveQuestion": "Activate",
      "headerDisableQuestion": "Disable",
      "QuestionTypeText": "Text",
      "QuestionTypeOpen": "open",
      "QuestionTypePhoto": "Photo",
      "QuestionTypeYoutube": "Video",
      "QuestionTypeImage": "Image"
      }
  }
  </i18n>
