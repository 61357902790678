<template>
  <v-card>
    <v-responsive>
      <div id="map" class="map-container__map" style="width: 100%; height: 499px;" />
    </v-responsive>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-row>
        <v-col>
          <v-slider
            v-model.number="zoom"
            :label="$t('labelZoom')"
            thumb-color="accent"
            thumb-label="always"
            :min="1"
            :max="25"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmR1Ym9pcyIsImEiOiJjamxhdTgwdGcwdHFjM3dvMGVkNHFoM3FsIn0.YPnVcZlRPAiX_AYw8FLGrw';
export default {
  props: {
    initialLatitude: {
      type: Number,
      required: true,
    },
    initialLongitude: {
      type: Number,
      required: true,
    },
    initialZoom: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    zoomEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      latitude: null,
      longitude: null,
      zoom: this.initialZoom,
      map: null,
      marker: null,
    };
  },
  watch: {
    zoom() {
      this.map.setZoom(this.zoom);
    },
  },
  methods: {
    centerMap() {
      this.marker.setLngLat([this.longitude, this.latitude]);
      this.map.panTo([this.longitude, this.latitude]);
    },

    updateCoordinates() {
      const coords = this.map.getCenter();
      // eslint-disable-next-line prefer-destructuring
      this.longitude = coords.lng;
      // eslint-disable-next-line prefer-destructuring
      this.latitude = coords.lat;
      this.$emit('positionchanged', { latitude: this.latitude, longitude: this.longitude, zoom: this.zoom });
    },
    loadMap() {
      this.map.on('dragend', this.updateCoordinates);
      this.map.on('zoomend', this.updateCoordinates);
    },
  },
  mounted() {
    this.latitude = this.initialLatitude;
    this.longitude = this.initialLongitude;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10?optimize=true',
      center: [this.initialLongitude, this.initialLatitude],
      zoom: this.initialZoom,
    });
    this.map.on('load', this.loadMap);
  },
  destroyed() {
    this.map.off('dragend', this.updateCoordinates);
    this.map.off('zoomend', this.updateCoordinates);

    this.map.remove();
    this.map = null;
  },
};
</script>
<i18n>
{
  "fr": {
    "labelZoom": "Niveau de zoom"
  },
  "en": {
    "labelZoom": "Zoom level"
  }
}
</i18n>
