import * as at from '../actionTypes';
import * as mt from '../mutationTypes';
import cfg from '../../config';
import client from '../../services/fetch';
import pictureApi from '../../services/picture';

export default {
  state: {
    toursCollection: [],
    isFetchingTours: false,
    didFetchTours: false,
    didFailToFetchTours: false,
  },
  mutations: {
    [mt.WILL_FETCH_TOURS](state) {
      state.isFetchingTours = true;
      state.didFailToFetchTours = false;
    },
    [mt.DID_FETCH_TOURS](state, tours) {
      state.isFetchingTours = false;
      state.didFailToFetchTours = false;
      state.didFetchTours = true;
      state.toursCollection = tours;
    },
  },
  actions: {
    [at.FETCH_TOURS]({ commit }, { siteId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/tours`;
        client.getJSON(url, {}, token).then((data) => {
          commit(mt.DID_FETCH_TOURS, data);
          resolve(data);
        }).catch((error) => {
          commit(mt.DID_FAIL_TO_FETCH_TOURS);
          reject(error);
        });
      });
    },
    [at.DELETE_TOUR](context, { siteId, tourId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/tours/${tourId}`;
        client.deleteJSON(url, token).then(() => {
          resolve();
        }).catch((error) => { reject(error); });
      });
    },
    [at.CREATE_TOUR](context, { siteId, tour, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/tours`;
        client.postJSON(url, tour, token).then((savedTour) => {
          resolve(savedTour);
        }).catch((error) => { reject(error); });
      });
    },
    [at.UPDATE_TOUR](context, { siteId, tour, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/tours/${tour.uuid}`;
        client.putJSON(url, tour, token).then((updatedTour) => {
          resolve(updatedTour);
        }).catch((error) => { reject(error); });
      });
    },
    async [at.CREATE_TOUR_PICTURE]({ dispatch }, {
      siteId, parentID, picture, token,
    }) {
      const response = await pictureApi.PostMetadata(
        siteId,
        'tour_picture',
        parentID,
        picture,
        token,
      );
      const formData = new FormData();
      formData.append('picture', picture.file, picture.name);
      await pictureApi.PutFile(siteId, response.uuid, formData, token);
      dispatch(at.FETCH_TOURS, { siteId, token });
    },
    async [at.UPDATE_TOUR_PICTURE]({ dispatch }, { siteId, picture, token }) {
      await pictureApi.PutMetadata(
        siteId,
        picture,
        token,
      );
      dispatch(at.FETCH_TOURS, { siteId, token });
    },
    async [at.DELETE_TOUR_PICTURE]({ dispatch }, { siteId, pictureId, token }) {
      await pictureApi.Delete(siteId, pictureId, token);
      dispatch(at.FETCH_TOURS, { siteId, token });
    },
  },
};
