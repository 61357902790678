<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small>
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="headline">{{ $t('title') }}</v-card-title>
      <v-card-text>{{ $t('text') }} </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent" text @click="dialog = false">
          {{ $t('cancelBtn') }}
        </v-btn>
        <v-btn color="primary" text @click="$emit('delete', temporaryPlace)">
          {{ $t('deleteBtn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    temporaryPlace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<i18n>
    {
        "fr": {
            "deleteBtn": "Supprimer",
            "cancelBtn": "Ne pas supprimer",
            "title": "Supprimer ce lieu temporaire ?",
            "text": "Les restrictions sur ce lieu n'existeront plus et il sera visible de tous les joeurs."
        },
        "en": {
            "deleteBtn": "Remove",
            "cancelBtn": "Do not remove",
            "title": "Remove this temporary place?",
            "text": "The POI will become visible to all players."
        }
    }
</i18n>
