<template>
  <v-container fluid>
    <game-list @new="createGame" @gameClicked="goToScoreboard" />
  </v-container>
</template>

<script>
import GameList from '../components/game/GameList.vue';

export default {
  components: {
    GameList,
  },
  computed: {
    siteId() {
      return this.$route.params.siteId;
    },
  },
  methods: {
    createGame() {
      this.$router.push({
        name: 'newGameEditor',
        params: { siteId: this.siteId },
      });
    },
    goToScoreboard(game) {
      const url = {
        name: 'scoreboard',
        params: {
          siteId: this.siteId,
          gameId: game.id,
        },
      };
      this.$router.push(url);
    },
  },
};
</script>
