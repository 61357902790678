/**
 * CONSTANTS FILE
 * These are the constants shared between several parts of the app
 */
export const DESTINATION_TYPE = Object.freeze({
  NIGHTQUIZ: 'night quiz',
  ICEBREAKER: 'icebreaker',
  INDOOR: 'indoor',
});

export const NIGHTQUIZ_THEMES = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

export default {
  dotmapOrgId: 4,
  locales: [
    { value: 'fr_FR', name: 'Français' },
    { value: 'en_GB', name: 'Anglais' },
    { value: 'de_DE', name: 'Allemand' },
    { value: 'it_IT', name: 'Italien' },
    { value: 'nl_NL', name: 'Néerlandais' },
    { value: 'es_ES', name: 'Espagnol' },
  ],
  teamColors: [
    { id: '#AD4363', name: 'Rose' },
    { id: '#FF0000', name: 'Rouge' },
    { id: '#CC0033', name: 'Rouge profond' },
    { id: '#0033CC', name: 'Bleu profond' },
    { id: '#428BCA', name: 'Bleu clair' },
    { id: '#34495E', name: 'Bleu sombre' },
    { id: '#44AD8E', name: 'Vert menthe' },
    { id: '#A8D695', name: 'Vert clair' },
    { id: '#5CB85C', name: 'Verte' },
    { id: '#004E00', name: 'Vert foncé' },
    { id: '#7F8C8D', name: 'Grise' },
    { id: '#D1D100', name: 'Jaune' },
    { id: '#A295D6', name: 'Mauve' },
    { id: '#5843AD', name: 'Violette' },
    { id: '#F0AD4E', name: 'Orange' },

  ],
};
