<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          type="article"
          elevation="1" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          type="image, article"
          elevation="1" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          type="table-heading, list-item-avatar, table-tfoot"
          elevation="1" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

};
</script>
