<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('pageTitle') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <template v-for="(d, idx) in value">

        <v-list-item :key="d" @click="copyToClipboard(d)">

          <v-list-item-content>
            <v-list-item-title>{{ d }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon ripple @click="deleteDomain(d)">
              <v-icon color="grey lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="idx" />

      </template>
    </v-list>
    <v-card-actions>
      <domain-editor
        @domainadded="addDomain" />
    </v-card-actions>
  </v-card>
</template>

<script>
import DomainEditor from './DomainEditor.vue';

import UserNotifications from '../../mixins/UserNotifications.vue';

export default {
  components: {
    DomainEditor,
  },
  mixins: [UserNotifications],
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  methods: {
    deleteDomain(dom) {
      const newDomains = this.value.filter((d) => d !== dom);
      this.$emit('input', newDomains);
    },
    addDomain(dom) {
      let newDomains = [];
      if (this.value && this.value !== null) {
        newDomains = this.value.slice();
      }
      newDomains.push(dom);
      this.$emit('input', newDomains);
    },
    copyToClipboard(dom) {
      navigator.clipboard.writeText(dom);
      this.successNotification('Nom de domain copié dans le presse-papier');
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Noms de domaine"
  },
  "en": {
    "pageTitle": "Domain names"
  }
}
</i18n>
