/* eslint no-param-reassign: 0 */

import decode from 'jwt-decode';
import axios from '../../services/axios';

const userStorageKey = 'pitc_user_store';

const getters = {
  user(state) {
    if (state.token === null) {
      // eslint-disable-next-line no-undef
      if (localStorage.getItem(userStorageKey) === null) {
        return null;
      }
      // eslint-disable-next-line no-undef
      return decode(localStorage.getItem(userStorageKey));
    }
    return decode(state.token);
  },
  token(state) {
    if (state.token === null) {
      // eslint-disable-next-line no-undef
      if (localStorage.getItem(userStorageKey) === null) {
        return null;
      }
      // eslint-disable-next-line no-undef
      return localStorage.getItem(userStorageKey);
    }
    return state.token;
  },
  authenticated(state) {
    if (state.token == null) {
      const token = localStorage.getItem(userStorageKey);
      if (!token) {
        return false;
      }
    }
    return true;
  },
};

const mutations = {
  logout(context) {
    context.token = null;
    localStorage.removeItem(userStorageKey);
  },
  saveToken(context, token) {
    context.token = token;
    localStorage.setItem(userStorageKey, token);
  },
  willAuthenticateUser(state) {
    state.authenticationInProgress = true;
    state.authenticationSucceded = false;
    state.authenticationFailed = false;
  },
  didAuthenticateUser(state) {
    state.authenticationInProgress = false;
    state.authenticationSucceded = true;
    state.authenticationFailed = false;
  },
  didFailToAuthenticateUser(state) {
    state.authenticationInProgress = false;
    state.authenticationSucceded = false;
    state.authenticationFailed = true;
  },
};

const actions = {
  authenticate(context, { username, password }) {
    context.commit('willAuthenticateUser');
    return new Promise(((resolve, reject) => {
      axios.post('/api/v3/auth/editor', { email: username, password }).then((response) => {
        localStorage.setItem(userStorageKey, response.data.token);
        context.commit('saveToken', response.data.token);
        context.commit('didAuthenticateUser');
        resolve(response.data.token);
      }).catch((error) => {
        context.commit('logout');
        context.commit('didFailToAuthenticateUser');
        reject(error);
      });
    }));
  },
  populate(state) {
    state.token = localStorage.getItem(userStorageKey);
  },
};

const state = {
  token: null,
  authenticationInProgress: false,
  authenticationSucceded: false,
  authenticationFailed: false,
};
const namespaced = true;
export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
