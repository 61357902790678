import axios from './axios';

const geocode = (siteId, address, token) => new Promise((resolve, reject) => {
  axios.get(`/api/v3/sites/${siteId}/address/geocode?address=${address}`, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
});

export default {
  geocode,
};
