// Rewards module
export const FETCH_REWARDS = 'FETCH_REWARDS';
export const CREATE_REWARD = 'CREATE_REWARD';
export const UPDATE_REWARD = 'UPDATE_REWARD';
export const DELETE_REWARD = 'DELETE_REWARD';
export const DELETE_REWARD_PICTURE = 'DELETE_REWARD_PICTURE';
export const UPLOAD_REWARD_PICTURE = 'UPLOAD_REWARD_PICTURE';

// Tours module
export const FETCH_TOURS = 'FETCH_TOURS';
export const DELETE_TOUR = 'DELETE_TOUR';
export const CREATE_TOUR = 'CREATE_TOUR';
export const UPDATE_TOUR = 'UPDATE_TOUR';
export const UPDATE_TOUR_PICTURE = 'UPDATE_TOUR_PICTURE';
export const CREATE_TOUR_PICTURE = 'CREATE_TOUR_PICTURE';
export const DELETE_TOUR_PICTURE = 'DELETE_TOUR_PICTURE';

// categories module
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

// games module
export const FETCH_PARTIES = 'FETCH_PARTIES';
export const CREATE_PARTY = 'CREATE_PARTY';
export const GET_PARTY = 'GET_PARTY';
export const UPDATE_PARTY = 'UPDATE_PARTY';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const CREATE_TEAMS = 'CREATE_TEAMS';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const GET_SCOREBOARD = 'GET_SCOREBOARD';
export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_TEAM_LOGS = 'GET_TEAM_LOGS';
export const GET_CHECKINS_PER_TEAM = 'at.GET_CHECKINS_PER_TEAM';
export const CORRECT_REVIEW = 'CORRECT_REVIEW';
export const GET_PENDING_REVIEWS = 'GET_PENDING_REVIEWS';
export const GET_REVIEW_BY_ID = 'GET_REVIEW_BY_ID';
export const CREATE_ADMIN_EVENT = 'CREATE_ADMIN_EVENT';

// Destinations module
export const FETCH_DESTINATIONS = 'FETCH_DESTINATIONS';
