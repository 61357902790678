import axios from './axios';

const allPOIs = (siteId, status, token) => new Promise((resolve, reject) => {
  axios.get(`/api/v3/sites/${siteId}/pois?status=${status}`, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const duplicatePOI = (poiId, oldSiteId, newSiteId, token) => new Promise((resolve, reject) => {
  axios.post(`/api/v3/sites/${oldSiteId}/pois/duplicate`, { poiId, targetDestinationId: newSiteId }, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const poiById = (poiId, token) => new Promise((resolve, reject) => {
  axios.get(`/api/v3/pois/by-id/${poiId}`, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const deletePOI = (siteId, poiID, token) => new Promise((resolve, reject) => {
  axios.delete(`/api/v3/sites/${siteId}/pois/${poiID}`, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  }).then(() => {
    resolve();
  }).catch((error) => {
    reject(error);
  });
});

const createPOI = (siteId, poi, token) => new Promise((resolve, reject) => {
  axios.post(`/api/v3/sites/${siteId}/pois`, poi, {
    headers: {
      Authorization: `bearer ${token}`,
    },

  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const updatePOI = (siteId, poi, token) => new Promise((resolve, reject) => {
  axios.put(
    `/api/v3/sites/${siteId}/pois/${poi.id}`,
    poi,
    {
      headers: {
        Authorization: `bearer ${token}`,
      },
    },
  ).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

export default {
  allPOIs,
  deletePOI,
  createPOI,
  updatePOI,
  poiById,
  duplicatePOI,
};
