<template>
  <v-container>
    <p class="text">{{ $t('textIntro') }}</p>
    <v-data-table
      :headers="headers"
      :items="editors"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ $t('title')}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('btnAddAdmin') }}
              </v-btn>
            </template>
            <editor-form
              @add="addAdmin"
              @close="dialog = false" />
          </v-dialog>
          <v-dialog v-model="deleteDialog">
            <confirmation-dialog
              :title="$t('confirmationTitle')"
              :subtitle="$t('confirmationSubtitle')"
              :cancel-button-title="$t('confirmationBtnCancel')"
              :confirm-button-title="$t('confirmationBtnConfirm')"
              @cancel="closeDeleteDialog"
              @confirm="deleteItemConfirm"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { getEditorsForOrg, deleteEditor, addEditor } from '../../services/editor';
import UserNotifications from '../../mixins/UserNotifications.vue';
import ConfirmationDialog from '../base/ConfirmationDialog.vue';
import EditorForm from './EditorForm.vue';

export default {
  components: { ConfirmationDialog, EditorForm },
  props: {
    organizationId: {
      type: Number,
      required: true,
    },
  },
  mixins: [UserNotifications],
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      editors: [],
      createNewEditor: false,
      editedItem: {
        id: null,
        name: '',
        email: '',
        password: '',
        organizationId: this.organizationId,
      },
      headers: [
        { text: this.$t('labelId'), value: 'id' },
        { text: this.$t('labelName'), value: 'name' },
        { text: this.$t('labelEmail'), value: 'email' },
        { text: this.$t('labelActions'), value: 'actions', align: 'end' },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'token',
    ]),
    formTitle() {
      return 'dialogue';
    },
    emptyEditor() {
      return {
        id: null,
        name: '',
        email: '',
        password: '',
      };
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    save() {
      this.dialog = false;
    },
    deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    async deleteItemConfirm() {
      if (!this.editedItem) {
        return;
      }
      try {
        await deleteEditor(this.editedItem.id, this.token);
        this.refreshEditors();
        this.successNotification('Suppression réussie');
      } catch (error) {
        this.errorNotification('Suppression échouée', error);
      } finally {
        this.deleteDialog = false;
        this.editedItem = null;
      }
    },
    closeDeleteDialog() {
      this.editedItem = this.emptyEditor;
      this.deleteDialog = false;
    },

    async refreshEditors() {
      try {
        this.editors = await getEditorsForOrg(this.organizationId, this.token);
        if (!this.editors) {
          this.editors = [];
        }
      } catch (error) {
        this.errorNotification('impossible de récupérer la liste des administrateurs', error);
      }
    },
    async addAdmin(admin) {
      try {
        await addEditor(this.organizationId, admin, this.token);
        this.successNotification('Administrateur enregistré');
      } catch (error) {
        this.errorNotification('Enregistrement échoué', error);
      } finally {
        this.refreshEditors();
        this.dialog = false;
        this.editedItem = null;
      }
    },
  },
  mounted() {
    this.refreshEditors();
  },
};
</script>
<i18n>
  {
    "fr": {
      "title": "Administrateurs",
      "textIntro": "Voici la liste des administrateurs rattachés à cette organisation",
      "labelId": "ID",
      "labelName": "Nom",
      "labelEmail": "Email",
      "labelActions": "Actions",
      "confirmationTitle": "Supprimer cet administrateur ?",
      "confirmationSubtitle": "Son accès sera révoqué immédiatement, cette action est irréversible",
      "confirmationBtnCancel": "Annuler",
      "confirmationBtnConfirm": "Supprimer",
      "btnAddAdmin": "Ajouter un administrateur"
    }
  }
</i18n>
