<template>
  <div>
    <h2
      v-if="organization.useCredits === true"
      class="text-h5 ml-9 mt-9 mb-3">
      {{$t('creditsOverview')}}</h2>
    <v-card
      class="mx-auto"
      max-width="344"
      outlined
      v-if="organization.useCredits === true"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 text-center">
            <p class="font-weight-bold">{{ credits }}</p>
          </v-list-item-title>
          <v-list-item-subtitle class="text-center">
            {{ $t('teamCredits')}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          v-if="isDotmap === true"
          outlined
          rounded
          class="mx-auto"
          text
          @click="editorOpen = true"
        >
          {{ $t('updateCred')}}
        </v-btn>
        <v-btn
          v-else
          outlined
          rounded
          class="mx-auto"
          text
          href="mailto:hello@dotmap.fr"
        >
          {{ $t('getCred')}}
        </v-btn>
        <credit-editor
          v-if="editorOpen === true"
          :credits="organization.creditBalance"
          @closeclicked="editorOpen = false"
          @save="saveCredits"
        />
      </v-card-actions>
    </v-card>
    <div>
      <h2
        class="text-h5 ml-9 mt-9 mb-3">
        {{$t('creditsHistory')}}
      </h2>
      <v-data-table
        :sort-by.sync="sortBy"
        :sort-desc="true"
        :loading="loading"
        :headers="headers"
        :items="creditHistory"
        :items-per-page="10"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: this.$t('itemsPerPageText') }"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CreditEditor from './CreditEditor.vue';
import creditCrud from '../../services/credits';
import Notifications from '../../mixins/UserNotifications.vue';
import k from '../../const';

export default {
  mixins: [Notifications],
  components: {
    CreditEditor,
  },
  props: {
    organization: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      editorOpen: false,
      loading: false,
      credits: this.organization.creditBalance,
      creditHistory: undefined,
      sortBy: 'date',
      headers: [
        {
          text: this.$t('partyName'), sortable: false, align: 'start', value: 'partyName',
        },
        {
          text: this.$t('dateLocale'), value: 'date2', sortable: false,
        },
        { text: this.$t('operationType'), sortable: false, value: 'operationType' },
        { text: this.$t('amount'), sortable: false, value: 'amount' },
        { text: this.$t('editorName'), sortable: false, value: 'editorName' },
        { text: this.$t('editorEmail'), sortable: false, value: 'editorEmail' },
        { text: this.$t('comment'), sortable: false, value: 'comment' },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('auth', ['token']),
    isDotmap() {
      return this.user.organizationId === k.dotmapOrgId;
    },

  },
  methods: {
    async getCreditHistory(id) {
      const creditHistory = await creditCrud.getCreditHistory(id, this.token);
      const history = creditHistory.map((element) => {
        const options = {
          weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
        };
        const date2 = new Date(element.date).toLocaleDateString('fr-FR', options);
        return { ...element, date2 };
      });
      this.creditHistory = history;
    },
    async saveCredits(creditFormContent) {
      const req = [];
      this.loading = true;
      try {
        req.push({
          organizationId: this.organization.id,
          partyId: '',
          editorEmail: this.user.email,
          amount: creditFormContent.amount,
          comment: creditFormContent.comment,
        });
        const changedCredits = req[0];
        const result = await creditCrud.updateCredits(changedCredits, this.token);
        if (result) {
          this.successNotification(this.$t('successSaving'));
          this.editorOpen = false;
          this.credits += creditFormContent.amount;
          this.getCreditHistory(this.organization.id);
          this.loading = false;
          this.$emit('update');
        }
      } catch (error) {
        this.errorNotification(this.$t('errorsSavingFailed'), error);
        this.editorOpen = false;
      }
    },
  },
  async created() {
    await this.getCreditHistory(this.organization.id);
  },
};
</script>
<i18n>
{
  "fr": {
    "creditsOverview": "Mon solde",
    "teamCredits": "Crédits équipe",
    "getCred": "Commander des crédits",
    "updateCred": "Mettre à jour le solde ",
    "creditsHistory": "Historique",
    "successSaving": "Organisation mise à jour !",
    "errorsSavingFailed": "Problème lors de la mise à jour !",
    "itemsPerPageText": "Nombre par page",
    "partyName": "Nom de la partie",
    "dateLocale": "Date",
    "operationType": "Type d'opération",
    "amount": "Montant",
    "editorName": "Nom éditeur",
    "editorEmail": "Email éditeur",
    "comment": "Commentaire"
  },
  "en": {
    "creditsOverview": "Credits balance",
    "teamCredits": "Team credits",
    "getCred": "Order new credits",
    "updateCred": "Update credits",
    "creditsHistory":" History",
    "successSaving": "Organization updated!",
    "errorsSavingFailed": "Update failed!",
    "itemsPerPageText": "Items per page",
    "partyName": "Party name",
    "dateLocale": "Date",
    "operationType": "Operation type",
    "amount": "Amount",
    "editorName": "Editor's name",
    "editorEmail": "Editor's email",
    "comment": "Commentaire"
  }
}
</i18n>
