<template>
  <v-card>
    <v-responsive>
      <div id="map" class="map-container__map" style="width: 100%; height: 499px;" />
    </v-responsive>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-row>
        <v-col xs="12" sm="4" offset-sm="1">
          <v-text-field
            v-model.number.lazy="latitude"
            :label="$t('fieldLabelLatitude')"
            type="number"
            required />
        </v-col>
        <v-col xs="12" sm="4" offset-sm="2">
          <v-text-field
            :label="$t('fieldLabelLongitude')"
            v-model.number.lazy="longitude"
            type="number"
            required />
        </v-col>
      </v-row>
      <v-row v-if="zoomEditable === true">
        <v-col>
          <v-slider
            v-model="zoom"
            :label="$t('fieldLabelZoom')"
            thumb-color="accent"
            thumb-label="always"
            :min="1"
            :max="25"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmR1Ym9pcyIsImEiOiJjamxhdTgwdGcwdHFjM3dvMGVkNHFoM3FsIn0.YPnVcZlRPAiX_AYw8FLGrw';
export default {
  props: {
    initialLatitude: {
      type: Number,
      required: true,
    },
    initialLongitude: {
      type: Number,
      required: true,
    },
    initialZoom: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    zoomEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      latitude: this.initialLatitude,
      longitude: this.initialLongitude,
      zoom: this.initialZoom,
      map: null,
      marker: null,
    };
  },
  watch: {
    initialLatitude() {
      this.latitude = this.initialLatitude;
      this.longitude = this.initialLongitude;
    },
    initialLongitude() {
      this.latitude = this.initialLatitude;
      this.longitude = this.initialLongitude;
    },
    latitude() {
      const markerLatitude = this.marker.getLngLat().lat.toFixed(6);
      if (markerLatitude !== this.latitude) {
        this.centerMap();
      }
      this.positionChanged();
    },
    longitude() {
      const markerLongitude = this.marker.getLngLat().lng.toFixed(6);
      if (markerLongitude !== this.longitude) {
        this.centerMap();
      }
      this.positionChanged();
    },
    zoom() {
      this.map.setZoom(this.zoom);
    },
  },
  methods: {
    centerMap() {
      if (typeof this.longitude !== 'number' || typeof this.latitude !== 'number') {
        return;
      }
      this.marker.setLngLat([this.longitude, this.latitude]);
      this.map.panTo([this.longitude, this.latitude]);
    },
    positionChanged() {
      if (typeof this.longitude !== 'number' || typeof this.latitude !== 'number') {
        return;
      }
      this.$emit('positionchanged', { latitude: this.latitude, longitude: this.longitude, zoom: this.zoom });
    },
  },
  mounted() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10?optimize=true',
      center: [this.initialLongitude, this.initialLatitude],
      zoom: this.initialZoom,
    });
    this.map.addControl(new MapboxGeocoder(
      { accessToken: mapboxgl.accessToken, mapboxgl },

    ));
    this.marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([this.initialLongitude, this.initialLatitude])
      .addTo(this.map);
    this.marker.on('dragend', () => {
      const coords = this.marker.getLngLat();
      this.latitude = Number.parseFloat(coords.lat.toFixed(6));
      this.longitude = Number.parseFloat(coords.lng.toFixed(6));
      this.positionChanged();
    });
  },
  destroyed() {
    this.map.remove();
    this.map = null;
  },
};
</script>
<i18n>
{
  "fr": {
    "fieldLabelLatitude": "Latitude",
    "fieldLabelLongitude": "Longitude",
    "fieldLabelZoom": "Niveau de zoom"
  },
  "en": {
    "fieldLabelLatitude": "Latitude",
    "fieldLabelLongitude": "Longitude",
    "fieldLabelZoom": "Zoom level"
  }
}
</i18n>
