<template>
  <v-container v-if="currentDestination">
    <h2 class="text-h3">{{ $t('pageTitle') }} {{ currentDestination.name }}</h2>

    <div class="ma-5">
      <v-row>
        <v-col cols="6" offset="3">
          <v-form>
            <v-select
              v-model="lang"
              :items="langs"
              item-text="name"
              item-value="value"
              :label="$t('labelLang')" />
            <v-switch :label="$t('textContentLabel')" v-model="includeContent" />
            <v-btn @click="startValidation" block class="primary">{{ btnText }}</v-btn>
          </v-form>
        </v-col>

      </v-row>
    </div>

    <div class="ma-5" v-if="validationDone === true">
      <v-row v-if="currentDestination">
        <v-col>
          <destination-validator :destination="currentDestination" :lang="lang" />
        </v-col>
      </v-row>
    </div>
    <div class="ma-5" v-if="validationDone === true">
      <validation-map
        :destination-id="currentDestination.id"
        :initial-zoom="currentDestination.zoom"
        :initial-center="[currentDestination.latitude, currentDestination.longitude]"
        :pois="pois" />
    </div>
    <div class="ma-5" v-if="validationDone === true">
      <v-row v-for="poi in pois" :key="poi.id">
        <v-col>
          <poi-validator :poi="poi" :lang="lang" :include-content="includeContent" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import UserNotifications from '../mixins/UserNotifications.vue';
import destApi from '../services/destination';
import poiApi from '../services/poi';
import DestinationValidator from '../components/destination/validator/DestinationSettingsValidator.vue';
import PoiValidator from '../components/destination/validator/POIValidator.vue';
import ValidationMap from '../components/destination/validator/ValidationMap.vue';
import k from '../const';

export default {
  mixins: [UserNotifications],
  components: {
    DestinationValidator,
    PoiValidator,
    ValidationMap,

  },
  data() {
    return {
      destinationId: this.$route.params.destinationId,
      currentDestination: null,
      pois: [],
      lang: 'fr_FR',
      validationDone: false,
      includeContent: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['token']),
    langs() {
      return k.locales;
    },
    btnText() {
      if (this.validationDone === true) {
        return this.$t('labelBtnRestart');
      }
      return this.$t('labelBtnStart');
    },
  },

  methods: {
    async getInitialData() {
      this.includeContent = localStorage.getItem('destination.validation.includeContent') === 'true';
      try {
        this.currentDestination = await destApi.destinationById(this.destinationId, this.token);
      } catch (error) {
        console.log(error);
        this.errorNotification(this.$t('errNotifUnableToLoadData'));
      }
    },
    async startValidation() {
      if (this.validationDone === true) {
        this.validationDone = false;
        this.pois = [];
      }
      try {
        this.currentDestination = await destApi.destinationById(this.destinationId, this.token);
        this.pois = await poiApi.allPOIs(this.destinationId, 'enabled', this.token);
        this.validationDone = true;
      } catch (error) {
        console.log(error);
        this.errorNotification(this.$t('errNotifUnableToLoadData'));
      }
    },
  },
  mounted() {
    this.getInitialData();
  },
  watch: {
    includeContent() {
      localStorage.setItem('destination.validation.includeContent', this.includeContent);
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "pageTitle": "Validation de la destination : ",
      "textContentLabel": "Inclure les contenus texte",
      "labelLang": "Langue à valider",
      "labelBtnStart": "Démarrer la validation",
      "labelBtnRestart": "Redémarrer la validation",
      "errNotifUnableToLoadData": "Impossible de charger les données de la destination"
    },
    "en": {
      "pageTitle": "Validating destination: ",
      "textContentLabel": "Include text content",
      "labelLang": "Language to validate",
      "labelBtnStart": "Start validation",
      "labelBtnRestart": "Restart validation",
      "errNotifUnableToLoadData": "Unable to load destination data"
    }
  }
</i18n>
