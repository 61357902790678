<template>
  <v-card class="pa-2">
    <v-row>
      <v-col
        sm="12"
        md="8">
        <v-text-field
          :label="$t('labelProposition')"
          :hint="$t('hintProposition')"
          persistent-hint
          v-model.lazy="title" />
      </v-col>
      <v-col
        sm="12"
        md="4">
        <v-text-field
          type="number"
          :label="$t('labelPoints')"
          :hint="$t('hintPoints')"
          v-model.number="score"
          persistent-hint />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          small
          text
          color="primary"
          @click="deleteProposition(currentProposition)"
        >{{ $t('btnRemoveProposition') }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    currentProposition: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      id: this.currentProposition.id,
      title: this.currentProposition.title,
      score: this.currentProposition.score,
    };
  },
  methods: {
    deleteProposition(proposition) {
      this.$emit('delete-proposition', proposition);
    },
    updateProposition() {
      this.$emit('update-proposition', { id: this.id, title: this.title, score: this.score });
    },
  },
  watch: {
    title() {
      this.updateProposition();
    },
    score() {
      this.updateProposition();
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "labelProposition": "Proposition",
      "hintProposition": "Indiquez la proposition de réponse",
      "labelPoints": "Points",
      "hintPoints": "Définissez le score de cette réponse",
      "btnRemoveProposition": "Supprimer la proposition"
    },
    "en": {
      "labelProposition": "Answer",
      "hintProposition": "Type the answer",
      "labelPoints": "Points",
      "hintPoints": "Define this answer's score",
      "btnRemoveProposition": "Delete answer"
    }
  }
</i18n>
