<template>
  <v-container fluid>
    <v-spacer />
    <div v-if="loading === true">
      <h3 class="text-xs-center">{{ $t('textLoading') }}.</h3>
      <v-progress-linear
        :indeterminate="true" />
    </div>
    <div v-else>
      <v-layout>
        <v-flex>
          <h1>{{ pageTitle }}</h1>
        </v-flex>
      </v-layout>
      <v-layout v-if="!isValid">
        <v-flex>
          <v-alert
            :value="true"
            type="warning">
            {{ $t('errorsAlert') }}
            <ul>
              <li v-for="e in errors" :key="e">{{ e }}</li>
            </ul>
          </v-alert>
        </v-flex>
      </v-layout>
      <v-layout pt-5>
        <v-flex>
          <identifier-card
            v-model="reward.name"
            :title="$t('identifierTitle')"
            :subtitle="$t('identifierSubtitle')" />
        </v-flex>
      </v-layout>
      <v-layout pt-5>
        <v-flex>
          <points-card
            :title="$t('costTitle')"
            :subtitle="$t('costSubtitle')"
            v-model="reward.cost"
            :initial-points="reward.cost" />
        </v-flex>
      </v-layout>
      <v-layout pt-5>
        <v-flex>
          <pictures-list
            :title="$t('picturesListTitle')"
            :subtitle="$t('picturesListSubtitle')"
            :pictures="reward.pictures"
            @deletepicture="onDeletePicture"
            @updatepictures="onUpdatePicture"
          />
        </v-flex>
      </v-layout>
      <v-layout pt-5>
        <v-flex>
          <place-selector
            :pois="pois"
            :siteId="siteId"
            :initialSelectedPois="reward.poiIds"
            @poisupdated="onUpdatedPOIs" />
        </v-flex>
      </v-layout>
      <v-layout pt-5>
        <v-flex>
          <stock-editor
            :title="$t('stocksTitle')"
            :subtitle="$t('stocksSubtitle')"
            :limitedStock="reward.hasLimitedQty"
            :initialQuantity="reward.initialQty"
            :remainingQuantity="reward.remainingQty"
            @stockchanged="onStockChanged" />
        </v-flex>
      </v-layout>
      <v-layout pt-5>
        <v-flex>
          <translations-list
            :initial-translations="reward.locales"
            @transactionschanged="onTranslationsChanged" />
        </v-flex>
      </v-layout>

      <v-layout pt-5>
        <v-flex>
          <v-spacer />
          <v-btn
            :disabled="!isValid"
            @click="save"
            raised
            large
            color="accent">{{ $t('btnSave') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import IdentifierCard from '../components/base/IdentifierCard.vue';
import PointsCard from '../components/base/PointsCard.vue';
import TranslationsList from '../components/reward/ObjectTranslationsList.vue';
import UserNotifications from '../mixins/UserNotifications.vue';
import StockEditor from '../components/reward/StockEditor.vue';
import PlaceSelector from '../components/reward/PlaceSelector.vue';
import PicturesList from '../components/pictures/rewards/PictureList.vue';

import * as gt from '../store/getterTypes';
import {
  FETCH_DESTINATIONS,
  FETCH_REWARDS,
  CREATE_REWARD,
  UPDATE_REWARD,
  DELETE_REWARD_PICTURE,
  UPLOAD_REWARD_PICTURE,
} from '../store/actionTypes';

export default {

  components: {
    IdentifierCard,
    PointsCard,
    TranslationsList,
    StockEditor,
    PlaceSelector,
    PicturesList,
  },
  mixins: [UserNotifications],

  data() {
    return {
      reward: {
        destinationId: null,
        name: null,
        cost: 0,
        hasLimitedQty: false,
        initialQty: 100,
        remainingQty: 100,
        placeIds: [],
        locales: {},
        pictures: [],
      },
      id: 0,
      rewardIdentifier: null,
      pointsValue: null,
      picture: null,
      translationsValue: {},
      hasLimitedQuantityValue: null,
      initialQuantityValue: null,
      remainingQuantityValue: null,
      placeIdsValue: null,
      loading: true,
      errors: [],
      picturesValues: null,
      picturesCreationQueue: [],
      picturesUpdateQueue: [],
      picturesDeleteQueue: [],
    };
  },
  computed: {
    ...mapState({
      pois: (state) => state.poi.poiList,
    }),

    ...mapGetters('auth', [
      'token',
    ]),
    ...mapGetters({
      rewardsByID: gt.REWARDS_BY_ID,
      destinationsByUUID: gt.DESTINATIONS_BY_UUID,
    }),

    siteId() {
      return this.currentDestination ? this.currentDestination.id : 0;
    },

    currentReward() {
      if (!this.$route.params.rewardId) {
        return null;
      }
      return this.rewardsByID[this.$route.params.rewardId];
    },
    currentDestination() {
      return this.destinationsByUUID[this.$route.params.destinationId];
    },
    isValid() {
      let valid = true;
      const errors = [];
      if (this.points < 1) {
        valid = false;
      }
      this.storeErrors(errors);
      return valid;
    },

    isNew() {
      if (!this.currentReward) {
        return true;
      }
      if (this.reward.id === 0) {
        return true;
      }
      return false;
    },

    pageTitle() {
      if (this.isNew) {
        return this.$t('pageTitleCreate');
      }
      return this.$t('pageTitleUpdate');
    },
  },

  async mounted() {
    try {
      await this.populateState();
      if (!this.isNew) {
        this.translationsValue = null;
        this.translationsValue = this.reward.locales;
        this.reward.destinationId = this.currentDestination.id;
      }
      if (this.currentReward) {
        this.reward = this.currentReward;
      } else {
        this.reward = {
          id: uuidv4(),
          name: '',
          destinationId: this.currentDestination.uuid,
          hasLimitedQty: false,
          initialQty: 100,
          remainingQty: 100,
          cost: 100,
          createdAt: new Date(),
          updatedAt: new Date(),
          versionHash: uuidv4(),
          unlockEmailBCCs: [],
          pictures: [],
          poiIds: [],
          locales: {},
        };
      }
      this.loading = false;
    } catch (error) {
      this.errorNotification(this.$t('errNotifLoading'), error);
      this.loading = false;
    }
  },
  methods: {

    ...mapActions({
      fetchDestinations: FETCH_DESTINATIONS,
      fetchRewards: FETCH_REWARDS,
      createReward: CREATE_REWARD,
      updateReward: UPDATE_REWARD,
      uploadPicture: UPLOAD_REWARD_PICTURE,
    }),
    storeErrors(errors) {
      this.errors = errors;
    },
    async populateState() {
      try {
        await this.fetchDestinations({ token: this.token });
        await this.fetchRewards(
          { destinationId: this.currentDestination.uuid, token: this.token },
        );
        await this.$store.dispatch(
          'poi/all',
          { siteId: this.currentDestination.id, token: this.token, status: 'enabled' },
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async save() {
      const folder = `destinations/${this.currentDestination.uuid}/rewards`;

      // let rewardId = null;
      if (this.isNew === true) {
        try {
          const createdPictures = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.picturesCreationQueue.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const res = await this.uploadPicture(
              {
                folder,
                file: this.picturesCreationQueue[i].file,
                token: this.token,
              },
            );
            const newPic = { ...this.picturesCreationQueue[i] };
            newPic.url = res.url;
            createdPictures.push(newPic);
          }
          this.reward.pictures = createdPictures;
          // create reward
          await this.createReward({
            reward: this.reward,
            token: this.token,
          });
          this.successNotification(this.$t('notifSuccess'));
          this.$router.push({ name: 'rewardsList', params: { siteId: this.siteId } });
        } catch (error) {
          this.errorNotification(this.$t('errNotifSaving'), error);
        }
      } else {
        try {
          const createdPictures = this.reward.pictures;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.picturesCreationQueue.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const res = await this.uploadPicture(
              {
                folder,
                file: this.picturesCreationQueue[i].file,
                token: this.token,
              },
            );
            const newPic = { ...this.picturesCreationQueue[i] };
            newPic.url = res.url;
            createdPictures.push(newPic);
          }

          this.picturesUpdateQueue.forEach((p) => {
            createdPictures.push(p);
          });
          this.reward.pictures = createdPictures;
          await this.updateReward({
            reward: this.reward,
            token: this.token,
          });
          // this.deletePictures();

          this.successNotification(this.$t('notifSuccess'));
          this.$router.push({ name: 'rewardsList', params: { siteId: this.siteId } });
        } catch (error) {
          this.errorNotification(this.$t('errNotifSaving'), error);
        }
      }
    },
    onUpdatePicture(pictures) {
      pictures.forEach((picture) => {
        if (picture.isNew) {
          const picturesCreateQueue = this.picturesCreationQueue.filter(
            (p) => p.uuid !== picture.uuid,
          );
          picturesCreateQueue.push(picture);
          this.picturesCreationQueue = picturesCreateQueue;
        } else {
          // remove the picture from the queue if it exists to avoid a double update
          const existingPictures = this.reward.pictures.filter((p) => p.uuid !== picture.uuid);
          existingPictures.push(picture);
          this.reward.pictures = existingPictures;
        }
      });
    },
    onDeletePicture(picture) {
      if (picture.isNew) {
        this.picturesCreationQueue = this.picturesCreationQueue.filter(
          (p) => p.uuid !== picture.uuid,
        );
      } else {
        this.reward.pictures = this.reward.pictures.filter((p) => p.uuid !== picture.uuid);
      }
    },
    deletePictures() {
      this.picturesDeleteQueue.forEach((picUUID) => {
        this.$store.dispatch(
          DELETE_REWARD_PICTURE,
          {
            siteId: this.siteId,
            pictureId: picUUID,
            token: this.token,
          },
        );
      });
    },
    onPositionChanged(newCoords) {
      this.latitude = newCoords.latitude;
      this.longitude = newCoords.longitude;
    },

    onTranslationsChanged(newTranslations) {
      this.reward.locales = newTranslations;
    },
    onUpdatedPOIs(newPOIS) {
      this.reward.poiIds = newPOIS;
    },
    onStockChanged(stockContent) {
      this.reward.remainingQty = stockContent.remainingQuantity;
      this.reward.hasLimitedQty = stockContent.hasLimitedStock;
      this.reward.initialQty = stockContent.initialQuantity;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "textLoading": "Chargement de la récompense...",
    "errorsAlert": "Merci de corriger les points suivants avant de sauvegarder la récompense :",
    "identifierTitle": "Identifiant de la récompense",
    "identifierSubtitle": "Choisissez un identifiant unique pour cette récompense",
    "costTitle": "Coût de la récompense",
    "costSubtitle": "Sélectionnez le nombre de points nécessaires au déblocage de la récompense",
    "picturesListTitle": "Photos de la récompense",
    "picturesListSubtitle": "Faites glisser les photos pour les réorganiser",
    "stocksTitle": "Gestion des stocks",
    "stocksSubtitle": "Vous pouvez gérer ici la disponibilité d'une récompense",
    "btnSave": "Sauvegarder la récompense",
    "errGPS": "La position GPS de la récompense est invalide",
    "errID": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer tous les caractères accentués, apostrophes et espaces",
    "pageTitleCreate": "Créer une récompense",
    "pageTitleUpdate": "Mettre à jour une récompense",
    "errNotifLoading": "Impossible de charger les données nécessaires.",
    "notifSuccess": "Récompense sauvegardée",
    "errNotifSaving": "Impossible de sauvegarder la récompense"
  },
  "en": {
    "textLoading": "Loading...",
    "errorsAlert": "Please fix the following issues before saving:",
    "identifierTitle": "reward identifier",
    "identifierSubtitle": "Choose a unique identifier for this reward",
    "costTitle": "Cost of the reward",
    "costSubtitle": "Define the number of points required to unlock the reward",
    "picturesListTitle": "Reward pictures",
    "picturesListSubtitle": "Drag the pictures to reorder them",
    "stocksTitle": "Quantity management",
    "stocksSubtitle": "You can manage the availability of a reward",
    "btnSave": "Save",
    "errGPS": "The GPS location of the reward is invalid",
    "errID": "Le format d'identifiant a changé en janvier 2019. Merci de supprimer tous les caractères accentués, apostrophes et espaces",
    "pageTitleCreate": "Create a reward",
    "pageTitleUpdate": "Update a reward",
    "errNotifLoading": "Unable to retrieve required data",
    "notifSuccess": "Reward saved",
    "errNotifSaving": "Unable to save the reward"
  }
}
</i18n>
