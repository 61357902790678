<template>
  <v-container fluid>
    <!-- items-per-page = -1 => display all organizations -->
    <v-row>
      <v-col>
        <h3 class="text-h3">{{ $t('mainTitle')}}</h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10" sm="12" md="8">
        <v-text-field v-model="searchInput" :label="$t('labelSearchDestination')" />
      </v-col>
    </v-row>
    <v-data-iterator :items="filteredDestination" :items-per-page="-1" hide-default-footer>

      <template v-slot:default="props">
        <organization-creator
          v-if="editorOpen === true"
          @closeclicked="editorOpen = false"
          @save="onSave"
        />
        <v-row>
          <v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ item.name }}
                <v-spacer />
                <v-chip
                  v-if="item.useCredits === true"
                  label
                  text-color="white"
                  sm
                  :color="creditChipColor(item)">
                  {{ $t("remainingCredits", { count: item.creditBalance }) }}
                </v-chip>
              </v-card-title>

              <v-divider />

              <v-list dense>
                <v-list-item>
                  <v-list-item-content>{{ $t('labelFieldAddress') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.addressMain }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>{{ $t('labelFieldTown') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.city }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>{{ $t('labelFieldZipcode') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.zipCode }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>{{ $t('labelFieldCountry') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.country }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>{{ $t('labelFieldContactEmail') }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ item.contactEmail }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-spacer />
                <v-btn :to="`/organizations/${item.id}`" icon color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-row>
      <v-col class="text-right">
        <v-fab-transition>
          <v-btn v-if="user.role === 'editor'" color="primary" dark fab @click="editorOpen = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import orga from '../services/orga';
import OrganizationCreator from '../components/organization/OrganizationCreator.vue';

export default {
  components: {
    OrganizationCreator,
  },
  data() {
    return {
      editorOpen: false,
      allOrganizations: undefined,
      searchInput: null,
    };
  },
  methods: {
    async getAllOrganizations() {
      const allOrganizations = await orga.allOrganizations(this.token);
      this.allOrganizations = allOrganizations.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    onSave() {
      this.editorOpen = false;
      this.getAllOrganizations();
    },
    creditChipColor(item) {
      if (item.creditBalance > 0) {
        return 'green';
      } if (item.creditBalance < 0) {
        return 'red';
      }
      return 'grey';
    },
  },
  computed: {
    ...mapGetters('auth', ['token']),
    ...mapGetters('auth', ['user']),

    filteredDestination() {
      let filteredOrganizations = this.allOrganizations;
      if (this.searchInput !== null) {
        filteredOrganizations = this.allOrganizations.filter(
          (el) => el.name.toLowerCase().includes(this.searchInput.toLowerCase()),
        );
      }
      return filteredOrganizations;
    },
  },
  async created() {
    await this.getAllOrganizations();
  },

};
</script>
<i18n>
{
  "fr":
  {
    "mainTitle": "Liste des organisations",
  "createOrganization": "Ajouter une organisation",
  "remainingCredits": "{count} crédits restants",
  "labelFieldName": "Nom :",
    "labelFieldAddress": "Adresse :",
    "labelFieldAddressBis": "Adresse(complément) :",
    "labelFieldZipcode": "Code postal :",
    "labelFieldTown": "Ville :",
    "labelFieldCountry": "Pays :",
    "labelFieldContactEmail": "Email de contact :",
    "labelSearchDestination": "Rechercher une organisation"

  },
  "en":
  {
    "mainTitle": "Organisation list",
  "createOrganization": "Create an organization",
  "labelFieldName": "Name :",
    "labelFieldAddress": "Address :",
    "labelFieldAddressBis": "Address(detailed) :",
    "labelFieldZipcode": "Zipcode :",
    "labelFieldTown": "Town :",
    "labelFieldCountry": "Country :",
    "labelFieldContactEmail": "Contact Email :",
    "labelSearchDestination": "Search a organization"

  }
}
</i18n>
