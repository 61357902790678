<template>
  <v-navigation-drawer v-model="drawerModel" app clipped temporary>
    <v-list class="pa-0">
      <v-list-item>
        <v-list-item-avatar>
          <v-icon large>account_circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('destinationSubtitle') }} {{ currentDestination ? currentDestination.name : $t('destinationSubtitleNoDestination') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content />
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-menu offset-y :max-height="400">
            <template v-slot:activator="{ on }">
              <v-btn color="info" block v-on="on">
                {{ $t('labelChangeDestination') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="s in sortedDestinations" :key="s.id" @click="switchSite(s.id)">
                <v-list-item-title>{{ s.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list nav dense class="pt-0">
      <v-list-item @click="goTo('home')">
        <v-list-item-action>
          <v-icon>home</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelHome') }}</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="goTo('site')">
        <v-list-item-action>
          <v-icon>location_city</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelLocationDetails') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isNigthQuizDestination === true"
        @click="goTo('simpleQuestions')">
        <v-list-item-action>
          <v-icon>question_mark</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelQuestions') }} </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isNigthQuizDestination === false"
        @click="goTo('places')">
        <v-list-item-action>
          <v-icon>location_on</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelPOI') }} </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isNigthQuizDestination === false"
        @click="goTo('categories')">
        <v-list-item-action>
          <v-icon>label</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelCategories') }} </v-list-item-title>
      </v-list-item>
      <v-list-item @click="goTo('games')">
        <v-list-item-action>
          <v-icon>mdi-gamepad-square</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelGames') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isNigthQuizDestination === false"
        @click="goTo('tours')">
        <v-list-item-action>
          <v-icon>map</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelTours') }} </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isNigthQuizDestination === false"
        @click="$router.push(`/destinations/${currentDestination.uuid}/rewards`)">
        <v-list-item-action>
          <v-icon>card_giftcard</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ $t('labelRewards') }}</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-group
        prepend-icon="domain"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>{{ $t('labelOrga') }}</v-list-item-title>
        </template>
        <v-list-item @click="$router.push(`/organization`)">
          <v-list-item-title>{{ $t('labelMyOrga') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="user.organizationId === 4"
          @click="$router.push(`/organizations`)">
          <v-list-item-title>{{ $t('labelAllOrga') }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { DESTINATION_TYPE } from '../../const';

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
    currentDestination: {
      type: Object,
      required: true,
    },
    destinations: {
      type: Object,
      required: true,
    },
    currentDestinationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    drawerModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    sortedDestinations() {
      const destValues = Object.values(this.destinations);
      return destValues.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    isNigthQuizDestination() {
      if (!this.currentDestination) {
        return false;
      }
      if (this.currentDestination.platformType === DESTINATION_TYPE.NIGHTQUIZ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    switchSite(siteId) {
      this.$store.commit('site/setCurrentSiteId', siteId);
      this.$router.push(`/switch/${siteId}`);
    },
    goTo(location) {
      if (this.currentDestinationId === -1) {
        return;
      }
      const url = {
        name: 'home',
        params: {
          siteId: this.currentDestinationId,
          destinationId: this.currentDestinationId,
          destinationUUID: this.currentDestination.uuid,
        },
      };

      switch (location) {
        case 'games':
          url.name = 'gamesList';
          break;
        case 'reviews':
          url.name = 'gameReviewsList';
          break;
        case 'site':
          url.name = 'destinationDetails';
          break;
        case 'places':
          url.name = 'placesList';
          break;
        case 'categories':
          url.name = 'categoriesList';
          break;
        case 'events':
          url.name = 'eventList';
          break;
        case 'openagenda':
          url.name = 'OpenAgendaEventList';
          break;
        case 'tours':
          url.name = 'toursList';
          break;
        case 'rewards':
          url.name = 'rewardsList';
          break;
        case 'home':
          url.name = 'home';
          break;
        case 'simpleQuestions':
          url.name = 'simpleQuestions';
          break;
        default:
          break;
      }
      if (this.$router.currentRoute.name !== url.name) {
        this.$router.push(url);
      }
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "destinationSubtitle": "Destination : ",
    "destinationSubtitleNoDestination": "Aucune sélectionnée",
    "labelChangeDestination": "Changer de destination",
    "labelLocationDetails": "Détails de la destination",
    "labelPOI": "Lieux d'intérêt",
    "labelCategories": "Catégories de lieux",
    "labelGames": "Parties",
    "labelGamesList": "Liste des parties",
    "labelReviews": "Liste des correction",
    "labelTours": "Circuits",
    "labelEvents": "Evènements",
    "labelManageEvents": "Gérer mes évènements",
    "labelOpenAgenda": "Open Agenda",
    "labelRewards": "Récompenses",
    "labelOrga": "Organisation",
    "labelMyOrga": "Mon Organisation",
    "labelAllOrga": "Liste des Organisations",
    "labelHome": "Accueil",
    "labelQuestions": "Questions"
  },
  "en": {
    "destinationSubtitle": "Destination : ",
    "destinationSubtitleNoDestination": "None selected",
    "labelChangeDestination": "Switch destination",
    "labelLocationDetails": "Destination details",
    "labelPOI": "POIs",
    "labelCategories": "POI Categories",
    "labelGames": "Games",
    "labelGamesList": "Games list",
    "labelReviews": "Reviews list",
    "labelTours": "Tours",
    "labelEvents": "Events",
    "labelManageEvents": "Manage my events",
    "labelOpenAgenda": "Open Agenda",
    "labelRewards": "Rewards",
    "labelOrga": "Organization",
    "labelMyOrga": "My organization",
    "labelAllOrga": "All Organizations",
    "labelHome": "Home",
    "labelQuestions": "Questions"
  }
}
</i18n>
