<template>
  <v-card>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-dialog
        v-model="dialog"
        max-width="350"
      >
        <v-card>
          <v-card-title class="headline">{{ $t('dialogDetachTitle') }}</v-card-title>
          <v-card-text>
            {{ $t('dialogDetachContent') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              text
              @click="dialog = false"
            >
              {{ $t('dialogDetachCancel') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteAll()"
            >
              {{ $t('dialogDetachConfirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <p>{{ $t('mainContent')}}<br /></p>
      <v-toolbar
        floating
        color="secondary"
        dark>
        <v-text-field
          single-line
          :label="$t('fieldSearchLabel')"
          v-model="currentSearchTerm"
        />
        <v-btn icon>
          <v-icon>search</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          icon
          :href="`/#/sites/${siteId}/places/new`"
          target="_blank"
          rel="noopener noreferrer">
          <v-icon>mdi-map-marker-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container grid-list-sm fluid>
        <v-row>
          <v-col
            xs="12"
            sm="6"
            md="4"
            lg="3"
            v-for="p in filteredPOIList"
            :key="p.id"
          >
            <grid-element
              :title="p.name"
              :picture="poiPicture(p)"
            >
              <template v-slot:actions>
                <v-spacer />
                <v-btn
                  icon
                  :disabled="itemIsSelected(p.uuid)"
                  @click="selectItem(p.uuid)">
                  <v-icon color="green">check</v-icon>
                </v-btn>
                <v-btn
                  icon
                  :disabled="!itemIsSelected(p.uuid)"
                  @click="unselectItem(p.uuid)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
            </grid-element>
          </v-col>
        </v-row>

        <div class="text-xs-center">
          <v-pagination
            v-model="currentPage"
            :length="numberOfPages"
            total-visible="7"
          />
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import cfg from '../../config';
import GridElement from './PlaceDisplayGridElement.vue';

const POIS_PER_PAGE = 12;

export default {
  components: {
    GridElement,
  },
  props: {
    pois: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: true,
    },
    initialSelectedPois: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      currentSearchTerm: '',
      selected: [],
      active: false,
      selectedPoisValue: null,
      dialog: false,
    };
  },
  computed: {

    selectedPois: {
      set(value) {
        this.selectedPoisValue = value;
      },
      get() {
        if (!this.selectedPoisValue) {
          return this.initialSelectedPois;
        }
        return this.selectedPoisValue;
      },
    },
    searchTerm() {
      return this.currentSearchTerm ? this.currentSearchTerm : '';
    },
    filteredPOIList() {
      if (this.searchTerm.length > 2) {
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
        return this.pois.filter(
          (poi) => poi.name.toLowerCase().includes(
            lowerCaseSearchTerm,
          ),
        );
      }
      return this.pois.slice(this.currentOffset, this.currentLimit);
    },
    numberOfPages() {
      let totalPages = 0;
      if (this.searchTerm.length > 3) {
        totalPages = Math.floor(this.filteredPOIList.length / POIS_PER_PAGE);
      } else {
        totalPages = Math.floor(this.pois.length / POIS_PER_PAGE);
      }
      if (totalPages < 1) {
        return 1;
      }
      if (totalPages % POIS_PER_PAGE !== 0) {
        return totalPages + 1;
      }
      return totalPages;
    },
    currentOffset() {
      return (this.currentPage - 1) * POIS_PER_PAGE;
    },
    currentLimit() {
      return ((this.currentPage - 1) * POIS_PER_PAGE) + POIS_PER_PAGE;
    },
    currentPOIs() {
      return this.pois.slice(this.currentOffset, this.currentLimit);
    },
  },
  methods: {
    selectItem(pid) {
      const selection = this.selectedPois ? this.selectedPois : [];
      selection.push(pid);
      this.selectedPois = selection;
      this.$emit('poisupdated', this.selectedPois);
    },
    unselectItem(pid) {
      this.selectedPois = this.selectedPois.filter((sp) => pid !== sp);
      this.$emit('poisupdated', this.selectedPois);
    },
    itemIsSelected(pid) {
      return this.selectedPois && this.selectedPois.includes(pid);
    },
    deleteAll() {
      this.selectedPois = [];
      this.$emit('poisupdated', this.selectedPois);
      this.dialog = false;
    },
    poiPicture(poi) {
      if (poi.pictures && poi.pictures.length > 0) {
        const pics = [...poi.pictures];
        pics.sort((a, b) => a.order - b.order);
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${poi.siteId}/${pics[0].filename}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Choisissez les lieux affichant cette récompense",
    "dialogDetachTitle": "Détacher tous les lieux d'intérêt ?",
    "dialogDetachContent": "Cette récompense ne sera plus attachée à aucun lieu d'intérêt",
    "dialogDetachCancel": "Annuler",
    "dialogDetachConfirm": "Détacher",
    "mainContent": "Vous pouvez rattacher cette récompense à un ou plusieurs lieux d'intérêt pour les afficher sur la carte. Si vous n'en sélectionnez aucun, elle n'apparaitra que dans la liste des récompenses",
    "fieldSearchLabel": "Rechercher un nom de lieu"
  },
  "en": {
    "title": "Choose POIs that display this reward",
    "dialogDetachTitle": "Detach all POIS?",
    "dialogDetachContent": "This reward will not be attached to any POI",
    "dialogDetachCancel": "Cancel",
    "dialogDetachConfirm": "Detach",
    "mainContent": "You can attach this reward to one or several POIs to display it on the map. If you don't, it will only appear in the rewards list",
    "fieldSearchLabel": "Search a POI by name"
  }
}
</i18n>
