var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle))]),_c('v-text-field',{attrs:{"label":_vm.$t('labelName')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-select',{attrs:{"items":[
        {
          text: _vm.$t('itemFrench'),
          value: 'fr_FR',
        },
        {
          text: _vm.$t('itemEnglish'),
          value: 'en_GB',
        },
        {
          text: _vm.$t('itemGerman'),
          value: 'de_DE',
        },
        {
          text: _vm.$t('itemNL'),
          value: 'nl_NL',
        },
        {
          text: _vm.$t('itemItalian'),
          value: 'it_IT',
        },
        {
          text: _vm.$t('itemSpanish'),
          value: 'es_ES',
        },
        {
          text: _vm.$t('itemPortuguese'),
          value: 'pt_PT',
        }],"menu-props":"auto","label":_vm.$t('labelLang')},model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t('btnCancel'))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.name || _vm.name.length === 0,"text":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('btnSave'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }