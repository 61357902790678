/* eslint no-param-reassign: 0 */
import { useLocalStorage } from '@vueuse/core';
import axios from '../../services/axios';

const normalizeSiteApiResponse = (incomingSites) => {
  const sites = {};
  const sitesList = [];
  const locales = {};
  const localesList = [];
  incomingSites.forEach((s) => {
    const localeIDs = [];
    if (s.locales != null) {
      s.locales.forEach((l) => {
        localeIDs.push(l.id);
        localesList.push(l.id);
        locales[l.id] = l;
      });
    }
    const newSite = s;
    newSite.locales = localeIDs;
    sitesList.push(newSite.id);
    sites[newSite.id] = newSite;
  });

  return {
    sites, sitesList, locales, localesList,
  };
};

const getters = {
  sitesSet(state) {
    return state.sitesList.map((siteId) => state.sites[siteId]);
  },
  localesSet(state) {
    return state.localesList.map((localeId) => state.locales[localeId]);
  },

  allSites(state) {
    return state.sites;
  },
  allLocales(state) {
    return state.locales;
  },
  getCurrentSite(state) {
    return state.site;
  },
};

const mutations = {
  setSite(state, newSite) {
    state.sites[newSite.id] = newSite;
  },
  // mutations for normalized structs
  setSites(state, sites) {
    state.sites = sites;
  },
  setSitesList(state, list) {
    state.sitesList = list;
  },
  setLocales(state, locales) {
    state.locales = locales;
  },
  setLocalesList(state, localesList) {
    state.localesList = localesList;
  },
  update(state, site) {
    state.sites[site.id] = site;
  },
  setCurrentSiteId(state, newSiteId) {
    state.currentSiteId = newSiteId;
  },
  willLoadSites(state) {
    state.sitesLoading = true;
    state.sitesLoadingFailed = false;
    state.sitesLoadingSucceeded = false;
  },
  didLoadSites(state) {
    state.sitesLoading = false;
    state.sitesLoadingFailed = false;
    state.sitesLoadingSucceeded = true;
  },
  didFailToLoadSites(state) {
    state.sitesLoading = false;
    state.sitesLoadingFailed = true;
    state.sitesLoadingSucceeded = false;
  },
};

const actions = {
  /**
     *  Loads all sites with locales from the API
     * @param {*} context
     */
  loadSites({ commit, state }, token) {
    if (state.sitesLoading === true) {
      return false;
    }
    commit('willLoadSites');
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/sites', { headers: { Authorization: `bearer ${token}` } }).then((response) => {
        const normalizedResponse = normalizeSiteApiResponse(response.data);
        commit('setSites', normalizedResponse.sites);
        commit('setSitesList', normalizedResponse.sitesList);
        commit('setLocales', normalizedResponse.locales);
        commit('setLocalesList', normalizedResponse.localesList);
        if (state.currentSiteId === -1) {
          if (normalizedResponse.sitesList.length > 0) {
            commit('setCurrentSiteId', normalizedResponse.sitesList[0]);
          }
        }
        commit('didLoadSites');
        resolve();
      }).catch((error) => {
        commit('didFailToLoadSites');
        reject(error);
      });
    });
  },
};

const state = {
  site: { // OBSOLETE
    latitude: 50.7,
    longitude: 3.14,
    zoom: 13,
    picture: 'img.jpg',
  },
  sites: {},
  sitesList: [],
  locales: {},
  localesList: [],
  currentSiteId: useLocalStorage('currentSiteId', -1),
  sitesLoading: false,
  sitesLoadingFailed: false,
  sitesLoadingSucceeded: false,

};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
