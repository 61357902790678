import * as at from '../actionTypes';
import * as mt from '../mutationTypes';
import http from '../../services/fetch';
import cfg from '../../config';

export default {

  state: {
    categoriesList: null,
    didFetchCategories: false,
    didFailToFetchCategories: false,
  },

  mutations: {
    [mt.DID_FETCH_CATEGORIES](state, categories) {
      state.didFetchCategories = true;
      state.didFailToFetchCategories = false;
      state.categoriesList = categories;
    },
    [mt.DID_FAIL_TO_FETCH_CATEGORIES](state) {
      state.didFailToFetchCategories = true;
    },
  },
  actions: {
    [at.FETCH_CATEGORIES]({ commit }, { siteId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/categories`;
        http.getJSON(url, {}, token).then((data) => {
          commit(mt.DID_FETCH_CATEGORIES, data);
          resolve(data);
        }).catch((error) => {
          commit(mt.DID_FAIL_TO_FETCH_CATEGORIES);
          reject(error);
        });
      });
    },
    [at.CREATE_CATEGORY]({ dispatch }, { siteId, category, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/categories`;
        http.postJSON(url, category, token).then((data) => {
          dispatch(at.FETCH_CATEGORIES, { siteId, token });
          resolve(data);
        }).catch((error) => { reject(error); });
      });
    },
    [at.UPDATE_CATEGORY]({ dispatch }, { siteId, category, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/categories/${category.id}`;
        http.putJSON(url, category, token).then((data) => {
          dispatch(at.FETCH_CATEGORIES, { siteId, token });
          resolve(data);
        }).catch((error) => { reject(error); });
      });
    },
    [at.DELETE_CATEGORY]({ dispatch }, { siteId, categoryId, token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/sites/${siteId}/categories/${categoryId}`;
        http.deleteJSON(url, token).then(() => {
          dispatch(at.FETCH_CATEGORIES, { siteId, token });
          resolve();
        }).catch((error) => { reject(error); });
      });
    },
  },
};
