<template>
  <v-card>
    <v-responsive>
      <div id="map" class="map-container__map" style="width: 100%; height: 499px;" />
    </v-responsive>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-layout>
        <v-flex xs12 sm4 offset-sm1>
          <v-switch
            :label="$t('switchLabelDrawTrace')"
            v-model="traceEnabled" />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm10 offset-sm1>
          <v-switch
            :label="$t('switchLabelPOINumber')"
            :hint="$t('switchHintPOINumber')"
            persistent-hint
            v-model="useNumberedPOIs" />
        </v-flex>
      </v-layout>
      <div v-if="traceEnabled === true">
        <v-layout>
          <v-flex xs12 sm10 offset-sm1>
            <v-alert
              v-if="pois.length > 25"
              border="left"
              colored-border
              type="warning"
              elevation="2"
            >
              <p>{{ $t('alertTooManyPOIs') }} <strong> GPX, KML, geojson</strong>

              </p>
            </v-alert>

            <v-radio-group v-model="traceBuildMode">
              <v-radio
                :disabled="pois.length > 25"
                key="auto"
                :label="$t('labelAutoDrawTrace')"
                value="auto"
              />
              <v-radio
                key="manual"
                :label="$t('labelManualTrace')"
                value="manual"
              />
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout class="flex-row" v-if="traceBuildMode === 'auto'">
          <v-flex class="justify-center text-center">
            <v-btn
              color="secondary"
              @click="getTraceFromMapbox">
              <v-icon>directions</v-icon>{{ $t('btnMapbox') }}
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-if="traceBuildMode === 'manual'">
          <v-flex xs12 sm6 offset-sm-3>
            <v-file-input
              v-model="traceInput"
              :label="$t('fileInputLabel')"
              :accept="`.kml,.gpx,.json,.geojson,text/json,application/json,
            application/geo+json,application/xml,application/vnd.google-earth.kml+xml,
            application/gpx+xml`"
              prepend-icon="mdi-paperclip"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-divider />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 sm3 offset-sm1>
            <p class="v-label">{{ $t('titleTraceColor')}}</p>
          </v-flex>
          <v-flex xs12 sm4>
            <v-color-picker
              class="ma-2"
              hide-inputs
              v-model="color" />
          </v-flex>
        </v-layout>
      </div>
      <v-layout>

        <v-flex xs12 sm10 offset-sm1>
          <v-divider />
          <v-alert
            v-if="traceBuildMode === 'auto' && traceEnabled === true "
            border="left"
            dense
            colored-border
            type="info"
            elevation="1"
          >
            {{ $t('autoDistanceAlert') }}
          </v-alert>
          <v-text-field
            :label="$t('distanceLabel')"
            v-model="distance"
            type="number"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import mbxDirections from '@mapbox/mapbox-sdk/services/directions';
import { kml, gpx } from '@tmcw/togeojson';
import { mapGetters } from 'vuex';
import UserNotifications from '../../mixins/UserNotifications.vue';

mapboxgl.accessToken = 'pk.eyJ1IjoiYmR1Ym9pcyIsImEiOiJjamxhdTgwdGcwdHFjM3dvMGVkNHFoM3FsIn0.YPnVcZlRPAiX_AYw8FLGrw';
const TRACE_LAYER_NAME = 'trace-layer';
const TRACE_SOURCE_NAME = 'trace-source';

export default {
  mixins: [UserNotifications],
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    enableTrace: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialTrace: {
      type: Object,
      required: false,
      default() { return null; },
    },
    initialColor: {
      type: String,
      required: true,
    },
    initialDistance: {
      type: Number,
      required: true,
    },
    initialUseNumberedPois: {
      type: Boolean,
      required: true,
    },
    transport: {
      type: String,
      required: true,
    },
    pois: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      mapLoaded: false,
      enableTraceValue: null,
      traceBuildModeValue: null,
      traceValue: null,
      markers: [],
      traceLayer: null,
      colorValue: null,
      traceInput: null,
      distanceValue: null,
      useNumberedPOIsValue: null,
    };
  },
  computed: {
    ...mapGetters('poi', [
      'byId',
    ]),
    poisList() {
      return this.byId;
    },
    traceEnabled: {
      get() {
        if (this.enableTraceValue === null) {
          if (this.trace) {
            return true;
          }
          return this.enableTrace;
        }
        return this.enableTraceValue;
      },
      set(value) {
        this.enableTraceValue = value;
      },
    },
    useNumberedPOIs: {
      get() {
        if (this.useNumberedPOIsValue === null) {
          return this.initialUseNumberedPois;
        }
        return this.useNumberedPOIsValue;
      },
      set(value) {
        this.useNumberedPOIsValue = value;
      },
    },
    traceBuildMode: {
      get() {
        if (this.pois.length > 25) {
          return 'manual';
        }
        return this.traceBuildModeValue;
      },
      set(value) {
        this.traceBuildModeValue = value;
      },
    },
    trace: {
      get() {
        if (this.traceValue === null) {
          return this.initialTrace;
        }
        return this.traceValue;
      },
      set(value) {
        this.traceValue = value;
      },
    },
    color: {
      get() {
        if (this.colorValue === null) {
          return this.initialColor;
        }
        return this.colorValue;
      },
      set(value) {
        this.colorValue = value;
      },
    },
    distance: {
      get() {
        if (this.distanceValue === null) {
          return this.initialDistance;
        }
        return this.distanceValue;
      },
      set(value) {
        this.distanceValue = Math.round(value);
      },
    },
  },
  methods: {
    onMapLoaded() {
      this.mapLoaded = true;
      this.displayPOIs();
      if (this.trace && this.trace.type) {
        this.drawTrace();
      }
    },
    displayPOIs() {
      this.markers.forEach((m) => {
        m.remove();
      });
      const bounds = [];
      this.pois.forEach((p) => {
        const fullPOI = this.poisList[p.poiId];
        if (!fullPOI) {
          return;
        }
        const el = document.createElement('div');
        if (fullPOI.events && fullPOI.events.length > 0) {
          el.className = 'marker-event';
        } else if (fullPOI.rewards && fullPOI.rewards.length > 0) {
          el.className = 'marker-reward';
        } else {
          el.className = 'marker-location';
        }
        const marker = new mapboxgl.Marker(el);
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
        });
        const description = `${fullPOI.name}`;
        popup.setText(description);
        const coords = [fullPOI.longitude, fullPOI.latitude];
        bounds.push(coords);
        marker.setLngLat(coords)
          .setPopup(popup)
          .addTo(this.map);
        this.markers.push(marker);
      });
      if (bounds.length > 1) {
        this.map.fitBounds(this.bounds(bounds), {
          padding: 60,
        });
      }
    },
    bounds(lngLatList) {
      let minLat = null;
      let maxLat = null;
      let minLong = null;
      let maxLong = null;
      if (!Array.isArray(lngLatList)) {
        throw new Error('invalid argument: lngLatBounds() expects an array');
      }
      if (lngLatList.length < 2) {
        return [];
      }
      // lngLatList is an array of [lng, lat] values.
      // We initialize the bounds with the 1st element of this array
      [[minLong, minLat]] = lngLatList;
      [[maxLong, maxLat]] = lngLatList;

      lngLatList.forEach((l) => {
        /* eslint-disable prefer-destructuring */
        if (minLat > l[1]) {
          minLat = l[1];
        }
        if (minLong > l[0]) {
          minLong = l[0];
        }
        if (maxLat < l[1]) {
          maxLat = l[1];
        }
        if (maxLong < l[0]) {
          maxLong = l[0];
        }
        /* eslint-enable prefer-destructuring */
      });
      return [[minLong, minLat], [maxLong, maxLat]];
    },
    drawTrace() {
      if (!this.trace || !this.mapLoaded) {
        return;
      }
      if (this.traceLayer) {
        this.map.removeLayer(TRACE_LAYER_NAME);
        this.map.removeSource(TRACE_SOURCE_NAME);
        this.traceLayer = false;
      }
      this.map.addSource(
        TRACE_SOURCE_NAME,
        { type: 'geojson', data: this.trace },
      );
      this.map.addLayer({
        id: TRACE_LAYER_NAME,
        type: 'line',
        source: TRACE_SOURCE_NAME,

        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': this.color,
          'line-width': 6,
          'line-opacity': 0.5,
        },
      });
      this.traceLayer = true;
      this.traceUpdated();
    },
    hideTrace() {
      this.map.setLayoutProperty(TRACE_LAYER_NAME, 'visibility', 'none');
    },
    async getTraceFromMapbox() {
      const pois = [...this.pois];
      pois.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a === b) {
          return 0;
        }
        return -1;
      });

      const fullPOIs = [];
      pois.forEach((p) => {
        if (this.byId[p.poiId]) {
          fullPOIs.push(this.byId[p.poiId]);
        }
      });
      const positions = fullPOIs.map((p) => ({ coordinates: [p.longitude, p.latitude] }));
      const routes = await this.fetchMapboxRoute(positions);
      this.trace = this.toGeoJson(routes[0].geometry.coordinates);
      this.distance = routes[0].distance;
      this.drawTrace();
    },
    fetchMapboxRoute(waypoints) {
      return new Promise((resolve, reject) => {
        const directionsClient = mbxDirections({ accessToken: mapboxgl.accessToken });
        directionsClient.getDirections({
          profile: this.transport,
          geometries: 'geojson',
          overview: 'full',
          waypoints,
        }).send()
          .then(({ body }) => {
            resolve(body.routes);
          }).catch((error) => { reject(error); });
      });
    },
    toGeoJson(coordinates) {
      return ({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates,
            },
          },
        ],
      });
    },

    /**
     * Event handler when GPS trace is updated (from mapbox or manual upload)
     */
    traceUpdated() {
      this.$emit('traceupdated', {
        trace: this.traceEnabled ? this.trace : { },
        distance: this.distance,
        color: this.color,
        hasTrace: this.traceEnabled,
        useNumberedPOIs: this.useNumberedPOIs,
      });
    },

    /**
     * converts a trace from the uploaded traceInput field
     * to a geojson object.
     * Accepts KML, GPX and geojson LineStrings
     */
    convertTrace() {
      let json = null;
      const reader = new FileReader();
      reader.onload = () => {
        const ext = this.traceInput.name.substring(this.traceInput.name.lastIndexOf('.') + 1, this.traceInput.name.length) || this.traceInput.name;
        if (ext === 'json' || ext === 'geojson') {
          json = JSON.parse(reader.result);
        } else {
          const parser = new DOMParser();
          const doc = parser.parseFromString(reader.result, 'text/xml');
          if (ext === 'kml') {
            json = kml(doc);
          } else if (ext === 'gpx') {
            json = gpx(doc);
          } else {
            this.errorNotification(this.$t('errInvalidTraceFormat'));
            return;
          }
        }
        this.trace = json;
        try {
          this.drawTrace();
        } catch (error) {
          this.errorNotification(this.$t('errCantDrawTrace'), error);
        }
      };
      reader.readAsText(this.traceInput);
    },
  },
  watch: {
    pois: {
      async handler() {
        if (this.mapLoaded === true) {
          this.displayPOIs();
        }
        if (this.traceEnabled && this.traceBuildMode === 'auto') {
          await this.getTraceFromMapbox();
        }
      },
      immediate: true,
    },
    distance() {
      this.traceUpdated();
    },
    useNumberedPOIs() {
      this.traceUpdated();
    },
    transport: {
      async handler() {
        if (this.traceEnabled && this.traceBuildMode === 'auto') {
          await this.getTraceFromMapbox();
        }
      },
    },
    color() {
      if (!this.mapLoaded) {
        return;
      }
      this.traceUpdated();
      if (this.traceLayer) {
        this.map.setLayoutProperty(TRACE_LAYER_NAME, 'visibility', 'visible');
        this.map.setPaintProperty(TRACE_LAYER_NAME, 'line-color', this.color);
      } else {
        this.drawTrace();
      }
    },
    traceInput() {
      if (this.traceInput) {
        this.convertTrace();
      }
    },
    traceEnabled() {
      this.traceUpdated();
      if (this.traceEnabled === false) {
        this.hideTrace();
      } else {
        this.drawTrace();
      }
    },
  },

  mounted() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10?optimize=true',
      center: [3, 50],
      zoom: 10,
    });
    this.map.on('load', this.onMapLoaded);
  },
};
</script>
<style lang="scss">
.marker-location {
  background-image: url('../../assets/ico-location.svg');
  background-size: cover;
  width: 23px;
  height: 32px;
  cursor: pointer;
}
.marker-event {
  background-image: url('../../assets/ico-event.svg');
  background-size: cover;
  width: 23px;
  height: 32px;
  cursor: pointer;
}
.marker-reward {
  background-image: url('../../assets/ico-reward.svg');
  background-size: cover;
  width: 23px;
  height: 32px;
  cursor: pointer;
}
</style>
<i18n>
{
  "fr": {
    "switchLabelDrawTrace": "Dessiner un tracé pour ce circuit sur la carte",
    "switchLabelPOINumber": "Numéroter les lieux d'intérêt sur la carte",
    "switchHintPOINumber": "Si cette option est active, chaque lieu sera représenté par une icône numérotée de la même couleur que le tracé, en fonction de son ordre",
    "alertTooManyPOIs": "Ce circuit comporte plus de 25 lieux, la génération automatique de tracé est désactivée.<br/> Vous pouvez envoyer manuellement une trace aux formats ",
    "labelAutoDrawTrace": "Générer automatiquement un tracé",
    "labelManualTrace": "Envoyer un tracé existant",
    "btnMapbox": "Générer un tracé via mapbox",
    "fileInputLabel": "Choisissez une trace au format KML, GPX ou GeoJSON à envoyer",
    "titleTraceColor": "Couleur du tracé",
    "autoDistanceAlert": "La distance ci-dessous est suggérée automatiquement par Mapbox",
    "distanceLabel": "Distance du circuit (en m)",
    "errInvalidTraceFormat": "Format incorrect. Utilisez un fichier au format geojson, KML ou GPX",
    "errCantDrawTrace": "Impossible de déssiner le tracé sur la carte"
  },
  "en": {
    "switchLabelDrawTrace": "Draw the tour on the map",
    "switchLabelPOINumber": "Display the POI number on the map",
    "switchHintPOINumber": "When active, each POI will have an icon indicated its order in the tour",
    "alertTooManyPOIs": "This tour has too many waypoints to be automatically generated<br/> you can upload a trace using the following formats: ",
    "labelAutoDrawTrace": "Automatically generate a trace",
    "labelManualTrace": "Upload an existing trace",
    "btnMapbox": "Create a trace through mapbox",
    "fileInputLabel": "Choose a GPX, KML or geoJSON trace to upload",
    "titleTraceColor": "Trace color",
    "autoDistanceAlert": "Distance is automatically computed by Mapbox",
    "distanceLabel": "Tour distance (in m)",
    "errInvalidTraceFormat": "Invalid format. Use a valid GPX, KML or geoJSON file",
    "errCantDrawTrace": "Unable to draw the trace on the map"
  }
}
</i18n>
