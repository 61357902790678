<template>
  <v-card class="mb-12" flat>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row align="center" justify="center" v-for="(team, index) in teams" :key="team.id">
            <v-col cols="4">
              <v-text-field
                :label="$t('fieldTeamName')"
                counter="1024"
                required
                :disabled="isTestGame === true"
                v-model="team.name"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('fieldTeamEmail')"
                counter="1024"
                :disabled="isTestGame === true"
                v-model="team.email"
              />
            </v-col>
            <v-col cols="3">
              <team-color-picker v-model="team.color" />
            </v-col>
            <v-col cols="1">
              <v-btn icon ripple @click="$emit('delete', index)" v-if="index > 0">
                <v-icon color="grey lighten-1">delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="isTestGame === false">
            <v-row align="center" justify="center">
              <v-btn fab color="primary" @click="$emit('add')"><v-icon>mdi-plus</v-icon></v-btn>
            </v-row>
          </div>
          <v-row class="mt-10" align="center" justify="center" v-if="isTestGame === false && organization.useCredits === true">
            <v-col cols="4">
              <h3 class="font-weight-black"> {{ $t('yourNumber') }} {{ organization.creditBalance }}</h3>
            </v-col>
            <v-col cols="4">
              <h3 class="font-weight-black"> {{ $t('consumedCredits') }} {{ teams.length }}</h3>
            </v-col>
            <v-col cols="4">
              <h3 class="font-weight-black"> {{ $t('remainingCredits') }} {{ organization.creditBalance - teams.length }}</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import TeamColorPicker from './TeamColorPicker.vue';

export default {
  components: {
    TeamColorPicker,
  },
  props: {
    teams: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    isTestGame: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      nameRules: [
        (v) => !!v || this.$t('ruleTeamName'),
        (v) => (v && v.length <= 1024) || this.$t('ruleTeamNameTooLong'),
        (v) => (v && v.length >= 2) || this.$t('ruleTeamNameTooShort'),
      ],
    };
  },
  watch: {
    valid(val) {
      this.$emit('valid', val);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Créer des équipes",
    "fieldTeamName": "Nom de l'équipe",
    "fieldTeamEmail": "Email de l'équipe",
    "ruleTeamName": "Veuillez saisir un nom d'équipe",
    "ruleTeamNameTooLong": "Le nom doit faire moins de 1024 caractères",
    "ruleTeamNameTooShort": "Le nom doit faire au moins 2 caractères",
    "yourNumber": "Votre nombre de crédits",
    "consumedCredits": "Crédits consommés",
    "remainingCredits": "Crédits restants"
  },
  "en": {
    "title": "Create teams",
    "fieldTeamName": "Team name",
    "fieldTeamEmail": "Team email",
    "ruleTeamName": "Please enter a team name",
    "ruleTeamNameTooLong": "Name must be less than 1024 chars long",
    "ruleTeamNameTooShort": "Name must be at least 2 chars long",
    "yourNumber": "Your credits number",
    "consumedCredits": "Consumed credits",
    "remainingCredits": "Remaining credits"
  }
}
</i18n>
