<template>
  <v-card tile>
    <v-img
      :src="this.picture"
      height="150px"
      class="white--text"
      gradient="rgba(0,0,0, 0.5), rgba(0,0,0,0.2)"
    >
      <v-card-title>
        <h3> {{ title }} </h3>
      </v-card-title>
    </v-img>
    <v-card-actions>
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import PictureUrl from '../../mixins/PictureUrl.vue';

export default {
  mixins: [PictureUrl],
  props: {
    title: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
  },
};
</script>
