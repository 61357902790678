<template>
  <v-dialog v-model="dialog" persistent max-width="550">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title>{{ $t('deleteBtnTitle') }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline">{{ $t('deleteTeamTitle') }}</v-card-title>
      <v-card-text>{{ $t('deleteTeamText') }} </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="accent"
          text
          @click="close">
          {{ $t('deleteTeamBtnNo') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="confirm()">
          {{ $t('deleteTeamBtnYes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
    },
    confirm() {
      this.$emit('confirmdelete', this.team);
      this.close();
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "deleteTeamTitle": "Supprimer l'équipe",
      "deleteTeamText": "Voulez-vous supprimer cette équipe ?",
      "deleteTeamBtnNo": "Ne pas supprimer",
      "deleteTeamBtnYes": "Supprimer",
      "deleteBtnTitle": "Supprimer"
    },
    "en": {
      "deleteTeamTitle": "Delete team",
      "deleteTeamText": "Do you want to delete this team?",
      "deleteTeamBtnNo": "Do not delete",
      "deleteTeamBtnYes": "Delete",
      "deleteBtnTitle": "Delete"
    }
  }
  </i18n>
