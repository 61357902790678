<template>
  <v-layout row justify-center>
    <v-dialog v-model="editorOpen" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ $t('labelFieldTitle')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-card
              flat
              color="transparent"
            >
              <v-subheader>{{$t('creditNumber')}}</v-subheader>

              <v-card-text>
                <v-row>
                  <v-col class="pr-4">
                    <v-slider
                      v-model="creditBalance"
                      class="align-center"
                      max="500"
                      min="-100"
                      thumb-color="red"
                      thumb-label="always"
                      hide-details
                    >
                      <template v-slot:append>
                        <v-text-field
                          v-model="creditBalance"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                        />
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="comment" solo label="Commentaire" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="$emit('closeclicked')">
            {{ $t('btnCancel')}}
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="save">
            {{ $t('btnSave')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  data() {
    return {
      editorOpen: true,
      creditBalance: 0,
      comment: '',
    };
  },
  computed: {
    title() {
      return this.$t('titleCreate');
    },
  },
  methods: {
    save() {
      this.$emit('save', {
        amount: this.creditBalance,
        comment: this.comment,
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
      "labelFieldTitle": "Ajouter des crédits",
      "creditNumber": "Nombre de crédits",
      "btnCancel": "Annuler",
      "btnSave": "Enregistrer"
  },
  "en": {
      "labelFieldTitle": "Add credits",
      "creditNumber": "Credit number",
      "btnCancel": "Cancel",
      "btnSave": "Save"
  }
}
</i18n>
