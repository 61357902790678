<script>
import cfg from '../config';

export default {

  methods: {
    getPictureUrl(prefix, pictureName) {
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${prefix}/${pictureName} `;
    },
  },
};
</script>
