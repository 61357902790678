import Vue from 'vue';

import VueI18n from 'vue-i18n';
import Toasted from 'vue-toasted';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import FlagIcon from 'vue-flag-icon';

import DatetimePicker from 'vuetify-datetime-picker';
import VueYouTubeEmbed from 'vue-youtube-embed';

import * as Sentry from '@sentry/vue';

import Vuelidate from 'vuelidate';
import release from '../public/version.json';
import vuetify from './plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    release: `${release.name}@${release.version}`,
    dsn: 'https://2a11017dcdf34ae7b97de7ca57fcdcec@o238725.ingest.sentry.io/5613101',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}
const l = navigator.language || navigator.userLanguage;
const lang = l.substring(0, 2);

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  fr: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    long: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  dateTimeFormats,
});

Vue.use(DatetimePicker);
Vue.use(FlagIcon);
Vue.use(Toasted);
Vue.use(Vuelidate);
Vue.use(VueYouTubeEmbed, { global: true, componentId: 'youtube-media' });
Vue.config.productionTip = false;

Vue.component(VueQrcode.name, VueQrcode);

const MAX_TRUNCATE_CHARCACTERS_COUNT = 30;
Vue.filter('truncate', (value) => (value.length <= MAX_TRUNCATE_CHARCACTERS_COUNT
  ? value
  : `${value.substring(0, MAX_TRUNCATE_CHARCACTERS_COUNT)}...`));
new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
