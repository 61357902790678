<template>
  <v-card>
    <div class="displ-svg" v-if="xml" v-html="xml" />
    <v-img
      v-else
      :src="imgUrl"
      :aspect-ratio="0.72"
      :max-height="100"
      contain
    >
      <v-container fill-height fluid>
        <v-layout fill-height>
          <v-flex xs12 align-end flexbox />
        </v-layout>
      </v-container>
    </v-img>
    <v-card-title>{{ $t('pageTitle') }}</v-card-title>
    <v-card-text>
      <p class="subtitle">{{ $t('pageSubtitle') }}</p>
      <v-container fluid>
        <v-row>
          <v-col xs="12" sm="6">
            <v-radio-group
              v-model="iconType"
              :mandatory="true"
              @change="emitUpdatedIcon">
              <v-radio :label="$t('labelIconTypeColor')" value="color" />
              <v-radio :label="$t('labelIconTypeCustom')" value="custom" />
            </v-radio-group>
          </v-col>
          <v-col xs="12" sm="6" v-if="iconType === 'color'">
            <v-color-picker
              @input="emitUpdatedIcon"
              v-model="color"
              mode="hexa"
              hide-mode-switch />
          </v-col>
          <v-col xs="12" sm="6" v-if="iconType === 'custom'">
            <v-file-input
              v-model="file"
              @change="readXML()"
              ref="file"
              accept="application/svg+xml"
              prepend-icon="mdi-camera"
              :label="$t('labelFieldCustomIcon')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable max-len */
import axios from '../../services/axios';
import UserNotifications from '../../mixins/UserNotifications.vue';

export default {
  mixins: [UserNotifications],
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: false,
      default: '',
    },
    destinationId: {
      type: Number,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      iconType: null,
      color: '#FF0000',
      isFetchingPreview: false,
      file: null,
      xml: null,
      currentIcon: null,
    };
  },
  computed: {
    imgUrl() {
      if (this.iconType === 'custom') {
        return `${this.baseUrl}/api/v3/sites/${this.destinationId}/icons/${this.iconName}.svg`;
      }
      return `${this.baseUrl}/api/v3/sites/${this.destinationId}/icons/preview.svg?color=${encodeURIComponent(this.color)}`;
    },
  },
  methods: {
    fetchCurrentIcon() {
      return new Promise((resolve, reject) => {
        if (this.isFetchingPreview === true || !this.iconName) {
          resolve();
          return;
        }
        this.isFetchingPreview = true;
        axios.get(`/api/v3/sites/${this.destinationId}/icons/${this.iconName}`).then((res) => {
          this.isFetchingPreview = false;
          this.iconType = res.data.isCustom ? 'custom' : 'color';
          if (this.iconType === 'color') {
            this.color = res.data.color;
          }
          this.emitUpdatedIcon();
          resolve(res.data);
        }).catch((error) => reject(new Error(`unable to fetch icon: ${error}`)));
      });
    },
    emitUpdatedIcon() {
      if (!this.loaded) {
        return;
      }
      if (this.iconType === 'custom' && this.xml === null) {
        return;
      }
      let newIconID = null;
      if (!this.isNew) {
        if (this.currentIcon.id) {
          newIconID = this.currentIcon.id;
        }
      }
      const newIcon = {
        id: newIconID,
        destinationId: this.destinationId,
        name: this.iconName,
        isCustom: this.iconType === 'custom',
        color: this.color,
        content: this.iconType === 'custom' ? this.xml : '',
      };
      this.$emit('icon-updated', newIcon);
    },
    readXML() {
      const reader = new FileReader();
      reader.onload = () => {
        this.xml = reader.result;
        this.emitUpdatedIcon();
      };
      reader.onerror = () => {
        reader.abort();
        this.errorNotification(this.$t('notificationErrorSVGReading'), reader.error);
      };
      reader.readAsText(this.file);
    },
  },
  async mounted() {
    try {
      this.currentIcon = await this.fetchCurrentIcon();
      this.loaded = true;
      this.emitUpdatedIcon();
    } catch (error) {
      this.loaded = true;
      this.errorNotification(this.$t('notificationErrorLoadingFailed'), error);
    }
  },
};
</script>
<style scoped>
.displ-svg {
  display:flex;
  height: 84px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
<i18n>
{
  "fr": {
    "pageTitle": "Icône",
    "pageSubtitle": "Choisissez une couleur pour l'icône de cette catégorie, ou envoyez une icône personnalisée",
    "labelIconTypeColor": "Couleur",
    "labelIconTypeCustom": "Icône personnalisée",
    "labelFieldCustomIcon": "Sélectionner une icône au format svg",
    "notificationErrorSVGReading": "Impossible de lire le fichier svg",
    "notificationErrorLoadingFailed": "Impossible de lire l'icône"
  },
  "en": {
    "pageTitle": "Icon",
    "pageSubtitle": "Choose a color for this category's icon, or upload a custom icon",
    "labelIconTypeColor": "Color",
    "labelIconTypeCustom": "Custom icon",
    "labelFieldCustomIcon": "Select an SVG icon",
    "notificationErrorSVGReading": "Unable to read the SVG file",
    "notificationErrorLoadingFailed": "Unable to read icon"
  }
}
</i18n>
