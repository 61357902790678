<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3" class="font-weight-bold">
        {{ $t('titleQuestion') }}
      </v-col>
      <v-col cols="9">
        {{ answer.question.title }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="font-weight-bold">
        {{ $t('titleResponse') }}
      </v-col>
      <v-col v-if="answer.question.type === 'open'" cols="9">
        {{ answer.answerText }}
      </v-col>
      <v-col v-if="answer.question.type === 'photo'" cols="9">
        <v-img
          :src="answer.answerText"
          aspect-ratio="1"
          class="pointer"
          max-width="500"
          max-height="300"
          contain
          @click="overlayImage = true"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular indeterminate color="secondary" />
            </v-row>
          </template>
        </v-img>
        <v-overlay :value="overlayImage">
          <v-img
            :src="answer.answerText"
            class="custom-height"
            contain
            @click="overlayImage = false"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="secondary" />
              </v-row>
            </template>
          </v-img>
        </v-overlay>
      </v-col>
    </v-row>
    <v-row v-if="answer.question.type === 'open' || 'photo'">
      <v-col cols="3" class="font-weight-bold">
        {{ $t('titleExpectedResponse') }}
      </v-col>
      <v-col cols="9">
        {{ answer.question.expectedAnswer }}
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-btn
        :color="isValid ? 'grey' : 'red'"
        dark
        small
        class="mx-4"
        @click="invalidate"
      >
        {{ $t('btnInvalid') }}
      </v-btn>
      <v-btn
        :color="isValid === false ? 'grey' : 'green'"
        dark
        small
        class="mx-4"
        @click="validate"
      >
        {{ $t('btnValid')}}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isValid: null,
      overlayImage: false,
    };
  },
  props: {
    answer: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    validate() {
      this.isValid = true;
      this.$emit('validate', true);
    },
    invalidate() {
      this.isValid = false;
      this.$emit('validate', false);
    },
  },
};
</script>

<style scoped>
.not-selected {
  background-color:'grey';
}

.custom-height {
  max-height: 95vh;
  max-width: 95vw;
}

.pointer {
  cursor: pointer;
}
</style>
<i18n>
{
  "fr": {
    "titleQuestion": "Question :",
    "titleResponse": "Réponse apportée :",
    "titleExpectedResponse": "Réponse attendue :",
    "btnValid": "Valide",
    "btnInvalid": "Invalide"
  },
  "en": {
    "titleQuestion": "Question:",
    "titleResponse": "Answer:",
    "titleExpectedResponse": "Expected answer:",
    "btnValid": "Valid",
    "btnInvalid": "Invalid"
  }
}
</i18n>
