<template>
  <div>
    <base-card
      :title="title"
      :description="description"
      :picture="picture"
      :left-button-title="leftButtonTitle"
      :right-button-title="null"
      @leftbuttonclicked="onEditButtonClicked">
      <template slot="content">
        <v-chip
          class="ma-1"
          v-if="place.enabled === false"
          label
          small
          color="red"
          text-color="white">
          <v-icon small>delete</v-icon>
          {{ $t('chipDisabled') }}
        </v-chip>
        <v-chip
          class="ma-A"
          label
          small
          color="green"
          text-color="white">
          <v-icon small>translate</v-icon>
          {{ $tc('chipTranslations', translationsCount) }}
        </v-chip>
        <v-chip
          class="ma-1"
          v-if="place.requiresMinimumBalance === true"
          label
          small
          color="orange"
          text-color="white">
          <v-icon>star</v-icon>
          {{ $t('chipVIP') }}
        </v-chip>
        <v-chip
          class="ma-1"
          v-if="place.events && place.events.length > 0"
          label
          small
          color="red"
          text-color="white">
          <v-icon>event</v-icon>
          {{ $tc('chipEventsCount', eventsCount) }}
        </v-chip>
        <v-chip
          class="ma-1"
          v-if="place.isTemporary === true"
          label
          small
          color="blue"
          text-color="white">
          <v-icon>alarm</v-icon>
          {{ $t('chipTemporary') }}
        </v-chip>
      </template>
      <template v-slot:buttons>
        <place-disabler
          v-if="place.enabled === true"
          :name="place.name"
          :is-deleting="isDeleting"
          @disable="deactivatePlace(place.id)" />
        <place-deleter
          v-if="place.enabled === false"
          :name="place.name"
          :is-deleting="isDeleting"
          @delete="deletePlace()" />
        <v-spacer />
        <place-duplicator
          v-if="place.enabled === true"
          :place-id="place.id"
          @duplicate="onDuplicate"
        />
      </template>
    </base-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import cfg from '../../config';
import BaseCard from '../base/BaseCard.vue';
import UserNotifications from '../../mixins/UserNotifications.vue';
import PlaceDuplicator from './PlaceDuplicator.vue';
import PlaceDisabler from './PlaceDisabler.vue';
import PlaceDeleter from './PlaceDeleter.vue';
import poiService from '../../services/poi';

export default {
  components: {
    BaseCard,
    PlaceDuplicator,
    PlaceDisabler,
    PlaceDeleter,
  },
  mixins: [UserNotifications],

  props: {
    place: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteDialogOpen: false,
      isDeleting: false,
    };
  },
  computed: {
    siteId() {
      if (this.$route.params.siteId) {
        return parseInt(this.$route.params.siteId, 10);
      }
      return -1;
    },
    ...mapGetters('auth', [
      'token',
    ]),
    ...mapGetters('site', [
      'allSites',
    ]),

    title() {
      return this.place.name;
    },
    translationsCount() {
      if (this.place.locales === null) {
        return 0;
      }
      return Object.keys(this.place.locales).length;
    },
    description() {
      if (this.place.locales === null) {
        return '0 traduction';
      }
      const localesCount = Object.keys(this.place.locales).length;
      if (localesCount > 1) {
        return `${localesCount} traductions`;
      }
      return `${localesCount} traduction`;
    },
    eventsCount() {
      if (!this.place.events) {
        return 0;
      }
      return this.place.events.length;
    },
    picture() {
      if (this.place.pictures.length > 0) {
        const pics = [...this.place.pictures];
        pics.sort((a, b) => a.order - b.order);
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${this.place.siteId}/${pics[0].filename}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
    leftButtonTitle() {
      return this.place.enabled ? this.$t('leftBtnTitleEdit') : this.$t('leftBtnTitleActivate');
    },
    rightButtontitle() {
      return this.place.enabled ? this.$t('rightBtnTitleDeactivate') : this.$t('rightBtnTitleDelete');
    },
  },
  methods: {
    askRefresh() {
      this.$emit('refresh');
    },
    deletePlace() {
      this.isDeleting = true;
      const token = this.$store.getters['auth/token'];
      this.$store.dispatch('poi/delete', { siteId: this.place.siteId, poiId: this.place.id, token }).then(() => {
        this.isDeleting = false;
        this.successNotification(this.$t('notificationDeleteSuccess'));
        this.askRefresh();
      }).catch((error) => {
        this.errorNotification(this.$t('notificationDeleteError'), error);
        this.isDeleting = false;
      });
    },
    onEditButtonClicked() {
      if (this.place.enabled === false) {
        this.activatePlace(this.place.id);
      } else {
        this.$router.push({
          name: 'placesEditor',
          params: {
            siteId: this.place.siteId,
            itemId: this.place.id,
            place: this.place,
          },

        });
      }
    },
    async onDuplicate(event) {
      await this.duplicatePlace(event.poiID, event.targetSiteId);
    },
    async duplicateSurveys(oldPlaceUUID, newPlaceUUID, newDestinationID) {
      const url = '/gameapi/v1/admin/surveys/duplicate';
      const http = axios.create({ baseURL: cfg.gameApiUrl });
      const q = {
        oldPOIUUID: oldPlaceUUID,
        newPOIUUID: newPlaceUUID,
        newDestinationID,
      };
      await http.post(url, q, { headers: { Authorization: `JWT ${this.token}` } });
    },
    async activatePlace(placeId) {
      try {
        const place = await poiService.poiById(placeId, this.token);
        place.enabled = true;
        await poiService.updatePOI(this.siteId, place, this.token);
        this.successNotification(this.$t('notificationActivateSuccess'));
        this.askRefresh();
      } catch (error) {
        this.errorNotification(this.$t('notificationActivateError'), error);
      }
    },
    async deactivatePlace(placeId) {
      try {
        const place = await poiService.poiById(placeId, this.token);
        place.enabled = false;
        await poiService.updatePOI(this.siteId, place, this.token);
        this.successNotification(this.$t('notificationDeactivateSuccess'));
        this.askRefresh();
      } catch (error) {
        this.errorNotification(this.$t('notificationDeactivateError'), error);
      }
    },
    async duplicatePlace(placeId, targetSiteId) {
      try {
        const res = await poiService.duplicatePOI(placeId, this.siteId, targetSiteId, this.token);

        const newSite = this.allSites[targetSiteId];
        let newSiteUUID = '';
        if (newSite) {
          newSiteUUID = newSite.uuid;
        }
        const oldPlace = await poiService.poiById(placeId, this.token);
        const newPlace = await poiService.poiById(res.poiId, this.token);
        await this.duplicateSurveys(oldPlace.uuid, newPlace.uuid, newSiteUUID);
        this.successNotification(this.$t('notificationDuplicateSuccess'));
        this.askRefresh();
      } catch (error) {
        this.errorNotification(this.$t('notificationDuplicateError'), error);
      }
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "chipDisabled": "Désactivé",
    "chipVIP": "Lieu VIP",
    "chipTemporary": "Temporaire",
    "chipTranslations": "Pas de traduction | Une traduction | {count} traductions",
    "chipEventsCount": "Pas d'évènement | Un évènement | {count} évènements",
    "leftBtnTitleActivate": "Activer",
    "leftBtnTitleEdit": "Modifier",
    "rightBtnTitleDeactivate": "Désactiver",
    "rightBtnTitleDelete": "Supprimer",
    "notificationDeleteSuccess": "Lieu d'intérêt supprimé.",
    "notificationDeleteError": "Impossible de supprimer le lieu",
    "notificationActivateSuccess": "Lieu activé avec succès",
    "notificationActivateError": "Impossible d'activer le lieu",
    "notificationDeactivateSuccess": "Lieu désactivé avec succès",
    "notificationDeactivateError": "Impossible de désactiver le lieu",
    "notificationDuplicateSuccess": "Lieu d'intérêt dupliqué",
    "notificationDuplicateError": "Impossible de dupliquer le lieu"
  },
  "en": {
    "chipDisabled": "Disabled",
    "chipVIP": "VIP POI",
    "chipTemporary": "Temporary",
    "chipTranslations": "No translation | One translation | {count} translations",
    "chipEventsCount": "No event | One event | {count} events",
    "leftBtnTitleActivate": "Enable",
    "leftBtnTitleEdit": "Edit",
    "rightBtnTitleDeactivate": "Disable",
    "rightBtnTitleDelete": "Delete",
    "notificationDeleteSuccess": "POI deleted.",
    "notificationDeleteError": "Unable to delete the POI",
    "notificationActivateSuccess": "POI enabled",
    "notificationActivateError": "Could not enab the POI",
    "notificationDeactivateSuccess": "POI disabled",
    "notificationDeactivateError": "Could not disable POI",
    "notificationDuplicateSuccess": "POI cloned",
    "notificationDuplicateError": "Unable to clone the POI"
  }
}
</i18n>
