import http from './axios';

const updateCredits = (credBody, token) => new Promise((resolve, reject) => {
  http.post(
    '/api/v3/credits/add',
    credBody,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});
const consumeCredits = (credBody, token) => new Promise((resolve, reject) => {
  http.post(
    '/api/v3/credits/consume',
    credBody,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
});
const getCreditHistory = (id, token) => new Promise((resolve, reject) => {
  http.get(`/api/v3/organizations/${id}/credit_history`, { headers: { Authorization: `BEARER ${token}` } })
    .then((res) => {
      resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
});

export default {
  updateCredits,
  consumeCredits,
  getCreditHistory,
};
