<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('closedialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="isNew === true">{{
        $t("titleAdd")
      }}</v-toolbar-title>
      <v-toolbar-title v-else>{{ $t("titleEdit") }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn :disabled="disabled" dark text @click="save">{{
          $t("btnSave")
        }}</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t("parametersTitle") }}</h3>
          </v-col>
        </v-row>
        <v-row v-if="disabled === true && isNew !== true">
          <v-col cols="12">
            <v-alert type="warning">
              {{ $t("alertDisabled") }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('labelQuizTitle')"
              required
              v-model="title"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              :label="$t('labelQuizLang')"
              :items="languages"
              v-model="lang"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12">
            <h3>{{ $t("questionsTitle") }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-model="panels" inset>
              <v-expansion-panel v-for="q in questions" :key="q.id">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                  <v-row>
                    <v-col sm="7" md="9"> {{ q.title }}</v-col>
                    <v-col sm="5" md="3">
                      <div>
                        <v-chip
                          dark
                          class="mx-5"
                          small
                          :color="chipContent(q).color"
                        >{{ chipContent(q).text }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-radio-group
                        :label="$t('radioQuestionTypeLabel')"
                        v-model="q.type"
                      >
                        <v-radio
                          key="open"
                          :label="$t('radioQuestionTypeValueOpen')"
                          value="open"
                        />
                        <v-radio
                          key="photo"
                          :label="$t('radioQuestionTypeValuePhoto')"
                          value="photo"
                        />
                        <v-radio
                          key="single"
                          :label="$t('radioQuestionTypeValueSingle')"
                          value="single"
                        />
                        <v-radio
                          key="multiple"
                          :label="$t('radioQuestionTypeValueMultiple')"
                          value="multiple"
                        />
                        <v-radio
                          key="video"
                          :label="$t('radioQuestionTypeValueVideo')"
                          value="video"
                        />
                        <v-radio
                          key="audio"
                          :label="$t('radioQuestionTypeValueAudio')"
                          value="audio"
                        />
                        <v-radio
                          key="image"
                          :label="$t('radioQuestionTypeValueImage')"
                          value="image"
                        />
                        <v-radio
                          key="team_name"
                          :label="$t('radioQuestionTypeValueTeamName')"
                          value="team_name"
                        />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <single-question-editor
                      v-if="q.type == 'single'"
                      :question="q"
                      :survey-id="id"
                      :question-id="q.id"
                      :isNewQuestion="isNewQuestion(q.id)"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                    />
                    <multiple-question-editor
                      :survey-id="id"
                      :question-id="q.id"
                      :isNewQuestion="isNewQuestion(q.id)"
                      :question="q"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                      v-else-if="q.type === 'multiple'"
                    />
                    <open-question-editor
                      v-else-if="q.type === 'open' || q.type === 'team_name'"
                      :question="q"
                      :isNewQuestion="isNewQuestion(q.id)"
                      :survey-id="id"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                    />
                    <photo-question-editor
                      v-else-if="q.type === 'photo'"
                      :question="q"
                      :question-id="q.id"
                      :isNewQuestion="isNewQuestion(q.id)"
                      :survey-id="id"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                    />
                    <video-question-editor
                      v-else-if="q.type === 'video'"
                      :question="q"
                      :isNewQuestion="isNewQuestion(q.id)"
                      :survey-id="id"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                    />
                    <audio-question-editor
                      v-else-if="q.type === 'audio'"
                      :question="q"
                      :isNewQuestion="isNewQuestion(q.id)"
                      :survey-id="id"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                    />
                    <image-question-editor
                      v-else-if="q.type === 'image'"
                      :question="q"
                      :isNewQuestion="isNewQuestion(q.id)"
                      :survey-id="id"
                      @question-updated="onQuestionUpdated"
                      @question-removed="onQuestionRemoved"
                    />
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <div>
          <v-btn color="primary" @click="addQuestion">
            {{ $t("btnAddQuestion") }}
          </v-btn>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import SingleQuestionEditor from './SingleQuestion.vue';
import MultipleQuestionEditor from './MultipleQuestion.vue';
import PhotoQuestionEditor from './PhotoQuestionEditor.vue';
import OpenQuestionEditor from './OpenQuestion.vue';
import VideoQuestionEditor from './VideoQuestion.vue';
import AudioQuestionEditor from './AudioQuestion.vue';
import ImageQuestionEditor from './ImageQuestion.vue';

export default {
  components: {
    SingleQuestionEditor,
    MultipleQuestionEditor,
    PhotoQuestionEditor,
    OpenQuestionEditor,
    VideoQuestionEditor,
    AudioQuestionEditor,
    ImageQuestionEditor,
  },
  props: {
    apiBaseUrl: {
      type: String,
      required: true,
    },
    survey: {
      type: Object,
      required: false,
      default: null,
    },
    destinationId: {
      type: String,
      required: true,
    },
    poiId: {
      type: String,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateDisabled: {
      type: Boolean,
      required: true,
    },
    creationDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // survey fileds
      id: uuidv4(),
      title: 'Défi sans titre',
      lang: 'fr_FR',
      questions: [],
      clues: null,
      // UI fields
      dialog: false,
      panels: null,
      newQuestionsIds: [],
      languages: [
        {
          text: this.$t('itemFrench'),
          value: 'fr_FR',
        },
        {
          text: this.$t('itemEnglish'),
          value: 'en_GB',
        },
        {
          text: this.$t('itemGerman'),
          value: 'de_DE',
        },
        {
          text: this.$t('itemNL'),
          value: 'nl_NL',
        },
        {
          text: this.$t('itemItalian'),
          value: 'it_IT',
        },
        {
          text: this.$t('itemSpanish'),
          value: 'es_ES',
        },
        {
          text: this.$t('itemPortuguese'),
          value: 'pt_PT',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['token']),
    disabled() {
      if (this.isNew === true) {
        return this.creationDisabled;
      }
      return this.updateDisabled;
    },
  },
  mounted() {
    if (this.survey) {
      this.populateWith(this.survey);
    }
  },
  methods: {
    chipContent(question) {
      const content = {
        text: this.$t('radioQuestionTypeValueMultiple'),
        color: 'orange',
      };
      switch (question.type) {
        case 'single':
          content.text = this.$t('radioQuestionTypeValueSingle');
          content.color = 'green';
          break;
        case 'multiple':
          content.text = this.$t('radioQuestionTypeValueMultiple');
          content.color = 'orange';
          break;
        case 'open':
          content.text = this.$t('radioQuestionTypeValueOpen');
          content.color = 'blue';
          break;
        case 'photo':
          content.text = this.$t('radioQuestionTypeValuePhoto');
          content.color = 'red';
          break;
        case 'video':
          content.text = this.$t('radioQuestionTypeValueVideo');
          content.color = 'purple';
          break;
        case 'audio':
          content.text = this.$t('radioQuestionTypeValueAudio');
          content.color = 'pink';
          break;
        case 'team_name':
          content.text = this.$t('radioQuestionTypeValueTeamName');
          content.color = 'black';
          break;
        case 'image':
          content.text = this.$t('radioQuestionTypeValueImage');
          content.color = 'black';
          break;
        default:
          break;
      }
      return content;
    },
    populateWith(survey) {
      this.id = survey.id;
      this.title = survey.title;
      this.lang = survey.lang;
      this.questions = survey.questions;
    },
    /*
     called when a subcomponent creates or updates a question
     */
    onQuestionUpdated(question, clues) {
      const questionsList = this.questions.filter((q) => q.id !== question.id);
      questionsList.push(question);
      this.questions = questionsList;
      this.clues = clues;
    },
    onQuestionRemoved(questionID) {
      this.questions = this.questions.filter((q) => q.id !== questionID);
    },
    addQuestion() {
      const q = {
        id: uuidv4(),
        expectedAnswer: null,
        points: 5,
        type: 'open',
        order: this.questions ? this.questions.length : 1,
        enabled: true,
        surveyId: this.id,
      };
      if (!this.questions) {
        this.questions = [];
      }
      this.questions.push(q);
      this.panels = this.questions ? this.questions.length - 1 : 1;
      this.newQuestionsIds.push(q.id);
    },

    save() {
      if (this.isNew === true) {
        this.create();
      } else {
        this.update();
      }
    },
    create() {
      const q = {
        id: this.id,
        poiId: this.poiId,
        destinationId: this.destinationId,
        title: this.title,
        lang: this.lang,
        questions: this.questions,
        enabled: true,
        fileUrl: this.fileUrl,
      };
      if (this.clues !== null) {
        this.$emit('createsurvey', q, this.clues);
      } else {
        this.$emit('createsurvey', q, null);
      }
    },
    async update() {
      const q = {
        id: this.id,
        poiId: this.poiId,
        destinationId: this.destinationId,
        title: this.title,
        lang: this.lang,
        questions: this.questions,
        enabled: true,
        fileUrl: this.fileUrl,
      };
      if (this.clues !== null) {
        this.$emit('updatesurvey', q, this.clues);
      } else {
        this.$emit('updatesurvey', q, null);
      }
    },
    isNewQuestion(questionId) {
      if (this.newQuestionsIds.includes(questionId)) {
        return true;
      }
      return false;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "titleAdd": "Ajouter un défi",
    "titleEdit": "Modifier un défi",
    "parametersTitle": "Paramètres du défi",
    "alertDisabled": "Les modifications des défis existants sont désactivées car au moins une partie n'est pas terminée.",
    "labelQuizTitle": "Titre du défi",
    "labelQuizLang": "Langue du défi",
    "questionsTitle": "Questions",
    "radioQuestionTypeLabel": "Type de question",
    "radioQuestionTypeValueOpen": "Question à réponse ouverte",
    "radioQuestionTypeValuePhoto": "Prise de photo",
    "radioQuestionTypeValueSingle": "Question à réponse unique",
    "radioQuestionTypeValueMultiple": "Question à réponses multiples",
    "radioQuestionTypeValueVideo": "Question sur une vidéo",
    "radioQuestionTypeValueAudio": "Question sur un document audio",
    "radioQuestionTypeValueImage": "Question sur une image",
    "radioQuestionTypeValueTeamName": "Choix du nom d'équipe",
    "btnAddQuestion": "Nouvelle question",
    "btnSave": "Enregistrer",
    "itemFrench": "Français",
    "itemEnglish": "Anglais",
    "itemGerman": "Allemand",
    "itemNL": "Néerlandais",
    "itemItalian": "Italien",
    "itemSpanish": "Espagnol",
    "itemPortuguese": "Portugais"
  },
  "en": {
    "titleAdd": "Add a challenge",
    "titleEdit": "Update a challenge",
    "parametersTitle": "Challenge parameters",
    "alertDisabled": "Existing challengers cannot be modified because at least one game is not finished",
    "labelQuizTitle": "Challenge title",
    "labelQuizLang": "Challenge language",
    "questionsTitle": "Questions",
    "radioQuestionTypeLabel": "Question type",
    "radioQuestionTypeValueOpen": "Open answer",
    "radioQuestionTypeValuePhoto": "Taking a picture",
    "radioQuestionTypeValueSingle": "Closed answer",
    "radioQuestionTypeValueMultiple": "Multiple answers",
    "radioQuestionTypeValueVideo": "Video",
    "radioQuestionTypeValueAudio": "Audio",
    "radioQuestionTypeValueImage": "Picture",
    "radioQuestionTypeValueTeamName": "Team name",
    "btnAddQuestion": "New question",
    "btnSave": "Save",
    "itemFrench": "French",
    "itemEnglish": "English",
    "itemGerman": "German",
    "itemNL": "Dutch",
    "itemItalian": "Italian",
    "itemSpanish": "Spanish",
    "itemPortuguese": "Portuguese"
  }
}
</i18n>
