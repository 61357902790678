<template>
  <v-container fluid v-if="sitesLoadingSucceeded">
    <v-spacer />

    <v-row>
      <v-col cols="12">
        <h1>{{ $t('pageTitle') }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="2" v-if="userIsDotmap === true">
        <v-select
          solo
          prepend-inner-icon="filter_list"
          v-model="currentFilter"
          :items="customerTypes"
        />
      </v-col>
      <v-col md="8" cols="12" sm="8" v-if="userIsDotmap === true">
        <v-text-field
          :label="$t('labelSearch')"
          solo
          clearable
          v-model="currentSearchTerm"
        />
      </v-col>
      <v-col v-else md="8" xs="12" sm="8" offset-md="2">
        <v-text-field
          :label="$t('labelSearch')"
          solo
          clearable
          v-model="currentSearchTerm"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="d of filteredDestinationsList" :key="d.id">
        <destination-card :destination="d" :displayCustomerType="userIsDotmap === true" />
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="userIsDotmap === true"
        fab
        fixed
        bottom
        right
        :to="{ name: 'createDestination' }"
        color="accent"><v-icon>mdi-plus</v-icon></v-btn>
    </v-fab-transition>
  </v-container>

</template>
<script>

import { mapState, mapGetters } from 'vuex';
import DestinationCard from '../components/destination/DestinationCard.vue';
import k from '../const';

export default {
  components: {
    DestinationCard,
  },
  data() {
    return {
      currentSearchTerm: '',
      currentFilter: 'all',
    };
  },
  computed: {
    ...mapState('site', [
      'currentSiteId',
      'sites',
      'sitesLoadingSucceeded',
    ]),
    ...mapGetters('auth', [
      'token',
      'user',
    ]),
    ...mapGetters('site', [
      'allSites',
    ]),
    searchTerm() {
      return this.currentSearchTerm ? this.currentSearchTerm : '';
    },

    userIsDotmap() {
      return this.user.organizationId === k.dotmapOrgId;
    },
    customerTypes() {
      return [
        { text: this.$t('customerTypeAll'), value: 'all' },
        { text: this.$t('customerTypeUnknwown'), value: 'unknown' },
        { text: this.$t('customerTypeDotmap'), value: 'dotmap' },
        { text: this.$t('customerTypeEvent'), value: 'final_event' },
        { text: this.$t('customerTypeAgency'), value: 'agency' },
        { text: this.$t('customerTypeSchool'), value: 'school' },
        { text: this.$t('customerTypeTourism'), value: 'final_tourism' },
        { text: this.$t('customerTypeDemo'), value: 'demo' },
      ];
    },
    filteredDestinationsList() {
      let destinationsToReturn = this.sortedDestinations;
      if (this.currentFilter && this.currentFilter !== 'all') {
        destinationsToReturn = destinationsToReturn.filter(
          (dest) => dest.customerType === this.currentFilter,
        );
      }

      if (this.searchTerm.length > 2) {
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
        destinationsToReturn = destinationsToReturn.filter(
          (d) => d.name.toLowerCase().includes(
            lowerCaseSearchTerm,
          ),
        );
      }
      return destinationsToReturn;
    },
    sortedDestinations() {
      const destValues = Object.values(this.sites);
      return destValues.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Les destinations",
    "labelSearch": "Chercher un nom de destination",
    "customerTypeAll": "Voir tout",
    "customerTypeUnknwown": "non défini",
    "customerTypeTourism": "Client final tourisme",
    "customerTypeEvent": "Evènement client",
    "customerTypeAgency": "Agence évènements",
    "customerTypeSchool": "Ecole",
    "customerTypeDotmap": "Dotmap",
    "customerTypeDemo": "Démo"

  },
  "en": {
    "pageTitle": "Destinations",
    "labelSearch": "Search a destination by name",
    "customerTypeAll": "See all",
    "customerTypeUnknwown": "Undefined",
    "customerTypeTourism": "Tourism",
    "customerTypeEvent": "Events (direct customer)",
    "customerTypeAgency": "Event agency",
    "customerTypeSchool": "School",
    "customerTypeDotmap": "Dotmap",
    "customerTypeDemo": "Demo"
  }
}
</i18n>
