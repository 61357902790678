<template>
  <v-container fluid v-if="site">
    <v-spacer />
    <v-row>
      <v-col>
        <h1 class="text-h2">{{ $t('pageTitle') }}</h1>
      </v-col>
    </v-row>
    <v-row dense class="mb-0">
      <v-col cols="3">
        <v-select
          solo
          prepend-inner-icon="filter_list"
          v-model="currentStatus"
          :items="currentStatusItems"
        />
      </v-col>
      <v-col cols="9">
        <v-text-field
          :label="$t('labelSearch')"
          solo
          clearable
          v-model="currentSearchTerm"
        />
      </v-col>
    </v-row>
    <v-row dense class="mt-0">
      <v-col
        cols="9"
        class="px-2"
      >
        <v-btn-toggle v-model="toggleList">
          <v-btn>
            <v-icon>mdi-view-grid</v-icon>
          </v-btn>
          <v-btn>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if="toggleList === 1 && currentStatus === 'enabled'" cols="3" class="text-rigth">
        <v-select
          solo
          prepend-inner-icon="filter_list"
          v-model="currentAction"
          :items="[
            {
              text: $t('selectPois'),
              value: 'select',
            },
            {
              text: $t('sortPois'),
              value: 'sort',
            },
          ]"
        />
      </v-col>
    </v-row>
    <v-row
      wrap
      v-if="toggleList === 0"
    >
      <v-col
        fill-height
        xs="12"
        sm="12"
        md="6"
        lg="4"
        xl="3"
        pa-1
        v-for="p in filteredPOIList"
        :key="p.id">
        <place-card
          :place="p"
          @refresh="refresh" />
      </v-col>
    </v-row>
    <v-row
      wrap
      v-if="toggleList === 1"
    >
      <v-col
        fill-height
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        pa-1>
        <places-list
          :places="filteredPOIList"
          :currentStatus="currentStatus"
          :currentAction="currentAction"
          @refresh="refresh" />
      </v-col>
    </v-row>
    <v-row pt-12>
      <v-col xs="12" class="text-xs-center">
        <v-pagination
          v-model="currentPage"
          :length="numberOfPages"
          total-visible="20"
        />
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="toggleList === 0"
        color="accent"
        dark
        fab
        fixed
        bottom
        right
        :to="{ name: 'newplacesEditor', params: { siteId: siteId, site: site } }">
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import PlaceCard from '../components/place/PlaceCard.vue';
import PlacesList from '../components/place/PlacesList.vue';
import * as at from '../store/actionTypes';
import UserNotifications from '../mixins/UserNotifications.vue';

const POIS_PER_PAGE = 30;

export default {
  components: {
    PlaceCard,
    PlacesList,
  },
  mixins: [UserNotifications],
  data() {
    return {
      currentPage: 1,
      currentSearchTerm: '',
      currentStatus: 'enabled',
      currentAction: 'select',
      toggleList: 0,
    };
  },

  computed: {
    ...mapState('poi', [
      'poiList',
      'poisDidLoad',
    ]),
    ...mapGetters('auth', [
      'token',
    ]),
    ...mapGetters('site', [
      'allSites',
    ]),
    searchTerm() {
      return this.currentSearchTerm ? this.currentSearchTerm : '';
    },
    site() {
      return this.allSites[this.siteId];
    },
    siteId() {
      return this.$route.params.siteId;
    },
    loaded() {
      return this.site && this.poisDidLoad;
    },
    filteredPOIList() {
      if (this.searchTerm.length > 2) {
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
        return this.poiList.filter(
          (poi) => poi.name.toLowerCase().includes(
            lowerCaseSearchTerm,
          ),
        );
      }
      return this.poiList.slice(this.currentOffset, this.currentLimit)
        .sort((a, b) => a.order - b.order);
    },
    numberOfPages() {
      let totalPages = 0;
      if (this.searchTerm.length > 2) {
        totalPages = Math.floor(this.filteredPOIList.length / POIS_PER_PAGE);
      } else {
        totalPages = Math.floor(this.poiList.length / POIS_PER_PAGE);
      }
      if (totalPages < 1) {
        return 1;
      }
      if (totalPages % POIS_PER_PAGE !== 0) {
        return totalPages + 1;
      }
      return totalPages;
    },
    currentOffset() {
      return (this.currentPage - 1) * POIS_PER_PAGE;
    },
    currentLimit() {
      return ((this.currentPage - 1) * POIS_PER_PAGE) + POIS_PER_PAGE;
    },
    currentPOIs() {
      return this.poiList.slice(this.currentOffset, this.currentLimit);
    },
    currentStatusItems() {
      let items;
      if (this.toggleList === 0) {
        items = [{
          text: this.$t('filterAll'),
          value: 'all',
        },
        {
          text: this.$t('filterEnabled'),
          value: 'enabled',
        },
        {
          text: this.$t('filterDisabled'),
          value: 'disabled',
        }];
      } else {
        items = [{
          text: this.$t('filterEnabled'),
          value: 'enabled',
        },
        {
          text: this.$t('filterDisabled'),
          value: 'disabled',
        }];
      }
      return items;
    },
  },
  watch: {
    currentStatus() {
      this.fetchPOIs({ siteId: this.siteId, status: this.currentStatus, token: this.token });
    },
  },
  methods: {

    ...mapActions({
      fetchCategories: at.FETCH_CATEGORIES,
      fetchPOIs: 'poi/all',
    }),
    clearPlaces() {
      this.$store.commit('places/clearPlaces');
    },
    async refresh() {
      try {
        await this.fetchPOIs(
          { siteId: this.siteId, status: this.currentStatus, token: this.token },
        );
        await this.fetchCategories({ siteId: this.siteId, token: this.token });
      } catch (error) {
        this.errorNotification(this.$t('notificationErrorFetching'), error);
      }
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitle": "Les lieux d'intérêt",
    "filterAll": "Tous",
    "filterEnabled": "Actifs",
    "filterDisabled": "Désactivés",
    "labelSearch": "Chercher un nom de lieu",
    "notificationErrorFetching": "Impossible de récupérer la liste des lieux.",
    "selectPois": "Selectionner lieux",
    "sortPois": "Trier les lieux"
  },
  "en": {
    "pageTitle": "POIs",
    "filterAll": "All",
    "filterEnabled": "Enabled",
    "filterDisabled": "Disabled",
    "labelSearch": "Search a POI by name",
    "notificationErrorFetching": "Unable to retrieve the POIs list",
    "selectPois": "Select POIs",
    "sortPois": "Sort POIs"
  }
}
</i18n>
