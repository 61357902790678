import { SET_DESTINATIONS } from '../mutationTypes';
import { FETCH_DESTINATIONS } from '../actionTypes';
import { DESTINATIONS_BY_UUID } from '../getterTypes';
import api from '../../services/fetch';
import cfg from '../../config';

export default {
  state: {
    destinations: [],
  },
  getters: {
    [DESTINATIONS_BY_UUID](state) {
      const destObject = {};
      state.destinations.forEach((d) => {
        destObject[d.uuid] = d;
      });
      return destObject;
    },
  },
  mutations: {
    [SET_DESTINATIONS](state, destinations) {
      state.destinations = destinations;
    },
  },
  actions: {
    [FETCH_DESTINATIONS](context, { token }) {
      return new Promise((resolve, reject) => {
        const url = `${cfg.apiUrl}/api/v3/destinations`;
        api.getJSON(url, {}, token).then((response) => {
          context.commit(SET_DESTINATIONS, response);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
};
