<template>
  <v-card>
    <v-card-title>
      {{ $t('title') }}
    </v-card-title>
    <v-card-text>
      <v-dialog
        v-model="dialog"
        max-width="350"
      >
        <v-card>
          <v-card-title class="headline">{{ $t('detachCardTitle') }}</v-card-title>
          <v-card-text>
            {{ $t('detachCardContent') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="secondary"
              text
              @click="dialog = false"
            >
              {{ $t('cancelBtn') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteAll()"
            >
              {{ $t('confirmBtn') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <p>{{ $t('mainContent') }}</p>
      <v-toolbar
        floating
        color="secondary"
        dark>
        <v-text-field
          single-line
          :label="$t('searchLabel')"
          v-model="currentSearchTerm"
        />
        <v-btn icon>
          <v-icon>search</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container grid-list-sm fluid>
        <v-row>
          <v-col
            xs="12"
            sm="6"
            md="4"
            lg="3"
            v-for="p in filteredPOIList"
            :key="p.id"
          >
            <grid-element
              :title="p.name"
              :picture="poiPicture(p)"
            >
              <template v-slot:actions>

                <v-btn
                  icon
                  :disabled="itemIsSelected(p.id)"
                  @click="selectItem(p.id)">
                  <v-icon color="green">check</v-icon>
                </v-btn>
                <v-switch
                  :label="$t('labelOptional')"
                  :input-value="itemIsOptional(p.id)"
                  :disabled="!itemIsSelected(p.id)"
                  @change="itemIsOptionalChanged(p.id)" />
                <v-spacer />
                <v-btn
                  icon
                  :disabled="!itemIsSelected(p.id)"
                  @click="unselectItem(p)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
            </grid-element>
          </v-col>
        </v-row>

        <div class="text-xs-center">
          <v-pagination
            v-model="currentPage"
            :length="numberOfPages"
            total-visible="7"
          />
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-dialog v-model="selector">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text color="primary">{{ $t('orderingBtn') }}</v-btn>
        </template>
        <poi-sorter
          :pois="orderedPois"
          @close="selector = !selector"
          @save="saveSortedPOIs"
        />
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import cfg from '../../config';
import GridElement from '../reward/PlaceDisplayGridElement.vue';
import PoiSorter from './POISorter.vue';

const POIS_PER_PAGE = 12;

export default {
  components: {
    GridElement,
    PoiSorter,
  },
  props: {
    pois: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedPois: {
      type: Array,
      required: true,
    },

    orderedPois: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      currentSearchTerm: '',
      selected: [],
      active: false,
      dialog: false,
      selector: null,
    };
  },
  computed: {
    searchTerm() {
      return this.currentSearchTerm ? this.currentSearchTerm : '';
    },
    filteredPOIList() {
      if (this.searchTerm.length > 2) {
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
        return this.pois.filter(
          (poi) => poi.name.toLowerCase().includes(
            lowerCaseSearchTerm,
          ),
        );
      }
      return this.pois.slice(this.currentOffset, this.currentLimit);
    },
    numberOfPages() {
      let totalPages = 0;
      if (this.searchTerm.length > 3) {
        totalPages = Math.floor(this.filteredPOIList.length / POIS_PER_PAGE);
      } else {
        totalPages = Math.floor(this.pois.length / POIS_PER_PAGE);
      }
      if (totalPages < 1) {
        return 1;
      }
      if (totalPages % POIS_PER_PAGE !== 0) {
        return totalPages + 1;
      }
      return totalPages;
    },
    currentOffset() {
      return (this.currentPage - 1) * POIS_PER_PAGE;
    },
    currentLimit() {
      return ((this.currentPage - 1) * POIS_PER_PAGE) + POIS_PER_PAGE;
    },
    currentPOIs() {
      return this.pois.slice(this.currentOffset, this.currentLimit);
    },
  },
  methods: {
    selectItem(pid) {
      const selection = this.selectedPois ? this.selectedPois : [];
      const poi = {
        poiId: pid,
        order: selection.length + 1,
        isOptional: false,
      };
      selection.push(poi);
      this.$emit('poisupdated', this.resortPOIs(selection));
    },
    unselectItem(poi) {
      const updatedPoisList = this.selectedPois.filter((sp) => poi.id !== sp.poiId);
      this.$emit('poisupdated', this.resortPOIs(updatedPoisList));
    },
    itemIsOptional(pid) {
      const [poi] = this.selectedPois.filter((sp) => pid === sp.poiId);
      if (!poi) {
        return false;
      }
      return poi.isOptional;
    },
    itemIsOptionalChanged(pid) {
      const [poi] = this.selectedPois.filter((sp) => pid === sp.poiId);
      if (!poi) {
        return;
      }
      poi.isOptional = !poi.isOptional;

      const updatedPoisList = this.selectedPois.filter((sp) => pid !== sp.poiId);
      updatedPoisList.push(poi);
      this.$emit('poisupdated', this.resortPOIs(updatedPoisList));
    },
    itemIsSelected(pid) {
      if (!this.selectedPois) {
        return false;
      }
      if (this.selectedPois.length === 0) {
        return false;
      }
      let found = false;
      this.selectedPois.forEach((p) => {
        if (p.poiId === pid) {
          found = true;
        }
      });
      return found;
    },

    resortPOIs(inputPois) {
      const initialSortedPOIs = inputPois.sort((a, b) => a.order - b.order);
      const finalSortedPOIs = [];
      let i = 1;
      let j = 101;
      initialSortedPOIs.forEach((p) => {
        const newPOI = { ...p };
        if (p.isOptional === true) {
          newPOI.order = j;
          j += 1;
        } else {
          newPOI.order = i;
          i += 1;
        }
        finalSortedPOIs.push(newPOI);
      });
      return finalSortedPOIs;
    },
    deleteAll() {
      this.$emit('poisupdated', []);
      this.dialog = false;
    },
    saveSortedPOIs(newPois) {
      this.$emit('sortedpois', this.resortPOIs(newPois));
      this.selector = !this.selector;
    },
    poiPicture(poi) {
      if (poi.pictures && poi.pictures.length > 0) {
        const pics = [...poi.pictures];
        pics.sort((a, b) => a.order - b.order);
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${poi.siteId}/${pics[0].filename}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "labelOptional": "Optionnel",
    "title": "Choisissez les lieux faisant partie du circuit",
    "detachCardTitle": "Détacher tous les lieux d'intérêt ?",
    "detachCardContent": "Ce circuit sera entièrement vide et ne s'affichera plus sur l'application",
    "cancelBtn": "Annuler",
    "confirmBtn": "Supprimer",
    "mainContent": "Choisissez les lieux d'intérêt faisant partie du circuit. Vous pouvez les réorganiser en cliquant sur \"Changer l'ordre des lieux\"",
    "searchLabel": "Rechercher un nom de lieu",
    "orderingBtn": "Changer l'ordre des lieux"
  },
  "en": {
    "labelOptional": "Optional",
    "title": "Choose the POIs attached to this tour",
    "detachCardTitle": "Detach all POIS?",
    "detachCardContent": "The tour will be empty and will not be displayed on the map",
    "cancelBtn": "Cancel",
    "confirmBtn": "Detach",
    "mainContent": "Choose the POIS to attach to this tour. You can reorder them by using the  \"Change POIs order\" button",
    "searchLabel": "Search a POI by name",
    "orderingBtn": "Change POIs order"
  }
}
</i18n>
