<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        Ajouter
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('mainTitle') }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            dark
            text
            :disabled="validate().length > 0 || selectedPoiUuid === null"
            @click="save"
          >
            {{ $t('btnSave') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-form>
        <v-container>
          <v-row>
            <v-col>
              <h3 class="text-h5">{{ $t('placeSettings')}}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert
                v-if="validate().length > 0"
                color="red"
                border="left"
                dark>
                <p>
                  <strong>{{ $t('errorsTitle') }}</strong>
                </p>
                <ul>
                  <li
                    v-for="e in validate()"
                    :key="e">
                    {{ e }}
                  </li>
                </ul>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-datetime-picker
                dateFormat="dd/MM/yyyy"
                :text-field-props="startDateProps"
                :label="$t('labelFieldStartDate')"
                v-model="startDate"
                :date-picker-props="startDatePickerProps"
                :time-picker-props="timeProps">
                <template v-slot:actions="props">
                  <v-btn color="primary" @click.native="props.parent.clearHandler">
                    {{ $t('btnClose') }}
                  </v-btn>
                  <v-btn color="secondary" @click="props.parent.okHandler">
                    {{ $t('btnValidate') }}
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-datetime-picker
                dateFormat="dd/MM/yyyy"
                label="Date de disparition"
                v-model="endDate"
                :date-picker-props="endDatePickerProps"
                :time-picker-props="timeProps">
                <template v-slot:actions="props">
                  <v-btn color="primary" @click.native="props.parent.clearHandler">
                    {{ $t('btnClose') }}
                  </v-btn>
                  <v-btn color="secondary" @click="props.parent.okHandler">
                    {{ $t('btnValidate') }}
                  </v-btn>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col xs="12" sm="6" md="4">
              <v-switch
                v-model="hideOnFirstCheckin"
                :label="$t('labelFieldDisappearOnCheckin')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 class="text-h5">{{ $t('selectPlace')}}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              xs="12"
              sm="6"
              md="4"
              lg="3"
              v-for="p in availablePOIs"
              :key="p.id"
            >
              <grid-element
                :title="p.name"
                :picture="poiPicture(p)"
              >
                <template v-slot:actions>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="selectedPoiUuid = p.uuid">
                    <v-icon :color=" p.uuid === selectedPoiUuid ? 'green' : 'grey'">check</v-icon>
                  </v-btn>

                </template>
              </grid-element>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  parse,
  isBefore,
  isAfter,
  addHours,
} from 'date-fns';
import { v4 as uuid4 } from 'uuid';
import GridElement from '../reward/PlaceDisplayGridElement.vue';
import cfg from '../../config';

export default {
  components: {
    GridElement,
  },
  props: {
    gameId: {
      type: String,
      required: true,
    },
    pois: {
      type: Array,
      required: true,
    },
    existingTemporaryPlaces: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      editedItem: {},
      startDate: new Date(),
      endDate: addHours(new Date(), 1),
      hideOnFirstCheckin: false,
      selectedPoiUuid: null,
      valid: false,
      timeProps: {
        format: '24hr',
      },
      startDatePickerProps: {
        locale: navigator.language || navigator.userLanguage,
        'allowed-dates': (val) => {
          const now = new Date();
          const date = parse(val, 'yyyy-MM-dd', now);
          const minDate = addHours(now, -24);
          return isAfter(date, minDate);
        },
      },
      endDatePickerProps: {
        locale: navigator.language || navigator.userLanguage,
        'allowed-dates': (val) => {
          const date = parse(val, 'yyyy-MM-dd', new Date());
          return isAfter(date, new Date());
        },
      },
      startDateProps: {
        rules: [(v) => !!v || this.$t('ruleStartDate')],
      },
      endDateProps: {
        rules: [(v) => !!v || this.$t('ruleEndDate'), this.checkDate],
      },
    };
  },
  computed: {
    availablePOIs() {
      const ids = [];
      this.existingTemporaryPlaces.forEach((tp) => {
        ids.push(tp.poiId);
      });
      return this.pois.filter((poi) => !ids.includes(poi.uuid));
    },
  },

  methods: {
    async save() {
      const temporaryPlace = {
        id: uuid4(),
        poiId: this.selectedPoiUuid,
        partyId: this.gameId,
        visibleFrom: this.startDate,
        visibleUntil: this.endDate,
        hideOnFirstCheckin: this.hideOnFirstCheckin,
      };
      this.$emit('added', temporaryPlace);
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
    checkDate(v) {
      return (
        isBefore(this.startDate, v) || this.$t('ruleEndDateAfterStartDate')
      );
    },
    poiPicture(poi) {
      if (poi.pictures && poi.pictures.length > 0) {
        const pics = [...poi.pictures];
        pics.sort((a, b) => a.order - b.order);
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${poi.siteId}/${pics[0].filename}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
    validate() {
      const errs = [];
      if (this.startDate === null) {
        errs.push(this.$t('ruleStartDate'));
      }
      if (this.endDate === null) {
        errs.push(this.$t('ruleEndDate'));
      }
      if (isBefore(this.endDate, this.startDate)) {
        errs.push(this.$t('ruleEndDateAfterStartDate'));
      }
      return errs;
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "btnClose": "Fermer",
      "btnValidate": "Valider",
      "mainTitle": "Ajouter un lieu temporaire à la partie en cours",
      "btnSave": "Enregistrer",
      "labelFieldStartDate": "Date d'apparition",
      "ruleStartDate": "Veuillez choisir une date de début",
      "ruleEndDate": "Veuillez choisir une date de fin",
      "ruleEndDateAfterStartDate": "Veuillez choisir une date de fin postérieure à la date de début",
      "errorsTitle": "Les erreurs suivantes empêchent d'enregistrer le lieu temporaire :",
      "placeSettings": "1. Définissez les réglages du lieu",
      "selectPlace": "2. Choisissez le lieu concerné",
      "labelFieldDisappearOnCheckin": "Faire disparaître le lieu au premier checkin"
    },
    "en": {
      "btnClose": "Close",
      "btnValidate": "Save",
      "mainTitle": "Add a temporary place to the current game",
      "btnSave": "Save",
      "labelFieldStartDate": "Activation date",
      "ruleStartDate": "Please choose a start date",
      "ruleEndDate": "Please choose an end date",
      "ruleEndDateAfterStartDate": "Please choose an end date after the start date",
      "errorsTitle": "The following errors prevent saving the place:",
      "placeSettings": "1. Define the place parameters",
      "selectPlace": "2. Choose the place to make temporary",
      "labelFieldDisappearOnCheckin": "Disable the place after the first checkin"
    }

  }
</i18n>
