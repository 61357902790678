/* eslint-disable no-param-reassign */
import api from '../../services/poi';
import pictureApi from '../../services/picture';
import audioApi from '../../services/audio';

export default {
  state: {
    isLoading: false,
    poiList: [],
    poisDidLoad: false,
    poisFailedToLoad: false,
  },
  getters: {
    byId(state) {
      const poiObject = {};
      state.poiList.forEach((p) => {
        poiObject[p.id] = p;
      });
      return poiObject;
    },
    byUuid(state) {
      const poiObject = {};
      state.poiList.forEach((p) => {
        poiObject[p.uuid] = p;
      });
      return poiObject;
    },
  },
  mutations: {
    willLoadPOIs(state) {
      state.poisFailedToLoad = false;
      state.isLoading = true;
    },
    didLoadPOIs(state, poiList) {
      state.poiList = poiList;
      state.isLoading = false;
      state.poisDidLoad = true;
      state.didFailToLoadPOIs = false;
    },
    didFailToLoadPOIs(state) {
      state.isLoading = false;
      state.poisFailedToLoad = true;
    },
    removePOI(state, poiId) {
      state.poiList = state.poiList.filter((p) => p.id !== poiId);
    },

  },
  actions: {
    all({ commit }, { siteId, token, status = 'enabled' }) {
      return new Promise((resolve, reject) => {
        api.allPOIs(siteId, status, token).then((poiList) => {
          commit('didLoadPOIs', poiList);
          resolve(poiList);
        }).catch((error) => {
          commit('didFailToLoadPOIs');
          reject(error);
        });
      });
    },
    delete({ commit }, { siteId, poiId, token }) {
      return new Promise((resolve, reject) => {
        api.deletePOI(siteId, poiId, token).then(() => {
          commit('removePOI', poiId);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    create({ dispatch }, { siteId, poi, token }) {
      return new Promise((resolve, reject) => {
        api.createPOI(siteId, poi, token).then((newPOI) => {
          dispatch('all', { siteId, token });
          resolve(newPOI);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    update({ dispatch }, { siteId, poi, token }) {
      return new Promise((resolve, reject) => {
        api.updatePOI(siteId, poi, token).then((updatedPOI) => {
          dispatch('all', { siteId, token });
          resolve(updatedPOI);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    createPicture({ dispatch }, {
      siteId, poiID, picture, token,
    }) {
      return new Promise((resolve, reject) => {
        pictureApi.PostMetadata(
          siteId,
          'poi_picture',
          poiID,
          picture,
          token,
        ).then((response) => {
          const formData = new FormData();
          formData.append('picture', picture.file, picture.name);
          pictureApi.PutFile(siteId, response.uuid, formData, token).then(() => {
            dispatch('all', { siteId, token }).then(() => {
              resolve();
            }).catch((error) => {
              reject(error);
            });
          });
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updatePicture({ dispatch }, { siteId, picture, token }) {
      return new Promise((resolve, reject) => {
        pictureApi.PutMetadata(
          siteId,
          picture,
          token,
        ).then(() => {
          dispatch('all', { siteId, token }).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deletePicture({ dispatch }, { siteId, pictureId, token }) {
      return new Promise((resolve, reject) => {
        pictureApi.Delete(siteId, pictureId, token).then(() => {
          dispatch('all', { siteId, token }).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }).catch((error) => {
          reject(error);
        });
      });
    },
    createAudioDocument(ctx, {
      destinationId,
      parentType,
      parentID,
      audioDoc,
      token,
    }) {
      return new Promise((resolve, reject) => {
        audioApi.PostMetadata(
          destinationId,
          parentType,
          parentID,
          audioDoc,
          token,
        ).then((response) => {
          audioApi.PutFile(destinationId, response.id, audioDoc.file, token).then((res2) => {
            resolve(res2);
          });
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateAudioDocument(ctx, { destinationId, document, token }) {
      return new Promise((resolve, reject) => {
        audioApi.PutMetadata(destinationId, document, token).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    deleteAudioDocument(ctx, { destinationId, docId, token }) {
      return new Promise((resolve, reject) => {
        audioApi.DeleteDocument(destinationId, docId, token).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
  namespaced: true,
};
