<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-right mb-4 py-0" style="min-height: 40px;">
        <template>
          <place-disabler
            v-if="dislayDisabler === true"
            :name="placeName"
            :is-deleting="dislayDisabler"
            :count="poisSelected.length"
            @disable="desactivatePlaces()" />
        </template>
        <v-btn
          v-if="displayActivateBtn"
          @click="activatePlace"
          text
          color="blue">{{$t('activateBtn')}}
        </v-btn>
        <v-btn
          v-if="displayUpdateOrderBtn"
          @click="updateOrder"
          text
          color="blue">{{$t('updateBtn')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-list class="py-0">
        <draggable v-if="displaySort" v-model="draggablePlaces" group="people" @start="drag = true" @end="drag = false, displayUpdateOrderBtn = true">
          <div v-for="(p, index) in draggablePlaces" :key="p.id">
            <template>
              <v-list-item style="cursor: move">
                <div class="py-1">
                  <v-img
                    contain
                    :lazy-src="picture(p)"
                    height="40"
                    width="40"
                    :src="picture(p)"
                  />
                </div>
                <v-list-item-title class="text-left ml-4">
                  {{ p.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon
                    color="grey lighten-1"
                  >
                    mdi-drag
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < places.length - 1"
                :key="index"
              />
            </template>
          </div>
        </draggable>
        <v-list-item-group
          v-if="displaySelect"
          active-class="pink--text"
          v-model="poisSelected"
          multiple
        >
          <template v-for="(p, index) in places">
            <v-list-item
              :key="p.id">
              <template v-slot:default="{ active }">
                <div class="py-1">
                  <v-img
                    contain
                    :lazy-src="picture(p)"
                    height="40"
                    width="40"
                    :src="picture(p)"
                  />
                </div>
                <v-list-item-title class="text-left ml-4">
                  {{ p.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon
                    v-if="!active"
                    color="grey lighten-1"
                  >
                    mdi-checkbox-blank-outline
                  </v-icon>
                  <v-icon
                    v-else
                    color="pink--text"
                  >
                    mdi-checkbox-marked
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              v-if="index < places.length - 1"
              :key="index"
            />
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import draggable from 'vuedraggable';
import cfg from '../../config';
import PlaceDisabler from './PlaceDisabler.vue';
import poiService from '../../services/poi';
import UserNotifications from '../../mixins/UserNotifications.vue';

export default {
  props: {
    places: {
      type: Array,
      required: true,
    },
    currentStatus: {
      type: String,
      required: true,
    },
    currentAction: {
      type: String,
      required: true,
    },
  },
  components: {
    PlaceDisabler,
    draggable,
  },
  mixins: [UserNotifications],
  data: () => ({
    poisSelected: [],
    draggablePlaces: [],
    displayActivateBtn: false,
    dislayDisabler: false,
    displayUpdateOrderBtn: false,
  }),
  computed: {
    ...mapGetters('auth', [
      'token',
    ]),
    placeName() {
      if (this.poisSelected.length === 1) {
        return this.places[this.poisSelected[0]].name;
      }
      return this.$t('MultiplePois');
    },
    displaySort() {
      if (this.currentAction === 'sort' && this.currentStatus === 'enabled') {
        return true;
      }
      return false;
    },
    displaySelect() {
      if (this.currentAction === 'select' || this.currentStatus === 'disabled') {
        return true;
      }
      return false;
    },
  },
  methods: {
    picture(place) {
      if (place.pictures.length > 0) {
        const pics = [...place.pictures];
        pics.sort((a, b) => a.order - b.order);
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${place.siteId}/${pics[0].filename}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
    desactivatePlaces() {
      this.poisSelected.forEach(async (poiIdx) => {
        const p = this.places[poiIdx];
        try {
          const place = await poiService.poiById(p.id, this.token);
          place.enabled = false;
          await poiService.updatePOI(place.siteId, place, this.token);
          this.successNotification(this.$t('notificationDeactivateSuccess'));
          const idx = this.draggablePlaces.indexOf(p);
          this.draggablePlaces.splice(idx, 1);
          this.dislayDisabler = false;
          this.poisSelected = [];
          this.$emit('refresh');
        } catch (error) {
          this.errorNotification(this.$t('notificationDesactivateError'));
          Sentry.captureException(error);
        }
      });
    },
    activatePlace() {
      this.poisSelected.forEach(async (poiIdx) => {
        const p = this.places[poiIdx];
        try {
          const place = await poiService.poiById(p.id, this.token);
          place.enabled = true;
          await poiService.updatePOI(place.siteId, place, this.token);
          this.successNotification(this.$t('notificationActivateSuccess'));
          this.displayActivateBtn = false;
          this.poisSelected = [];
          this.$emit('refresh');
        } catch (error) {
          this.errorNotification(this.$t('notificationActivateError'));
          Sentry.captureException(error);
        }
      });
    },
    updateOrder() {
      this.draggablePlaces.forEach(async (place, idx) => {
        let succes = null;
        const newPlace = place;
        newPlace.order = idx + 1;
        try {
          await poiService.updatePOI(newPlace.siteId, place, this.token);
          this.$emit('refresh');
          if (this.draggablePlaces.length === idx + 1) {
            succes = true;
            this.notifUpdateOrder(succes);
          }
        } catch (error) {
          succes = false;
          this.notifUpdateOrder(succes);
          this.$emit('refresh');
          Sentry.captureException(error);
        }
      });
    },
    notifUpdateOrder(succes) {
      if (succes === false) {
        this.errorNotification(this.$t('notificationNewOrderError'));
        this.displayUpdateOrderBtn = false;
      }
      if (succes === true) {
        this.successNotification(this.$t('notificationNewOrderSuccess'));
        this.displayUpdateOrderBtn = false;
      }
    },

  },
  watch: {
    poisSelected(value) {
      if (value.length > 0) {
        if (this.currentStatus === 'enabled') {
          this.dislayDisabler = true;
        } else {
          this.displayActivateBtn = true;
        }
      } else {
        this.dislayDisabler = false;
        this.displayActivateBtn = false;
      }
    },
    places(value) {
      this.draggablePlaces = value;
    },
    currentAction(value) {
      if (value === 'sort') {
        this.poisSelected = [];
        this.draggablePlaces = this.places;
      } if (value === 'select') {
        this.displayUpdateOrderBtn = false;
      }
    },
  },
  mounted() {
    this.draggablePlaces = this.places;
  },
};
</script>
<i18n>
{
  "fr": {
    "notificationDeactivateSuccess": "Lieu désactivé avec succès",
    "notificationActivateSuccess": "Lieu activé avec succès",
    "notificationActivateError": "Erreur lors de l'activation",
    "notificationDesactivateError": "Erreur lors de la désactivation",
    "notificationNewOrderSuccess": "Ordre du lieu mis à jour",
    "notificationNewOrderError": "Erreur de la mise à jour de l'ordre des lieux",
    "activateBtn": "Activer",
    "MultiplePois": "Ces lieux",
    "updateBtn": "Mettre à jour"
  },
  "en": {
    "notificationDeactivateSuccess": "POI disabled",
    "notificationActivateSuccess": "POI enabled",
    "notificationActivateError": "Activation error",
    "notificationDesactivateError": "Desactivation error",
    "notificationNewOrderSuccess": "Updated POI order",
    "notificationNewOrderSuccess": "POI order update error",
    "activateBtn": "Activate",
    "MultiplePois": "These places",
    "updateBtn": "Update"
  }
}
</i18n>
<style>
