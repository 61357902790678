<template>
  <v-dialog
    ref="dialog"
    v-model="timeDialog"
    :return-value.sync="time"
    persistent
    full-width
    width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        slot="activator"
        v-model="time"
        :label="label"
        :error-messages="validationErrors"
        prepend-icon="access_time"
        readonly
        v-on="on" />
    </template>
    <v-time-picker
      v-if="timeDialog"
      v-model="time"
      format="24hr"
      full-width>
      <v-spacer />
      <v-btn text color="primary" @click="$refs.dialog.save(time)">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</template>
<script>
export default {
  model: {
    prop: 'time',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    initialTime: {
      type: String,
      required: false,
      default: null,
    },

  },
  data() {
    return {
      time: this.initialTime,
      timeDialog: false,
    };
  },
  watch: {
    time() {
      this.$emit('change', this.time);
    },
  },
  computed: {
    validationErrors() {
      if (this.time === null) {
        return this.$t('validationError');
      }
      return null;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "validationError": "Ce champ est obligatoire"
  },
  "en": {
    "validationError": "This field is required"
  }
}
</i18n>
