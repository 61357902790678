import http from './axios';

const getEditorsForOrg = (orgId, token) => new Promise((resolve, reject) => {
  http.get(
    `/api/v3/organizations/${orgId}/editors`,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    console.log(res);
    resolve(res.data);
  })
    .catch((error) => {
      reject(error);
    });
});

const addEditor = ((orgId, editor, token) => new Promise((resolve, reject) => {
  http.post(
    `/api/v3/organizations/${orgId}/editors`,
    editor,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then((res) => {
    resolve(res.data);
  }).catch((error) => {
    reject(error);
  });
}));

const deleteEditor = ((editorId, token) => new Promise((resolve, reject) => {
  http.delete(
    `/api/v3/editors/${editorId}`,
    { headers: { Authorization: `BEARER ${token}` } },
  ).then(() => {
    resolve();
  }).catch((error) => reject(error));
}));

export {
  getEditorsForOrg,
  addEditor,
  deleteEditor,
};
