import http from './fetch';
import cfg from '../config';

const getClues = async (questionId, token) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/clues?question_id=${questionId}`;
  const res = await http.getJSON(
    url,
    {},
    token,
  );
  return res;
};

const putClue = async (clue, token) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/clues/${clue.id}`;
  const res = await http.putJSON(
    url,
    clue,
    token,
  );
  return res;
};

const deleteClue = async (clueId, token) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/clues/${clueId}`;
  await http.deleteJSON(
    url,
    token,
  );
};
const postClues = async (clue, token) => {
  const url = `${cfg.gameApiUrl}/gameapi/v1/clues`;
  const res = await http.postJSON(
    url,
    clue,
    token,
  );
  return res;
};

export {
  getClues, deleteClue, postClues, putClue,
};
