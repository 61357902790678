<template>
  <div>
    <base-card
      :title="title"
      :description="''"
      :picture="picture"
      :right-button-title="$t('labelEditBtn')"
      right-button-color="blue"
      :left-button-title="''"
      @rightbuttonclicked="onEditButtonClicked">
      <template slot="content">
        <v-chip
          label
          small
          color="green"
          text-color="white">
          <v-icon small>translate</v-icon>
          {{ $tc('labelTranslations', destination.locales ? destination.locales.length : 0) }}

        </v-chip> &nbsp;
        <v-chip
          v-if="displayCustomerType === true"
          label
          small
          :color="typeChipContent.color"
          text-color="white">
          <v-icon small>{{ typeChipContent.icon }}</v-icon>
          &nbsp;{{ typeChipContent.text}}

        </v-chip> &nbsp;
        <v-chip
          v-if="destination.platformType"
          label
          small
          color="yellow">{{ destination.platformType }}</v-chip>
      </template>
    </base-card>
  </div>
</template>

<script>
import cfg from '../../config';
import BaseCard from '../base/BaseCard.vue';
import UserNotifications from '../../mixins/UserNotifications.vue';

export default {
  components: { BaseCard },
  mixins: [UserNotifications],

  props: {
    destination: {
      type: Object,
      required: true,
    },
    displayCustomerType: {
      Type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    title() {
      return this.destination.name;
    },
    picture() {
      if (this.destination.picture) {
        return `${cfg.imagesBaseUrl}${cfg.baseUrl}/${this.destination.id}/${this.destination.picture}`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
    typeChipContent() {
      const chip = {
        color: 'grey',
        icon: 'help',
        text: this.$t('customerTypeUnknwown'),
      };

      switch (this.destination.customerType) {
        case 'dotmap':
          chip.color = 'pink';
          chip.icon = 'mdi-pin';
          chip.text = this.$t('customerTypeDotmap');
          break;
        case 'school':
          chip.color = 'blue';
          chip.icon = 'school';
          chip.text = this.$t('customerTypeSchool');
          break;
        case 'agency':
          chip.color = '#0d9149';
          chip.icon = 'domain';
          chip.text = this.$t('customerTypeAgency');
          break;
        case 'final_event':
          chip.color = '#f52a2a';
          chip.icon = 'mdi-calendar';
          chip.text = this.$t('customerTypeEvent');
          break;
        case 'final_tourism':
          chip.color = 'orange';
          chip.icon = 'mdi-glass-cocktail';
          chip.text = this.$t('customerTypeTourism');
          break;
        case 'demo':
          chip.color = '#722c9c';
          chip.icon = 'gamepad';
          chip.text = this.$t('customerTypeDemo');
          break;
        default: break;
      }

      return chip;
    },
  },
  methods: {
    onEditButtonClicked() {
      this.$store.commit('site/setCurrentSiteId', this.destination.id);
      this.$router.push({
        name: 'destinationDetails',
        params: {
          destinationId: this.destination.id,
        },

      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "labelEditBtn":"Modifier",
    "labelTranslations": "Pas de traduction | 1 traduction | {count} traductions",
    "customerTypeUnknwown": "non défini",
    "customerTypeTourism": "Client final tourisme",
    "customerTypeEvent": "Evènement client",
    "customerTypeAgency": "Agence évènements",
    "customerTypeSchool": "Ecole",
    "customerTypeDotmap": "Dotmap",
    "customerTypeDemo": "Démo"
  },
  "en": {
    "labelEditBtn":"Edit",
    "labelTranslations": "No translation | 1 translation | {count} translations",
    "customerTypeUnknwown": "Undefined",
    "customerTypeTourism": "Tourism",
    "customerTypeEvent": "Events (direct customer)",
    "customerTypeAgency": "Event agency",
    "customerTypeSchool": "School",
    "customerTypeDotmap": "Dotmap",
    "customerTypeDemo": "Demo"
  }
}
</i18n>
