<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="9">
            <v-slider
              v-model="points"
              :min="min"
              :max="max"
              :step="1"
              :label="$t('labelPoints')"
              thumb-label="always"
            />
          </v-col>
          <v-col cols="3" md="3">
            <v-text-field
              v-model="points"
              class="mt-0"
              type="number"
            />
          </v-col>
        </v-row>
        <v-divider />

        <v-row>
          <v-col>
            <h4>{{ $t('titleLosePointsAfterCheckin') }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    inline
                    v-bind="attrs"
                    v-on="on"
                    icon
                    x-small
                    color="secondary"> <v-icon>help</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('alertLosePoints') }}</span>
              </v-tooltip>
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-switch
              v-model="losePointsAfterFirstCheckin"
              :label="$t('labelLosePoints')"
              :disabled="points < 0"
            />

          </v-col>
        </v-row>
        <v-divider />

        <v-row>
          <v-col>
            <h4>{{$t('titleBlockAfterCheckin')}}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    inline
                    v-bind="attrs"
                    v-on="on"
                    icon
                    x-small
                    color="secondary"> <v-icon>help</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('alertBlockAfterCheckin') }}</span>
              </v-tooltip>
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" xs="12" sm="12" md="7">
            <v-switch
              v-model="blockAfterCheckin"
              :label="$t('labelBlockAfterCheckin')"
            />

          </v-col>
          <v-col cols="12" xs="12" sm="12" offset-md="2" md="3">
            <v-text-field
              type="number"
              v-model.number="blockDelay"
              :disabled="blockAfterCheckin === false"
              :label="$t('labelBlockDelay')" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initialPoints: {
      type: Number,
      required: false,
      default: 100,
    },
    initialLosePointsAfterCheckin: {
      type: Boolean,
      required: true,
    },
    initialBlockAfterCheckin: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialBlockDelay: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 3000,
    },
  },
  data() {
    return {
      points: this.initialPoints,
      losePointsAfterFirstCheckin: this.initialLosePointsAfterCheckin,
      blockAfterCheckin: this.initialBlockAfterCheckin,
      blockDelay: this.initialBlockDelay,
    };
  },
  methods: {
    update() {
      this.$emit(
        'update',
        {
          points: this.points,
          losePointsAfterFirstCheckin: this.losePointsAfterFirstCheckin,
          blockAfterCheckin: this.blockAfterCheckin,
          blockDelay: this.blockDelay,

        },
      );
    },
  },
  watch: {
    points() {
      if (this.points < 0) {
        this.losePointsAfterFirstCheckin = false;
      }
      this.update();
    },
    losePointsAfterFirstCheckin() {
      this.update();
    },
    blockAfterCheckin() {
      this.update();
    },
    blockDelay() {
      this.update();
    },
  },
};
</script>
<i18n>
  {
    "fr": {
      "labelPoints": "Points",
      "labelLosePoints": "Faire perdre des points après le premier checkin ?",
      "alertLosePoints": "Lorsque cette option est activée, la première équipe d'une partie gagnera des points normalement. Les équipes suivantes perdront les points indiqués, et seront informées du \"piège\". Cette option ne peut être activée que si le nombre de points est > 0",
      "labelBlockDelay": "Temps de blocage de l'équipe (en secondes)",
      "labelBlockAfterCheckin": "Bloquer temporairement toute action de l'équipe après un checkin sur ce lieu",
      "alertBlockAfterCheckin": "Lorsque cette option est activée, l'équipe ne peut plus ni faire de checkin, ni répondre aux défis sur aucun lieu pendant le délai précisé",
      "titleLosePointsAfterCheckin": "Piéger les checkins",
      "titleBlockAfterCheckin": "Blocage temporaire des joueurs"
    },
    "en": {
      "labelPoints": "Points",
      "labelLosePoints": "Lose points after first checkin?",
      "alertLosePoints": "When this option is on, the first team earns points normally.All other teams will lose points and will be informed of the trap. This option is only usable when points are > 0",
      "labelBlockDelay": "Team blocking duration (in seconds)",
      "labelBlockAfterCheckin": "Temporary Block all actions from a team after checkin here",
      "alertBlockAfterCheckin": "When this option is active, the team cannot do any action (checkin or challenges) on any place during the given period"
    }
  }
</i18n>
