<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-container>
        <v-layout row wrap>
          <v-flex xs12>
            <v-switch
              v-model="hasLimitedStock"
              @change="stockChanged"
              :label="$t('limitedQtyLabel')" />
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="hasLimitedStock">
          <v-flex xs3 offset-xs2>
            <v-text-field
              v-model.number="initialQty"
              v-on:input="stockChanged"
              :label="$t('initialQtyLabel')"
              type="number"
              required />
          </v-flex>
          <v-flex xs3 offset-xs1>
            <v-text-field
              v-model.number="remainingQty"
              v-on:input="stockChanged"
              :label="$t('remainingQtyLabel')"
              type="number"
              required />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    limitedStock: {
      type: Boolean,
      required: true,
      default: false,
    },
    initialQuantity: {
      type: Number,
      required: false,
      default: 20,
    },
    remainingQuantity: {
      type: Number,
      required: false,
      default: 20,
    },
  },
  data() {
    return {
      hasLimitedStockValue: null,
      initialQuantityValue: null,
      remainingQuantityValue: null,
    };
  },

  computed: {
    hasLimitedStock: {
      set(value) {
        this.hasLimitedStockValue = value;
      },
      get() {
        if (this.hasLimitedStockValue === null) {
          return this.limitedStock;
        }
        return this.hasLimitedStockValue;
      },
    },
    initialQty: {
      set(value) {
        this.initialQuantityValue = value;
      },
      get() {
        if (this.initialQuantityValue === null) {
          return this.initialQuantity;
        }
        return this.initialQuantityValue;
      },
    },
    remainingQty: {
      set(value) {
        this.remainingQuantityValue = value;
      },
      get() {
        if (this.remainingQuantityValue === null) {
          return this.remainingQuantity;
        }
        return this.remainingQuantityValue;
      },
    },
  },
  methods: {
    stockChanged() {
      this.$emit('stockchanged', {
        hasLimitedStock: this.hasLimitedStock,
        initialQuantity: this.initialQty,
        remainingQuantity: this.remainingQty,
      });
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "limitedQtyLabel": "Stock limité ?",
    "initialQtyLabel": "Quantité initiale",
    "remainingQtyLabel": "Quantité restante"
  },
  "en": {
    "limitedQtyLabel": "Limited quantity?",
    "initialQtyLabel": "Initial quantity",
    "remainingQtyLabel": "Remaining quantity"
  }
}
</i18n>
