<template>
  <div>
    <base-card
      :title="title"
      :description="description"
      :picture="picture"
      :left-button-title="$t('editBtn')"
      :right-button-title="$t('deleteBtn')"
      @rightbuttonclicked="deleteDialogOpen = true"
      @leftbuttonclicked="onEditButtonClicked">
      <template slot="content">
        <v-chip label small color="green" text-color="white">
          <v-icon>translate</v-icon>
          {{ description }}

        </v-chip>
      </template>
    </base-card>

    <v-dialog
      v-model="deleteDialogOpen"
      max-width="50%">
      <v-card>
        <v-card-title class="headline">{{ $t('deleteDialogTitle')}}</v-card-title>
        <v-card-text>
          {{ $t('deleteDialogContent', { name: reward.name }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="deleteDialogOpen = false"
          >{{ $t('deleteDialogBtnCancel') }}
          </v-btn>

          <v-btn
            color="pink"
            text
            :loading="isDeleting"
            @click="deleteReward()"
          >{{ $t('deleteDialogBtnConfirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cfg from '../../config';
import BaseCard from '../base/BaseCard.vue';
import UserNotifications from '../../mixins/UserNotifications.vue';

import * as at from '../../store/actionTypes';

export default {
  components: { BaseCard },
  mixins: [UserNotifications],
  props: {
    reward: {
      type: Object,
      required: true,
    },
    destinationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleteDialogOpen: false,
      isDeleting: false,
    };
  },
  computed: {
    title() {
      return this.reward.name;
    },
    description() {
      if (!this.reward.locales) {
        return this.$tc('translationsCount', 0);
      }
      const localesCount = Object.keys(this.reward.locales).length;
      return this.$tc('translationsCount', localesCount);
    },
    picture() {
      if (this.reward.pictures.length > 0) {
        const pics = [...this.reward.pictures];
        pics.sort((a, b) => a.order - b.order);
        return pics[0].url ? pics[0].url : `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
      }
      return `${cfg.imagesBaseUrl}${cfg.baseUrl}/default.png`;
    },
  },
  methods: {
    async deleteReward() {
      this.isDeleting = true;
      const token = this.$store.getters['auth/token'];
      try {
        await this.$store.dispatch(
          at.DELETE_REWARD,
          { rewardId: this.reward.id, token },
        );
        await this.$store.dispatch(
          at.FETCH_REWARDS,
          { destinationId: this.reward.destinationId, token },
        );
        this.isDeleting = false;
        this.deleteDialogOpen = false;
        this.successNotification(this.$t('successDelete'));
      } catch (error) {
        this.isDeleting = false;
        this.deleteDialogOpen = false;
        this.errorNotification(this.$t('errorDelete'), error);
      }
    },
    onEditButtonClicked() {
      this.$router.push(`/destinations/${this.destinationId}/rewards/${this.reward.id}`);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "translationsCount": "Pas de traduction | Une traduction | {count} traductions",
    "deleteDialogTitle": "Supprimer cette récompense ?",
    "deleteDialogContent": "Vous allez supprimer {name}. Cette action est irréversible",
    "deleteDialogBtnCancel": "Annuler",
    "deleteDialogBtnConfirm": "Supprimer",
    "deleteBtn": "Supprimer",
    "editBtn": "Modifier",
    "errorDelete": "Impossible de supprimer la récompense",
    "successDelete": "Récompense supprimée"
  },
  "en": {
    "translationsCount": "No translation | One translation | {count} translations",
    "deleteDialogTitle": "Delete this reward?",
    "deleteDialogContent": "{name} will be deleted. This cannot be undone",
    "deleteDialogBtnCancel": "Cancel",
    "deleteDialogBtnConfirm": "Delete",
    "deleteBtn": "Delete",
    "editBtn": "Edit",
    "errorDelete": "Unable to remove the reward",
    "successDelete": "Reward removed"
  }
}
</i18n>
