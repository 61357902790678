<template>
  <v-card flat>
    <v-toolbar dark color="primary">
      <v-toolbar-title>{{ $t('toolbarTitle') }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col>
          <v-card flat max-width="700" class="mx-auto">
            <v-img
              v-if="url"
              class="mx-auto"
              :src="url"
              max-width="450"
              aspect-ratio="1.78" />
            <v-img
              v-else-if="showImagePreview === true && imagePreview !== ''"
              class="mx-auto"
              :src="imagePreview"
              max-width="450"
              aspect-ratio="1.78" />
            <p
              class="text-center caption">
              {{ $t('caption') }}
            </p>
            <v-file-input
              v-model="file"
              v-if="newPicture === true"
              ref="file"
              @change="handleFileUpload"
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              :label="$t('inputFileLabel')"
            />
            <v-text-field
              :label="$t('descriptionLabel')"
              :hint="$t('descriptionHint')"
              persistent-hint
              v-model="description"
            />

            <v-text-field
              :label="$t('authorLabel')"
              :hint="$t('authorHint')"
              persistent-hint
              v-model="author"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="$emit('close')">{{ $t('btnCancel') }}</v-btn>
      <v-btn text color="secondary" @click="save">{{ $t('btnSave') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    newPicture: {
      type: Boolean,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
    existingDescription: {
      type: String,
      required: false,
      default: null,
    },
    existingAuthor: {
      type: String,
      required: false,
      default: null,
    },
    existingUuid: {
      type: String,
      required: false,
      default: null,
    },
    existingOrder: {
      type: Number,
      required: false,
      default: 0,
    },
    existingFile: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      descriptionValue: null,
      authorValue: null,
      imagePreview: '',
      file: null,
      showImagePreview: false,
    };
  },
  computed: {
    description: {
      set(value) {
        this.descriptionValue = value;
      },
      get() {
        if (this.descriptionValue === null) {
          if (this.newPicture === true) {
            return '';
          }
          return this.existingDescription;
        }
        return this.descriptionValue;
      },
    },
    author: {
      set(value) {
        this.authorValue = value;
      },
      get() {
        if (this.authorValue === null) {
          if (this.newPicture === true) {
            return '';
          }
          return this.existingAuthor;
        }
        return this.authorValue;
      },
    },
  },
  mounted() {
    if (this.newPicture === true) {
      this.showImagePreview = false;
      this.file = null;
      this.imagePreview = '';
    }
  },
  methods: {
    save() {
      const newValue = {
        isNew: this.newPicture === true,
        altText: this.description,
        author: this.author,
        uuid: this.existingUuid,
        order: this.existingOrder,
      };
      if (this.newPicture === true) {
        newValue.uuid = uuidv4();
        newValue.filename = this.file.name;
        newValue.file = this.file;
      } else {
        newValue.filename = this.existingFile;
      }
      this.descriptionValue = null;
      this.authorValue = null;
      this.$emit('save', newValue);
    },
    handleFileUpload() {
      this.showImagePreview = false;
      this.imagePreview = '';
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.showImagePreview = true;
        this.imagePreview = reader.result;
      }, false);
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name.toLowerCase())) {
          reader.readAsDataURL(this.file);
        }
      } else {
        this.imagePreview = null;
        this.showImagePreview = false;
      }
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "toolbarTitle": "Paramètres de l'image",
    "caption": "Affichage de l'image selon le format qu'elle aura sur le site",
    "inputFileLabel": "Sélectionnez une photo",
    "descriptionLabel": "Description*",
    "descriptionHint": "Entrez une description qui sera utilisée en texte alternatif",
    "authorLabel": "Auteur",
    "authorHint": "Ajoutez un auteur (facultatif)",
    "btnCancel": "Annuler",
    "btnSave": "Enregistrer"
  },
  "en": {
    "toolbarTitle": "Picture parameters",
    "caption": "Image preview",
    "inputFileLabel": "Select a picture",
    "descriptionLabel": "Description*",
    "descriptionHint": "Alternative text",
    "authorLabel": "Author",
    "authorHint": "Enter the author (not required)",
    "btnCancel": "Cancel",
    "btnSave": "Save"
  }
}
</i18n>
