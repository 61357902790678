<script>
import * as Sentry from '@sentry/vue';
import cfg from '../config';

export default {
  methods: {
    errorNotification(message, error = null) {
      this.notification(message, 'error');
      if (error !== null) {
        if (cfg.useConsoleLogForErrors === true) {
          console.log(error);
        } else {
          Sentry.captureException(error);
        }
      }
    },
    successNotification(message) {
      this.notification(message, 'check_circle');
    },
    notification(message, icon) {
      this.$toasted.show(
        message,
        { icon, duration: 3000 },
      );
    },
  },
};
</script>
