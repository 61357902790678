<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('title') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-list three-line>
        <v-list-item v-for="d in documents" :key="d.uuid">
          <v-list-item-avatar>
            <flag :iso="flagIconClass(d.lang)" />
          </v-list-item-avatar>
          <v-list-item-content @click="openEditor(d)">
            <v-list-item-title>{{ d.title }}</v-list-item-title>
            <v-list-item-subtitle> {{ d.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="openEditor(d)">
              <v-icon color="secondary lighten-1">edit</v-icon>
            </v-btn>
            <v-btn icon @click="openDeletionDialog(d)">
              <v-icon color="primary lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="secondary"
        @click.stop="openEditor()"
      >
        {{ $t('btnAdd') }}
      </v-btn>
    </v-card-actions>
    <document-editor
      :open="editorOpen"
      :audio-document="currentDocument"
      @close="closeEditor"
      @documentupdated="onDocumentUpdated" />
    <v-dialog v-model="deleteDialog" max-width="350">
      <v-card>
        <v-card-title class="headline">{{ $t('removeTitle') }}</v-card-title>
        <v-card-text>
          {{ $t('removeSubtitle') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('btnCancel') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirmDeletion"
          >
            {{ $t('btnDelete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import DocumentEditor from './DocumentEditor.vue';

export default {
  components: { DocumentEditor },
  props: {
    documents: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentDocument: null,
      editorOpen: false,
      deleteDialog: false,
    };
  },
  methods: {
    flagIconClass(locale) {
      const countryCode = locale.substring(locale.indexOf('_') + 1).toLowerCase();
      return `flag-icon flag-icon-${countryCode}`;
    },
    openEditor(document) {
      if (document) {
        this.currentDocument = { ...document };
      } else {
        this.currentDocument = this.defaultDocument();
      }
      this.editorOpen = true;
    },
    closeEditor() {
      this.editorOpen = false;
      this.currentDocument = null;
    },
    openDeletionDialog(document) {
      this.currentDocument = document;
      this.deleteDialog = true;
    },
    closeDeletionDialog() {
      this.currentDocument = null;
      this.deleteDialog = false;
    },
    confirmDeletion() {
      this.$emit('documentdeleted', this.currentDocument);
      this.currentDocument = null;
      this.deleteDialog = false;
    },
    onDocumentUpdated(document) {
      this.$emit('documentupdated', document);
      this.editorOpen = false;
      this.currentDocument = null;
    },
    defaultDocument() {
      const doc = {
        id: null,
        title: null,
        description: null,
        lang: 'fr_FR',
        transcript: null,
        copyright: null,
        url: null,
        isNew: true,
        uuid: uuidv4(),
        file: null,
      };
      return doc;
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Documents audio",
    "btnAdd": "Ajouter un document",
    "removeTitle": "Supprimer ce document audio ?",
    "removeSubtitle": "Cette action est irréversible",
    "btnCancel": "Ne pas supprimer",
    "btnDelete": "Supprimer"
  },
  "en": {
    "title": "Audio documents",
    "btnAdd": "Add a document",
    "removeTitle": "Delete this audio document ?",
    "removeSubtitle": "This cannot be undone",
    "btnCancel": "Cancel",
    "btnDelete": "Delete"
  }
}
</i18n>
