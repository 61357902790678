<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('questionsTitle') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels
              v-model="panels"
              inset>
              <v-expansion-panel
                v-for="q in questions"
                :key="q.name">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                  <v-row>
                    <v-col
                      sm="7"
                      md="9">
                      {{ q.question }}</v-col
                    >
                    <v-col
                      sm="5"
                      md="3">
                      <div>
                        <v-chip
                          dark
                          class="mx-5"
                          small
                          :color="chipContent(q).color"
                        >{{ chipContent(q).text }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <question-card
                      :question="q"
                      @question-created="postNewQuestion"
                      @question-updated="putQuestion"
                      @question-removed="deleteQuestion" />
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            color="primary"
            @click="newQuestion">
            {{ $t('btnAddQuestion') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getQuestions,
  getThemes,
  postQuestion,
  updateQuestion,
  delQuestion,
} from '../../services/simpleQuestion';
import QuestionCard from './QuestionCard.vue';
import putImageFile from '../../services/media';
import { QUESTION_TYPE, LANG, THEME } from './index';
import UserNotifications from '../../mixins/UserNotifications.vue';

export default {
  components: {
    QuestionCard,
  },
  mixins: [UserNotifications],
  props: {
    destinationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      themes: [],
      inactiveTheme: null,
      panels: null,
      questions: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['token']),
  },
  methods: {
    chipContent(question) {
      const content = {
        text: '',
        color: '',
      };
      switch (question.type) {
        case QUESTION_TYPE.TEXT:
          content.text = this.$t('chipValueText');
          content.color = 'green';
          break;
        case QUESTION_TYPE.OPEN:
          content.text = this.$t('chipValueOpen');
          content.color = 'blue';
          break;
        case QUESTION_TYPE.PHOTO:
          content.text = this.$t('chipValuePhoto');
          content.color = 'red';
          break;
        case QUESTION_TYPE.YOUTUBE:
          content.text = this.$t('chipValueVideo');
          content.color = 'purple';
          break;
        case QUESTION_TYPE.IMAGE:
          content.text = this.$t('chipValueImage');
          content.color = 'black';
          break;
        default:
          break;
      }
      return content;
    },
    newQuestion() {
      const nQuestion = {
        themeId: null,
        propositions: [],
        question: '',
        type: null,
        lang: LANG.FR,
        url: null,
        versionHash: null,
        allowMultipleChoices: false,
        hasTimeout: false,
        timeout: 0,
      };
      nQuestion.themeId = this.inactiveTheme.id;
      this.questions.push(nQuestion);
      this.panels = this.questions.length - 1;
    },
    handleQuestionsIndex(question) {
      let result;
      this.questions.forEach((q, index) => {
        if (question.question === q.question) {
          result = index;
        } else {
          result = this.questions.length + 1;
        }
      });
      return result;
    },
    async getquestionsOfAllThemes() {
      const res = [];
      await Promise.all(
        this.themes.map(async (theme) => {
          if (theme.name === THEME.INACTIVE) {
            this.inactiveTheme = theme;
          }
          res.push(...(await getQuestions(theme.id)));
        }),
      );
      this.questions = res;
    },
    async postNewQuestion(question) {
      const newQuestion = question;
      newQuestion.order = this.handleQuestionsIndex(question);
      if (newQuestion.type === QUESTION_TYPE.IMAGE) {
        try {
          const response = await putImageFile(newQuestion.url, this.token);
          newQuestion.url = response.url;
        } catch (error) {
          this.errorNotification(this.$t('errorUploadPicture'), error);
        }
      }
      try {
        await postQuestion(newQuestion, this.token);
        await getQuestions(this.inactiveTheme.id);
        this.panels = null;
      } catch (error) {
        this.errorNotification(this.$t('errorSaveQuestion'), error);
      }
    },
    async putQuestion(question, newFile) {
      const newQuestion = question;
      if (question.type === QUESTION_TYPE.IMAGE && newFile === true) {
        try {
          const response = await putImageFile(newQuestion.url, this.token);
          newQuestion.url = response.url;
        } catch (error) {
          this.errorNotification(this.$t('errorUploadPicture'), error);
        }
      }
      try {
        await updateQuestion(newQuestion, this.token);
        await this.getquestionsOfAllThemes();
        this.panels = null;
      } catch (error) {
        this.errorNotification(this.$t('errorSaveQuestion'), error);
      }
    },
    async deleteQuestion(question) {
      if (question.id) {
        try {
          await delQuestion(question.id, this.token);
          await this.getquestionsOfAllThemes();
        } catch (error) {
          this.errorNotification(this.$t('errorDeleteQuestion'), error);
        }
      } else {
        const index = this.questions.indexOf(question);
        this.questions.splice(index, 1);
        if (this.panels === index) {
          this.panels = -1;
        }
      }
    },
  },
  async mounted() {
    try {
      this.themes = await getThemes(this.destinationId);
      this.getquestionsOfAllThemes();
    } catch (error) {
      this.errorNotification(this.$t('errorFetchingData'), error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "questionsTitle": "Questions",
    "btnAddQuestion": "Nouvelle question",
    "chipValueOpen": "Question à réponse ouverte",
    "chipValuePhoto": "Prise de photo",
    "chipValueText": "Question texte",
    "chipValueVideo": "Question sur une vidéo",
    "chipValueImage": "Question sur une image",
    "errorFetchingData": "Impossible de récupérer la liste des questions",
    "errorUploadPicture": "Impossible de stocker l'image",
    "errorSaveQuestion": "Une erreur est survenue pendant la sauvegarde de la question",
    "errorDeleteQuestion": "Une erreur est survenue pendant la suppression de la question"
  },
  "en": {
    "questionsTitle": "Questions",
    "btnAddQuestion": "New question",
    "chipValuePhoto": "Taking a picture",
    "chipValueText": "Text question",
    "chipValueVideo": "Video",
    "chipValueImage": "Picture",
    "errorFetchingData": "Unable to retrieve the questions list",
    "errorSaveQuestion": "An error occurred while saving the question",
    "errorDeleteQuestion": "An error occurred while removing the question"
  }
}
</i18n>
