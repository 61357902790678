/* eslint-disable import/no-unresolved */
<template>
  <v-layout row justify-center>
    <v-dialog v-model="editorOpen" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ $t('labelFieldTitle')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-card
              flat
              color="transparent"
            >

              <v-card-text>
                <h2
                  class="text-h5 ml-9 mt-9 mb-6">
                  {{$t('billAddress')}}</h2>
                <v-form>
                  <v-row
                    class="mx-auto">
                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="name"
                        class="my-0 py-0"
                        :label="$t('labelFieldName')"
                        :disabled="user.role !== 'editor'"
                      />
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="addressMain"
                        class="my-0 py-0"
                        :label="$t('labelFieldAddress')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="addressComplement"
                        class="my-0 py-0"
                        :label="$t('labelFieldAddressBis')"
                        :disabled="user.role !== 'editor'"
                      />
                    </v-col>
                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="zipCode"
                        class="my-0 py-0"
                        :label="$t('labelFieldZipcode')"
                        :disabled="user.role !== 'editor'"
                      />
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="city"
                        class="my-0 py-0"
                        :label="$t('labelFieldTown')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="country"
                        class="my-0 py-0"
                        :label="$t('labelFieldCountry')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>
                  </v-row>
                  <h2
                    class="text-h5  ml-9 mt-9 mb-3">
                    {{$t('detailsCoords')}}

                  </h2>
                  <v-row
                    class="mx-auto">
                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="contactName"
                        class="mb-0 mt-2 py-0"
                        :label="$t('labelFieldContactName')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="contactEmail"
                        class="mb-0 mt-2 py-0"
                        :label="$t('labelFieldContactEmail')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>

                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        v-model="billingEmail"
                        class="my-0 py-0"
                        :label="$t('labelFieldBillEmail')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>
                  </v-row>
                  <h2
                    class="text-h5  ml-9 mt-9 mb-5">
                    {{$t('credits')}}

                  </h2>
                  <v-row>
                    <v-col
                      class="mx-auto"
                      cols="8"
                      md="8"
                    >
                      <v-text-field
                        type="number"
                        v-model.number="creditBalance"
                        class="my-0 py-0"
                        :label="$t('labelFieldCredit')"
                        :disabled="user.role !== 'editor'"

                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="$emit('closeclicked')">
            {{ $t('btnCancel')}}
          </v-btn>
          <v-btn
            color="secondary"
            text
            @click="saveOrga()">
            {{ $t('btnSave')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, numeric } from 'vuelidate/lib/validators';
import orgaCrud from '../../services/orga';
import Notifications from '../../mixins/UserNotifications.vue';

export default {
  mixins: [Notifications, validationMixin],
  data() {
    return {
      addressMain: '',
      addressComplement: '',
      billingEmail: '',
      city: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      country: '',
      name: '',
      zipCode: '',
      creditBalance: 1,
      editorOpen: true,
    };
  },
  validations: {
    name: { required },
    addressMain: { required },
    city: { required },
    zipCode: { required, numeric },
    country: { required },
    contactEmail: { required, email },
    billingEmail: { required, email },

  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('auth', ['token']),

  },
  methods: {
    async saveOrga() {
      if (this.$v.$invalid) {
        this.errorNotification(this.$t('errorMissingField'));
      } else {
        const req = [];
        try {
          req.push({
            name: this.name,
            addressMain: this.addressMain,
            addressComplement: this.addressComplement,
            billingEmail: this.billingEmail,
            city: this.city,
            contactEmail: this.contactEmail,
            contactPhone: this.contactPhone,
            country: this.country,
            zipCode: this.zipCode,
            creditBalance: this.creditBalance,
            contactName: this.contactName,
          });
          const changedOrganization = req[0];
          const result = await orgaCrud.createOrganization(changedOrganization, this.token);
          if (result) {
            this.$emit('save');
            this.successNotification(this.$t('successSaving'));
          }
        } catch (error) {
          this.errorNotification(this.$t('errorsSavingFailed'));
        }
      }
    },
  },
};
</script>
<i18n>
{
  "fr":
  {
    "labelFieldTitle" : "Créer une organisation",
    "billAddress": "Adresse de facturation",
    "labelFieldName": "Nom *",
    "labelFieldAddress": "Adresse *",
    "labelFieldAddressBis": "Adresse(complément)",
    "labelFieldZipcode": "Code postal *",
    "labelFieldTown": "Ville *",
    "labelFieldCountry": "Pays *",
    "detailsCoords": "Coordonnées",
    "credits": "Nombre de crédits",
    "labelFieldCredit": "Crédits",
    "labelFieldContactEmail": "Email de contact *",
    "labelFieldBillEmail": "Email de facturation *",
    "labelFieldNumber": "Numéro de téléphone",
    "modifyButton": "Sauvegarder les modifications",
    "successSaving": "Organisation mise à jour !",
    "errorsSavingFailed": "Problème lors de la mise à jour !",
    "btnCancel": "Annuler",
    "btnSave": "Sauvegarder",
    "errorMissingField": "Veuillez remplir tous les champs avec un *",
    "labelFieldContactName": "Nom du contact*"
  },
  "en":
  {
    "labelFieldTitle": "Create an organization",
    "billAddress": "Billing address",
    "labelFieldContactName": "Contact name*",
    "labelFieldName": "Name *",
    "labelFieldAddress": "Address *",
    "labelFieldAddressBis": "Address(detailed)",
    "labelFieldZipcode": "Zipcode *",
    "labelFieldTown": "Town *",
    "labelFieldCountry": "Country *",
    "detailsCoords": "Localisation",
    "credits": "Number of Credits",
    "labelFieldCredit": "Credit number",
    "labelFieldContactEmail": "Contact Email *",
    "labelFieldBillEmail": "Billing Email *",
    "labelFieldNumber": "Telephone number",
    "modifyButton": "Save changes",
    "successSaving": "Organization updated!",
    "errorsSavingFailed": "Update failed!",
    "btnCancel": "Cancel",
    "btnSave": "Save",
    "errorMissingField": "Please fill all the form containing *"

  }
}
</i18n>
