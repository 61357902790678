<template>
  <v-container fluid>
    <v-spacer />
    <v-row>
      <v-col cols="9" xs="12" sm="12" md="9">
        <h1 class="text-h2">{{ title }}</h1>
      </v-col>

    </v-row>
    <v-container
      fluid
      v-if="loaded === false">
      <destination-loader />
    </v-container>
    <v-container
      fluid
      v-else>
      <v-row>
        <v-col cols="12">
          <name-card
            v-model="currentDestination.name"
            :title="$t('titleNameCard')"
            :subtitle="$t('subtitleNameCard')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <logos-card
            @handleLogoDestination="handleLogoDestination"
            @handleLogoGallery="handleLogoGallery"
            @onDeleteDestinationLogo="onDeleteDestinationLogo"
            @onDeleteGalleryLogo="onDeleteGalleryLogo"
            :logoDestination="
              currentDestination.logoUrl
                ? currentDestination.logoUrl
                : ''
            "
            :logoGallery="
              currentDestination.galleryLogoUrl
                ? currentDestination.galleryLogoUrl
                : ''
            " />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <map-card
            :title="$t('titleMapCard')"
            :subtitle="$t('subtitleMapCard')"
            :initial-latitude="currentDestination.latitude"
            :initial-longitude="currentDestination.longitude"
            :initial-zoom="currentDestination.zoom"
            :zoom-editable="true"
            @positionchanged="onPositionChanged" />
          <!-- map -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- traductions -->
          <translations-list
            :currentDestination="currentDestination"
            :initial-translations="currentDestination.locales"
            :include-description="true"
            @transactionschanged="onTranslationsChanged"
            @updateoptin="onUpdateOptin"
            @updatesecondaryoptin="onUpdateSecondaryOptin" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- traductions -->
          <domains-list
            v-if="user.organizationId === 4"
            v-model="currentDestination.domains" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <destination-parameters
            v-if="user.organizationId === 4"
            :value="currentDestination" />
        </v-col>
      </v-row>
      <v-row v-if="validate().length > 0">
        <v-col cols="12">
          <v-alert
            color="red"
            border="left"
            dark>
            <p>
              <strong>{{ $t('errorsTitle') }}</strong>
            </p>
            <ul>
              <li
                v-for="e in validate()"
                :key="e">
                {{ e }}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn
            color="primary"
            :to="`/destinations/${currentDestination.id}/validate`">{{ $t('validationBtn') }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            class="text-right"
            :disabled="validate().length > 0"
            color="accent"
            @click="save">
            {{ $t('saveBtnTitle') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
/* eslint-disable max-len */

import { mapGetters, mapActions } from 'vuex';
import MapCard from '../components/destination/DestinationMap.vue';
import NameCard from '../components/base/IdentifierCard.vue';
import TranslationsList from '../components/destination/TranslationsList.vue';
import DestinationParameters from '../components/destination/DestinationParameters.vue';
import DestinationLoader from '../components/destination/DestinationLoader.vue';
import DomainsList from '../components/destination/DomainsList.vue';
import api from '../services/destination';
import rules from '../services/validation';
import LogosCard from '../components/base/LogosCard.vue';

import Notifications from '../mixins/UserNotifications.vue';
import k from '../const';
import pictureApi from '../services/fetch';
import cfg from '../config';

export default {
  components: {
    MapCard,
    DestinationParameters,
    NameCard,
    LogosCard,
    TranslationsList,
    DestinationLoader,
    DomainsList,
  },
  mixins: [Notifications],
  data() {
    return {
      currentDestination: null,
      loaded: false,
      galleryLogoUrl: '../assets/logo.png',
      newLogoDestination: null,
      newLogoGallery: null,
    };
  },
  computed: {
    editorBelongsToDotmap() {
      return this.user.organizationId === k.dotmapOrgId;
    },
    destinationId() {
      return this.$route.params.destinationId
        ? this.$route.params.destinationId
        : null;
    },
    ...mapGetters('auth', ['token', 'user']),
    isNew() {
      return this.$route.name === 'createDestination';
    },
    title() {
      return this.isNew ? this.$t('pageTitleCreate') : this.$t('pageTitleEdit');
    },
  },
  watch: {
    async destinationId(val) {
      this.loaded = false;
      this.currentDestination = null;
      this.currentDestination = await api.destinationById(val, this.token);
      this.loaded = true;
      await this.$nextTick();
    },
  },
  async mounted() {
    try {
      if (this.isNew === false) {
        this.currentDestination = await api.destinationById(
          this.destinationId,
          this.token,
        );
      } else {
        this.currentDestination = this.defaultDestination();
      }
      this.loaded = true;
    } catch (error) {
      this.errorNotification(this.$t('errorsLoading'), error);
    }
  },
  methods: {
    ...mapActions({
      fetchSites: 'site/loadSites',
    }),
    defaultDestination() {
      return {
        id: null,
        uuid: null,
        name: '',
        picture: '',
        latitude: 48.83534,
        longitude: 2.3488,
        zoom: 8,
        locales: [],
        enableCheckin: false,
        enableRanking: false,
        enablePolls: false,
        rankingScore: 0,
        pollsScore: 0,
        legalInfo: [],
        enableOpenAgenda: false,
        openAgendaUrl: '',
        optin: false,
        secondaryOptin: false,
        cssTheme: 'dotmap',
        matomoSiteId: 0,
        mapboxTheme: '',
        mapboxToken: '',
        enableGeolocation: true,
        enableGeolocationRouting: true,
        enableRegistration: false,
        enableLogin: false,
        enableRewards: false,
        enableWelcomeTour: false,
        baseCheckinDistance: 50,
        dataUpdateInterval: 60,
        locationUpdateInterval: 10,
        useGameApi: false,
        domains: [],
        customerType: 'unknown',
      };
    },
    onPositionChanged(pos) {
      this.currentDestination.latitude = pos.latitude;
      this.currentDestination.longitude = pos.longitude;
      this.currentDestination.zoom = pos.zoom;
    },
    onTranslationsChanged(translations) {
      this.currentDestination.locales = translations;
    },
    validate() {
      const errors = [];
      if (!rules.ValidateIdentifier(this.currentDestination.name)) {
        errors.push(this.$t('errorsValidateIdentifier'));
      }
      if (
        !this.currentDestination.locales
        || this.currentDestination.locales.length === 0
      ) {
        errors.push(this.$t('errorsValidationNoLocale'));
      }
      if (!this.validateStdText(this.currentDestination.cssTheme)) {
        errors.push(this.$t('errorsValidationInvalidCSS'));
      }
      if (!this.validateStdText(this.currentDestination.mapboxTheme)) {
        errors.push(this.$t('errorsValidationMapboxthemeInvalid'));
      }
      if (!this.validateStdText(this.currentDestination.mapboxToken)) {
        errors.push(this.$t('errorsValidationMapboxTokenInvalid'));
      }
      if (
        !this.currentDestination.domains
        || this.currentDestination.domains.length === 0
      ) {
        errors.push(this.$t('errorsValidationNoDomain'));
      }
      return errors;
    },
    validateStdText(str) {
      return (
        rules.ValidateMinLength(str, 1) && rules.ValidateMaxLength(str, 255)
      );
    },
    onUpdateOptin(value) {
      this.currentDestination.hasOptin = value;
    },
    onUpdateSecondaryOptin(value) {
      this.currentDestination.hasSecondaryOptin = value;
    },
    async save() {
      let newDestination;
      try {
        if (!this.isNew) {
          await api.updateDestination(this.currentDestination, this.token);
        } else {
          // create a destination
          newDestination = await api.createDestination(
            this.currentDestination,
            this.token,
          );
        }
        if (this.newLogoDestination !== null) {
          const uuid = this.isNew
            ? newDestination.uuid
            : this.currentDestination.uuid;
          await pictureApi.putFile(
            `${cfg.apiUrl}/api/v3/destinations/${uuid}/logo/logo_destination`,
            this.newLogoDestination,
            this.token,
          );
          this.newLogoDestination = null;
        }
        if (this.newLogoGallery !== null) {
          const uuid = this.isNew
            ? newDestination.uuid
            : this.currentDestination.uuid;
          await pictureApi.putFile(
            `${cfg.apiUrl}/api/v3/destinations/${uuid}/logo/logo_gallery`,
            this.newLogoGallery,
            this.token,
          );

          this.newLogoGallery = null;
        }
        this.successNotification(this.$t('successSaving'));
        await this.fetchSites(this.token);
        this.$router.push({ name: 'home' });
      } catch (error) {
        this.errorNotification(this.$t('errorsSavingFailed'), error);
      }
    },

    handleLogoDestination(val) {
      this.newLogoDestination = val;
    },
    handleLogoGallery(val) {
      this.newLogoGallery = val;
    },
    async onDeleteDestinationLogo() {
      await pictureApi.deleteJSON(
        `${cfg.apiUrl}/api/v3/destinations/${this.currentDestination.uuid}/logo/logo_destination`,
        this.token,
      );
      this.currentDestination = await pictureApi.getJSON(
        `${cfg.apiUrl}/api/v3/destinations/${this.currentDestination.id}`,
        {},
        this.token,
      );
    },
    async onDeleteGalleryLogo() {
      await pictureApi.deleteJSON(
        `${cfg.apiUrl}/api/v3/destinations/${this.currentDestination.uuid}/logo/logo_gallery`,
        this.token,
      );
      this.currentDestination = await pictureApi.getJSON(
        `${cfg.apiUrl}/api/v3/destinations/${this.currentDestination.id}`,
        {},
        this.token,
      );
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "pageTitleCreate": "Ajouter une destination",
    "pageTitleEdit": "Modifier une destination",
    "titleNameCard":  "Identifiant de la destination",
    "subtitleNameCard": "L'identifiant est un nom technique de la destination, destiné à l'identifier dans l'espace d'administration.",
    "titleMapCard": "Carte de la destination",
    "subtitleMapCard": "Placez le point sur le centre de la carte désiré. Faites varier le niveau de zoom pour que la carte affiche toute votre destination",
    "errorsTitle": "Les erreurs suivantes empêchent d'enregistrer la destination :",
    "errorsLoading": "impossible de charger l'éditeur de destination :(",
    "errorsValidateIdentifier": "Identifiant invalide",
    "errorsValidationNoLocale": "Au moins une traduction est nécessaire",
    "errorsValidationInvalidCSS": "Thème CSS invalide",
    "errorsValidationMapboxthemeInvalid": "Thème Mapbox invalide",
    "errorsValidationMapboxTokenInvalid": "Token Mapbox invalide",
    "errorsValidationNoDomain": "Au moins un domaine est nécessaire",
    "errorsSavingFailed": "Une erreur est survenue pendant l'enregistrement de la destination.",
    "successSaving": "Destination enregistrée.",
    "saveBtnTitle": "Enregistrer",
    "validationBtn": "Accéder à la page de validation"
  },
  "en": {
    "pageTitleCreate": "Add a destination",
    "pageTitleEdit": "Update a destination",
    "titleNameCard":  "Destination identifier",
    "subtitleNameCard": "Used in the admin panel to identify the destination uniquely",
    "titleMapCard": "Destination map",
    "subtitleMapCard": "Drag the marker on the desired center of the map. Change the zoom level to include your whole destination in the frame.",
    "errorsTitle": "The following issues prevent saving:",
    "errorsLoading": "Unable to load the destination editor :(",
    "errorsValidateIdentifier": "Invalid identifier",
    "errorsValidationNoLocale": "At least one translation is required",
    "errorsValidationInvalidCSS": "Invalid CSS theme",
    "errorsValidationMapboxthemeInvalid": "Invalid mapbox theme",
    "errorsValidationMapboxTokenInvalid": "Invalid mapbox token",
    "errorsValidationNoDomain": "At least one domain name is required",
    "errorsSavingFailed": "Unable to save the destination",
    "successSaving": "Destination saved",
    "saveBtnTitle": "Save",
    "validationBtn": "Validate destination"
  }
}
</i18n>
