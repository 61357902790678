<template>
  <v-card>
    <v-card-title>
      {{ $t('logoTitleCard') }}
    </v-card-title>
    <v-card-text>
      <v-file-input
        @click="logoType = 'logo_destination'"
        @change="
          uploadLogo($event);
          handleLogo();
        "
        :rules="rulesLogoInput"
        accept="image/png, image/jpeg"
        :label="$t('logoSubtitleDestination')"
        type="file"
        prepend-icon="mdi-camera"
        chips
        counter
        show-size
        truncate-length="35" />
      <p>{{ $t('currentLogo') }}</p>
      <p v-if="logoDestination !== ''">{{ logoDestination }}</p>
      <v-card-text
        class="mb-4"
        v-if="logoDestination.includes('http')">
        <v-img
          :lazy-src="logoDestination"
          max-height="100"
          max-width="100"
          :src="logoDestination" />
        <v-btn
          absolute
          right
          v-if="logoDestination.includes('http')"
          @click="
            logoType = 'logo_destination';
            deleteLogo();
          ">
          {{ $t('deleteBtn') }}
        </v-btn>
      </v-card-text>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-file-input
        @click="logoType = 'logo_gallery'"
        @change="
          uploadLogo($event);
          handleLogo();
        "
        :rules="rulesLogoInput"
        accept="image/png, image/jpeg"
        :label="$t('logoSubtitleGallery')"
        type="file"
        prepend-icon="mdi-camera"
        chips
        counter
        show-size
        truncate-length="35" />
      <p>{{ $t('currentLogo') }}</p>
      <p v-if="logoGallery !== ''">{{ logoGallery }}</p>
      <v-card-text
        v-if="logoGallery.includes('http')"
        class="mb-4">
        <v-img
          :lazy-src="logoGallery"
          max-height="100"
          max-width="100"
          :src="logoGallery" />
        <v-btn
          absolute
          right
          v-if="logoGallery.includes('http')"
          @click="
            logoType = 'logo_gallery';
            deleteLogo();
          ">
          {{ $t('deleteBtn') }}
        </v-btn>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    logoDestination: {
      type: String,
      required: true,
    },
    logoGallery: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rulesLogoInput: [
        (value) => !value || value.size < this.MAX_SIZE_FILE || this.$t('logoSize'),
      ],
      dataLogoDestination: null,
      dataLogoGallery: null,
      logoType: String,
      MAX_SIZE_FILE: 4000000,
    };
  },
  methods: {
    uploadLogo(event) {
      const data = new FormData();
      data.append('picture', event);

      if (this.logoType === 'logo_destination') {
        this.dataLogoDestination = data;
      } else if (this.logoType === 'logo_gallery') {
        this.dataLogoGallery = data;
      }
    },
    handleLogo() {
      if (this.dataLogoDestination !== null) {
        this.$emit('handleLogoDestination', this.dataLogoDestination);
      }
      if (this.dataLogoGallery !== null) {
        this.$emit('handleLogoGallery', this.dataLogoGallery);
      }
    },
    deleteLogo() {
      if (this.logoType === 'logo_destination') {
        this.$emit('onDeleteDestinationLogo');
      }
      if (this.logoType === 'logo_gallery') {
        this.$emit('onDeleteGalleryLogo');
      }
    },
  },
};
</script>

<i18n>
{
  "fr": {
    "logoTitleCard": "Logos de la destination et de la galerie",
    "logoSubtitleDestination": "Logo de la destination",
    "logoSubtitleGallery": "Logo de la galerie",
    "currentLogo": "Logo actuel : ",
    "deleteBtn": "supprimer",
    "logoSize": "La taille du logo doit faire moins de 4 MB"
    },
  "en": {
    "logoTitleCard": "Destination and gallery logos",
    "logoSubtitleDestination": "Logo of the destination",
    "logoSubtitleGallery": "Logo of the gallery",
    "currentLogo": "Current logo : ",
    "deleteBtn": "delete",
    "logoSize": "Logo size should be less than 4 MB"
  }
}
</i18n>
