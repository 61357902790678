<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t('title') }}</v-toolbar-title>
    </v-toolbar>
    <v-list two-line>
      <template>
        <div v-for="(t, index) in translations" :key="index">
          <v-list-item @click="i = 1">
            <v-list-item-avatar>
              <flag :iso="flagIconClass(t.locale)" />
            </v-list-item-avatar>
            <v-list-item-content @click="editTranslation(t, index)">
              <v-list-item-title>{{ t.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="includeDescription">
                {{ truncate(stripHTML(t.description)) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon ripple @click="deleteTranslation(t)">
                <v-icon color="grey lighten-1">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </template>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="editorOpen = true"
        text
        color="secondary">
        {{ $t('btnAdd') }}
      </v-btn>
    </v-card-actions>
    <translation-editor
      v-if="editorOpen === true && currentTranslation !== null"
      :initial-translation="currentTranslation"
      :include-description="includeDescription"
      @savetranslation="saveTranslation"
      @closeclicked="editorOpen = false" />
  </v-card>
</template>

<script>
// eslint-disable-next-line
import FlagIcon from 'vue-flag-icon';
import TranslationEditor from './ObjectTranslationEditor.vue';

export default {
  components: { TranslationEditor },
  props: {
    initialTranslations: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    includeDescription: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      editorOpen: false,
      translations: {},
      currentTranslation: this.resetCurrentTranslation(),
      i: 1,
    };
  },
  mounted() {
    if (this.initialTranslations) {
      this.translations = this.initialTranslations;
    }
  },
  methods: {
    editTranslation(translation) {
      this.currentTranslation = translation;
      this.editorOpen = true;
    },
    deleteTranslation(t) {
      const transObject = { ...this.translations };
      delete transObject[t.locale];
      this.translations = transObject;
      this.transactionsChanged();
    },
    saveTranslation(incomingTranslation) {
      this.editorOpen = false;
      if (!this.translations) {
        this.translations = {};
      }
      this.translations[incomingTranslation.locale] = incomingTranslation;
      this.transactionsChanged();
      this.resetCurrentTranslation();
    },

    transactionsChanged() {
      this.$emit('transactionschanged', this.translations);
    },
    flagIconClass(locale) {
      const countryCode = locale.substring(locale.indexOf('_') + 1).toLowerCase();
      return `flag-icon flag-icon-${countryCode}`;
    },
    resetCurrentTranslation() {
      this.currentTranslation = {
        name: '', description: '', locale: 'fr_FR',
      };
    },
    truncate(value) {
      return value.length < 150 ? value : `${value.substring(0, 150)}...`;
    },
    stripHTML(value) {
      const div = document.createElement('div');
      div.innerHTML = value;
      return div.textContent || div.innerText || '';
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Traductions",
    "btnAdd": "Ajouter une traduction"
  },
  "en": {
    "title": "Translations",
    "btnAdd": "Add a translation"
  }
}
</i18n>
