<template>
  <v-container>
    <v-divider />
    <v-row>
      <v-col cols="12">
        <h4>{{ $t('title') }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :label="$t('labelQuestionTitle')"
          v-model="title"
          :rules="rules"
        >{{ $t('placeholderQuestion') }}</v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-switch
          v-model="hasTimeout"
          :label="$t('labelHasTimeout')"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          type="number"
          :disabled="hasTimeout === false"
          :label="$t('labelTimeout')"
          v-model.number="timeout" />
      </v-col>
    </v-row>
    <v-row v-if="questionType === 'photo' ">
      <v-col cols="6">
        <v-text-field
          type="number"
          :label="$t('labelPictureScore')"
          v-model.number="photoScore" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-radio-group
          :label="$t('radioQuestionTypeLabel')"
          v-model="questionType">
          <v-radio
            key="photo"
            :label="$t('radioQuestionTypeValuePhoto')"
            value="photo" />
          <v-radio
            key="text"
            :label="$t('radioQuestionTypeValueText')"
            value="text" />
          <v-radio
            key="image"
            :label="$t('radioQuestionTypeValueImage')"
            value="image" />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row class="mb-2" v-if="displaySwitchAndPropositions === true">
      <v-switch
        v-model="multipleChoices"
        :label="$t('multipleChoices')"
      /> <v-tooltip transition="fade-transition" right><span>{{ $t('tooltipMultipleChoices') }}</span>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon><v-icon small>mdi-help-circle</v-icon></v-btn>
        </template>
      </v-tooltip>
    </v-row>
    <v-row v-if="questionType === 'image'">
      <v-col cols="12">
        <v-card class="pa-2">
          <v-text-field
            v-if="fileUrl"
            :label="$t('questionTitleExistingFile')"
            readonly
            v-model="fileUrl" />
          <v-file-input
            :label="uploadLabel"
            v-model="file"
            clearable
            required />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="questionType === 'youtube'">
      <v-col cols="12">
        <youtube-video
          v-model="videoUrl"
          :auto-play-status="false"
          :loop-status="false"
          :choicesVideoVisibility="false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert type="info">
          {{ $t('alert') }}
        </v-alert>
      </v-col>
    </v-row>
    <propositions-list
      v-if="displaySwitchAndPropositions === true"
      :propositions="propositions"
      @add-proposition="addProposition"
      @update-proposition="onPropositionUpdated"
      @remove-proposition="deleteProposition"
    />
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="removeQuestion"
        >{{ $t('btnRemoveQuestion') }}</v-btn
        >&nbsp;
        <v-btn
          color="secondary"
          @click="save"
        >{{ $t('btnSaveQuestion') }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import YoutubeVideo from '../video/YoutubeVideo.vue';
import { QUESTION_TYPE } from './index';
import PropositionsList from './PropositionsList.vue';

export default {
  components: {
    YoutubeVideo,
    PropositionsList,
  },
  props: {
    question: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      propositions: [],
      title: null,
      fileUrl: null,
      file: null,
      videoUrl: null,
      multipleChoices: false,
      questionType: null,
      hasTimeout: false,
      photoScore: 0,
      timeout: 0,
      rules: [
        (value) => !!value || `${this.$t('requiredField')}`,
      ],
    };
  },
  computed: {
    uploadLabel() {
      if (this.fileUrl) {
        return this.$t('questionTitleFileReplace');
      }
      return this.$t('questionTitleFile');
    },
    displaySwitchAndPropositions() {
      if (this.questionType === QUESTION_TYPE.PHOTO || this.question.type === QUESTION_TYPE.OPEN) {
        return false;
      }
      return true;
    },
  },
  methods: {
    populateWith(question) {
      this.propositions = question.propositions;
      this.questionType = question.type;
      this.title = question.question;
      this.multipleChoices = question.allowMultipleChoices;
      this.hasTimeout = question.hasTimeout;
      this.timeout = question.timeout;
      this.photoScore = question.photoScore;
      if (question.type === QUESTION_TYPE.IMAGE) {
        this.fileUrl = question.url;
      } else if (question.type === QUESTION_TYPE.YOUTUBE) {
        this.videoUrl = question.url;
      }
    },
    onPropositionUpdated(prop) {
      const props = this.propositions.filter((p) => p.id !== prop.id);
      props.push(prop);
      this.propositions = props;
    },
    addProposition() {
      const nProposition = {
        id: uuidv4(),
        title: '',
        score: 0,
        order: this.propositions.length + 1,
      };
      this.propositions.push(nProposition);
    },
    deleteProposition(proposition) {
      const index = this.propositions.indexOf(proposition);
      this.propositions.splice(index, 1);
    },
    newQuestion() {
      const nq = this.question;
      nq.question = this.title;
      nq.propositions = this.propositions;
      nq.allowMultipleChoices = this.multipleChoices;
      nq.type = this.questionType;
      nq.hasTimeout = this.hasTimeout;
      nq.photoScore = this.photoScore;
      nq.timeout = this.timeout;
      if (this.file) {
        nq.url = this.file;
      } else if (this.videoUrl) {
        nq.url = this.videoUrl;
      } else {
        nq.url = this.question.url;
      }
      this.$emit('question-created', nq);
    },
    updateQuestion() {
      const uq = this.question;
      const newFile = !!this.file;
      uq.question = this.title;
      uq.propositions = this.propositions;
      uq.url = this.file ? this.file : this.question.url;
      uq.allowMultipleChoices = this.multipleChoices;
      uq.photoScore = this.photoScore;
      uq.hasTimeout = this.hasTimeout;
      uq.timeout = this.timeout;
      this.$emit('question-updated', uq, newFile);
    },
    removeQuestion() {
      const dq = this.question;
      this.$emit('question-removed', dq);
    },
    save() {
      if (this.question.id !== undefined) {
        this.updateQuestion();
      } else {
        this.newQuestion();
      }
    },
  },
  mounted() {
    if (this.question) {
      this.populateWith(this.question);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Paramètres d'une question à réponses multiples",
    "alert": "Attribuez un score à chaque réponse. Un score peut être 0 ou négatif ;)",
    "labelQuestionTitle": "Intitulé de la question",
    "placeholderQuestion": "Question",
    "questionTitleFileReplace": "Cliquez pour ajouter une image en remplacement de l'existante",
    "questionTitleFile": "Cliquez pour ajouter une image",
    "questionTitleExistingFile": "Image existante",
    "multipleChoices": "Réponse multiple",
    "btnRemoveQuestion": "Supprimer",
    "btnSaveQuestion": "Enregistrer",
    "requiredField": "Champ requis",
    "radioQuestionTypeLabel": "Type de question",
    "radioQuestionTypeValueOpen": "Question à réponse ouverte",
    "radioQuestionTypeValuePhoto": "Prise de photo",
    "radioQuestionTypeValueText": "Question texte",
    "radioQuestionTypeValueVideo": "Question sur une vidéo",
    "radioQuestionTypeValueAudio": "Question sur un document audio",
    "radioQuestionTypeValueImage": "Question sur une image",
    "labelHasTimeout": "Limiter le temps de réponse",
    "labelTimeout": "durée de réponse (secondes)",
    "labelPictureScore": "Score de la photo",
    "tooltipMultipleChoices": "Lorsque cette option est activée, les joueurs peuvent sélectionner plusieurs réponses"
  },
  "en": {
    "title": "Multiple answers parameters",
    "alert": "Give a score to each possible answer. Score can be O or negative ;)",
    "labelQuestionTitle": "Question title",
    "placeholderQuestion": "Question",
    "questionTitleFileReplace": "Click to add an image to replace the existing one",
    "questionTitleFile": "Click to add an image",
    "questionTitleExistingFile": "Existing image",
    "btnAddProposition": "Add an answer",
    "multipleChoices": "Multiple response",
    "btnRemoveQuestion": "Delete",
    "btnSaveQuestion": "Save",
    "requiredField": "required field",
    "radioQuestionTypeLabel": "Question type",
    "radioQuestionTypeValueOpen": "Open answer",
    "radioQuestionTypeValuePhoto": "Taking a picture",
    "radioQuestionTypeValueText": "Text question",
    "radioQuestionTypeValueVideo": "Video",
    "radioQuestionTypeValueImage": "Picture",
    "labelHasTimeout": "Limit response time",
    "labelTimeout": "Time limit(seconds)",
    "labelPictureScore": "Score when a picture is uploaded",
    "tooltipMultipleChoices": "When active, players can select several propositions"

  }
}
</i18n>
