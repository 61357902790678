<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ $t('titleAdd') }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col
              cols="12"

            />
            <v-select
              :label="$t('labelDestinationToAdd')"
              v-model="targetDestinationID"
              :cache-items="true"
              :eager="true"
              :items="destinationsItems" />
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="accent"
        text
        @click="close"
      >
        {{ $t('btnClose') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        :disabled="targetDestinationID === -1"
        @click="save"
      >
        {{ $t('btnAdd') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { FETCH_DESTINATIONS } from '../../store/actionTypes';

export default {
  props: {
    associatedDestinations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      allDestinations: [],
      targetDestinationID: -1,
    };
  },

  computed: {
    ...mapGetters('auth', [
      'token',
    ]),
    availableDestinations() {
      const destinationIds = [];
      this.associatedDestinations.forEach((d) => {
        destinationIds.push(d.uuid);
      });
      return this.allDestinations.filter((d) => !destinationIds.includes(d.uuid));
    },
    destinationsItems() {
      const items = [];
      this.availableDestinations.forEach(
        (d) => { items.push({ text: d.name, value: d.id }); },
      );
      items.sort((a, b) => a.text.localeCompare(b.text));
      return items;
    },
  },
  methods: {
    ...mapActions({
      refreshDestinations: FETCH_DESTINATIONS,
    }),

    close() {
      this.targetDestinationID = -1;
      this.$emit('close');
    },
    save() {
      this.$emit('save', this.targetDestinationID);
    },
  },
  async beforeMount() {
    try {
      this.allDestinations = await this.refreshDestinations({ token: this.token });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<i18n>
  {
    "fr": {
      "titleAdd": "Associer une destination",
      "labelDestinationToAdd": "Destination à associer",
      "btnAdd": "Associer",
      "btnClose": "Fermer"
    },
    "en": {
      "titleAdd": "Associate a destination",
      "labelDestinationToAdd": "Destination to associate",
      "btnAdd": "Associate",
      "btnClose": "Close"
    }
  }
</i18n>
