const ValidateIdentifier = (identifier) => {
  if (!identifier) {
    return false;
  }
  if (identifier.match(/^([\w|-]+)$/)) {
    return true;
  }
  return false;
};

const ValidateMinLength = (str, minLength) => str && str.length >= minLength;

const ValidateMaxLength = (str, maxLength) => !str || (str && str.length <= maxLength);

const ValidateEmail = (str) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(str);
};

export default {
  ValidateIdentifier,
  ValidateEmail,
  ValidateMinLength,
  ValidateMaxLength,
};
