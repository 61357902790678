<template>
  <v-row class="p-4">
    <v-col align-self="center" md="12" xs="12">
      <v-container>
        <v-alert prominent type="warning">
          <v-checkbox
            class="pt-8"
            v-model="checked"
            @change="$emit('onCheck')"
            :label="$t('alertInsufficientCredits')"
          />
          <div class="d-flex justify-end">
            <v-btn href="/20220801 CGV DOTMAP.pdf" target="blank" right>{{ $t("CGVlink") }}</v-btn>
          </div>
        </v-alert>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<i18n>
{
  "fr": {
    "insufficientCredits": "Nombre de crédits d'équipe insuffisant",
    "alertInsufficientCredits": "Je comprends que la création d'une équipe supplémentaire entraînera la consommation d'un crédit non prépayé, qui me sera facturé. Je m'engage à les payer au tarif défini dans les conditions générales de vente, à réception de la facture.",
    "CGVlink": "Consulter les CGV"
  },
  "en": {
    "insufficientCredits": "Number of team credits insufficient",
    "alertInsufficientCredits": "I understand that creating an additional team will result in the consumption of 1 non-prepaid credit, which will be charged to me. I agree to pay for them at the rate defined in the General Terms and Conditions of Sale upon receipt of the invoice.",
    "CGVlink": "Consult the contract"
  }
}
</i18n>
