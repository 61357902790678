var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.editorOpen),callback:function ($$v) {_vm.editorOpen=$$v},expression:"editorOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"single-line":"","placeholder":_vm.$t('labelTitle'),"rules":[_vm.rules.required, _vm.rules.maxLength],"counter":"255","required":""},model:{value:(_vm.translation.name),callback:function ($$v) {_vm.$set(_vm.translation, "name", $$v)},expression:"translation.name"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":[
                  {
                    text: _vm.$t('itemFrench'),
                    value: 'fr_FR',
                  },
                  {
                    text: _vm.$t('itemEnglish'),
                    value: 'en_GB',
                  },
                  {
                    text: _vm.$t('itemGerman'),
                    value: 'de_DE',
                  },
                  {
                    text: _vm.$t('itemNL'),
                    value: 'nl_NL',
                  },
                  {
                    text: _vm.$t('itemItalian'),
                    value: 'it_IT',
                  },
                  {
                    text: _vm.$t('itemSpanish'),
                    value: 'es_ES',
                  },
                  {
                    text: _vm.$t('itemPortuguese'),
                    value: 'pt_PT',
                  }],"menu-props":"auto","label":"Langue*","hide-details":"","single-line":""},model:{value:(_vm.translation.locale),callback:function ($$v) {_vm.$set(_vm.translation, "locale", $$v)},expression:"translation.locale"}})],1),(_vm.includeDescription === true)?_c('v-flex',{attrs:{"xs12":""}},[_c('vue-editor',{attrs:{"placeholder":_vm.$t('descriptionPlaceHolder'),"editorToolbar":_vm.customToolbar,"id":"description-editor"},model:{value:(_vm.translation.description),callback:function ($$v) {_vm.$set(_vm.translation, "description", $$v)},expression:"translation.description"}})],1):_vm._e()],1)],1),_c('small',[_vm._v(_vm._s(_vm.$t('labelMandatoryFields')))])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('closeclicked')}}},[_vm._v(_vm._s(_vm.$t('btnCancel')))]),_c('v-btn',{attrs:{"color":"secondary","disabled":!_vm.translationIsValid,"text":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('btnSave'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }