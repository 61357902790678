<template>
  <v-dialog
    :value="dialog"
    hide-overlay
    fullscreen
    transition="dialog-bottom-transition"
    @input="emitCloseEvent"
  >
    <v-card v-if="teams && teamsWithCheckin && teams.length > 0">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="emitCloseEvent"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ poiName }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-progress-linear v-if="poiCompletion" :value="poiCompletion" height="30">
          <template v-slot:default="{ value }">
            <strong style="color: #ffffff">{{ value }}%</strong>
          </template>
        </v-progress-linear>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t('teamName')}}
                </th>
                <th class="text-left">
                  {{ $t('teamScore')}}
                </th>
                <th class="text-left">
                  {{ $t('checkinStatus') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="team in teams"
                :key="team.id"
              >
                <td><v-chip class="ma-2" small :color="team.color" />{{ team.name }}</td>
                <td>{{ team.score }}</td>
                <td>{{ getCheckinStatus(team.id) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    poiName: {
      type: String,
      required: true,
    },
    teams: {
      type: Array,
      default: () => [],
    },
    teamsWithCheckin: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'dialog',
    event: 'close',
  },
  computed: {
    poiCompletion() {
      if (this.teams && this.teamsWithCheckin && this.teams.length > 0) {
        return ((this.teamsWithCheckin.length * 100) / this.teams.length).toFixed(0);
      }
      return null;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    getCheckinStatus(teamId) {
      return this.teamsWithCheckin.find((id) => id === teamId) ? this.$t('checkinOK') : null;
    },
  },
};
</script>

<i18n>
{
  "fr": {
    "teamName": "Nom d'équipe",
    "teamScore": "Points",
    "checkinStatus": "Checkin Effectué ?",
    "checkinOK": "OK"
  },
  "en": {
    "teamName": "Team Name",
    "teamScore": "Score",
    "checkinStatus": "Checkin Done ?",
    "checkinOK": "OK"
  }
}
</i18n>
