<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-container fluid grid-list-sm>
        <v-layout row wrap>
          <v-flex xs12>
            <v-switch
              v-model="isTemporary"
              :label="$t('switchIsTemporaryLabel')" />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="isTemporary === true"
          row
          wrap>
          <v-flex xs12 sm3>
            <date-picker
              :label="$t('startDateLabel')"
              :initialDate="startDate"
              v-model="startDate" />
          </v-flex>

          <v-flex xs12 sm3>
            <time-picker
              :label="$t('startTimeLabel')"
              :initialTime="startTimeString"
              v-model="startTime" />
          </v-flex>
          <v-flex xs12 sm3>
            <date-picker
              :label="$t('endDateLabel')"
              :initialDate="endDate"
              v-model="endDate" />
          </v-flex>

          <v-flex xs12 sm3>
            <time-picker
              :label="$t('endTimeLabel')"
              :initialTime="endTimeString"
              v-model="endTime" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

import DatePicker from '../base/DatePicker.vue';
import TimePicker from '../base/TimePicker.vue';

export default {
  components: {
    DatePicker,
    TimePicker,
  },

  mixins: [TitleSubtitle],

  props: {
    initialIsTemporaryValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialStartDate: {
      type: String,
      required: false,
      default: null,
    },
    initialEndDate: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    let startDate; let startTime = null; let endDate; let
      endTime = null;

    if (this.initialStartDate !== null) {
      const mt = moment(this.initialStartDate);
      startDate = mt.format();
      startTime = mt.format('HH:mm');
    }

    if (this.initialEndDate !== null) {
      const emt = moment(this.initialEndDate);
      endDate = emt.format();
      endTime = emt.format('HH:mm');
    }
    return {
      startDateValue: startDate,
      startTimeValue: startTime,
      endDateValue: endDate,
      endTimeValue: endTime,
      isTemporaryValue: this.initialIsTemporaryValue,

    };
  },
  computed: {
    startDate: {
      set(value) {
        this.startDateValue = this.mergeDateAndTime(value, this.startTime);
        this.triggerUpdate();
      },
      get() {
        if (this.startDateValue !== null) {
          return moment(this.startDateValue).format('YYYY-MM-DD');
        }
        return null;
      },
    },
    startTime: {
      set(value) {
        if (this.startDateValue !== null) {
          this.startDateValue = this.mergeDateAndTime(moment(this.startDateValue).format('YYYY-MM-DD'), value);
        }
        this.startTimeValue = value;
        this.triggerUpdate();
      },
      get() {
        if (this.startTimeValue === null) {
          if (this.startDateValue !== null) {
            return moment(this.startDateValue).format('HH:MM');
          }
          return null;
        }
        return this.startTimeValue;
      },
    },
    startTimeString() {
      if (this.startDateValue !== null) {
        return moment(this.startDateValue).format('HH:mm');
      }
      return null;
    },

    endDate: {
      set(value) {
        this.endDateValue = this.mergeDateAndTime(value, this.endTime);
        this.triggerUpdate();
      },
      get() {
        if (this.endDateValue !== null) {
          return moment(this.endDateValue).format('YYYY-MM-DD');
        }
        return null;
      },
    },
    endTimeString() {
      if (this.endDateValue !== null) {
        return moment(this.endDateValue).format('HH:mm');
      }
      return null;
    },
    endTime: {
      set(value) {
        if (this.endDateValue !== null) {
          this.endDateValue = this.mergeDateAndTime(moment(this.endDateValue).format('YYYY-MM-DD'), value);
        }
        this.endTimeValue = value;
        this.triggerUpdate();
      },

      get() {
        if (this.endTimeValue === null) {
          if (this.endDateValue !== null) {
            return moment(this.endDateValue).format('HH:mm');
          }
          return null;
        }
        return this.endTimeValue;
      },
    },
    isTemporary: {
      set(value) {
        this.isTemporaryValue = value;
        this.triggerUpdate();
      },
      get() {
        return this.isTemporaryValue;
      },
    },

    isComplete() {
      if (this.isTemporary === false) {
        return true;
      }
      if (this.startDate === null) {
        return false;
      }
      if (this.startTime === null) {
        return false;
      }
      if (this.endDate === null) {
        return false;
      }
      if (this.endTime === null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    mergeDateAndTime(dateString, timeString) {
      if (timeString !== null) {
        return moment(`${dateString} ${timeString}`).format();
      }
      return moment(dateString, 'YYYY-MM-DD').format();
    },
    triggerUpdate() {
      if (!this.isComplete) {
        return;
      }
      const payload = {
        isTemporary: this.isTemporary,
        startDate: this.startDateValue,
        endDate: this.endDateValue,
      };
      this.$emit('temporaryplacechanged', payload);
    },
  },

};
</script>
<i18n>
{
  "fr": {
    "switchIsTemporaryLabel": "Rendre ce lieu temporaire",
    "startDateLabel": "Date de début",
    "startTimeLabel": "Heure de début",
    "endDateLabel": "Date de fin",
    "endTimeLabel": "Heure de fin"
  },
  "en": {
    "switchIsTemporaryLabel": "Make this POI temporary",
    "startDateLabel": "Start date",
    "startTimeLabel": "Start time",
    "endDateLabel": "End date",
    "endTimeLabel": "End time"
  }
}
</i18n>
