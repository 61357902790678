<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>
    <v-list three-line>
      <v-divider />
      <draggable :options="{ forceFallback: true }" v-model="picturesList" group="pictures">
        <v-list-item
          v-for="(p, i) in picturesList"
          :key="i"
        >
          <v-img
            v-if="p.filename && p.filename !== '' && !p.isNew"
            :src="`${picturesURL}/${p.filename}`"
            class="mr-4"
            max-width="64"
            min-width="64" />

          <v-img
            v-else-if="p.file && Object.keys(filePreviewStore).includes(p.uuid)"
            :src="filePreviewStore[p.uuid]"
            class="mr-4"
            max-width="64"
            min-width="64" />

          <v-icon size="48" v-else>insert_photo</v-icon> &nbsp;&nbsp;
          <v-list-item-content @click="() => {}">
            <span
              class="text-uppercase font-weight-regular caption"
              v-text="p.author ? p.author : $t('labelUnknownAuthor')"
            />

            <div v-text="p.altText ? p.altText : $t('labelNoDescription')" />
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="openEditionDialog(p)">
              <v-icon color="secondary lighten-1">edit</v-icon>
            </v-btn>
            <v-btn icon @click="openDeletionDialog(p)">
              <v-icon color="primary lighten-1">delete</v-icon>
            </v-btn>
          </v-list-item-action>

        </v-list-item>
      </draggable>
    </v-list>

    <v-dialog v-model="deleteDialog" max-width="350">
      <v-card>
        <v-card-title class="headline">{{ $t('deleteDialogTitle') }}</v-card-title>
        <v-card-text>
          {{ $t('deleteDialogSubtitle') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('deleteDialogCancelBtn') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirmDeletion"
          >
            {{ $t('deleteDialogConfirmBtn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      max-width="600">
      <picture-editor
        :new-picture="newPicture"
        :existing-description="currentPicture ? currentPicture.altText : null"
        :existing-author="currentPicture ? currentPicture.author : null"
        :existing-uuid="currentPicture ? currentPicture.uuid : null"
        :existing-order="currentPicture ? currentPicture.order : null"
        :existing-file="currentPicture ? currentPicture.filename : null"
        :url="currentPicture ? `${picturesURL}/${currentPicture.filename}` : ''"
        @save="savePicture"
        @close="closeEditionDialog" />
    </v-dialog>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="secondary" @click="openNewDialog">{{ $t('btnAddPicture') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import PictureEditor from './PictureEditor.vue';

import cfg from '../../../config';

export default {
  components: {
    PictureEditor,
    draggable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    pictures: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPicture: null,
      deleteDialog: false,
      editDialog: false,
      picturesValue: null,
      newPicture: false,
      filePreviewStore: {},
    };
  },
  computed: {
    destinationID() {
      return this.$route.params.siteId;
    },
    picturesURL() {
      return `${cfg.baseUrl}${cfg.imagesRoot}/${this.destinationID}`;
    },
    picturesList: {
      get() {
        const sorter = (a, b) => a.order - b.order;
        if (this.picturesValue === null) {
          const picList = [...this.pictures];
          return picList.sort(sorter);
        }
        const updatedPicList = [...this.picturesValue];
        return updatedPicList.sort(sorter);
      },
      set(value) {
        let order = 1;
        const updatedPics = [];
        value.forEach((p) => {
          const newPic = { ...p };
          newPic.order = order;
          updatedPics.push(newPic);
          order += 1;
        });
        this.picturesValue = updatedPics;
        this.$emit('updatepictures', this.picturesList);
      },
    },
  },
  methods: {
    openNewDialog() {
      this.newPicture = true;
      this.editDialog = true;
    },
    openEditionDialog(picture) {
      this.newPicture = false;
      this.currentPicture = picture;
      this.editDialog = true;
    },
    closeEditionDialog() {
      this.editDialog = false;
      this.currentPicture = null;
    },
    openDeletionDialog(picture) {
      this.currentPicture = picture;
      this.deleteDialog = true;
    },
    closeDeletionDialog() {
      this.currentPicture = null;
      this.deleteDialog = false;
    },
    confirmDeletion() {
      this.deleteDialog = false;
      const newList = this.picturesList.filter((p) => {
        if (this.currentPicture === null) {
          return true;
        }
        if (p.uuid !== this.currentPicture.uuid) {
          return true;
        }
        return false;
      });
      const sorter = (a, b) => a.order - b.order;
      newList.sort(sorter);
      const newListWithoutGaps = [];

      // remove order gaps from the remaining pictures
      let newOrder = 1;
      newList.forEach((p) => {
        const newPlace = { ...p };
        newPlace.order = newOrder;
        newListWithoutGaps.push(newPlace);
        newOrder += 1;
      });
      this.picturesList = newListWithoutGaps;
      if (!this.currentPicture.isNew) {
        this.$emit('deletepicture', this.currentPicture);
      }
      this.currentPicture = null;
    },
    savePicture(pictureData) {
      this.newPicture = false;
      const newPicture = { ...pictureData };
      if (newPicture.isNew === true) {
        newPicture.order = this.picturesList.length + 1;
        if (newPicture.file) {
          this.readLocalFile(newPicture.file, newPicture.uuid);
        }
      }
      const updatedPictList = [
        ...this.picturesList.filter((p) => p.uuid !== pictureData.uuid),
        newPicture,
      ];
      const sorter = (a, b) => a.order - b.order;
      this.picturesList = updatedPictList.sort(sorter);
      this.$emit('updatepictures', this.picturesList);
      this.editDialog = false;
      this.currentPicture = null;
    },
    readLocalFile(file, uuid) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.$set(this.filePreviewStore, uuid, reader.result);
      });
      reader.readAsDataURL(file);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "labelUnknownAuthor": "Auteur inconnu",
    "labelNoDescription": "Pas de description",
    "deleteDialogTitle": "Supprimer cette photo ?",
    "deleteDialogSubtitle": "Cette action est irréversible",
    "deleteDialogCancelBtn": "Ne pas supprimer",
    "deleteDialogConfirmBtn": "Supprimer",
    "btnAddPicture": "Ajouter une photo"
  },
  "en": {
    "labelUnknownAuthor": "Unknown author",
    "labelNoDescription": "No description",
    "deleteDialogTitle": "delete this picture?",
    "deleteDialogSubtitle": "This cannot be undone",
    "deleteDialogCancelBtn": "Cancel",
    "deleteDialogConfirmBtn": "Delete",
    "btnAddPicture": "Add a picture"
  }
}
</i18n>
