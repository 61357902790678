<template>
  <v-container
    fluid
    v-if="site">
    <v-spacer />
    <v-row>
      <v-col>
        <h1 class="text-h2">{{ $t('title') }}</h1>
      </v-col>
    </v-row>
    <v-row wrap>

      <v-col xs="12" sm="12" md="6" lg="4" xl="3" v-for="t in tours" :key="t.id">
        <tour-card :tour="t" />
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        color="accent"
        dark
        fab
        fixed
        bottom
        right
        :to="{ name: 'NewTourEditor', params: { siteId: siteId, site: site } }">
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import * as at from '../store/actionTypes';

import TourCard from '../components/tour/TourCard.vue';

export default {
  components: { TourCard },
  computed: {
    siteId() {
      return this.$route.params.siteId;
    },
    ...mapState({
      tours: (state) => state.tours.toursCollection,
    }),
    ...mapGetters('auth', [
      'token',
    ]),
    site() {
      return this.$store.getters['site/allSites'][this.siteId];
    },
  },
  methods: {
    ...mapActions({ fetchTours: at.FETCH_TOURS }),
  },
  async mounted() {
    try {
      await this.fetchTours({ siteId: this.siteId, token: this.token });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<i18n>
{
  "fr": {
    "title": "Les circuits"
  },
  "en": {
    "title": "Tours"
  }
}
</i18n>
