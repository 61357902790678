<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
      <v-container fluid grid-list-sm>
        <v-layout row wrap>
          <v-flex xs12>
            <v-switch
              v-model="isVip"
              :label="$t('isVipSwitchLabel')" />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="isVip === true"
          row
          wrap>
          <v-flex xs9>
            <v-slider
              v-model="points"
              :min="0"
              :max="12000"
              :step="1"
              :label="$t('labelPoints')"
              thumb-label="always"
            />
          </v-flex>

          <v-flex xs3>
            <v-text-field
              v-model="points"
              class="mt-0"
              type="number"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TitleSubtitle from '../../mixins/TitleSubtitle.vue';

export default {
  mixins: [TitleSubtitle],
  props: {
    initialIsVipValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialPoints: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isVip: this.initialIsVipValue,
      points: this.initialPoints,
    };
  },
  watch: {
    points() {
      this.triggerUpdate();
    },
    isVip() {
      this.triggerUpdate();
    },
  },

  methods: {
    triggerUpdate() {
      const payload = {
        requiresMinimumBalance: this.isVip,
        requiredBalance: this.points,
      };
      this.$emit('onvipplacechanged', payload);
    },
  },
};
</script>
<i18n>
{
  "fr": {
    "isVipSwitchLabel": "Ceci est un lieu VIP ?",
    "labelPoints": "Points"
  },
  "en": {
    "isVipSwitchLabel": "is this a VIP POI?",
    "labelPoints": "Points"
  }
}
</i18n>
