<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-if="shouldDisplayMenu === true"
        v-bind="attrs"
        v-on="on"
        @click="displayAddTeamDialog = true"
      >
        <v-list-item-title>{{ $t('addTeamDialogBtn') }}</v-list-item-title>
      </v-list-item>
    </template>
    <add-team-dialog
      v-if="organization"
      :game-id="game.id"
      :destination-id="game.destinationId"
      :teams-length="teamsLength"
      :organization-credit-balance="organization.creditBalance"
      :organization-use-credits="organization.useCredits"
      :game-is-test="game.isTest"
      :game="game"
      @close="dialog = false; $emit('close')"
      @teamAdded="$emit('teamAdded')"
    />
  </v-dialog>
</template>

<script>
import AddTeamDialog from '../AddTeam.vue';

export default {
  components: {
    AddTeamDialog,
  },
  props: {
    shouldDisplayMenu: {
      type: Boolean,
      required: true,
    },
    game: {
      type: Object,
      required: true,
    },
    teamsLength: {
      type: Number,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<i18n>
  {
    "fr": {
      "addTeamDialogBtn": "Ajouter une équipe"
    },
    "en": {
      "addTeamDialogBtn": "Add a team"
    }
  }
  </i18n>
